import React from 'react';
import SanitizerOutlinedIcon from '@mui/icons-material/SanitizerOutlined';
import { MdDevices, MdOutlineStroller } from 'react-icons/md';
import { IoShirtOutline } from 'react-icons/io5';
import { GiGardeningShears } from 'react-icons/gi';
import { FiWatch } from 'react-icons/fi';
import { BsBicycle, BsDisc, BsHouse } from 'react-icons/bs';
import { FaChessPawn } from 'react-icons/fa';
import {
  AiOutlineCar,
  AiOutlinePartition,
  AiOutlineDash,
} from 'react-icons/ai';
import { icon } from '../../theme/theme';

const styles = {
  width: icon.fontSize.medium,
  height: icon.fontSize.medium,
  color: icon.colorLight,
};

function renderCategoryIcon(category: string): JSX.Element {
  switch (category) {
    case 'Electronics':
      return <MdDevices style={styles} />;
    case 'Fashion & Accessories':
      return <IoShirtOutline style={styles} />;
    case 'Home & Garden':
      return <GiGardeningShears style={styles} />;
    case 'Baby & Toddler':
      return <MdOutlineStroller style={styles} />;
    case 'Jewellery & Watches':
      return <FiWatch style={styles} />;
    case 'Health & Beauty':
      return <SanitizerOutlinedIcon sx={styles} />;
    case 'Sport & Leisure':
      return <BsBicycle style={styles} />;
    case 'Toys & Games':
      return <FaChessPawn style={styles} />;
    case 'Media':
      return <BsDisc style={styles} />;
    case 'Motors':
      return <AiOutlineCar style={styles} />;
    case 'Property':
      return <BsHouse style={styles} />;
    case 'Services':
      return <AiOutlinePartition style={styles} />;
    case 'Other':
      return <AiOutlineDash style={styles} />;

    default:
      return <AiOutlineDash style={styles} />;
  }
}

export default renderCategoryIcon;
