import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyDialog from '../../components/MyDialog';
import MyCloseIcon from '../../components/MyCloseIcon';
import MyList, {
  MyCategoryListItem,
  MyListItemText,
} from '../../components/MyList';
import { icon } from '../../theme/theme';

type SortByDialogProps = {
  open: boolean;
  onClose: () => void;
  options: string[];
  selectedOption: string | undefined;
  onSelectOption: (option: string) => void;
};

const SortByDialog = ({
  open,
  onClose,
  options,
  selectedOption,
  onSelectOption,
}: SortByDialogProps): JSX.Element => (
  <MyDialog open={open} onClose={onClose}>
    <MyAppBar
      leftSideRender={() => <div />}
      middleRender={() => <Typography variant="h5">Sort by</Typography>}
      rightSideRender={() => (
        <AppBarIconWrapper justifyContent="flex-end">
          <MyIconButton sx={{ marginRight: '-8px' }} onClick={onClose}>
            <MyCloseIcon />
          </MyIconButton>
        </AppBarIconWrapper>
      )}
    />

    <Box className="SortByDialog">
      <MyList>
        <MyCategoryListItem
          secondaryAction={
            selectedOption === '' ? (
              <CheckIcon
                sx={{ fontSize: icon.fontSize.small, color: icon.color }}
              />
            ) : (
              <div />
            )
          }
          onClick={() => onSelectOption('')}
        >
          <MyListItemText>
            <Box
              fontWeight={
                selectedOption === '' ? 'bold !important' : 'normal !important'
              }
              sx={{ padding: '1px 0' }}
            >
              Closest first
            </Box>
          </MyListItemText>
        </MyCategoryListItem>
        {options.map((option) => (
          <MyCategoryListItem
            key={option}
            secondaryAction={
              option === selectedOption ? (
                <CheckIcon
                  sx={{ fontSize: icon.fontSize.small, color: icon.color }}
                />
              ) : (
                <div />
              )
            }
            onClick={() => onSelectOption(option)}
          >
            <MyListItemText>
              <Box
                fontWeight={
                  option === selectedOption
                    ? 'bold !important'
                    : 'normal !important'
                }
                sx={{ padding: '1px 0' }}
              >
                {option}
              </Box>
            </MyListItemText>
          </MyCategoryListItem>
        ))}
      </MyList>
    </Box>
  </MyDialog>
);

export default SortByDialog;
