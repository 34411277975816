import React from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { badge } from '../../theme/theme';

const MyBadge = (props: BadgeProps): JSX.Element => (
  <Badge
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    color="primary"
    sx={{
      '& .MuiBadge-badge': {
        color: badge.color,
        padding: badge.padding,
        fontSize: '12px',
      },
    }}
  />
);

export default MyBadge;
