import React from 'react';
import { Select, SelectProps } from '@mui/material';
import { select, borderRadius } from '../../theme/theme';

const MySelect = (props: SelectProps<string>): JSX.Element => (
  <Select
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      width: '100% !important',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: select.border.color,
        borderRadius,
      },
    }}
    MenuProps={{
      sx: {
        '& .MuiList-root': {
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
        },
        '& .MuiMenuItem-root': {
          fontSize: '15px !important',
          padding: '16px !important',
        },
      },
    }}
    inputProps={{
      sx: {},
    }}
  />
);

MySelect.displayName = 'MySelect';

export default MySelect;
