import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PageLayout from '../../components/PageLayout';
import SettingsList from './SettingsList';
import EmailNotificationsContainer from './EmailNotificationsContainer';
import PrivacyNSafetyContainer from './PrivacyNSafetyContainer';

const SettingsPage = (): JSX.Element => (
  <PageLayout>
    <Switch>
      <Route exact path="/settings">
        <SettingsList />
      </Route>
      <Route path="/settings/privacy-safety">
        <PrivacyNSafetyContainer />
      </Route>
      <Route path="/settings/email-notifications">
        <EmailNotificationsContainer />
      </Route>
    </Switch>
  </PageLayout>
);

export default SettingsPage;
