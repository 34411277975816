import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DrawerProps } from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import useDialogsManager from '../../hooks/useDialogsManager';
import useSecurity from '../../hooks/useSecurity';
import useBadgeCount from '../../hooks/useBadgeCount';
import { Profile } from '../../types/Profile';
import { Need } from '../../types/Need';
import { State } from '../../store/types';
import MyDrawer from './MyDrawer';

type MyDrawerContainerProps = {
  open: boolean;
  onClose: DrawerProps['onClose'];
  anchor: DrawerProps['anchor'];
};

const MyDrawerContainer = ({
  open,
  onClose,
  anchor,
}: MyDrawerContainerProps): JSX.Element => {
  const badgeCount = useBadgeCount();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { loggedIn, updateLoggedIn } = useSecurity();
  const profile = useSelector<State, Profile | null>((state) => state.profile);
  const {
    openSignupDialog,
    openLoginDialog,
    openCreateNewNeedDialog,
    openSearchMoneyDialog,
    openSearchMoneyByProductDialog,
  } = useDialogsManager();

  const handleLinkClick =
    (link: string) => (e: React.MouseEvent<HTMLLIElement>) => {
      history.push(link);
      if (onClose) onClose(e, 'backdropClick');
    };

  const handleSignupLinkClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
    if (openSignupDialog) {
      openSignupDialog();
    }
    if (onClose) onClose(e, 'backdropClick');
  };

  const handleLoginLinkClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
    if (openLoginDialog) {
      openLoginDialog();
    }
    if (onClose) onClose(e, 'backdropClick');
  };

  const handleLogoutLinkClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
    if (updateLoggedIn) {
      updateLoggedIn(false);
    }
    if (onClose) onClose(e, 'backdropClick');
  };

  const handleCreateNeedLinkClick: React.MouseEventHandler<HTMLLIElement> = (
    e,
  ) => {
    if (!loggedIn) {
      if (openLoginDialog) {
        openLoginDialog();
      }
    } else if (openCreateNewNeedDialog) {
      openCreateNewNeedDialog((created: Need) =>
        history.push(`/need/${created.ID}`),
      );
    }
    if (onClose) onClose(e, 'backdropClick');
  };

  const handleSearchButtonClick: React.MouseEventHandler<HTMLLIElement> = (
    e,
  ) => {
    if (openSearchMoneyDialog) {
      openSearchMoneyDialog();
    }
    if (onClose) onClose(e, 'backdropClick');
  };

  const handleSearchByProductButtonClick: React.MouseEventHandler<HTMLLIElement> =
    (e) => {
      if (openSearchMoneyByProductDialog) {
        openSearchMoneyByProductDialog();
      }
      if (onClose) onClose(e, 'backdropClick');
    };

  const theme = useTheme();
  const isMediumDevice = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MyDrawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      profile={profile}
      handleLinkClick={handleLinkClick}
      handleSignupLinkClick={handleSignupLinkClick}
      handleLoginLinkClick={handleLoginLinkClick}
      handleLogoutLinkClick={handleLogoutLinkClick}
      handleCreateNeedLinkClick={handleCreateNeedLinkClick}
      handleSearchButtonClick={handleSearchButtonClick}
      handleSearchByProductButtonClick={handleSearchByProductButtonClick}
      isMediumDevice={isMediumDevice}
      path={path}
      badgeCount={badgeCount}
    />
  );
};

export default MyDrawerContainer;
