import React, { useState, useRef } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import SendIcon from '@mui/icons-material/Send';
import MyIconButton from '../../components/MyIconButton';
import { colors, searchInput, icon } from '../../theme/theme';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderColor: searchInput.borderColor,
  borderWidth: 'thin !important',
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  '& .MuiInputBase-root': {
    width: '100%',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: '10px 8px 10px 8px',
    transition: theme.transitions.create('width'),
    width: '100%',
    fontSize: theme.typography.body1.fontSize,
  },
}));

type InputFieldProps = {
  onSend: (text: string) => void;
  onTyping: (typing: boolean) => void;
};

const InputField = ({ onSend, onTyping }: InputFieldProps): JSX.Element => {
  const [text, setText] = useState('');
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  function restartTimeout(callback: () => void) {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(callback, 4000);
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.currentTarget.value);
    onTyping(true);
    restartTimeout(() => onTyping(false));
  };

  const submit = () => {
    if (text !== '') {
      onTyping(false);
      onSend(text);
      setText('');
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    submit();
  };

  const handleSendIconClick = () => {
    submit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems="center">
        <Grid item flexGrow={1}>
          <Search>
            <StyledInputBase
              placeholder="Aa"
              inputProps={{ 'aria-label': 'input' }}
              onChange={handleChange}
              value={text}
            />
          </Search>
        </Grid>
        <Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '34px',
              height: '34px',
              marginLeft: '4px',
            }}
          >
            <MyIconButton onClick={handleSendIconClick}>
              <SendIcon
                sx={{ fontSize: icon.fontSize.medium, color: colors.primary }}
              />
            </MyIconButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default InputField;
