import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MyList, { MyListItem, MyListItemText } from '../../components/MyList';
import MyCheckbox from '../../components/MyCheckbox';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import Spinner from '../../components/Spinner';
import PageSection from '../../components/PageSection';
import { Settings } from '../../types/Settings';
import { divider, colors, borderRadius } from '../../theme/theme';

type EmailNotificationsProps = {
  settings: Settings | null;
  loading: boolean;
  error: boolean;
  updateSettings: (updated: Settings) => void;
};

const EmailNotifications = ({
  settings,
  loading,
  error,
  updateSettings,
}: EmailNotificationsProps): JSX.Element => {
  const canShowSpinner = () => !settings && (loading || !loading) && !error;
  const canShowError = () => !settings && !loading && error;

  if (canShowSpinner()) return <Spinner />;

  if (canShowError()) return <SomethingWentWrongFeedback />;

  if (settings) {
    return (
      <Box>
        <Helmet>
          <title>Email notifications | Bonvih</title>
        </Helmet>
        <PageSection
          title="Email notifications"
          backButtonEnabled
          backgroundColor
        >
          <Box
            sx={{
              paddingBottom: '12px',
              backgroundColor: '#FFFFFF',
              borderRadius,
            }}
          >
            <Box padding="12px 16px">
              <Typography variant="h5" variantMapping={{ h5: 'h1' }}>
                Updates on your money
              </Typography>
            </Box>
            <Box padding="12px 16px">
              <Typography
                variant="body2"
                sx={{
                  color: colors.grey,
                }}
              >
                Get all the important updates on the money you have listed.
              </Typography>
            </Box>
            <MyList>
              <MyListItem
                secondaryAction={
                  <MyCheckbox
                    checked={settings.newPropositionEmail}
                    onChange={(e) => {
                      updateSettings({
                        ...settings,
                        newPropositionEmail: e.target.checked,
                      });
                    }}
                  />
                }
              >
                <MyListItemText>
                  <Typography variant="body1">
                    Propositions made to you
                  </Typography>
                </MyListItemText>
              </MyListItem>
              <MyListItem
                secondaryAction={
                  <MyCheckbox
                    checked={settings.newChatMessageEmail}
                    onChange={(e) => {
                      updateSettings({
                        ...settings,
                        newChatMessageEmail: e.target.checked,
                      });
                    }}
                  />
                }
              >
                <MyListItemText>
                  <Typography variant="body1">
                    Chat messages sent to you
                  </Typography>
                </MyListItemText>
              </MyListItem>
            </MyList>
            <Divider sx={{ borderColor: divider.color, margin: '4px 0' }} />
            <Box padding="12px 16px">
              <Typography variant="h5" variantMapping={{ h5: 'h1' }}>
                News from Bonvih
              </Typography>
            </Box>
            <Box padding="12px 16px">
              <Typography
                variant="body2"
                sx={{
                  color: colors.grey,
                }}
              >
                {/* Fulfill your needs faster by receiving tips and limited offers. */}
                Spend your money wisely by receiving tips.
              </Typography>
            </Box>
            <MyList>
              <MyListItem
                secondaryAction={
                  <MyCheckbox
                    checked={settings.emailNewsLetter}
                    onChange={(e) => {
                      updateSettings({
                        ...settings,
                        emailNewsLetter: e.target.checked,
                      });
                    }}
                  />
                }
              >
                <MyListItemText>
                  <Typography variant="body1">Bonvih Update</Typography>
                </MyListItemText>
              </MyListItem>
              {/* <MyListItem
              secondaryAction={
                <MyCheckbox
                  checked={settings.emailPromo}
                  onChange={(e) => {
                    updateSettings({
                      ...settings,
                      emailPromo: e.target.checked,
                    });
                  }}
                />
              }
            >
              <MyListItemText>
                <Typography variant="body1">Promotions & discounts</Typography>
              </MyListItemText>
            </MyListItem> */}
            </MyList>
            {/* <Divider sx={{ borderColor: divider.color, margin: '4px 0' }} />
          <Box padding="12px 16px">
            <Typography variant="h5" variantMapping={{ h5: 'h1' }}>
              Promotional Content
            </Typography>
          </Box>
          <Box padding="12px 16px">
            <Typography
              variant="body2"
              sx={{
                color: colors.grey,
              }}
            >
              Promotional content allows us to keep our service free of charge.
            </Typography>
          </Box>
          <MyList>
            <MyListItem
              secondaryAction={
                <MyCheckbox
                  checked={settings.emailAds}
                  onChange={(e) => {
                    updateSettings({
                      ...settings,
                      emailAds: e.target.checked,
                    });
                  }}
                />
              }
            >
              <MyListItemText>
                <Typography
                  variant="body1"
                  sx={{
                    paddingRight: '58px',
                  }}
                >
                  I want Bonvih to send me promotional content from them and
                  their partners. We will never share your email address with
                  anyone.
                </Typography>
              </MyListItemText>
            </MyListItem>
          </MyList> */}
          </Box>
        </PageSection>
      </Box>
    );
  }

  return <div />;
};

export default EmailNotifications;
