import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import MyTextField from '../../components/MyTextField';
import MyDialog from '../../components/MyDialog';
import { Profile } from '../../types/Profile';

type EditProfileDialog = {
  open: boolean;
  onClose: () => void;
  profile: Profile;
  handleSaveButtonClick: (firstname: string) => void;
  error: boolean;
};

const EditProfileDialog = ({
  open,
  onClose,
  profile,
  handleSaveButtonClick,
  error,
}: EditProfileDialog): JSX.Element => {
  const { control, formState, getValues } = useForm<{ firstname: string }>({
    mode: 'onChange',
  });
  const { errors, isDirty, isValid } = formState;

  function handleCloseIconClick() {
    onClose();
  }

  return (
    <MyDialog open={open} onClose={onClose}>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <CloseIcon sx={{ fontSize: '20px' }} />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography variant="h5" variantMapping={{ h5: 'h1' }}>
            Edit profile
          </Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              disabled={!isDirty || !isValid || Boolean(error)}
              onClick={() =>
                handleSaveButtonClick(getValues().firstname.trim())
              }
            >
              Save
            </MyButton>
          </Box>
        )}
      />

      {error && <SomethingWentWrongFeedback />}

      {!error && (
        <Box sx={{ padding: '12px 16px' }}>
          <Controller
            control={control}
            name="firstname"
            defaultValue={profile.firstname}
            rules={{
              required: 'Name is required',
              maxLength: {
                value: 50,
                message: 'Too many characters... Limit is 50',
              },
            }}
            render={({ field }) => (
              <MyTextField
                id="input-firstname"
                label="Name"
                error={!!errors.firstname}
                helperText={errors.firstname && errors.firstname.message}
                {...field} // eslint-disable-line react/jsx-props-no-spreading
              />
            )}
          />
        </Box>
      )}
    </MyDialog>
  );
};

export default EditProfileDialog;
