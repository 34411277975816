import React from 'react';
import {
  MyListItem,
  MyListItemText,
  MyListItemAvatar,
} from '../../components/MyList';
import { ItemAvatar } from '../../components/MyAvatars';
import MyButton from '../../components/MyButton';
import { Item } from '../../types/Item';
import {
  addPhotoDimensionsToCloudfrontURL,
  convertS3ToCloudfrontURL,
} from '../../aws/s3';

type Props = {
  item: Item;
  proposing: boolean;
  buttonDisabled: boolean;
  onPropose: () => void;
};

const ProposeItem = ({
  item,
  proposing,
  buttonDisabled,
  onPropose,
}: Props): JSX.Element => (
  <MyListItem
    key={item.ID}
    secondaryAction={
      <MyButton
        size="small"
        variant={proposing ? 'outlined' : 'contained'}
        onClick={onPropose}
        disabled={buttonDisabled}
      >
        {proposing ? 'Proposing' : 'Propose'}
      </MyButton>
    }
  >
    <MyListItemAvatar>
      <ItemAvatar
        src={
          item.photos.length > 0
            ? addPhotoDimensionsToCloudfrontURL(
                '48',
                '48',
                convertS3ToCloudfrontURL(item.photos[0].url),
              )
            : ''
        }
      />
    </MyListItemAvatar>
    <MyListItemText>{item.name}</MyListItemText>
  </MyListItem>
);

export default ProposeItem;
