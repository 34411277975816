import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import worker from './mocks/backend-app/browser';
import './index.css';
import { environmentVariablesOK } from './constants';
import logger from './logger';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// import reportWebVitals from './reportsWebVitals';

if (process.env.REACT_APP_MSW_ENABLE === 'true') {
  worker.start();
}

if (!environmentVariablesOK()) {
  logger.log({
    message: 'Failed to start app',
    reason: 'All environment variables are required!',
  });
  throw new Error('All environment variables are required!');
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
