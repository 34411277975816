import React, { useState, useEffect, ReactNode } from 'react';
import useFetch from 'use-http';
import { useSelector } from 'react-redux';
import { State } from '../../store/types';
import { Proposition } from '../../types/Proposition';
import { Profile } from '../../types/Profile';
import { BASE_URL } from '../../constants';
import context from './context';

type ProviderProps = {
  children: ReactNode;
};

const Provider = ({ children }: ProviderProps): JSX.Element => {
  const profile = useSelector<State, Profile | null>((state) => state.profile);

  const [propositions, setPropositions] = useState<Proposition[] | null>(null);
  const [reloadPropositions, setReloadPropositions] = useState(false);

  const { get, loading, error, response } = useFetch(BASE_URL);

  async function getPropositions() {
    if (profile) {
      const data = await get(`/profiles/${profile.ID}/propositions`);
      if (response.ok) {
        setPropositions(data);
      }
    }
  }

  useEffect(() => {
    getPropositions();
  }, [reloadPropositions]);

  function reload() {
    setReloadPropositions(!reloadPropositions);
  }

  return (
    <context.Provider
      value={{ propositions, loading, error: Boolean(error), reload }}
    >
      {children}
    </context.Provider>
  );
};

export default Provider;
