import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import {
  AlertFeedback,
  SomethingWentWrongFeedback,
} from '../../components/Feedback';
import PageLayout from '../../components/PageLayout';
import PageSection from '../../components/PageSection';
import Spinner from '../../components/Spinner';
import PropositionCard from '../../components/MyCards/PropositionCard';
import { Proposition } from '../../types/Proposition';
import formatMoney from '../../utils/formatMoney';
import ChatContainer from './ChatContainer';
import { colors, borderRadius } from '../../theme/theme';

type PropositionPageProps = {
  proposition: Proposition;
  loading: boolean;
  error: boolean;
  propositionNotFound: boolean;
  iAmProposer: boolean;
  iAmNeeder: boolean;
  hasNewMessages: boolean;
  updateHasNewMessages: (newValue: boolean) => void;
  handleNotInterested: () => void;
};

const PropositionPage = ({
  proposition,
  loading,
  error,
  propositionNotFound,
  iAmProposer,
  iAmNeeder,
  hasNewMessages,
  updateHasNewMessages,
  handleNotInterested,
}: PropositionPageProps): JSX.Element => {
  const theme = useTheme();

  const canShowSpinner = () =>
    (!loading || loading) &&
    proposition === null &&
    !propositionNotFound &&
    !error;
  const canShowError = () =>
    !loading && proposition === null && !propositionNotFound && error;
  const canShowNotFound = () =>
    !loading && proposition === null && propositionNotFound && error;
  const canShowProposition = () =>
    !loading && proposition !== null && !propositionNotFound && !error;

  return (
    <PageLayout>
      <Helmet>
        <title>Proposing | Bonvih</title>
      </Helmet>
      <PageSection title="Proposition" backButtonEnabled backgroundColor>
        {canShowSpinner() && <Spinner />}

        {canShowNotFound() && (
          <AlertFeedback
            renderIcon={() => (
              <SentimentVeryDissatisfiedIcon sx={{ fontSize: '40px' }} />
            )}
            title="Proposition not found"
            subtitle="The proposition you requested does not exist or has been deleted."
          />
        )}

        {canShowError() && <SomethingWentWrongFeedback />}

        {canShowProposition() && (
          <Box>
            <Helmet>
              <title>
                {`${proposition.item.name} proposed for ${formatMoney(
                  proposition.need.offer,
                  proposition.need.currency,
                )}`}
              </title>
            </Helmet>

            {hasNewMessages && (
              <Box marginBottom="12px">
                <Alert
                  severity="info"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: theme.typography.body1.fontSize,
                    padding: '12px 16px',
                    '& > .MuiAlert-icon': { padding: '0' },
                    '& > .MuiAlert-message': { padding: '0' },
                    '& > .MuiAlert-action': { padding: '0', marginRight: '0' },
                    '& a': {
                      textDecoration: 'none',
                      color: colors.black,
                    },
                    color: colors.black,
                  }}
                  action={
                    <a href="#chat">
                      <Button
                        color="inherit"
                        size="small"
                        sx={{
                          fontSize: theme.typography.button.fontSize,
                          padding: '0 16px !important',
                          minHeight: '32px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        GO TO CHAT
                      </Button>
                    </a>
                  }
                >
                  You have unread messages!
                </Alert>
              </Box>
            )}

            <Box
              sx={{
                background: '#FFFFFF',
                borderRadius,
              }}
            >
              <PropositionCard
                proposition={proposition}
                iAmProposer={iAmProposer}
                iAmNeeder={iAmNeeder}
                handleNotInterested={handleNotInterested}
              />
            </Box>
          </Box>
        )}
      </PageSection>
      {(iAmProposer || iAmNeeder) && !loading && false && (
        <PageSection title="Chat" backgroundColor>
          <Box id="chat">
            <ChatContainer
              proposition={proposition}
              updateHasNewMessages={updateHasNewMessages}
            />
          </Box>
        </PageSection>
      )}
    </PageLayout>
  );
};

export default PropositionPage;
