import React from 'react';
import useFetch from 'use-http';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import { MyPasswordField } from '../../components/MyTextField';
import Spinner from '../../components/Spinner';
import MyCloseIcon from '../../components/MyCloseIcon';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { SignupData } from '../../types/SignupData';
import { BASE_URL } from '../../constants';
import { dialog, colors } from '../../theme/theme';
import logger from '../../logger';
import useGeoLocation from '../../hooks/useGeoLocation';

type FormValues = {
  password: string;
};

type VerifyEmailProps = {
  goNext: () => void;
  onClose: () => void;
};

const ChoosePassword = ({ goNext, onClose }: VerifyEmailProps): JSX.Element => {
  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;

  const { post, loading, error, response } = useFetch(BASE_URL);

  const { countryISOCode } = useGeoLocation();

  function getSignupDataFromCache(): SignupData | null {
    const signupData = localStorage.getItem('signupData');
    if (signupData === null) {
      return null;
    }

    return JSON.parse(signupData);
  }

  function storeSignupDataInCache(data: SignupData) {
    localStorage.setItem('signupData', JSON.stringify(data));
  }

  async function sendSignupData(data: SignupData) {
    await post(`/onboarding`, data);
    if (response.ok) {
      storeSignupDataInCache(data);
      goNext();
    } else {
      localStorage.removeItem('signupData');
    }
  }

  function buildSignupData(): SignupData {
    const signupData = getSignupDataFromCache();
    if (signupData === null) {
      logger.log({
        message: 'Failed to choose password during Signup',
        reason: 'No signup data in localstorage',
      });
      throw new Error('');
    }

    signupData.steps.push({
      name: 'choose_password',
      inputs: getValues(),
    });

    signupData.country = countryISOCode;

    return signupData;
  }

  function handleNextClick() {
    const signupData = buildSignupData();
    sendSignupData(signupData);
  }

  function handleCloseIconClick() {
    onClose();
  }

  return (
    <>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography variant="h5">You&apos;ll need a password</Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              disabled={!isDirty || !isValid || Boolean(error)}
              onClick={handleNextClick}
            >
              Next
            </MyButton>
          </Box>
        )}
      />

      {loading && <Spinner />}

      {error && !loading && <SomethingWentWrongFeedback />}

      {!error && !loading && (
        <>
          <Box sx={{ padding: dialog.input.padding }}>
            <Typography
              variant="body1"
              sx={{
                color: colors.grey,
              }}
            >
              Make sure it&apos;s 8 characters or more.
            </Typography>
          </Box>

          <Box sx={{ padding: dialog.input.padding }}>
            <Controller
              control={control}
              name="password"
              rules={{
                required: true,
                minLength: 8,
                maxLength: 64,
              }}
              defaultValue=""
              render={({ field }) => (
                <MyPasswordField
                  id="password-input"
                  label="Password"
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default ChoosePassword;
