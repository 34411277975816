import React from 'react';
import { Box } from '@mui/system';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MyList, {
  MyListItem,
  MyListItemText,
  MyListItemAvatar,
} from '../../../components/MyList';
import { ItemAvatar } from '../../../components/MyAvatars';
import Spinner from '../../../components/Spinner';
import Feedback, {
  SomethingWentWrongFeedback,
} from '../../../components/Feedback';
import MyIconButton from '../../../components/MyIconButton';
import { Profile } from '../../../types/Profile';
import { Item } from '../../../types/Item';
import { icon, colors } from '../../../theme/theme';
import {
  addPhotoDimensionsToCloudfrontURL,
  convertS3ToCloudfrontURL,
} from '../../../aws/s3';

type ItemsProps = {
  items: Item[] | null;
  loading: boolean;
  error: boolean;
  itsMe: () => boolean;
  profile: Profile;
  handleDeleteIconClick: (item: Item) => void;
};

const Items = ({
  items,
  loading,
  error,
  itsMe,
  profile,
  handleDeleteIconClick,
}: ItemsProps): JSX.Element => {
  if (loading) return <Spinner />;

  if (error) return <SomethingWentWrongFeedback />;

  if (items === null) return <Spinner />;

  if (items.length === 0) {
    if (itsMe()) {
      return (
        <Feedback
          title="You haven’t proposed any item yet"
          subtitle="When you propose items, it will show up here."
          // renderAction={() => (
          //   <MyButton size="large" onClick={handleCreateButtonClick}>
          //     Create item
          //   </MyButton>
          // )}
        />
      );
    }

    return (
      <Feedback
        title={`${profile.firstname} has not proposed any item yet`}
        subtitle="When he proposes items, it will show up here."
      />
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <MyList>
        {items
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (b.name > a.name) {
              return -1;
            }
            return 0;
          })
          .map((item) => (
            <MyListItem
              key={item.ID}
              secondaryAction={
                <Box display="flex" alignItems="center">
                  {/* <Box marginRight="4px">
                    <Box
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: icon.fontSize.large,
                          color: colors.grey,
                        },
                      }}
                    >
                      <MyIconButton onClick={() => handleSoldIconClick(item)}>
                        <MyCheckbox checked={item.sold} />
                      </MyIconButton>
                    </Box>
                  </Box> */}
                  <MyIconButton onClick={() => handleDeleteIconClick(item)}>
                    <DeleteOutlinedIcon
                      sx={{ fontSize: icon.fontSize.large, color: colors.grey }}
                    />
                  </MyIconButton>
                </Box>
              }
            >
              <MyListItemAvatar>
                <ItemAvatar
                  src={
                    item.photos.length > 0
                      ? addPhotoDimensionsToCloudfrontURL(
                          '48',
                          '48',
                          convertS3ToCloudfrontURL(item.photos[0].url),
                        )
                      : ''
                  }
                />
              </MyListItemAvatar>
              <MyListItemText>
                <Box
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  {item.name}
                </Box>
              </MyListItemText>
            </MyListItem>
          ))}
      </MyList>
    </Box>
  );
};

export default Items;
