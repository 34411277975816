import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetch from 'use-http';
import { Settings } from '../../types/Settings';
import { Profile } from '../../types/Profile';
import { State } from '../../store/types';
import { BASE_URL } from '../../constants';
import EmailNotifications from './EmailNotifications';

const EmailNotificationsContainer = (): JSX.Element => {
  const [reload, setReload] = useState(false);
  const profile = useSelector<State, Profile | null>((state) => state.profile);
  const [settings, setSettings] = useState<Settings | null>(null);

  const { get, put, cache, response, loading, error } =
    useFetch<Settings>(BASE_URL);

  async function getSettings() {
    const data = await get(`/profiles/${profile?.ID}/settings`);

    if (response.ok) {
      setSettings(data);
    }
  }

  async function updateSettings(updated: Settings) {
    await put(`/profiles/${profile?.ID}/settings`, updated);

    if (response.ok) {
      cache.clear();
      setReload(!reload);
    }
  }

  useEffect(() => {
    getSettings();
  }, [reload]);

  return (
    <EmailNotifications
      settings={settings}
      loading={loading}
      error={Boolean(error)}
      updateSettings={updateSettings}
    />
  );
};

export default EmailNotificationsContainer;
