import React from 'react';
import { Button, ButtonProps, useTheme } from '@mui/material';
import { colors } from '../../theme/theme';

const MyUnstyledButton = ({
  children,
  sx,
  ...rest
}: ButtonProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        ...sx,
        color: colors.black,
        padding: '0',
        textTransform: 'none',
        verticalAlign: 'unset',
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: 'unset',
        },
      }}
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Button>
  );
};

MyUnstyledButton.defaultProps = {
  sx: {},
};

export default MyUnstyledButton;
