import React, { useState, useEffect, ReactNode } from 'react';
import useFetch from 'use-http';
import { Box } from '@mui/system';
import Spinner from '../Spinner';
import { SomethingWentWrongFeedback } from '../Feedback';
import logger from '../../logger';
import { GEOIP_ENDPOINT } from '../../constants';
import { GeoLocation } from '../../types/GeoLocation';
import { bar } from '../../theme/theme';

type GeolocatorProps = {
  children: ReactNode;
};

type MaxMindData = {
  city?: {
    geonameId: number;
    names: string;
  };
  country: {
    isoCode: string;
    geonameId: number;
    names: string;
  };
  continent: {
    code: string;
    geonameId: number;
    names: string;
  };
  location: {
    accuracyRadius: number;
    latitude: number;
    longitude: number;
    timeZone: string;
  };
  postal?: {
    code: string;
  };
  registeredCountry: {
    isoCode: string;
    geonameId: number;
    isInEuropeanUnion: boolean;
    names: string;
  };
};

function adaptData(data: MaxMindData): GeoLocation {
  return {
    cityGeonameID: data.city?.geonameId,
    cityNames: JSON.stringify(data.city?.names),
    countryISOCode: data.country.isoCode,
    countryGeonameID: data.country.geonameId,
    countryNames: JSON.stringify(data.country.names),
    continentCode: data.continent.code,
    continentGeonameID: data.continent.geonameId,
    continentNames: JSON.stringify(data.continent.names),
    locationAccuracyRadius: data.location.accuracyRadius,
    locationLatitude: data.location.latitude,
    locationLongitude: data.location.longitude,
    locationTimeZone: data.location.timeZone,
    postalCode: data.postal?.code,
    registeredCountryISOCode: data.registeredCountry.isoCode,
    registeredCountryGeonameID: data.registeredCountry.geonameId,
    registeredCountryIsInEuropeanUnion:
      data.registeredCountry.isInEuropeanUnion,
    registeredCountryNames: JSON.stringify(data.registeredCountry.names),
  };
}

const Geolocator = ({ children }: GeolocatorProps): JSX.Element => {
  const [done, setDone] = useState(false);

  const {
    get: myPublicIPGet,
    response: myPublicIPReponse,
    error: myPublicIPError,
  } = useFetch(`https://ipv4.icanhazip.com`);

  const {
    get: geoIPGet,
    response: geoIPReponse,
    error: geoIPError,
  } = useFetch(GEOIP_ENDPOINT);

  async function handleGeoLocation() {
    const myPublicIP = await myPublicIPGet('/');

    if (myPublicIPReponse.ok) {
      const data = await geoIPGet(
        `?ip=${encodeURIComponent((myPublicIP as string).trim())}`,
      );

      if (geoIPReponse.ok) {
        sessionStorage.setItem('GeoLocation', JSON.stringify(adaptData(data)));
        setDone(true);
      } else {
        logger.log({
          message: 'Failed to get geoip',
          error: geoIPError as Error,
        });
        throw new Error('Failed to get geoip');
      }
    } else {
      logger.log({
        message: 'Failed to get public ip',
        error: myPublicIPError as Error,
      });
      throw new Error('Failed to get public ip');
    }
  }

  useEffect(() => {
    const geoLocationString = sessionStorage.getItem('GeoLocation');
    if (geoLocationString !== null) {
      setDone(true);
    } else {
      handleGeoLocation();
    }
  }, []);

  return (
    <>
      {!done && (
        <>
          {!geoIPError && !myPublicIPError && (
            <Box
              sx={{
                paddingTop: bar.height,
              }}
            >
              <Spinner />
            </Box>
          )}
          {(geoIPError || myPublicIPError) && <SomethingWentWrongFeedback />}
        </>
      )}

      {done && children}
    </>
  );
};

export default Geolocator;
