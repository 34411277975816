import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HomeIcon from '@mui/icons-material/Home';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
// import PersonIcon from '@mui/icons-material/Person';
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Profile } from '../../types/Profile';
import MyAppBar from '../MyAppBar';
import MyIconButton from '../MyIconButton';
import Footer from '../Footer';
import Logo from '../Logo';
import AppBarIconWrapper from '../AppBarIconWrapper';
import SearchField from '../SearchField';
import MyButton from '../MyButton';
import MyBadge from '../MyBadge';
import { BadgeCount } from '../../contexts/badgeCount/context';
import { appBar, icon, borderRadius } from '../../theme/theme';
import WhatsHappening from './WhatsHappeningContainer';

const AsideCard = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Box marginBottom="16px">
    <Box
      sx={{
        border: `1px solid #FFFFFF`,
        borderRadius,
        background: '#FFFFFF',
      }}
    >
      <Box padding="12px 16px">
        <Typography variant="h5">{title}</Typography>
      </Box>
      {children}
    </Box>
  </Box>
);

const iconStyles = {
  drawer: {
    fontSize: `${icon.fontSize.medium} !important`,
    cursor: 'pointer',
    color: icon.color,
  },
  bottomAppBar: {
    fontSize: `${icon.fontSize.xlarge} !important`,
    cursor: 'pointer',
    color: icon.color,
  },
};

type PageLayoutProps = {
  children: React.ReactNode;
  loggedIn: boolean;
  profile: Profile | null;
  isMediumDeviceUp: boolean;
  isLargeDeviceUp: boolean;
  handleMenuIconClick?: () => void | undefined;
  handleHomeIconClick?: () => void | undefined;
  handleCreateIconClick?: () => void | undefined;
  handleNotificationsIconClick?: () => void | undefined;
  handleChatIconClick?: () => void | undefined;
  handleCreateNeedButtonClick?: () => void | undefined;
  handleLoginButtonClick?: () => void | undefined;
  handleSearchIconClick?: () => void | undefined;
  badgeCount: BadgeCount;
  path: string;
};

const PageLayout = ({
  children,
  loggedIn,
  profile,
  isMediumDeviceUp,
  isLargeDeviceUp,
  handleMenuIconClick,
  handleHomeIconClick,
  handleCreateIconClick,
  handleNotificationsIconClick,
  handleChatIconClick,
  handleCreateNeedButtonClick,
  handleLoginButtonClick,
  handleSearchIconClick,
  badgeCount,
  path,
}: PageLayoutProps): JSX.Element => {
  function renderTitle() {
    switch (path) {
      case '/':
        return 'Bag Item & Service Purchases';

      case '/explore':
        return 'Explore';

      case '/login':
        return 'Login';

      case '/create-need':
        return 'Create a new need';

      case '/search':
        return 'Search';

      case '/profile/:id':
        if (profile) {
          return profile.firstname;
        }
        return '';

      case '/profile/:id/notifications':
        return 'Notifications';

      case '/profile/:id/chats':
        return 'Chats';

      case '/profile/:id/spending':
        return 'Spending';

      case '/profile/:id/spent':
        return 'Spent';

      case '/profile/:id/proposing':
        return 'Proposing';

      case '/settings':
        return 'Settings';

      default:
        return '';
    }
  }

  const canShowSidebar = () =>
    ![
      '/settings',
      '/settings/privacy-safety',
      '/settings/email-notifications',
      '/contact',
      '/terms-conditions',
      '/privacy-policy',
      '/cookie-policy',
      '/privacy-safety',
    ].includes(path);

  return (
    <Box>
      <Helmet>
        <title>{`WhoCanBuyMyStuff | ${renderTitle()}`}</title>
      </Helmet>

      <MyAppBar
        leftSideRender={() => (
          <AppBarIconWrapper justifyContent="flex-start">
            <Link to="/" style={{ color: 'unset', textDecoration: 'none' }}>
              <Logo />
            </Link>
          </AppBarIconWrapper>
        )}
        middleRender={() => <SearchField />}
        rightSideRender={() => (
          <AppBarIconWrapper justifyContent="flex-end">
            <Box display="flex">
              <Box marginLeft={appBar.spacingBetweenSearchInputAndCTA} />
              {!loggedIn && isMediumDeviceUp && (
                <MyButton
                  size="small"
                  variant="outlined"
                  onClick={handleLoginButtonClick}
                >
                  Sign in
                </MyButton>
              )}

              {loggedIn && isLargeDeviceUp && (
                <>
                  <MyButton size="small" onClick={handleCreateNeedButtonClick}>
                    For buyers
                  </MyButton>
                </>
              )}

              {loggedIn && isMediumDeviceUp && (
                <>
                  <Box marginLeft={appBar.spacingBetweenSearchInputAndCTA}>
                    <MyIconButton onClick={handleNotificationsIconClick}>
                      {badgeCount.unseenNotificationsCount > 0 && (
                        <MyBadge
                          badgeContent={badgeCount.unseenNotificationsCount}
                        >
                          <NotificationsNoneIcon sx={iconStyles.drawer} />
                        </MyBadge>
                      )}
                      {badgeCount.unseenNotificationsCount === 0 && (
                        <NotificationsNoneIcon sx={iconStyles.drawer} />
                      )}
                    </MyIconButton>
                  </Box>
                  <Box marginLeft={appBar.spacingBetweenIcons}>
                    <MyIconButton onClick={handleChatIconClick}>
                      {badgeCount.unseenMessagesCount > 0 && (
                        <MyBadge badgeContent={badgeCount.unseenMessagesCount}>
                          <ChatBubbleOutlineIcon sx={iconStyles.drawer} />
                        </MyBadge>
                      )}
                      {badgeCount.unseenMessagesCount === 0 && (
                        <ChatBubbleOutlineIcon sx={iconStyles.drawer} />
                      )}
                    </MyIconButton>
                  </Box>
                </>
              )}
              <Box
                marginLeft={appBar.spacingBetweenIcons}
                marginRight={appBar.menuIconNegativeMargin}
              >
                <MyIconButton onClick={handleMenuIconClick}>
                  <MenuIcon sx={iconStyles.drawer} />
                </MyIconButton>
              </Box>
            </Box>
          </AppBarIconWrapper>
        )}
      />

      <Container disableGutters maxWidth="lg">
        <Box display="flex" justifyContent="space-between">
          <Container disableGutters maxWidth="sm">
            <Box minHeight="100vh" marginBottom="100px">
              <Box
                sx={{
                  marginTop: {
                    sm: '12px',
                  },
                }}
              />
              {children}
            </Box>
          </Container>
          {isLargeDeviceUp && (
            <Box>
              <Box position="sticky" top={`calc(${appBar.height} + 12px)`}>
                <Box width="350px" marginRight="10px">
                  <Box minHeight="600px" height="600px" marginBottom="12px">
                    {canShowSidebar() && (
                      <>
                        {/* <AsideCard title="What is Bonvih">
                        <Box padding="12px 16px">
                          <Typography variant="body1">
                            Bonvih is a money advertisements website motivating
                            people to find pleasure from spending. Bonvih is
                            facilitating turning cash into electronics, fashion
                            and (almost) anything else.
                          </Typography>
                        </Box>
                      </AsideCard> */}
                        <AsideCard title="You may be interested">
                          <WhatsHappening />
                        </AsideCard>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
      <Footer />
      {loggedIn && !isMediumDeviceUp && (
        <MyAppBar
          bottom
          leftSideRender={() => <div />}
          middleRender={() => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <MyIconButton onClick={handleHomeIconClick}>
                {path === `/` ? (
                  <HomeIcon sx={iconStyles.bottomAppBar} />
                ) : (
                  <HomeOutlinedIcon sx={iconStyles.bottomAppBar} />
                )}
              </MyIconButton>
              <MyIconButton onClick={handleCreateIconClick}>
                {path === `/create-need` ? (
                  <AddIcon sx={iconStyles.bottomAppBar} />
                ) : (
                  <AddOutlinedIcon sx={iconStyles.bottomAppBar} />
                )}
              </MyIconButton>
              <MyIconButton onClick={handleSearchIconClick}>
                {path === `/search` ? (
                  <SearchOutlinedIcon sx={iconStyles.bottomAppBar} />
                ) : (
                  <SearchOutlinedIcon sx={iconStyles.bottomAppBar} />
                )}
              </MyIconButton>
              <MyIconButton onClick={handleNotificationsIconClick}>
                {badgeCount.unseenNotificationsCount > 0 && (
                  <MyBadge badgeContent={badgeCount.unseenNotificationsCount}>
                    {path === `/profile/:id/notifications` ? (
                      <NotificationsIcon sx={iconStyles.bottomAppBar} />
                    ) : (
                      <NotificationsNoneIcon sx={iconStyles.bottomAppBar} />
                    )}
                  </MyBadge>
                )}
                {badgeCount.unseenNotificationsCount === 0 && (
                  <>
                    {path === `/profile/:id/notifications` ? (
                      <NotificationsIcon sx={iconStyles.bottomAppBar} />
                    ) : (
                      <NotificationsNoneIcon sx={iconStyles.bottomAppBar} />
                    )}
                  </>
                )}
              </MyIconButton>
              <MyIconButton onClick={handleChatIconClick}>
                {badgeCount.unseenMessagesCount > 0 && (
                  <MyBadge badgeContent={badgeCount.unseenMessagesCount}>
                    {path === `/profile/:id/chats` ? (
                      <ChatBubbleIcon sx={iconStyles.bottomAppBar} />
                    ) : (
                      <ChatBubbleOutlineIcon sx={iconStyles.bottomAppBar} />
                    )}
                  </MyBadge>
                )}
                {badgeCount.unseenMessagesCount === 0 && (
                  <>
                    {path === `/profile/:id/chats` ? (
                      <ChatBubbleIcon sx={iconStyles.bottomAppBar} />
                    ) : (
                      <ChatBubbleOutlineIcon sx={iconStyles.bottomAppBar} />
                    )}
                  </>
                )}
              </MyIconButton>
            </Box>
          )}
          rightSideRender={() => <div />}
        />
      )}
    </Box>
  );
};

PageLayout.defaultProps = {
  handleMenuIconClick: undefined,
  handleHomeIconClick: undefined,
  handleCreateIconClick: undefined,
  handleNotificationsIconClick: undefined,
  handleChatIconClick: undefined,
  handleCreateNeedButtonClick: undefined,
  handleLoginButtonClick: undefined,
  handleSearchIconClick: undefined,
};

export default PageLayout;
