function buildTree(root: CategoryNode, categories: Category[]) {
  categories.forEach((category) => {
    if (root.children) {
      root.children.push({
        parent: root,
        children: category.sub === undefined ? null : [],
        name: category.name,
      });
    }
  });

  if (root.children !== null) {
    root.children.forEach((child, idx) => {
      if (categories[idx] !== undefined) {
        if (categories[idx].sub !== undefined) {
          buildTree(child, categories[idx].sub as Category[]);
        }
      }
    });
  }
}

export type CategoryNode = {
  parent: CategoryNode | null;
  children: CategoryNode[] | null;
  name: string;
};

type Category = {
  name: string;
  sub?: Category[];
};

const allCategories: Category[] = [
  {
    name: 'Electronics',
    sub: [
      {
        name: 'Phones & Accessories',
        sub: [
          {
            name: 'Mobile & Smartphones',
          },
          {
            name: 'Cases, Covers & Skins',
          },
          {
            name: 'Phone Earphones & Headphones',
          },
          {
            name: 'Phone Chargers, Adaptors & Cables',
          },
          {
            name: 'Mobile Phone Parts & Tools',
          },
          {
            name: 'Mobile Phone Accessories',
          },
          {
            name: 'Vintage Mobile Phones',
          },
          {
            name: 'Home Phones & Accessories',
          },
          {
            name: 'Vintage Home Phones',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Gaming',
        sub: [
          {
            name: 'Games Consoles',
          },
          {
            name: 'Handheld Consoles',
          },
          {
            name: 'Video Games',
          },
          {
            name: 'Replacement Gaming Parts & Tools',
          },
          {
            name: 'Video Gaming Merchandise',
          },
          {
            name: 'Video Game Strategy Guides & Cheats',
          },
          {
            name: 'PC Gaming',
          },
          {
            name: 'Video Gaming Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Cameras & Photography',
        sub: [
          {
            name: 'Digital Cameras',
          },
          {
            name: 'Video Games & Camcorders',
          },
          {
            name: 'Vintage Cameras',
          },
          {
            name: 'Lenses & Filters',
          },
          {
            name: 'Tripods & Supports',
          },
          {
            name: 'Flashes & Flash Accessories',
          },
          {
            name: 'Binoculars & Telescopes',
          },
          {
            name: 'Sport & Waterproof Cameras',
          },
          {
            name: 'Lighting & Photo Studio',
          },
          {
            name: 'Digital Photo Frames',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Car Technology',
        sub: [
          {
            name: 'Car Steroes & Bluetooth',
          },
          {
            name: 'Sat Nav & GPS',
          },
          {
            name: 'In Car DVD Players',
          },
          {
            name: 'Car Speakers, Amps & Subs',
          },
          {
            name: 'Phone Holders, Cables & Sockets',
          },
          {
            name: 'Dash Cams',
          },
          {
            name: 'Speed Camera Dectectors',
          },
          {
            name: 'Car Security',
          },
          {
            name: 'Parking Sensors',
          },
          {
            name: 'Car Tech Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Computers, PCs & Laptops',
        sub: [
          {
            name: 'Desktops',
          },
          {
            name: 'Laptops, Netbooks & 2-in-1',
          },
          {
            name: 'Monitors & Screens',
          },
          {
            name: 'Printers & Scanners',
          },
          {
            name: 'Computer Software',
          },
          {
            name: 'Computer Cables & Connectors',
          },
          {
            name: 'Computer Component & Parts',
          },
          {
            name: 'Vintage Computers',
          },
          {
            name: '3D Printers',
          },
          {
            name: 'Computer Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Drones',
        sub: [
          {
            name: 'Drones',
          },
          {
            name: 'Drone Cameras',
          },
          {
            name: 'Drone Parts & Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Home Appliances',
        sub: [
          {
            name: 'Cookers, Ovens & Hubs',
          },
          {
            name: 'Dishwashers',
          },
          {
            name: 'Fridges & Freezers',
          },
          {
            name: 'Ironing',
          },
          {
            name: 'Vacuuming',
          },
          {
            name: 'Small Kitchen Appliances',
          },
          {
            name: 'Home Appliances Parts & Accessories',
          },
          {
            name: 'Washing Machines',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Smart Home & Security',
        sub: [
          {
            name: 'Smart Assistans & Speakers',
          },
          {
            name: 'Home Surveillance, Camera Systems & CCTV',
          },
          {
            name: 'Smart Thermostats',
          },
          {
            name: 'Smart Lighting',
          },
          {
            name: 'Smart Locks',
          },
          {
            name: 'Smart Cleaning Appliances',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Sound & Audio',
        sub: [
          {
            name: 'Home Speakers, Subwoofers & Amplifiers',
          },
          {
            name: 'Bluetooth Speakers',
          },
          {
            name: 'Headphones & Earphones',
          },
          {
            name: 'Docking Stations',
          },
          {
            name: 'Portable Stereos',
          },
          {
            name: 'Microphones',
          },
          {
            name: 'DJ, Electronics Music & Karaoke',
          },
          {
            name: 'Studio Recording Equipment',
          },
          {
            name: 'Audio Accessories',
          },
          {
            name: 'Vintage Audio',
          },
          {
            name: 'Electronic Musical Instrument',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Tablets & eReaders',
        sub: [
          {
            name: 'Tablets',
          },
          {
            name: 'eReaders',
          },
          {
            name: 'Cases, Covers & Skins',
          },
          {
            name: 'Tablet Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'TV & Video',
        sub: [
          {
            name: 'Televisions',
          },
          {
            name: 'Projectors & Screens',
          },
          {
            name: 'Internet TV & Media Streaming',
          },
          {
            name: 'TV Set-Top, Cable & Freeview Boxes',
          },
          {
            name: 'Blu-ray & DVD Players',
          },
          {
            name: 'Video Cables & Connectors',
          },
          {
            name: 'Remote Controls',
          },
          {
            name: 'TV Stands & Mounts',
          },
          {
            name: 'Home Cinema & Surround Sound',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Wearables',
        sub: [
          {
            name: 'Smart Watches',
          },
          {
            name: 'Smart Watch Accessories',
          },
          {
            name: 'Fitness Trackers',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Virtual Reality',
        sub: [
          {
            name: 'VR Headsets',
          },
          {
            name: 'VR Phone Cases',
          },
          {
            name: 'VR Games',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Fashion & Accessories',
    sub: [
      {
        name: 'Women',
        sub: [
          {
            name: 'Shoes & Boots',
          },
          {
            name: 'Activewear & Sportswear',
          },
          {
            name: 'Dresses',
          },
          {
            name: 'Tops',
          },
          {
            name: 'Jumpers & Cardingans',
          },
          {
            name: 'Hoodies & Sweatshirts',
          },
          {
            name: 'Coats & Jackets',
          },
          {
            name: 'Jeans',
          },
          {
            name: 'Trousers & Leggings',
          },
          {
            name: 'Shorts',
          },
          {
            name: 'Skirts',
          },
          {
            name: 'Jumpsuits & Playsuits',
          },
          {
            name: 'Lingerie & Nightwear',
          },
          {
            name: 'Swimwear & Beachwear',
          },
          {
            name: 'Suits & Blazers',
          },
          {
            name: 'Bags & Accessories',
          },
          {
            name: 'Masks & Facewear',
          },
          {
            name: 'Sunglasses',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Men',
        sub: [
          {
            name: 'Shoes & Boots',
          },
          {
            name: 'Coats & Jackets',
          },
          {
            name: 'Activewear & Sportswear',
          },
          {
            name: 'Jeans',
          },
          {
            name: 'Hoodies & Sweatshirts',
          },
          {
            name: 'Loungewear & Nightwear',
          },
          {
            name: 'Polo Shirts',
          },
          {
            name: 'Shirts',
          },
          {
            name: 'Masks & Facewear',
          },
          {
            name: 'Shorts',
          },
          {
            name: 'Jumpers & Cardigans',
          },
          {
            name: 'Socks & Underwear',
          },
          {
            name: 'Suits & Blazers',
          },
          {
            name: 'Swimwear & Beachwear',
          },
          {
            name: 'T-Shirts & Vests',
          },
          {
            name: 'Trousers & Chinos',
          },
          {
            name: 'Bags & Accessories',
          },
          {
            name: 'Sunglasses',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Girls',
        sub: [
          {
            name: 'Dresses',
          },
          {
            name: 'Shoes & Boots',
          },
          {
            name: 'Tops',
          },
          {
            name: 'Coats & Jackets',
          },
          {
            name: 'School Uniform',
          },
          {
            name: 'Bodysuits',
          },
          {
            name: 'Sleepsuits',
          },
          {
            name: 'Bags & Accessories',
          },
          {
            name: 'Trousers & Leggings',
          },
          {
            name: 'Jumpsuits & Playsuits',
          },
          {
            name: 'Hoodies & Sweatshirts',
          },
          {
            name: 'Jeans',
          },
          {
            name: 'Skirts',
          },
          {
            name: 'Swimwear & Beachwear',
          },
          {
            name: 'Masks & Facewear',
          },
          {
            name: 'Shorts',
          },
          {
            name: 'Activewear & Sportswear',
          },
          {
            name: 'Nightwear',
          },
          {
            name: 'Socks & Tights',
          },
          {
            name: 'Jumpers & Cardigans',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Boys',
        sub: [
          {
            name: 'Coats & Jackets',
          },
          {
            name: 'Shoes & Boots',
          },
          {
            name: 'T-Shirts & Vests',
          },
          {
            name: 'Polo Shirts',
          },
          {
            name: 'Trousers & Chinos',
          },
          {
            name: 'Jeans',
          },
          {
            name: 'School Uniform',
          },
          {
            name: 'Bodysuits',
          },
          {
            name: 'Sleepsuits',
          },
          {
            name: 'Hoodies & Sweatshirts',
          },
          {
            name: 'Swimwear & Beachwear',
          },
          {
            name: 'Masks & Facewear',
          },
          {
            name: 'Shorts',
          },
          {
            name: 'Activewear & Sportswear',
          },
          {
            name: 'Nightwear',
          },
          {
            name: 'Jumpers & Cardigans',
          },
          {
            name: 'Shirts',
          },
          {
            name: 'Socks & Underwear',
          },
          {
            name: 'Bags & Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Home & Garden',
    sub: [
      {
        name: 'Bathroom',
        sub: [
          {
            name: 'Bathroom Cabinets & Storage',
          },
          {
            name: 'Bathroom Mirrors',
          },
          {
            name: 'Shower & Bath Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Bedroom Furniture',
        sub: [
          {
            name: 'Beds & Mattresses',
          },
          {
            name: 'Headboards',
          },
          {
            name: 'Bedside Tables',
          },
          {
            name: 'Chest of Drawers',
          },
          {
            name: 'Wardrobes',
          },
          {
            name: 'Dressing Tables',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'DIY',
        sub: [
          {
            name: 'Tools',
          },
          {
            name: 'Building Materials',
          },
          {
            name: 'Heating & Plumbing',
          },
          {
            name: 'Electrical & Lighting',
          },
          {
            name: 'Screws, Nails & Fixings',
          },
          {
            name: 'Security & Ironmongery',
          },
          {
            name: 'Safety & Workwear',
          },
          {
            name: 'Sealants & Adhesives',
          },
          {
            name: 'Decorating',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Garden',
        sub: [
          {
            name: 'Outdoor Furniture',
          },
          {
            name: 'BBQs',
          },
          {
            name: 'Sheds & Storage',
          },
          {
            name: 'Garden Tools',
          },
          {
            name: 'Ornaments',
          },
          {
            name: 'Plants & Pots',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Hallway Furniture',
        sub: [
          {
            name: 'Console Tables',
          },
          {
            name: 'Cock Racks & Stands',
          },
          {
            name: 'Shoe Storage',
          },
          {
            name: 'Hallway Cabinets',
          },
          {
            name: 'Benches',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Furnishings & Accessories',
        sub: [
          {
            name: 'Bedding',
          },
          {
            name: 'Pillow & Duvets',
          },
          {
            name: 'Rugs & Carpets',
          },
          {
            name: 'Curtains & Blinds',
          },
          {
            name: 'Cushions',
          },
          {
            name: 'Throws & Blankets',
          },
          {
            name: 'Towels & Bath Mats',
          },
          {
            name: 'Fabrics',
          },
          {
            name: 'Lamps & Lighting',
          },
          {
            name: 'Mirrors',
          },
          {
            name: 'Frames & Art',
          },
          {
            name: 'Candles & Home Fragrance',
          },
          {
            name: 'Ornaments, Vases & Clocks',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Home Office',
        sub: [
          {
            name: 'Desks',
          },
          {
            name: 'Office Chairs',
          },
          {
            name: 'Filling Cabinets',
          },
          {
            name: 'Desk Organisers',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Kitchen & Dining Room',
        sub: [
          {
            name: 'Dining Tables & Chairs',
          },
          {
            name: 'Cabinets & Sideboards',
          },
          {
            name: 'Bars & Bar Stools',
          },
          {
            name: 'Cooking',
          },
          {
            name: 'Baking',
          },
          {
            name: 'Kitchen & Food Storage',
          },
          {
            name: 'Cutlery & Utensils',
          },
          {
            name: 'Glassware',
          },
          {
            name: 'Drinks Cabinets & Trolleys',
          },
          {
            name: 'Wine Racks',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Living Room Furniture',
        sub: [
          {
            name: 'Sofas & Armchairs',
          },
          {
            name: 'Media Units & TV Stands',
          },
          {
            name: 'Coffee & Side Tables',
          },
          {
            name: 'Foodstools',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Pet Care',
      },
      {
        name: 'Storage',
        sub: [
          {
            name: 'Desks',
          },
          {
            name: 'Bookcases',
          },
          {
            name: 'Ottomans & Trunks',
          },
          {
            name: 'Shelves',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Utility & Laundry',
        sub: [
          {
            name: 'Ironing Boards',
          },
          {
            name: 'Washing Lines & Clothes Airers',
          },
          {
            name: 'Linen Baskets & Laundry Bins',
          },
          {
            name: 'Clothes Steamers',
          },
          {
            name: 'Laundry Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Baby & Toddler',
    sub: [
      {
        name: 'Baby Clothing',
        sub: [
          {
            name: 'Bodysuits',
          },
          {
            name: 'Coats & Jackets',
          },
          {
            name: 'Jumpers & Cardigans',
          },
          {
            name: 'Tops & T-shirts',
          },
          {
            name: 'Dresses & Skirts',
          },
          {
            name: 'Joggers',
          },
          {
            name: 'Shoes & Boots',
          },
          {
            name: 'Sleepsuits & Pyjamas',
          },
          {
            name: 'Trousers & Leggings',
          },
          {
            name: 'Socks & Tights',
          },
          {
            name: 'Swimwear',
          },
          {
            name: 'Baby Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Baby Changing & Nappies',
        sub: [
          {
            name: 'Nappies',
          },
          {
            name: 'Swim Nappies',
          },
          {
            name: 'Changing Bags',
          },
          {
            name: 'Changing Mats & Covers',
          },
          {
            name: 'Nappy Bins & Bags',
          },
          {
            name: 'Cloth Nappies',
          },
          {
            name: 'Wipes, Creams & Cotton Wool',
          },
          {
            name: 'Potties',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Bath Time',
        sub: [
          {
            name: 'Bath Tubs',
          },
          {
            name: 'Bath Seats',
          },
          {
            name: 'Bath Toys',
          },
          {
            name: 'Towels & Flannels',
          },
          {
            name: 'Bath Robes',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Feeding',
        sub: [
          {
            name: 'Bottles, Warmers & Sterilisers',
          },
          {
            name: 'Bibs & Burp Cloths',
          },
          {
            name: 'Breast Pumps & Accessories',
          },
          {
            name: 'Nursing Pillows & Covers',
          },
          {
            name: 'Cups, Dishes & Utensils',
          },
          {
            name: 'Baby Food Processors',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Nursery & Furniture',
        sub: [
          {
            name: 'Furniture Sets',
          },
          {
            name: 'Moses Baskets',
          },
          {
            name: 'Cots, Cot Beds & Cribs',
          },
          {
            name: 'Cot Mattresses',
          },
          {
            name: 'Bumpers',
          },
          {
            name: 'Baby Bedding',
          },
          {
            name: 'Toddler Bedding',
          },
          {
            name: 'High Chairs',
          },
          {
            name: 'Changing Units & Tables',
          },
          {
            name: 'Baby Wardrobes',
          },
          {
            name: 'Baby Monitors & Night Lights',
          },
          {
            name: 'Bouncers & Swings',
          },
          {
            name: 'Cot Mobiles',
          },
          {
            name: 'Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Pushchairs & Prams',
        sub: [
          {
            name: 'Strollers',
          },
          {
            name: 'Buggies',
          },
          {
            name: 'Carrycots',
          },
          {
            name: 'Travel Systems',
          },
          {
            name: 'Buggy Boards & Wheeled Board',
          },
          {
            name: 'Adaptors',
          },
          {
            name: 'Footmuffs',
          },
          {
            name: 'Parasols & Sunshades',
          },
          {
            name: 'Pushchair Liners',
          },
          {
            name: 'Raincover',
          },
          {
            name: 'Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Travel',
        sub: [
          {
            name: 'Car Seats & Bases',
          },
          {
            name: 'Baby Carriers & Slings',
          },
          {
            name: 'Safety Reins',
          },
          {
            name: 'Travel Cots',
          },
          {
            name: 'Travel Potties',
          },
          {
            name: 'Shades & Blinds',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Health & Safety',
        sub: [
          {
            name: 'Stair Gates',
          },
          {
            name: 'Bed Rails & Guards',
          },
          {
            name: 'Play Pens',
          },
          {
            name: 'Baby Proofing',
          },
          {
            name: 'Baby Thermometers',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Jewellery & Watches',
    sub: [
      {
        name: 'Womens',
        sub: [
          {
            name: 'Earrings',
          },
          {
            name: 'Necklaces',
          },
          {
            name: 'Charms & Beads',
          },
          {
            name: 'Bracelets & Bangles',
          },
          {
            name: 'Rings',
          },
          {
            name: 'Watches',
          },
          {
            name: 'Nose Rings & Studs',
          },
          {
            name: 'Brooches',
          },
          {
            name: 'Jewellery Sets',
          },
          {
            name: 'Jewellery Boxes & Rolls',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Mens',
        sub: [
          {
            name: 'Watches',
          },
          {
            name: 'Rings',
          },
          {
            name: 'Bracelets',
          },
          {
            name: 'Necklaces',
          },
          {
            name: 'Cufflinks',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Kids',
        sub: [
          {
            name: 'Watches',
          },
          {
            name: 'Earrings',
          },
          {
            name: 'Necklaces',
          },
          {
            name: 'Charms & Beads',
          },
          {
            name: 'Bracelets & Bangles',
          },
          {
            name: 'Rings',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Health & Beauty',
    sub: [
      {
        name: 'Cosmetics',
        sub: [
          {
            name: 'Make up',
          },
          {
            name: 'Fragrance',
          },
          {
            name: 'Skin Care',
          },
          {
            name: 'Bath & Shower',
          },
          {
            name: 'Beauty Accessories',
          },
          {
            name: 'Body Care',
          },
          {
            name: 'Gift Sets',
          },
          {
            name: 'Hair Care',
          },
          {
            name: 'Nail Care',
          },
          {
            name: 'Shaving & Hair Removal',
          },
          {
            name: 'Sun Care & Tanning',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Beauty Appliances',
        sub: [
          {
            name: 'Hair Styling',
          },
          {
            name: 'Nail Lamps',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Health',
        sub: [
          {
            name: 'Face Masks',
          },
          {
            name: 'Air Purifiers',
          },
          {
            name: 'Humidifiers & Diffusers',
          },
          {
            name: 'Sleep Aids',
          },
          {
            name: 'Blood Pressure Monitors',
          },
          {
            name: 'Digital Pain Relief',
          },
          {
            name: 'Diagnostics & Health Monitors',
          },
          {
            name: 'Mobility & Daily Living Aids',
          },
          {
            name: 'Occupational & Physical Theraphy Aids',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Sport & Leisure',
    sub: [
      {
        name: 'Art & Craft',
        sub: [
          {
            name: 'Baking',
          },
          {
            name: 'Clay & Pottery',
          },
          {
            name: 'Craft Blanks & Bases',
          },
          {
            name: 'Craft Tools & Accessories',
          },
          {
            name: 'Craft Storage',
          },
          {
            name: 'Decor & Frames',
          },
          {
            name: 'Digital Cutting',
          },
          {
            name: 'Jewellery Making',
          },
          {
            name: 'Kids Craft',
          },
          {
            name: 'Knitting & Crochet',
          },
          {
            name: 'Models & Puzzles',
          },
          {
            name: 'Paints & Art Supplies',
          },
          {
            name: 'Papercraft',
          },
          {
            name: 'Sewing & Dressmaking',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Camping & Caravanning',
        sub: [
          {
            name: 'Chairs & Furniture',
          },
          {
            name: 'Cooking & Eating',
          },
          {
            name: 'Gazebos',
          },
          {
            name: 'Roof Bars & Boxes',
          },
          {
            name: 'Sleeping Bags',
          },
          {
            name: 'Sleeping Mats & Airbeds',
          },
          {
            name: 'Tents & Awnings',
          },
          {
            name: 'Camping Accessories',
          },
          {
            name: 'Caravanning Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Musical Instruments',
        sub: [
          {
            name: 'Brass',
          },
          {
            name: 'Drums & Percussion',
          },
          {
            name: 'Guitars',
          },
          {
            name: 'Keyboards & Pianos',
          },
          {
            name: 'Sheet Music & Song Books',
          },
          {
            name: 'String',
          },
          {
            name: 'Woodwind & Wind Instruments',
          },
          {
            name: 'Vintage Musical Instruments',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Sports Equipment',
        sub: [
          {
            name: 'Athletics & Running',
          },
          {
            name: 'Archery',
          },
          {
            name: 'Badminton',
          },
          {
            name: 'Basketball',
          },
          {
            name: 'Bowls',
          },
          {
            name: 'Boxing',
          },
          {
            name: 'Canoeing & Kayaking',
          },
          {
            name: 'Climbing & Mountaineering',
          },
          {
            name: 'Cricket',
          },
          {
            name: 'Cycling',
          },
          {
            name: 'Dance',
          },
          {
            name: 'Darts',
          },
          {
            name: 'Fishing',
          },
          {
            name: 'Fitness & Gym',
          },
          {
            name: 'Football',
          },
          {
            name: 'Golf',
          },
          {
            name: 'Gymnastics',
          },
          {
            name: 'Hiking & Trekking',
          },
          {
            name: 'Hockey',
          },
          {
            name: 'Horse Riding & Equestrian',
          },
          {
            name: 'Ice Skating & Ice Hockey',
          },
          {
            name: 'Inline & Roller Skating',
          },
          {
            name: 'Martial Arts',
          },
          {
            name: 'Netball',
          },
          {
            name: 'Rugby',
          },
          {
            name: 'Scootering',
          },
          {
            name: 'Skateboarding',
          },
          {
            name: 'Skydiving, Para & Hang Gliding',
          },
          {
            name: 'Snooker & Pool',
          },
          {
            name: 'Squash',
          },
          {
            name: 'Swimming',
          },
          {
            name: 'Table Tennis',
          },
          {
            name: 'Tennis',
          },
          {
            name: 'Trampolining',
          },
          {
            name: 'Watersports',
          },
          {
            name: 'Winter Sports',
          },
          {
            name: 'Yoga & Pilates',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Toys & Games',
    sub: [
      {
        name: 'Baby & Preschool',
        sub: [
          {
            name: 'Bouncers & Swings',
          },
          {
            name: 'Playmats & Gyms',
          },
          {
            name: 'Role Play',
          },
          {
            name: 'Sensory Toys',
          },
          {
            name: 'Wooden Toys',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Board Games',
        sub: [
          {
            name: 'Adult Board Games',
          },
          {
            name: 'Family Board Games',
          },
          {
            name: 'Kids Games',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Creative & Science',
        sub: [
          {
            name: 'Craft Kits',
          },
          {
            name: 'Dough & Modelling',
          },
          {
            name: 'Jewellery & Fashion',
          },
          {
            name: 'Lego & Construction Toys',
          },
          {
            name: 'Makeup & Beauty Toys',
          },
          {
            name: 'Musical Toys Instruments',
          },
          {
            name: 'Puppet Theatre',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Dolls & Accessories',
        sub: [
          {
            name: 'Baby Dolls',
          },
          {
            name: 'Dolls Houses',
          },
          {
            name: 'Fashion Dolls',
          },
          {
            name: 'Film & TV Dolls',
          },
          {
            name: 'Rag Dolls',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Electronics Toys',
        sub: [
          {
            name: 'Cameras',
          },
          {
            name: 'Electronic Learning',
          },
          {
            name: 'Electronic Pets',
          },
          {
            name: 'Remote Controlled & RC Toys',
          },
          {
            name: 'Robots',
          },
          {
            name: 'Scalextric & Slot Cars',
          },
          {
            name: 'Train Sets',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Figures & Playsets',
        sub: [
          {
            name: 'Action Figures',
          },
          {
            name: 'Film & TV Figures',
          },
          {
            name: 'Animals',
          },
          {
            name: 'Playsets',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Fancy Dress',
        sub: [
          {
            name: 'Costumes',
          },
          {
            name: 'Face Paints',
          },
          {
            name: 'Masks, Wigs & Helmets',
          },
          {
            name: 'Fancy Dress Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Garden & Outdoor',
        sub: [
          {
            name: 'Ball Pits',
          },
          {
            name: 'Bouncy Castles',
          },
          {
            name: 'Bubble Machines',
          },
          {
            name: 'Dart Blasters',
          },
          {
            name: 'Electronic Ride-Ons',
          },
          {
            name: 'Hoverboards',
          },
          {
            name: 'Inflatable Toys',
          },
          {
            name: 'Kids Bikes',
          },
          {
            name: 'Paddling Pools',
          },
          {
            name: 'Playhouses',
          },
          {
            name: 'Ride-Ons',
          },
          {
            name: 'Scooters',
          },
          {
            name: 'Swings & Slides',
          },
          {
            name: 'Trampolines',
          },
          {
            name: 'Trikes',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Jigsaws & Puzzles',
        sub: [
          {
            name: '3D Puzzles',
          },
          {
            name: 'Adult Jigsaw Puzzles',
          },
          {
            name: 'Jigsaw Storage',
          },
          {
            name: 'Kids Jigsaw Puzzles',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Soft Toys',
        sub: [
          {
            name: 'Beanies',
          },
          {
            name: 'Teddy Bears',
          },
          {
            name: 'Soft Animal Toys',
          },
          {
            name: 'Film & TV Soft Toys',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Toy Vehicles',
        sub: [
          {
            name: 'Aeroplanes & Helicopters',
          },
          {
            name: 'Boats',
          },
          {
            name: 'Cars & Trucks',
          },
          {
            name: 'Rockets & Spacecraft',
          },
          {
            name: 'Train',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Media',
    sub: [
      {
        name: 'Audiobooks',
        sub: [
          {
            name: "Children's Books",
          },
          {
            name: 'Fiction',
          },
          {
            name: 'Non-Fiction',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Books',
        sub: [
          {
            name: 'Antique & Collectable',
          },
          {
            name: "Children's Books",
          },
          {
            name: 'Fiction',
          },
          {
            name: 'Non-Fiction',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Comics',
        sub: [
          {
            name: 'Manga & Asian',
          },
          {
            name: 'American Comics',
          },
          {
            name: 'European Comics',
          },
          {
            name: 'Comic Memorabilia',
          },
          {
            name: 'Original Comic Art',
          },
          {
            name: 'Art',
          },
        ],
      },
      {
        name: 'Films & TV',
        sub: [
          {
            name: 'DVD & Blu-Ray',
          },
          {
            name: 'Film Memorabilia',
          },
          {
            name: 'TV Memorabilia',
          },
          {
            name: 'Laserdiscs',
          },
          {
            name: 'Film Stock',
          },
          {
            name: 'VHS Tapes',
          },
          {
            name: 'UMD',
          },
          {
            name: 'Storage & Media Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Magazines',
        sub: [
          {
            name: 'Journals',
          },
          {
            name: 'Magazines',
          },
          {
            name: 'Periodicals',
          },
          {
            name: 'Other',
          },
        ],
      },
      {
        name: 'Music',
        sub: [
          {
            name: 'CD',
          },
          {
            name: 'Vinyl',
          },
          {
            name: 'Cassette Tapes',
          },
          {
            name: 'Music Memorabilia',
          },
          {
            name: 'Music Storage',
          },
          {
            name: 'Music Accessories',
          },
          {
            name: 'Other',
          },
        ],
      },
    ],
  },
  {
    name: 'Motors',
    sub: [
      {
        name: 'Cars',
      },
      {
        name: 'Motorcycles & Scooters',
      },
      {
        name: 'Car Parts & Accessories',
      },
    ],
  },
  {
    name: 'Property',
    sub: [
      {
        name: 'Property for Sale',
      },
      {
        name: 'Property for Rent',
      },
      {
        name: 'Shared Property',
      },
    ],
  },
  {
    name: 'Services',
    sub: [
      {
        name: 'Tuition & Classes',
      },
      {
        name: 'Childcare',
      },
      {
        name: 'Computer & Telecoms',
      },
      {
        name: 'Food & Drink',
      },
      {
        name: 'Health & Beauty',
      },
      {
        name: 'Transport',
      },
      {
        name: 'Property & Maintenance',
      },
      {
        name: 'Tradesmen & Contruction',
      },
      {
        name: 'Weddings',
      },
      {
        name: 'Other',
      },
    ],
  },
  {
    name: 'Other',
  },
];

export function addToTree(root: CategoryNode, fullCategoryName: string): void {
  const splittedCategoryName = fullCategoryName.split(' > ');
  let currentCategoryNode = root;
  splittedCategoryName.forEach((categoryName, currentIdx) => {
    if (currentCategoryNode.children !== null) {
      const found = currentCategoryNode.children.find(
        (child) => child.name === categoryName,
      );
      if (found === undefined) {
        const newNode: CategoryNode = {
          parent: currentCategoryNode,
          children: currentIdx === splittedCategoryName.length - 1 ? null : [],
          name: categoryName,
        };
        currentCategoryNode.children.push(newNode);
        currentCategoryNode = newNode;
      } else {
        currentCategoryNode = found;
      }
    }
  });
}

export function buildCategoryTree(fullCategoryNames: string[]): CategoryNode {
  const root: CategoryNode = {
    parent: null,
    children: [],
    name: '',
  };

  fullCategoryNames.forEach((fullCategoryName) => {
    addToTree(root, fullCategoryName);
  });

  return root;
}

export function getAllCategoriesTree(): CategoryNode {
  const root: CategoryNode = {
    parent: null,
    children: [],
    name: '',
  };
  buildTree(root, allCategories);
  return root;
}

export function searchCategoryNode(
  root: CategoryNode,
  fullCategoryName: string,
): CategoryNode {
  const splittedCategoryName = fullCategoryName.split(' > ');
  let currentCategoryNode = root;

  splittedCategoryName.forEach((categoryName) => {
    if (currentCategoryNode.children) {
      const found = currentCategoryNode.children.find(
        (node) => node.name === categoryName,
      );
      if (found) {
        currentCategoryNode = found;
      }
    }
  });

  return currentCategoryNode;
}
