import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import useSecurity from '../../hooks/useSecurity';

const SecureRoute = (props: RouteProps): JSX.Element => {
  const { loggedIn } = useSecurity();

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />; // eslint-disable-line react/jsx-props-no-spreading
};

export default SecureRoute;
