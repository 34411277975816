import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import PageLayout from '../../components/PageLayout';

export const PageTitle: FunctionComponent = (
  { children }, // eslint-disable-line react/prop-types
) => (
  <Box margin="20px 0">
    <Typography variant="h3">{children}</Typography>
  </Box>
);

export const ParagraphTitle1: FunctionComponent = (
  { children }, // eslint-disable-line react/prop-types
) => (
  <Box margin="18px 0">
    <Typography variant="h4">{children}</Typography>
  </Box>
);

export const ParagraphTitle2: FunctionComponent = (
  { children }, // eslint-disable-line react/prop-types
) => (
  <Box margin="16px 0">
    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
      {children}
    </Typography>
  </Box>
);

export const ParagraphTitle3: FunctionComponent = (
  { children }, // eslint-disable-line react/prop-types
) => (
  <Box margin="20px 0">
    <Typography variant="h6">{children}</Typography>
  </Box>
);

export const Paragraph: FunctionComponent = (
  { children }, // eslint-disable-line react/prop-types
) => (
  <Box marginBottom="12px">
    <Typography variant="body1">{children}</Typography>
  </Box>
);

const PrivacyPolicyPage = (): JSX.Element => (
  <PageLayout>
    <Helmet>
      <title>Privacy Policy | Bonvih</title>
    </Helmet>
    <Box padding="0 16px">
      <PageTitle>Privacy Policy</PageTitle>
      <Paragraph>Welcome to Shpock - The Joy of Selling!</Paragraph>
      <Paragraph>
        The finderly GmbH, Gertrude-Fröhlich-Sandner Straße 2, SPACES Icon
        Tower, Turm 9, 1100 Vienna, Austria (hereinafter referred to as
        &quot;Operator&quot;) operates websites (shpock.com and other country
        specific top level domains like e.g. .co.uk, .de, .at) and mobile apps
        (Shpock app for Android, Shpock app for iOS) (hereinafter collectively
        &quot;Shpock&quot; / &quot;Shpock services&quot;), under the name
        &quot;Shpock&quot;. The Operator is the controller of personal data
        within the meaning of the General Data Protection Regulation (GDPR). The
        Operator decides on the purposes and means of processing personal data
        and is ultimately responsible for ensuring that the processing takes
        place in accordance with governing personal data legislation.
      </Paragraph>
      <ParagraphTitle1>I Preamble</ParagraphTitle1>
      <Paragraph>
        The Operator takes data privacy very seriously and wants to reveal
        openly and transparently how this topic is dealt with.
      </Paragraph>
      <Paragraph>
        When Shpock is used, the Operator is provided with a set of personal
        data. Personal data means information which can directly or indirectly
        be linked to a living natural person. Examples of personal data include
        name, address, telephone number and email address; however, information
        about IP numbers and user behaviour when using the services may also
        constitute personal data. Below, the Operator explains to the users of
        Shpock (hereinafter referred to as “user” or “users”) how data, what
        kind of data and for what purpose data is collected and to whom this
        data may be shared with. Finally, the Operator informs users about their
        rights as well as internal rules for data storage.
      </Paragraph>
      <Paragraph>
        This Privacy Policy is a supplement to the terms and conditions and
        applies to the use of the Shpock services and the utilisation of all
        other offered services on Shpock. The privacy policy applied refers to
        the policy available online at Shpock at the time of a user’s specific
        visit or at the time of the specific use of a Shpock service.
      </Paragraph>
      <Paragraph>
        This privacy policy is translated from the original German version.
        Please note that if this privacy policy is inconsistent with the privacy
        policy in German, the German version prevails.
      </Paragraph>
      <ParagraphTitle1>II Data collection and processing</ParagraphTitle1>
      <Paragraph>
        The Operator collects data in connection with the registration of an
        account and also in connection with subsequent use of the Shpock
        services. In order to give a good overview of how the processing of
        personal data is carried out, the personal data has been divided into
        three categories based on how it is collected:
      </Paragraph>
      <ParagraphTitle2>1. Data that is given to us by the user</ParagraphTitle2>
      <Paragraph>
        When an account is created or activities are carried out through the
        framework of the account, personal information about the individual user
        is needed. Personal data in this category is characterised by the fact
        that the user actively provides the data to us by, e.g. completing a web
        form, reporting incidents to support, or providing us with feedback
        through functions created for such purposes.
      </Paragraph>
      <Paragraph>
        To facilitate the registration for users and to reduce the threat of
        scammers and spammers the user has three ways of registering an account:
      </Paragraph>
      <ParagraphTitle3>a. Registration via Facebook Connect:</ParagraphTitle3>
      <Paragraph>
        The Operator accesses user data, which is saved in the individual’s
        Facebook-Profile. The user agrees to share this data with the Operator
        during the registration process. This permission can be revoked at
        Facebook at any time. Of course, the Operator does not have access to
        the users’ Facebook-Login data. The Operator is not liable or
        responsible for wrong or unauthorized data transfers from Facebook. In
        order to preserve a certain amount of anonymity towards other users,
        only the profile picture of the user, the first name and the last name
        abbreviated to the first letter (John D.) instead of the full name (John
        Doe) is shown to other users.
      </Paragraph>
      <ParagraphTitle3>b. Registration via Google Sign in:</ParagraphTitle3>
      <Paragraph>
        The Operator accesses user data, which is saved in the individual’s
        Google-Profile. The user agrees to share this data with the Operator
        during the registration process. This permission can be revoked at
        Google at any time. Of course, the Operator does not have access to the
        users’ Google-Login data. The Operator is not liable or responsible for
        wrong or unauthorized data transfers from Google. In order to preserve a
        certain amount of anonymity towards other users, only the profile
        picture of the user, the first name and the last name abbreviated to the
        first letter (John D.) instead of the full name (John Doe) is shown to
        other users.
      </Paragraph>
      <ParagraphTitle3>
        c. Registration with E-Mail and SMS-confirmation:
      </ParagraphTitle3>
      <Paragraph>
        The Operator hereby accesses the data of the user, which he has
        specified during the registration process, i.e. name, e-mail address and
        telephone number. The profile data given by the user must be correct,
        complete and must continuously be kept up-to-date. In order to preserve
        a certain amount of anonymity towards other users, only the profile
        picture of the user, the first name and the last name abbreviated to the
        first letter (John D.) instead of the full name (John Doe) is shown to
        other users.
      </Paragraph>
      <ParagraphTitle2>
        2. Data collected when you use the Shpock services
      </ParagraphTitle2>
      <Paragraph>
        By using the Shpock services and carrying out activities, the user
        provides data which is stored by the Operator in its database. This
        process takes place for user recognition and to ensure the delivery of
        the service that the user has requested. The Operator will also store
        information when the user contacts the Operator, for example when the
        user e-mails the Operator’s customer support, so that the Operator can
        assist the user and answer open questions. The Operator may use
        anonymous and aggregated data about such inquiries to improve its
        services. By using the Shpock services, the Operator gathers information
        about individual user’s activity, e.g. which services and screens are
        visited within Shpock and how they are used. The collected data can be
        divided into the following types:
      </Paragraph>
      <Paragraph>
        By using the Shpock services, the Operator gathers information about
        individual user’s activity, e.g. which services and screens are visited
        within Shpock and how they are used. The collected data can be divided
        into the following types:
      </Paragraph>
      <ParagraphTitle3>
        a. Technical information about the user’s device and internet connection
      </ParagraphTitle3>
      <Paragraph>
        Through server logs and other tools the Operator records information
        about the user’s device and internet connection, including operating
        system, browser version, IP addresses, cookies and other unique
        identifiers. The technical information is usually processed as
        aggregated data in order to analyse how the Operator’s services are
        used. The Operator may, however, link such data to the user’s account,
        for instance so that the Operator can customize the Shpock-services to
        the User’s device.
      </Paragraph>
      <ParagraphTitle3>
        b. Information about the use of the Shpock services
      </ParagraphTitle3>
      <Paragraph>
        The Operator registers the user’s account activities such as logging in
        or out of the user account, and any purchase of products or services
        through the Shpock services. The Operator also stores information about
        a user’s visit on Shpock. The Operator uses this information, amongst
        other things, to prevent abuse and fraudulent activities, to improve the
        Shpock services and to provide users with personalised services,
        recommendations and informations.
      </Paragraph>
      <ParagraphTitle3>c. Information about users’ location</ParagraphTitle3>
      <Paragraph>
        When Shpock is used, the individual user’s location may be estimated
        through the IP or Geo-location detected on the individual’s device. This
        information is, for instance, used to show content or provide offers
        relevant to the user’s location. This is done to improve the Shpock
        services and to identify and display the location of the classified ads
        listed by the user. In order to avoid the identification of a user’s
        actual exact location, a displaced location of the seller is shown in
        the published classified ads.
      </Paragraph>
      <ParagraphTitle3>d. Cookies and locally stored content</ParagraphTitle3>
      <Paragraph>
        When the user uses the Shpock services the Operator applies various
        technologies in order to recognize the user. The Shpock website uses
        “cookies” in order to enable all functionalities of the Shpock website
        and to facilitate the use of the page. For instance, the Operator uses
        cookies so that the user does not have to log in every time he visits
        the Operator’s services. The Operator may also store anonymous
        identifiers locally on the user’s device, especially mobile devices that
        are incapable of using cookies. See Clause VII below for more
        information on cookies and anonymous identifiers.
      </Paragraph>
      <ParagraphTitle2>3. Information from other sources</ParagraphTitle2>
      <Paragraph>
        The Operator may from time to time receive information from other
        sources such as partners, ad networks and other third parties that help
        the Operator understand user activity and preferences. This is collected
        to improve the Shpock services. Examples of this include when the user
        creates a user account by connecting with his/her Facebook profile, and
        the Operator may add basic profile information. An advertising partner
        may also share information about what happened after the user had
        clicked on an ad on the Operator’s website, so that the Operator can
        measure the impact of an ad and provide more relevant content or
        advertisements. The analysis is usually carried out at an aggregated and
        anonymized level.
      </Paragraph>
      <ParagraphTitle1>III Processing purpose</ParagraphTitle1>
      <Paragraph>
        The Operator uses data of the user for the following purposes:
      </Paragraph>
      <Paragraph>
        The Operator processes data for the purposes of (1) delivering the
        Shpock services; (2) improving and developing the Shpock services; (3)
        delivering personalised advertisements, offerings and recommendations to
        the users; (4) identification of market trends; (5) preventing, limiting
        and investigating abuse of the Shpock services.
      </Paragraph>
      <Paragraph>
        More detailed information about purposes is available here below.
      </Paragraph>
      <ParagraphTitle2>1. Delivery of the Shpock-Services</ParagraphTitle2>
      <Paragraph>
        For the usage of Shpock-Services it is necessary, that the Operator
        identifies the users’ current location. This allows Shpock to show
        classified ads located close to the user and is also used when
        publishing classified ads by sellers. In order to avoid the
        identification of a user’s actual exact location, a displaced location
        of the seller is shown in the published classified ads.
      </Paragraph>
      <Paragraph>
        In addition, the Operator uses other personal information to personalise
        a user’s account and offer a good user experience. This relates
        primarily to user experience in connection with registration, login,
        surfing between services and purchases of products and services.
      </Paragraph>
      <ParagraphTitle2>
        2. Improvement and further development of the Shpock Services
      </ParagraphTitle2>
      <Paragraph>
        The Operator uses information to improve its services, for instance by
        trying to make the registration, log in and payment processes for
        premium services as user-friendly as possible. This may result in, for
        instance, registering all steps (screen views, clicks) related to the
        search of a particular product in order to understand if certain
        elements of the process are confusing to the users or not. The analysis
        is usually carried out at an aggregated and anonymized level, but the
        Operator may also use identifiable data to provide technical support and
        to understand how each individual uses the Shpock services.
      </Paragraph>
      <Paragraph>
        To improve the Shpock services and to detect and analyse errors, the
        Operator uses certain tracking-tools. Therefore, the Operator traces
        user behaviour independently as well as via third-party suppliers (e.g.
        Google Analytics, Firebase, Crashlytics).
      </Paragraph>
      <Paragraph>
        The Operator may inform the user about changes to or improvements of the
        Shpock services by using his e-mail address or – if the user uses mobile
        devices - through push notifications. In addition, the user will
        recurrently receive the Shpock newsletter. The newsletter can be
        unsubscribed in the user profile or by directly clicking on the
        unsubscribe link within the newsletter. Push notifications can be
        easiliy turned off directly in the settings of the used mobile device.
      </Paragraph>
      <ParagraphTitle2>
        3. Personalised contents, offerings and recommendations
      </ParagraphTitle2>
      <Paragraph>
        The Operator uses information to personalise and tailor services, and to
        also provide the users with a content that may be relevant to them.
        Sometimes the Operator serves content based on the user’s explicit
        wishes, such as if the user opts in to receive search alerts. The
        Operator may also serve content and recommendations based on user
        activities; for instance, when the Operator recommends products based on
        popularity among users.
      </Paragraph>
      <Paragraph>
        The Operator can advertise via the Shpock services and collaborates with
        third party providers in order to optimise the impact of the
        advertisement.
      </Paragraph>
      <Paragraph>
        In order to display personalised advertisements in the Services, data
        such as birthday (if shared by the user), gender (if shared by the
        user), approximate geographic location, language, internet service
        provider (ISP), categories of interest, can be shared with advertising
        network operators such as Google, Facebook, AppNexus and other
        comparable providers.
      </Paragraph>
      <Paragraph>
        If explicit user consent for such data transfer is required, consent
        will be collected. In this case the user consent can be withdrawn at any
        time in the user settings.
      </Paragraph>
      <ParagraphTitle2>4. Identification of market trends</ParagraphTitle2>
      <Paragraph>
        The Operator analyses data to understand market trends in order to
        improve, develop and customise the Shpock services. Such analysis can be
        made both by the Operator directly or by so called data processors,
        which analyse the data on behalf of the Operator (subject to strict data
        processing agreements that maintain data confidentiality and integrity).
      </Paragraph>
      <ParagraphTitle2>
        5. Preventing, limiting and investigating abuse of the Shpock services
      </ParagraphTitle2>
      <Paragraph>
        We use information to restrict various forms of abuse of the Shpock
        services, such as fraudulent activities, denial of service attacks,
        spamming, unauthorized logins and other actions that violate our Terms &
        Conditions, or are prohibited by law.
      </Paragraph>
      <ParagraphTitle1>IV Legal Basis for data processing</ParagraphTitle1>
      <Paragraph>
        If the operator processes personal user data such processing is
        necessary for the performance of the contractual services or for the
        purposes of the legitimate interests pursued by the operator.
      </Paragraph>
      <Paragraph>
        Any further data processing not necessary for the aforementioned
        purposes will be only carried out if the user has given consent to the
        processing or if necessary for compliance with a legal obligation to
        which the operator is subject.
      </Paragraph>
      <ParagraphTitle1>V Usage of data by third parties</ParagraphTitle1>
      <Paragraph>
        For all services, that use social networking functionalities such as the
        Facebook-like button or the Google +1 button, please be aware, that
        Facebook and other suppliers of social networks can collect user data
        even if the user is not registered on the page or has not specifically
        approved the data transfer. By using popular functions such as the
        Facebook “Like-Button” or the Google “+1 Button” the providers of these
        features can draw conclusions concerning page visits and the individual
        user’s behaviour. Further information can be found in the terms and
        privacy policy of the respective supplier (amongst others
        <a href="http://www.google.at/intl/en/policies/privacy/">
          http://www.google.at/intl/en/policies/privacy/
        </a>
        ,{' '}
        <a href="http://www.facebook.com/policy.php">
          http://www.facebook.com/policy.php
        </a>
        ).
      </Paragraph>
      <Paragraph>
        Users may prevent such data collection by logging out of social media
        networks before logging in to the services.
      </Paragraph>
      <ParagraphTitle1>VI Transfer of data</ParagraphTitle1>
      <Paragraph>
        The Operator will not share, sell, convey or otherwise disclose personal
        data other than as stated in this Privacy Policy, unless it is required
        to do so by law or the user has given his/her explicit consent to do so.
      </Paragraph>
      <Paragraph>
        However, the operator may collaborate with service providers who process
        data based on corresponding data processing agreements. Service
        providers processing user data on behalf of the operator will only do so
        for the purposes as prescribed in the data processing agreement and as
        explicitly instructed by the operator.
      </Paragraph>
      <Paragraph>
        Personal data may be transferred due to the following reasons:
      </Paragraph>
      <ParagraphTitle2>
        1. Transfer of personal data within the Adevinta Group
      </ParagraphTitle2>
      <Paragraph>
        Personal data may be transferred to other companies within Adevinta
        Group and companies owned wholly or partially, directly or indirectly by
        Adevinta insofar it is relevant for achieving the purposes under this
        privacy policy except where the interests of the user in confidentiality
        prevail. Such companies may independently process personal data with the
        aim of personalising and improving user’s experience and/or their own
        products and services, in accordance with the privacy policy applicable
        to each specific company.
      </Paragraph>
      <ParagraphTitle2>
        2. Disclosure of personal data to a third party
      </ParagraphTitle2>
      <Paragraph>
        If there is suspicion of illegal activities in connection with the use
        of our services, information may be disclosed to the police and other
        public authorities subject to a court or administrative order. The
        Operator may share information with other third parties if they can
        prove an overriding interest in the identity verification of a user as
        well as in the determination of certain unlawful facts. Further third
        parties have to substantiate that the knowledge of this information is
        essential evidence for a prosecution act.
      </Paragraph>
      <ParagraphTitle2>3. Data processors</ParagraphTitle2>
      <Paragraph>
        The operator collaborates with service providers (data processors within
        the meaning of the GDPR) especially for displaying adverts, to analyse
        user behaviour and to integrate third party services. The operator
        enters into data processor agreements according to Article 28 GDPR with
        such service providers.
      </Paragraph>
      <ParagraphTitle2>
        4. Transfer of personal data to a country outside the EU or the EEA
      </ParagraphTitle2>
      <Paragraph>
        The Operator transfers personal data only to a third country, i.e. a
        country outside the EU or the EEA where there is either a decision of
        the European Commission on sufficient data protection standards, or
        there is an agreement on the EU Standard Contractual Clauses (
        <a href="http://ec.europa.eu/justice/data-protection/international-transfers/transfer/index_en.htm">
          http://ec.europa.eu/justice/data-protection/international-transfers/transfer/index_en.htm
        </a>
        ; and
        <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en">
          https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en
        </a>
        ) and such transfer based on Standard Contractual Clauses is legally. In
        all other cases any export of personal data in Non-EU-countries is made
        only with the consent of the user.
      </Paragraph>
      <ParagraphTitle2>5. Payment via PayPal</ParagraphTitle2>
      <Paragraph>
        In case the user is using the PayPal payment function within the Shpock
        App, the following personal data will be stored and/or shared with
        PayPal:
      </Paragraph>
      <Paragraph>
        The billing details entered into the form by a seller during the
        check-in process will not be shared with PayPal.
      </Paragraph>
      <Paragraph>
        The operator will save the seller’s billing details in the check-in form
        for the duration of the seller’s contractual relationship with the
        operator, to make the payment process easier for the seller. The billing
        address will be automatically deleted 5 years after the seller’s last
        use of Shpock. Invoices will be saved for up to 10 years, because it is
        a legal requirement in Austria.
      </Paragraph>
      <Paragraph>
        The shipping address entered into the form by a buyer during the
        checkout process will be shared with PayPal with the buyer`s explicit
        consent, as it is a requirement to be fully covered by PayPal’s Buyer
        Protection. For more information on how PayPal uses the user data,
        please see the PayPal Privacy Policy.
      </Paragraph>
      <Paragraph>
        The operator will save the buyer’s shipping address for the duration of
        your contractual relationship with the operator, to make the payment
        process easier for the buyer. The shipping address will be automatically
        deleted 5 years after the buyer’s last use of Shpock.
      </Paragraph>
      <ParagraphTitle1>VII Cookies and other technologies</ParagraphTitle1>
      <Paragraph>
        The Shpock website uses “cookies” in order to enable all functionalities
        of the Shpock website and to facilitate the use of the page.
        &quot;Cookies&quot; are small text files that allow the Operator to
        store specific information on the user’s PC while he/she visits the
        Operator’s website. A list of the cookies placed directly by the
        operator can be found here.
      </Paragraph>
      <Paragraph>
        The User may disable cookies in general, restrict them from certain
        websites or adjust his/her browser to notify him/her about cookies.
        Cookies can be removed from a PCs hard disk at any time. Please note
        that in this case the display of the website might be restricted and
        that the user guidance might be limited.
      </Paragraph>
      <ParagraphTitle1>VIII User Rights</ParagraphTitle1>
      <ParagraphTitle2>1. Right to Information</ParagraphTitle2>
      <Paragraph>
        The user has the right to be informed on if and what personal data of
        the user the operator processes and to request a copy of such data.
      </Paragraph>
      <Paragraph>
        To exercise this right the user can log in to the Shpock account in its
        web version under https://www.shpock.com/ and log in to “My Shpock”.
        Then the user has to click on the gear wheel to open up the contextual
        settings menu. Inside this menu the user has to click on \&aps;Privacy
        settings\&aps;. Under these settings the user can click on the button
        “request data take out”. The user will be informed that the data takeout
        is being processed and may take up to a few days. As soon as the data
        takeout is ready there will be an information via e-mail. The link will
        be shared in the notification e-mail and in the privacy settings and
        will be accessible for one week as from notification for security
        reasons.
      </Paragraph>
      <ParagraphTitle2>
        2. Right of rectification, restriction and deletion
      </ParagraphTitle2>
      <Paragraph>
        The user has the right to rectification, restriction, addition or
        deletion of personal data.
      </Paragraph>
      <Paragraph>Right of deletion may be exercised as follows:</Paragraph>
      <Paragraph>
        App users may visit the help center which can be found under “My
        Shpock”. The article “How to delete my Shpock Account” contains a link
        to the automated account deletion process. The deletion request will be
        processed within 30 days at the latest. As soon as the deletion is
        completed the user will be informed accordingly.
      </Paragraph>
      <Paragraph>
        User of the Shpock web service may find the deletion request process
        under the “FAQ”. The question “How to delete my Shpock account” contains
        a link to the automated account deletion process. The deletion request
        will be processed within 30 days at the latest. As soon as the deletion
        is completed the user will be informed accordingly.
      </Paragraph>
      <ParagraphTitle2>3. Right to object</ParagraphTitle2>
      <Paragraph>
        The user is in certain cases entitled to object against processing
        personal data concerning him and to withdraw consent where consent has
        been given.
      </Paragraph>
      <Paragraph>
        The right to object may be exercised directly in the user settings under
        “My Shpock”.
      </Paragraph>
      <ParagraphTitle2>4. Right to data portability</ParagraphTitle2>
      <Paragraph>
        The user has the right to demand data portability of registration data
        concerning him. In order to exercise the right to data portability the
        user has to log in to the Shpock account in its web version under
        https://www.shpock.com/ and log in to “My Shpock”. Then the user has to
        click on the gear wheel to open up the contextual settings menu. Inside
        this menu the user has to click on \&apos;Privacy settings\&apos;.
      </Paragraph>
      <Paragraph>
        The user can generate a data portability link containing the
        registration data, which can be shared with third parties.
      </Paragraph>
      <ParagraphTitle2>5. Right to complaint</ParagraphTitle2>
      <Paragraph>
        The user has the right to lodge a complaint with the competent
        supervisory authority (www.dsb.gv.at).
      </Paragraph>
      <Paragraph>
        Our data protection officer can be reached under
        dataprotectionofficer@shpock.com.
      </Paragraph>
      <ParagraphTitle1>IX Duration of storage</ParagraphTitle1>
      <Paragraph>
        Personal data is kept no longer than necessary in order to be able to
        satisfy the purpose of the processing. Personal data is deleted or
        anonymized on a regular basis when it is no longer relevant for the
        purposes for which it has been collected. The Operator will, for
        example, retain personal data as long as the account has not been
        deleted or the user has revoked his consent according to Clause VI or as
        long as necessary for the provision of the Shpock services or as
        otherwise necessary to comply with applicable laws.
      </Paragraph>
      <Paragraph>
        Privacy Policy Version: 2019-03-27 Finderly GmbH, Vienna, Austria
      </Paragraph>
    </Box>
  </PageLayout>
);

export default PrivacyPolicyPage;
