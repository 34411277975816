import React, { useState } from 'react';
import useFetch from 'use-http';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import MyTextField from '../../components/MyTextField';
import Spinner from '../../components/Spinner';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { PasswordResetData } from '../../types/PasswordResetData';
import { BASE_URL } from '../../constants';
import { dialog, colors } from '../../theme/theme';
import MyCloseIcon from '../../components/MyCloseIcon';
import logger from '../../logger';

type FormValues = {
  code: string;
};

type CheckEmailProps = {
  goNext: () => void;
  onClose: () => void;
};

const CheckEmail = ({ goNext, onClose }: CheckEmailProps): JSX.Element => {
  const [invalidCode, setInvalidCode] = useState(false);

  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;

  const { post, loading, error, response } = useFetch(BASE_URL);

  function getPasswordResetDataFromCache(): PasswordResetData | null {
    const paswordResetData = localStorage.getItem('passwordResetData');
    if (paswordResetData === null) {
      return null;
    }

    return JSON.parse(paswordResetData);
  }

  function storePasswordResetDataInCache(data: PasswordResetData) {
    localStorage.setItem('passwordResetData', JSON.stringify(data));
  }

  async function sendPasswordResetData(data: PasswordResetData) {
    await post('/password_reset', data);
    if (response.ok) {
      storePasswordResetDataInCache(data);
      goNext();
    } else if (response.status === 403) {
      setInvalidCode(true);
    }
  }

  function buildPasswordResetData(): PasswordResetData {
    const passwordResetData = getPasswordResetDataFromCache();
    if (passwordResetData === null) {
      logger.log({
        message: 'Failed to check email during Password Reset',
        reason: 'No password reset data in localstorage',
      });
      throw new Error('');
    }

    passwordResetData.steps.push({
      name: 'check_email',
      inputs: getValues(),
    });

    return passwordResetData;
  }

  function handleNextClick() {
    const passwordResetData = buildPasswordResetData();
    sendPasswordResetData(passwordResetData);
  }

  function handleCloseIconClick() {
    onClose();
  }

  return (
    <>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography
            variant="h5"
            sx={{
              textOverflow: 'ellipsis',
            }}
          >
            Check your email
          </Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              disabled={!isDirty || !isValid}
              onClick={handleNextClick}
            >
              Verify
            </MyButton>
          </Box>
        )}
      />

      {loading && !error && !invalidCode && <Spinner />}

      {!loading && error && !invalidCode && <SomethingWentWrongFeedback />}

      {!loading && (!error || (error && invalidCode)) && (
        <>
          <Box sx={{ padding: dialog.input.padding }}>
            <Typography
              variant="body1"
              sx={{
                color: colors.grey,
              }}
            >
              You&apos;ll receive a code to verify here so you can reset your
              account password.
            </Typography>
          </Box>
          <Box sx={{ padding: dialog.input.padding }}>
            <Controller
              control={control}
              name="code"
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <MyTextField
                  id="code-input"
                  label="Verification code"
                  error={invalidCode}
                  helperText={
                    invalidCode
                      ? 'The code you provided is not the right one... Please provide the right code'
                      : ''
                  }
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default CheckEmail;
