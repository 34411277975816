import React from 'react';
import { Card } from '@mui/material';
import { useHistory } from 'react-router';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { Need } from '../../types/Need';
import getArticle from '../../utils/getArticle';
import formatMoney from '../../utils/formatMoney';
import { getTimeFromNow } from '../../utils/date';
import { formatLocation } from './SearchNeedCard';
import Hashtag from './Hashtag';
import { colors } from '../../theme/theme';

const WhatsHappeningCard = ({ need }: { need: Need }): JSX.Element => {
  const history = useHistory();

  return (
    <Box
      onClick={() => history.push(`/need/${need.ID}`)}
      sx={{ cursor: 'pointer' }}
    >
      <Box
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'unset',
          },
        }}
      >
        {/* <CardTitle>{formatMoney(need.offer, need.currency)}</CardTitle> */}
        <Card
          sx={{
            boxShadow: 'unset',
            borderRadius: '0px',
          }}
        >
          <Box padding="12px 16px">
            <Box display="flex" justifyContent="space-between">
              <Box marginRight="12px">
                <Typography variant="body2" variantMapping={{ body2: 'div' }}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      fontWeight: 'normal !important',
                      color: colors.grey,
                    }}
                  >
                    {formatLocation(need.geocode)}
                    <Box
                      sx={{
                        display: 'inline-block',
                        fontWeight: 'normal !important',
                        color: colors.grey,
                        padding: '0 4px',
                      }}
                    >
                      ·
                    </Box>
                    {getTimeFromNow(need.UpdatedAt)}
                  </Box>
                </Typography>
                <Box margin="2px 0">
                  <Typography variant="h6" variantMapping={{ h6: 'div' }}>
                    {need.profile.firstname}
                  </Typography>
                </Box>
                <Box marginTop="4px">
                  <Typography
                    variant="body2"
                    sx={{
                      color: `${colors.grey} !important`,
                    }}
                  >
                    💸 Offering {formatMoney(need.offer, need.currency)} for{' '}
                    {getArticle(need.title)} <Hashtag need={need} />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default WhatsHappeningCard;
