import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { useSelector } from 'react-redux';
import { State } from '../../store/types';
import { Item } from '../../types/Item';
import { Need } from '../../types/Need';
import ProposeItemDialog from './ProposeItemDialog';
import { Profile } from '../../types/Profile';
import useDialogsManager from '../../hooks/useDialogsManager';
import { BASE_URL } from '../../constants';

type ProposeItemDialogContainerProps = {
  need: Need;
  open: boolean;
  onClose: () => void;
  callback?: () => void;
};

const ProposeItemDialogContainer = ({
  need,
  open,
  onClose,
  callback,
}: ProposeItemDialogContainerProps): JSX.Element => {
  const profile = useSelector<State, Profile | null>(
    (state) => state.profile,
  ) as Profile;

  const [proposeError, setProposeError] = useState(false);

  const { openCreateNewItemDialog, openProposeItemDialog } =
    useDialogsManager();

  const [items, setItems] = useState<Item[] | null>(null);

  const { get, cache, loading, error, response } = useFetch(BASE_URL);

  async function getItems() {
    const data = await get(`/profiles/${profile.ID}/proposing`);
    if (response.ok) {
      setItems(data);
    }
  }

  useEffect(() => {
    getItems();
  }, []);

  function handleAddIconClick() {
    if (openCreateNewItemDialog && openProposeItemDialog) {
      openCreateNewItemDialog(() => {
        cache.clear();
        openProposeItemDialog(need, callback);
      });
    }
  }

  function handleProposeError() {
    setProposeError(true);
  }

  return (
    <ProposeItemDialog
      open={open}
      onClose={onClose}
      items={items}
      need={need}
      handleAddIconClick={handleAddIconClick}
      loading={loading || (!error && items === null)}
      error={Boolean(error) || Boolean(proposeError)}
      onProposeError={handleProposeError}
      callback={callback}
    />
  );
};

ProposeItemDialogContainer.defaultProps = {
  callback: undefined,
};

export default ProposeItemDialogContainer;
