import React from 'react';
import { Box, SxProps } from '@mui/system';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import Typography from '@mui/material/Typography';
import useSecurity from '../../hooks/useSecurity';
import { Need } from '../../types/Need';
import { Geocode } from '../../types/Geocode';
import getArticle from '../../utils/getArticle';
import { getTimeFromNow } from '../../utils/date';
import useDialogsManager from '../../hooks/useDialogsManager';
import useMyPropositions from '../../hooks/useMyPropositions';
import formatMoney from '../../utils/formatMoney';
import { State } from '../../store/types';
import { Profile } from '../../types/Profile';
import { Item } from '../../types/Item';
import { MyCard, MyCardHeader, MyCardContent } from './MyCards';
import CardHover from './CardHover';
import Hashtag from './Hashtag';
import { colors, icon } from '../../theme/theme';
import MyIconButton from '../MyIconButton';

export const CardTitle = ({
  colorBlack,
  children,
}: {
  colorBlack?: boolean;
  children: React.ReactNode;
}): JSX.Element => (
  <Box padding="12px 16px">
    <Typography
      variant="h5"
      sx={{
        color: colorBlack ? colors.black : colors.primary,
      }}
      // align="center"
    >
      {children}
    </Typography>
  </Box>
);

CardTitle.defaultProps = {
  colorBlack: false,
};

export const YouTookIt = ({ items }: { items: Item[] }): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Typography
      variant="body2"
      sx={{ fontWeight: 'bold !important', color: colors.grey }}
    >
      You proposed {items[0].name}
    </Typography>
  </Box>
);

export const Taken = (): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Typography
      variant="body2"
      sx={{ fontWeight: 'bold !important', color: colors.secondary }}
    >
      Taken
    </Typography>
  </Box>
);

export const ButtonHover = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover *': {
          color: `${theme.palette.primary.main} !important`,
        },
      }}
    >
      {children}
    </Box>
  );
};

export const CurrencyIcon = ({
  currency,
  sx,
}: {
  currency: string;
  sx: SxProps;
}): JSX.Element => (
  <>
    {currency === 'USD' && <AttachMoneyOutlinedIcon sx={sx} />}

    {currency === 'GBP' && <CurrencyPoundOutlinedIcon sx={sx} />}

    {currency === 'EUR' && (
      <EuroOutlinedIcon
        sx={{
          ...sx,
          marginRight: '4px',
        }}
      />
    )}
  </>
);

type SearchNeedCardProps = {
  need: Need;
};

export function formatLocation(geocode: Geocode): string {
  if (geocode.PostalCode && geocode.Municipality) {
    if (geocode.Country === 'GBR') {
      return `${geocode.PostalCode.split(' ')[0]} ${geocode.Municipality}`;
    }
    return `${geocode.PostalCode} ${geocode.Municipality}`;
  }

  if (geocode.Municipality) return geocode.Municipality;

  return geocode.Label;
}

const SearchNeedCard = ({ need }: SearchNeedCardProps): JSX.Element => {
  const history = useHistory();

  const { openProposeItemDialog, openLoginDialog } = useDialogsManager();
  const { description, offer, currency } = need;
  const { loggedIn } = useSecurity();
  const me = useSelector<State, Profile>((state) => state.profile as Profile);
  const { propositions } = useMyPropositions();

  function itsMe(): boolean {
    if (!loggedIn) return false;
    return need.profile.ID === me.ID;
  }

  const proposedItems: Item[] = [];

  if (propositions) {
    propositions.forEach((proposition) => {
      if (proposition.need.ID === need.ID) {
        proposedItems.push(proposition.item);
      }
    });
  }

  return (
    <Box
      onClick={() => history.push(`/need/${need.ID}`)}
      sx={{ cursor: 'pointer' }}
    >
      {/* <CardTitle>{formatMoney(offer, currency)}</CardTitle> */}
      <CardHover>
        <MyCard>
          {proposedItems.length > 0 && (
            <Box marginBottom="4px">
              <YouTookIt items={proposedItems} />
            </Box>
          )}
          {need.fulfilled && (
            <Box marginBottom="4px">
              <Taken />
            </Box>
          )}
          <MyCardHeader
            title={
              <Typography variant="h6" variantMapping={{ h6: 'div' }}>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/profile/${need.profile.ID}`);
                  }}
                  sx={{
                    display: 'inline-block',
                    cursor: 'pointer !important',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  <span className="card-username">
                    {need.profile.firstname}
                  </span>
                </Box>
                <Box
                  sx={{
                    marginLeft: '4px',
                    display: 'inline-block',
                    fontWeight: 'normal !important',
                    color: colors.grey,
                  }}
                >
                  {formatLocation(need.geocode)}
                  <Box
                    sx={{
                      display: 'inline-block',
                      fontWeight: 'normal !important',
                      color: colors.grey,
                      padding: '0 4px',
                    }}
                  >
                    ·
                  </Box>
                  {getTimeFromNow(need.UpdatedAt)}
                </Box>
              </Typography>
            }
            subheader={
              <Typography
                variant="body1"
                sx={{
                  color: `${colors.grey} !important`,
                }}
              >
                💸 Offering {formatMoney(offer, currency)} for{' '}
                {getArticle(need.title)} <Hashtag need={need} />
              </Typography>
            }
          />
          <MyCardContent>
            <Box marginTop="12px">
              <Typography variant="body1">{description}</Typography>
            </Box>
            {!itsMe() && !need.fulfilled && (
              <Box marginTop="12px">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      if (loggedIn) {
                        if (openProposeItemDialog) {
                          openProposeItemDialog(need);
                        }
                      } else if (openLoginDialog) {
                        openLoginDialog();
                      }
                    }}
                  >
                    <ButtonHover>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box marginRight="4px">
                          <MyIconButton>
                            <HandshakeOutlinedIcon
                              sx={{
                                fontSize: icon.fontSize.small,
                                color: colors.grey,
                              }}
                            />
                          </MyIconButton>
                        </Box>
                        <Typography
                          variant="button"
                          sx={{ color: colors.grey }}
                        >
                          Accept
                        </Typography>
                      </Box>
                    </ButtonHover>
                  </Box>
                </Box>
              </Box>
            )}
          </MyCardContent>
        </MyCard>
      </CardHover>
    </Box>
  );
};

export default SearchNeedCard;
