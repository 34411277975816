import React, { useState } from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyUnstyledButton from '../../components/MyButton/MyUnstyledButton';
import MyDialog from '../../components/MyDialog';
import MyCloseIcon from '../../components/MyCloseIcon';
import { divider } from '../../theme/theme';
import useDialogsManager from '../../hooks/useDialogsManager';
import SignupDialog from './SignupDialog';
import {
  MyEmailLoginButton,
  MyGoogleLoginButton,
  MyFacebookLoginButton,
} from '../../components/SocialLoginButtons';

type SignupWithProps = {
  open: boolean;
  onClose: () => void;
};

const SignupWith = ({ open, onClose }: SignupWithProps): JSX.Element => {
  const [selectedWith, setSelectedWith] = useState<null | 'with_email'>(null);
  const { openLoginDialog } = useDialogsManager();

  function handleCloseIconClick() {
    onClose();
  }

  function handleLoginButtonClick() {
    if (openLoginDialog) {
      openLoginDialog();
    }
  }

  function handleSignupWithEmailBtnClick() {
    setSelectedWith('with_email');
  }

  return (
    <MyDialog open={open} onClose={onClose}>
      {selectedWith === null && (
        <>
          <MyAppBar
            leftSideRender={() => (
              <Box marginLeft="0">
                <AppBarIconWrapper justifyContent="flex-start">
                  <MyIconButton onClick={handleCloseIconClick}>
                    <MyCloseIcon />
                  </MyIconButton>
                </AppBarIconWrapper>
              </Box>
            )}
            middleRender={() => <div />}
            rightSideRender={() => <div />}
          />

          <Box width="100%" maxWidth="300px" margin="0 auto">
            <Box marginBottom="16px">
              <Box padding="16px 0">
                <Typography variant="h4" align="center">
                  Sign up
                </Typography>
              </Box>
            </Box>
            <Box marginBottom="16px" />

            <Box margin="12px 0">
              <MyGoogleLoginButton text="Continue with Google" />
            </Box>

            <Box margin="12px 0">
              <MyFacebookLoginButton text="Continue with Facebook" />
            </Box>
            <Box margin="0px 20px">
              <Divider
                sx={{
                  '&:before, &:after': {
                    borderColor: divider.color,
                  },
                }}
              >
                or
              </Divider>
            </Box>
            <Box margin="12px 0">
              <MyEmailLoginButton
                text="Continue with Email"
                onClick={() => handleSignupWithEmailBtnClick()}
              />
            </Box>

            <Box sx={{ marginTop: '40px' }}>
              <Typography variant="body1" align="center">
                Already a member?{' '}
                <MyUnstyledButton onClick={handleLoginButtonClick}>
                  Log in
                </MyUnstyledButton>
              </Typography>
            </Box>
          </Box>
        </>
      )}

      {selectedWith === 'with_email' && <SignupDialog onClose={onClose} />}
    </MyDialog>
  );
};

export default SignupWith;
