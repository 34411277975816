import React, { MouseEventHandler, useState, useRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Portal from '@mui/material/Portal';
import Image from 'material-ui-image';
import ImgsViewer from 'react-images-viewer';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { PhotoMetadata } from '../../types/Item';
import {
  addPhotoDimensionsToCloudfrontURL,
  convertS3ToCloudfrontURL,
} from '../../aws/s3';
import theme, { icon } from '../../theme/theme';

type ImageGalleryProps = {
  photos: PhotoMetadata[];
};

const ImageGallery = ({ photos }: ImageGalleryProps): JSX.Element => {
  const portalContainer = useRef(null);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [currImg, setCurrImg] = useState(0);

  function viewerGoPrevious() {
    if (currImg === 0) return;
    setCurrImg(currImg - 1);
  }

  function viewerGoNext() {
    if (currImg > photos.length - 1) return;
    setCurrImg(currImg + 1);
  }

  function openViewer() {
    setViewerOpen(true);
  }

  function closeViewer() {
    setViewerOpen(false);
  }

  function buildImageClickHandler(index: number) {
    return function handleImageClick() {
      setCurrImg(index);
      openViewer();
    };
  }

  const handleGalleryClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Box
        onClick={handleGalleryClick}
        sx={{
          borderRadius: '4px',
          borderColor: 'rgba(0,0,0,0.12)',
          borderWidth: 'thin !important',
          borderStyle: 'solid',
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Box
            sx={{
              paddingTop:
                photos.length === 1
                  ? `${(
                      (photos[0].height * 100) /
                      photos[0].width
                    ).toString()}%`
                  : '56.25%',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              bottom: '0px',
              width: '100%',
              height: '100%',
            }}
          >
            {photos.length === 1 && (
              <Image
                onClick={buildImageClickHandler(0)}
                src={addPhotoDimensionsToCloudfrontURL(
                  '694',
                  `${Math.ceil(
                    (694 * photos[0].height * 100) / photos[0].width / 100,
                  ).toString()}`,
                  convertS3ToCloudfrontURL(photos[0].url),
                )}
                imageStyle={{
                  height: '100%',
                  width: '100%',
                }}
                style={{
                  paddingTop: `${(
                    (photos[0].height * 100) /
                    photos[0].width
                  ).toString()}%`,
                }}
                loading={<CircularProgress size={26} color="primary" />}
              />
            )}
            {photos.length > 1 && (
              <Box
                className="images"
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gridAutoRows: '1fr',
                  width: '100%',
                  gap: '2px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  height: '100%',
                  margin: '0',
                  padding: '0',
                }}
              >
                {photos.map((photo, idx) => (
                  <Box
                    key={photo.filename + idx} // eslint-disable-line react/no-array-index-key
                    sx={{
                      position: 'relative',
                      borderRadius: 'unset',
                      height: '100%',
                      width: '100%',
                      listStyle: 'none',
                    }}
                  >
                    <Image
                      onClick={buildImageClickHandler(idx)}
                      src={
                        photos.length === 2
                          ? addPhotoDimensionsToCloudfrontURL(
                              '342',
                              '386',
                              convertS3ToCloudfrontURL(photo.url),
                            )
                          : addPhotoDimensionsToCloudfrontURL(
                              '342',
                              '192',
                              convertS3ToCloudfrontURL(photo.url),
                            )
                      }
                      style={{
                        paddingTop: '0 !important',
                        height: '100%',
                      }}
                      imageStyle={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                      loading={<CircularProgress size={26} color="primary" />}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ImgsViewer
        imgs={photos.map((p) => ({ src: p.url }))}
        currImg={currImg}
        isOpen={viewerOpen}
        onClickPrev={viewerGoPrevious}
        onClickNext={viewerGoNext}
        onClose={closeViewer}
        showImgCount={false}
        spinner={() => (
          <ThemeProvider theme={theme}>
            <CircularProgress size={26} color="primary" />
          </ThemeProvider>
        )}
      />
      {viewerOpen && (
        <Portal container={portalContainer.current}>
          <Box
            sx={{
              position: 'fixed',
              top: '0',
              right: '0',
              zIndex: 2002,
            }}
          >
            <Box padding="12px">
              <IconButton
                sx={{
                  width: '36px',
                  height: '36px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(51, 51, 51, 0.75)',
                }}
                onClick={closeViewer}
              >
                <CloseIcon
                  sx={{ fontSize: icon.fontSize.medium, color: '#FFFFFF' }}
                />
              </IconButton>
            </Box>
          </Box>
        </Portal>
      )}
    </>
  );
};

export default ImageGallery;
