import React from 'react';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { colors } from '../../theme/theme';

const LoadingAlert = (): JSX.Element => (
  <Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 12px',
      }}
    >
      <CircularProgress
        size={26}
        sx={{ color: `${colors.primary} !important` }}
      />
    </Box>
  </Box>
);

export default LoadingAlert;
