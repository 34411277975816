import React from 'react';
import { Button, ButtonProps, useTheme } from '@mui/material';
import { colors, button } from '../../theme/theme';

const MyButton = (props: ButtonProps): JSX.Element => {
  const theme = useTheme();

  const { children, size, sx, color } = props;

  const styles = {
    boxShadow: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    border: `1px solid ${color === 'primary' ? colors.primary : colors.black}`,
    borderRadius: `${button.border.radius} !important`,
    color: '#fff !important',
    fontWeight: '700 !important',
    backgroundColor: `${
      color === 'primary' ? colors.primary : colors.black
    } !important`,
    '&': {
      textTransform: 'initial !important',
    },
    '&.MuiButton-outlined': {
      color: `${
        color === 'primary' ? colors.primary : colors.black
      } !important`,
      border: `1px solid ${
        color === 'primary' ? colors.primary : button.border.color
      } !important`,
      backgroundColor: 'transparent !important',
    },
    '&.MuiButtonBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
      boxShadow: 'none !important',
      border: 'none',
    },
  };

  if (size === 'small')
    return (
      <Button
        variant="contained"
        fullWidth
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        sx={{
          fontSize: theme.typography.button.fontSize,
          padding: '0 16px !important',
          minHeight: '32px',
          ...styles,
          ...sx,
        }}
      >
        {children}
      </Button>
    );

  if (size === 'medium' || size === undefined)
    return (
      <Button
        variant="contained"
        fullWidth
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        sx={{
          fontSize: theme.typography.body1.fontSize,
          padding: '0 16px !important',
          minHeight: '36px',
          ...styles,
          ...sx,
        }}
      >
        {children}
      </Button>
    );

  return (
    <Button
      variant="contained"
      fullWidth
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        fontSize: '18px !important',
        padding: '0 32px !important',
        minHeight: '52px',
        ...styles,
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export default MyButton;
