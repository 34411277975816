import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { buildCategoryTree, CategoryNode } from '../../utils/categories';
import useExploreNeeds from '../../hooks/useExploreNeeds';
import {
  NEWEST_FIRST,
  OFFER_HIGH_TO_LOW,
  OFFER_LOW_TO_HIGH,
  findCorrespondingSortOption,
  getQueryParamFromSortOption,
} from '../SearchNeedsPage/sortOptions';
import SearchExplorePage from './SearchExplorePage';
import {
  FilterByLocationOptions,
  BiasPoint,
} from '../SearchNeedsPage/LocationFilter';
import { calculateValue } from '../SearchNeedsPage/miles';

const SearchExplorePageContainer = (): JSX.Element => {
  const searchParams = new URLSearchParams(useLocation().search);
  const minAmount = searchParams.get('minAmount') as string;
  const maxAmount = searchParams.get('maxAmount') as string;
  const searchedItemTitle = searchParams.get('title') as string;

  const [needTitleQueryParam, setNeedTitleQueryParam] =
    useState(searchedItemTitle);

  const [sortOptionQueryParam, setSortOptionQueryParam] = useState('');

  const [selectedCategoryNode, setSelectedCategoryNode] =
    useState<CategoryNode | null>(null);
  const radiusIndexRef = useRef(17);
  const biasPointRef = useRef<BiasPoint | null>(null);
  const radiusOnRef = useRef(false);

  const {
    loading,
    error,
    data = [],
    reload,
  } = useExploreNeeds({
    minAmount: Number.parseInt(minAmount, 10),
    maxAmount: maxAmount ? Number.parseInt(maxAmount, 10) : -1,
    title: needTitleQueryParam,
    radius: calculateValue(radiusIndexRef.current),
    biasPoint: biasPointRef.current,
  });

  // const extractCategories = (needs: Need[]): string[] =>
  //   needs.map((need) => need.category).filter((v, i, a) => a.indexOf(v) === i);

  const handleCategoryNodeChange = (selected: CategoryNode) => {
    setSelectedCategoryNode(selected);
  };

  useEffect(() => {
    if (data.length > 0) {
      const rootCategoryNode = buildCategoryTree(
        data.map((need) => need.category),
      );
      setSelectedCategoryNode(rootCategoryNode);
    }
  }, [data]);

  const handleFilterByLocation = (newOptions: FilterByLocationOptions) => {
    radiusIndexRef.current = newOptions.radius;
    if (radiusIndexRef.current === 17) {
      radiusOnRef.current = false;
    } else {
      radiusOnRef.current = true;
    }

    biasPointRef.current = newOptions.biasPoint;

    (
      window as {
        scroll: ({ top, left }: { top: number; left: number }) => void;
      }
    ).scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth',
    });

    reload();
  };

  const handleSortOptionChange = (sortOption: string) => {
    setSortOptionQueryParam(getQueryParamFromSortOption(sortOption));
    reload();
  };

  const handleFilterByNeedTitle = (needTitle: string) => {
    setNeedTitleQueryParam(needTitle.trim());
    reload();
  };

  return (
    <SearchExplorePage
      needs={data}
      searchedItemTitle={needTitleQueryParam}
      minAmount={Number.parseInt(minAmount, 10)}
      maxAmount={maxAmount ? Number.parseInt(maxAmount, 10) : -1}
      needTitle={needTitleQueryParam}
      sortOptions={[NEWEST_FIRST, OFFER_HIGH_TO_LOW, OFFER_LOW_TO_HIGH]}
      selectedSortOption={findCorrespondingSortOption(sortOptionQueryParam)}
      selectedCategoryNode={selectedCategoryNode}
      handleCategoryNodeChange={handleCategoryNodeChange}
      handleFilterByLocation={handleFilterByLocation}
      handleSortOptionChange={handleSortOptionChange}
      handleFilterByNeedTitle={handleFilterByNeedTitle}
      loading={loading}
      error={Boolean(error)}
      biasPoint={biasPointRef.current}
      radiusIndex={radiusIndexRef.current}
      radiusOn={radiusOnRef.current}
    />
  );
};

export default SearchExplorePageContainer;
