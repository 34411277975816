import React from 'react';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import { CookiesBanner, StyledProvider } from 'components-extra';
import AppBar from '@mui/material/AppBar';
import styled from 'styled-components';
import { colors, borderRadius } from '../../theme/theme';

type MyCookiesBannerProps = {
  onClose: () => void;
};

const MyCookiesBanner = ({ onClose }: MyCookiesBannerProps): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();

  const Container = styled.div`
    ${CookiesBanner} {
      padding: 20px;
    }

    ${CookiesBanner} > .MuiTypography-root {
      font-size: ${theme.typography.body2.fontSize};
      margin-top: 16px !important;
      margin-bottom: 12px !important;
    }

    ${CookiesBanner.Button} {
      font-size: ${theme.typography.body1.fontSize};
      border-radius: ${borderRadius};
      text-transform: initial !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      word-break: initial;
      width: auto;
    }
  `;

  function handleSetPreferencesClick() {
    history.push('/privacy-safety');
    onClose();
  }

  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <StyledProvider
        theme={{
          palette: {
            primary: {
              main: colors.black,
            },
          },
        }}
      >
        <Container>
          <CookiesBanner text="By using Bonvih’s services you agree to our Cookies Use. We and our partners operate globally and use cookies, including for analytics, personalisation, and ads.">
            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <CookiesBanner.Button onClick={handleSetPreferencesClick}>
                  Set preferences
                </CookiesBanner.Button>
              </Grid>
              <Grid item>
                <CookiesBanner.Button onClick={onClose}>
                  Close
                </CookiesBanner.Button>
              </Grid>
            </Grid>
          </CookiesBanner>
        </Container>
      </StyledProvider>
    </AppBar>
  );
};

export default MyCookiesBanner;
