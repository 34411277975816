import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useSearchNeeds from '../../hooks/useSearchNeeds';
import useDialogsManager from '../../hooks/useDialogsManager';
import useSecurity from '../../hooks/useSecurity';
import { Need } from '../../types/Need';
import {
  NEWEST_FIRST,
  sortOptionMappings,
} from '../SearchNeedsPage/sortOptions';
import HomePage from './HomePage';

const HomePageContainer = (): JSX.Element => {
  const history = useHistory();
  const { loggedIn } = useSecurity();

  const {
    openCreateNewNeedDialog,
    openLoginDialog,
    openSearchMoneyDialog,
    openSearchMoneyByProductDialog,
  } = useDialogsManager();

  const { needs, error, loading } = useSearchNeeds(
    {
      limit: 10,
      orderBy: sortOptionMappings[NEWEST_FIRST],
    },
    [],
  );

  const extractCategories = (): string[] =>
    needs.map((need) => need.category).filter((v, i, a) => a.indexOf(v) === i);

  // Store categories so they persist when user changes categories
  const categoriesRef = useRef<string[] | null>(null);
  if (categoriesRef.current === null && needs.length > 0) {
    categoriesRef.current = extractCategories();
  }

  function handleCreateNeedButtonClick() {
    if (!loggedIn) {
      if (openLoginDialog) {
        openLoginDialog();
      }
    } else if (openCreateNewNeedDialog) {
      openCreateNewNeedDialog((created: Need) =>
        history.push(`/need/${created.ID}`),
      );
    }
  }

  function handleExploreButtonClick() {
    history.push('/explore');
  }

  function handleSearchButtonClick() {
    if (openSearchMoneyDialog) {
      openSearchMoneyDialog();
    }
  }

  function handleSearchByProductButtonClick() {
    if (openSearchMoneyByProductDialog) {
      openSearchMoneyByProductDialog();
    }
  }

  return (
    <HomePage
      needs={needs}
      loading={loading}
      error={Boolean(error)}
      handleCreateNeedButtonClick={handleCreateNeedButtonClick}
      handleSearchButtonClick={handleSearchButtonClick}
      handleSearchByProductButtonClick={handleSearchByProductButtonClick}
      handleExploreButtonClick={handleExploreButtonClick}
    />
  );
};

export default HomePageContainer;
