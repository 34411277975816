import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export function getDateAndTime(date: Date): string {
  const d = dayjs(date);
  return d.format('LT · LL');
}

export function getDate(date: Date): string {
  const d = dayjs(date);
  return d.format('LL');
}

export function getTimeFromNow(date: Date): string {
  const d = dayjs(date);
  return d.fromNow(true);
}
