import React from 'react';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Spinner from '../../components/Spinner';
import {
  SomethingWentWrongFeedback,
  AlertFeedback,
} from '../../components/Feedback';
import SearchNeedCard from '../../components/MyCards/SearchNeedCard';
import getArticle from '../../utils/getArticle';
import { Need } from '../../types/Need';
import Results from './Results';
import { calculateValue } from './miles';
import { icon } from '../../theme/theme';

type NeedsProps = {
  needs: Need[] | null;
  loading: boolean;
  firstLoadingDone: boolean;
  more: boolean;
  error: boolean;
  searchedItemTitle: string;
  handleFetchMore: () => void;
  radiusIndex?: number;
  radiusOn?: boolean;
};

const ResultTab = ({
  needs,
  loading,
  firstLoadingDone,
  more,
  error,
  searchedItemTitle,
  radiusIndex = -1,
  radiusOn = false,
  handleFetchMore,
}: NeedsProps): JSX.Element => {
  if (loading) return <Spinner />;

  if (error) return <SomethingWentWrongFeedback />;

  if (needs === null) return <Spinner />;

  if (needs.length === 0 && radiusOn) {
    return (
      <AlertFeedback
        renderIcon={() => (
          <SentimentVeryDissatisfiedIcon
            sx={{ fontSize: `${icon.fontSize.xxlarge} !important` }}
          />
        )}
        title={`Nothing in a ${calculateValue(radiusIndex)} miles radius`}
        subtitle="Increase the radius to see more results."
      />
    );
  }

  if (needs.length === 0 && firstLoadingDone) {
    return (
      <AlertFeedback
        renderIcon={() => (
          <SentimentVeryDissatisfiedIcon
            sx={{ fontSize: `${icon.fontSize.xxlarge} !important` }}
          />
        )}
        title={`No "${searchedItemTitle}" needs found`}
        subtitle={`No user has expressed a need for ${getArticle(
          searchedItemTitle,
        )} "${searchedItemTitle}" - yet`}
      />
    );
  }

  return (
    <Results
      items={needs}
      renderItem={(need: Need) => <SearchNeedCard need={need} />}
      more={more}
      handleFetchMore={handleFetchMore}
    />
  );
};

ResultTab.defaultProps = {
  radiusIndex: -1,
  radiusOn: false,
};

export default ResultTab;
