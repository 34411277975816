import React from 'react';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { useTheme } from '@mui/material';
import { tabs } from '../../theme/theme';

const MyTabs = (props: TabsProps): JSX.Element => (
  <Tabs
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
    sx={{
      minHeight: `${tabs.height} !important`,
      '& .MuiTabs-scroller': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiTabs-flexContainer': {
        height: '100% !important',
      },
    }}
  />
);

export const MyTab = (props: TabProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Tab
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.fontFamily,
        color: tabs.tab.color,
      }}
    />
  );
};

export default MyTabs;
