import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import MyDialog from '../MyDialog';
import Logo from '../Logo';
import MyButton from '../MyButton';
import Spinner from '../Spinner';
import { SomethingWentWrongFeedback } from '../Feedback';

type Props = {
  open: boolean;
  onButtonClick: () => void;
  buttonText: string;
  loading: boolean;
  error: boolean;
  title: string;
  message: string;
};

const DialogWithImportantMessage = ({
  open,
  onButtonClick,
  buttonText,
  loading,
  error,
  title,
  message,
}: Props): JSX.Element => (
  <MyDialog open={open}>
    {loading && !error && <Spinner />}

    {!loading && Boolean(error) && <SomethingWentWrongFeedback />}

    {!loading && !error && (
      <Box display="flex" flexDirection="column">
        <Box height="48px" />

        <Box>
          <Box padding="16px 0">
            <Box display="flex" justifyContent="center">
              <Logo />
            </Box>
          </Box>
          <Box maxWidth="400px" margin="32px auto" padding="0 32px">
            <Box marginBottom="32px">
              <Box marginBottom="8px">
                <Typography variant="h4">{title}</Typography>
              </Box>
              <Typography variant="body1">{message}</Typography>
            </Box>
            <MyButton
              size="large"
              fullWidth
              onClick={onButtonClick}
              color="primary"
            >
              {buttonText}
            </MyButton>
          </Box>
        </Box>

        <Box height="48px" />
      </Box>
    )}
  </MyDialog>
);

export default DialogWithImportantMessage;
