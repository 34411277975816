import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import {
  ParagraphTitle1,
  ParagraphTitle2,
  ParagraphTitle3,
  Paragraph,
} from '../PrivacyPolicyPage/PrivacyPolicyPage';
import PageLayout from '../../components/PageLayout';

const CookiePolicyPage = (): JSX.Element => (
  <PageLayout>
    <Helmet>
      <title>Cookie Policy | Bonvih</title>
    </Helmet>
    <Box padding="0 16px">
      <ParagraphTitle1>1. What is a cookie?</ParagraphTitle1>
      <Paragraph>
        Cookies are small text files that are stored by your browser on your
        device (e.g. computer, mobile phone, tablet) when you browse websites.
        Other technologies, including data we store on your web browser or
        device, identifiers associated with your device, and other software, are
        used for similar purposes. They are widely used in order to make
        websites work or work in a better, more efficient way. In this policy,
        we refer to all of these technologies as cookies. Unless stated
        otherwise in this Cookie Policy, the cookies we use are necessary for
        the functionality and performance of the Shpock Website.
      </Paragraph>
      <ParagraphTitle1>2. Why does Shpock use cookies?</ParagraphTitle1>
      <ParagraphTitle3>We use cookies to:</ParagraphTitle3>
      <ul>
        <li>
          <Paragraph>
            Make our Platform work as you would expectImprove the speed/security
            of the Platform
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Recognise you when you re-visit our Platform. This helps us to
            personalise our content for you, greet you by your name and note
            your preferences (functionality cookies)
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Recognise how many visitors visit our Platform and how they behave
            when they are here. This helps us to improve the functionality of
            our Platform, e.g. by ensuring that users can find what they are
            looking for (analytic/performance cookies) and by helping us
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Capture your visit to our Platform and which pages you visit and
            which links you click on. We will use this information to better
            tailor our Platform, and the advertising we show you, to your
            interests.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Allow you to share pages with social networks like Facebook or
            Instagram
          </Paragraph>
        </li>
      </ul>
      <ParagraphTitle1>3. Types of Cookies?</ParagraphTitle1>
      <ParagraphTitle2>3.1 Strictly necessary cookie</ParagraphTitle2>
      <Paragraph>
        These cookies are essential to a website in order to enable a user to
        move around and to use a website’s features, such as remembering
        information entered on forms when a user moves between pages within a
        browser session. Without strictly necessary cookies, certain services on
        a website may not be made possible and a website may not perform as
        smoothly as it should. These cookies don’t gather any information for
        marketing purposes or to remember where you have been on the internet.
      </Paragraph>
      <ParagraphTitle2>3.2 Performance cookie</ParagraphTitle2>
      <Paragraph>
        Performance cookies collect information about the use of a website and
        help to improve the way a website works. For example, performance
        cookies may show which are the most frequently visited pages, help to
        record any difficulties with a website and show whether advertising on a
        website is effective or not. Performance cookies do not collect
        information that identifies a user personally and all information that
        is collected by these cookies is aggregated and anonymous.
      </Paragraph>
      <ParagraphTitle2>3.1 Strictly necessary cookie</ParagraphTitle2>
      <Paragraph>
        These cookies are essential to a website in order to enable a user to
        move around and to use a website’s features, such as remembering
        information entered on forms when a user moves between pages within a
        browser session. Without strictly necessary cookies, certain services on
        a website may not be made possible and a website may not perform as
        smoothly as it should. These cookies don’t gather any information for
        marketing purposes or to remember where you have been on the internet.
      </Paragraph>
      <ParagraphTitle2>3.3 Functionality cookie</ParagraphTitle2>
      <Paragraph>
        Functionality cookies allow a website to remember the choices you make
        and to provide enhanced and more personal features. Functionality
        cookies also remember changes you have made, such as customising a
        certain webpage and for other services you request such as watching a
        video or commenting on our webpage. Functionality cookies will not track
        your activity on other websites.
      </Paragraph>
      <ParagraphTitle2>3.4 Targeting or advertising cookie</ParagraphTitle2>
      <Paragraph>
        Targeting or advertising cookies are used to deliver adverts which are
        more relevant to you and your interests, or to limit the number of times
        you see the same advert on a website. These types of cookies are also
        used to help measuring the effectiveness of an advertising campaign.
        These cookies may also be used to remember what you have looked at on a
        website. Further, such cookies may track if you have visited a website
        and may transfer such information to the third-party provider where the
        information may be combined with other third-party functionalities.
        Special targeting-cookies may enable displaying ads to social media
        users.
      </Paragraph>
      <ParagraphTitle2>3.5 Third-party cookie</ParagraphTitle2>
      <Paragraph>
        These cookies are not placed by Shpock, but by third-party providers.
        The third-party provider, usually an advertiser, can track user visits
        of users on all sites displaying ads of this third-party provider using
        such cookies. This may be done to measure coverage or activation of
        context- or targeted advertising. Further, we use third-party cookies to
        measure data transfer and usage of our users, to analyse browsing
        behaviour, to create target groups for marketing purposes, to improve
        our advertising management and to improve functionality of our website
        and services. You may prohibit storage of and tracking by third-party
        cookies by adjusting your web browser settings. Please find further
        information on this below.
      </Paragraph>
      <ParagraphTitle1>4. Cookies on Shpock</ParagraphTitle1>
      <Paragraph>
        <table>
          <thead>
            <tr>
              <th>Cookie Category</th>
              <th>Cookie name </th>
              <th>Cookie purpose </th>
              <th>Cookie expiry</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>strictly necessary cookie</td>
              <td>shpock_sid</td>
              <td>This cookie allows us to identify a logged in user.</td>
              <td>On logout</td>
            </tr>
            <tr>
              <td>strictly necessary cookie</td>
              <td>shpock_sid</td>
              <td>This cookie allows us to identify a logged in user.</td>
              <td>On logout</td>
            </tr>
            <tr>
              <td>strictly necessary cookie</td>
              <td>shpock_sid</td>
              <td>This cookie allows us to identify a logged in user.</td>
              <td>On logout</td>
            </tr>
            <tr>
              <td>strictly necessary cookie</td>
              <td>shpock_sid</td>
              <td>This cookie allows us to identify a logged in user.</td>
              <td>On logout</td>
            </tr>
            <tr>
              <td>strictly necessary cookie</td>
              <td>shpock_sid</td>
              <td>This cookie allows us to identify a logged in user.</td>
              <td>On logout</td>
            </tr>
            <tr>
              <td>strictly necessary cookie</td>
              <td>shpock_sid</td>
              <td>This cookie allows us to identify a logged in user.</td>
              <td>On logout</td>
            </tr>
          </tbody>
        </table>
      </Paragraph>
      <ParagraphTitle1>5. Managing Cookies</ParagraphTitle1>
      <Paragraph>
        You can choose in your web browser settings if you want to accept
        cookies or not. Further, you can choose to allow storage of cookies by
        websites you visit and/or by third-parties linked to this website. You
        can also choose to be informed every time as soon as a new cookie is
        stored. The concrete setting possibilities may depend on which browser
        or user device you are using. You may prohibit cookies completely in
        your browser settings. Further, there are special services that can be
        used as plug-ins in your browser to block or manage cookies.
      </Paragraph>
      <Paragraph>
        Please note that the Shpock services are largely free of charge. We
        therefore depend on advertising revenue to a certain extent. If you
        decide to block advertising on Shpock, it will be more difficult for us
        to offer our services free of charge in the future.
      </Paragraph>
      <Paragraph>
        If you deactivate local storage of cookies this may lead to reduced
        function of our website and services. Further, you will have to log in
        again every time you visit our Website.
      </Paragraph>
      <Paragraph>
        Please find further information on how to manage cookies in the
        different browsers:
      </Paragraph>
      <ul>
        <li>
          <Paragraph>
            <a href="https://support.mozilla.org/de/kb/Cookies-blockieren">
              Mozilla Firefox
            </a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <a href="http://windows.microsoft.com/de-at/windows-vista/block-or-allow-cookies">
              Internet Explorer
            </a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <a href="https://support.google.com/accounts/answer/61416?hl=de">
              Google Chrome
            </a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <a href="http://help.opera.com/Linux/9.01/de/cookies.html">Opera</a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac">
              Safari
            </a>
          </Paragraph>
        </li>
      </ul>
      <Paragraph>
        Please find further information on how to manage or deactivate certain
        cookies in the category “targeting- or advertising cookies”:
      </Paragraph>
      <ul>
        <li>
          <Paragraph>
            Google:{' '}
            <a href="https://www.google.com/+/policy/pagesterm.html">
              https://www.google.com/+/policy/pagesterm.html
            </a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            OpenX:{' '}
            <a href="https://www.openx.com/legal/privacy-policy/">
              https://www.openx.com/legal/privacy-policy/
            </a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Index:{' '}
            <a href="https://www.indexexchange.com/privacy/">
              https://www.indexexchange.com/privacy/
            </a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Rubicon:{' '}
            <a href="https://rubiconproject.com/privacy/consumer-online-profile-and-opt-out/">
              https://rubiconproject.com/privacy/consumer-online-profile-and-opt-out/
            </a>
          </Paragraph>
        </li>
      </ul>
      <Paragraph>
        Additional useful information on how to deactivate and manage cookies
        can be found here{' '}
        <a href="http://www.youronlinechoices.com/at/">
          http://www.youronlinechoices.com/at/
        </a>
        .
      </Paragraph>
      <Paragraph>
        “Performance cookies” may also be managed or deactivated directly under
        the following links:
      </Paragraph>
      <ul>
        <li>
          <Paragraph>
            IVW: <a href="https://optout.ioam.de/">https://optout.ioam.de/</a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            ÖWA:{' '}
            <a href="https://optout-at.iocnt.net/">
              https://optout-at.iocnt.net/
            </a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Google Analytics:{' '}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=en.">
              https://tools.google.com/dlpage/gaoptout?hl=en.
            </a>
          </Paragraph>
        </li>
      </ul>
    </Box>
  </PageLayout>
);

export default CookiePolicyPage;
