import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';
import {
  MyListItem,
  MyListItemText,
  MyListItemAvatar,
  MyListItemButton,
} from '../../../components/MyList';
import {
  UserAvatar,
  ExtraSmallUserAvatar,
} from '../../../components/MyAvatars';
import useDialogsManager from '../../../hooks/useDialogsManager';
import { Profile } from '../../../types/Profile';
import { Message } from '../../../types/Message';
import { BASE_URL } from '../../../constants';
import { colors } from '../../../theme/theme';
import useBadgeCount from '../../../hooks/useBadgeCount';
import formatMoney from '../../../utils/formatMoney';
import getArticle from '../../../utils/getArticle';

function shortenText(text: string): string {
  if (text.length < 20) {
    return text;
  }

  return `${text.substring(0, 19)}...`;
}

type ChatItem = {
  message: Message;
  profile: Profile;
  reloadChats: () => void;
};

const ChatItem = ({ message, profile, reloadChats }: ChatItem): JSX.Element => {
  const theme = useTheme();
  const [lastMessage, setLastMessage] = useState(message);
  const [unseenMessagesCount, setUnseenMessagesCount] = useState(0);
  const { openChatDialog } = useDialogsManager();
  const badgeCount = useBadgeCount();

  const { get, response } = useFetch(BASE_URL);

  useEffect(() => {
    setLastMessage(message);
  }, [message]);

  async function getChatUnseenMessagesCount() {
    const data = await get(
      `/propositions/${message.proposition.ID}/profiles/${profile.ID}/unseen_messages_count`,
    );
    if (response.ok) {
      setUnseenMessagesCount(data);
    }
  }

  useEffect(() => {
    if (badgeCount.onNewNonEmptyChatMessagesCount) {
      badgeCount.onNewNonEmptyChatMessagesCount(reloadChats);
    }

    return () => {
      if (badgeCount.clearNewNonEmptyChatMessagesCountListeners) {
        badgeCount.clearNewNonEmptyChatMessagesCountListeners();
      }
    };
  }, []);

  useEffect(() => {
    getChatUnseenMessagesCount();
  }, []);

  function handleChatClick() {
    if (openChatDialog) {
      openChatDialog(
        message.proposition,
        () => setUnseenMessagesCount(0),
        (newMessage: Message) => setLastMessage(newMessage),
      );
    }
  }

  const iAmProposer = lastMessage.proposition.item.profile.ID === profile.ID;

  return (
    <MyListItemButton onClick={handleChatClick}>
      <MyListItem
        secondaryAction={
          unseenMessagesCount > 0 ? (
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: theme.typography.body2.fontSize,
                  color: '#FFFFFF',
                },
                marginLeft: '-10px',
              }}
              badgeContent={unseenMessagesCount}
              color="primary"
            />
          ) : null
        }
      >
        <MyListItemAvatar>
          <AvatarGroup>
            <UserAvatar
              src={
                iAmProposer
                  ? lastMessage.proposition.need.profile.avatar
                  : lastMessage.proposition.item.profile.avatar
              }
            />
          </AvatarGroup>
        </MyListItemAvatar>
        <MyListItemText
          primary={
            <Box sx={{ overflow: 'hidden' }}>
              <Box
                component="span"
                sx={{
                  fontSize: theme.typography.body1.fontSize,
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                }}
              >
                <Box marginBottom="2px">
                  {iAmProposer
                    ? lastMessage.proposition.need.profile.firstname
                    : lastMessage.proposition.item.profile.firstname}
                </Box>
                <Box marginBottom="2px">
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      fontWeight: 'normal',
                      color: `${colors.grey} !important`,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {iAmProposer
                      ? `💸 ${
                          lastMessage.proposition.need.profile.firstname
                        } is spending ${formatMoney(
                          lastMessage.proposition.need.offer,
                          lastMessage.proposition.need.currency,
                        )} on ${getArticle(
                          lastMessage.proposition.need.title,
                        )} ${lastMessage.proposition.need.title}`
                      : `${
                          lastMessage.proposition.item.profile.firstname
                        } is proposing ${getArticle(
                          lastMessage.proposition.item.name,
                        )} ${lastMessage.proposition.item.name} to you`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          }
          secondary={
            <Box
              component="span"
              sx={{
                color: 'inherit',
                display: 'flex',
                alignItems: 'center',
                fontSize: theme.typography.body2.fontSize,
              }}
            >
              <ExtraSmallUserAvatar
                alt={lastMessage.profile.firstname}
                src={lastMessage.profile.avatar}
                // eslint-disable-next-line react/jsx-props-no-spreading
                // {...stringAvatar(lastMessage.profile.firstname)}
              />
              <Box
                component="span"
                sx={{
                  color: colors.black,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: 'bold',
                  marginRight: '4px',
                  marginLeft: '4px',
                }}
              >
                {lastMessage.profile.firstname}
              </Box>
              <Box
                component="span"
                sx={{
                  color: colors.grey,
                  fontSize: theme.typography.body2.fontSize,
                  marginRight: '4px',
                }}
              >
                -
              </Box>
              <Box
                component="span"
                sx={{
                  color: colors.grey,
                  fontSize: theme.typography.body2.fontSize,
                  marginRight: '4px',
                }}
              >
                {shortenText(lastMessage.text)}
              </Box>
            </Box>
          }
        />
      </MyListItem>
    </MyListItemButton>
  );
};

export default ChatItem;
