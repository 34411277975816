import React, { useState } from 'react';
import useFetch from 'use-http';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { BonvihMessage } from '../../types/BonvihMessage';
import Logo from '../Logo';
import { MyCard, MyCardHeader } from './MyCards';
import CardHover from './CardHover';
import DialogWithImportantMessage from '../DialogWithImportantMessage';

type BonvihNotificationCardProps = {
  bonvihMessage: BonvihMessage;
};

const BonvihNotificationCard = ({
  bonvihMessage,
}: BonvihNotificationCardProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { loading, error } = useFetch(
    `http://${process.env.REACT_APP_BONVIH_API_HOST}:4000`,
  );

  function handleCardClick() {
    setDialogOpen(true);
  }

  function handleOkButtonClick() {
    setDialogOpen(false);
  }

  return (
    <>
      <Box
        onClick={handleCardClick}
        sx={{
          '& .MuiCardHeader-root': {
            alignItems: 'stretch !important',
          },
          '& .MuiCardHeader-avatar': {
            flexBasis: '48px',
            justifyContent: 'flex-end',
          },
        }}
      >
        <CardHover>
          <MyCard>
            <MyCardHeader
              title={
                <Typography variant="body1">{bonvihMessage.message}</Typography>
              }
              avatar={<Logo />}
            />
          </MyCard>
        </CardHover>
      </Box>

      <DialogWithImportantMessage
        open={dialogOpen}
        onButtonClick={handleOkButtonClick}
        buttonText="Got it"
        loading={loading}
        error={Boolean(error)}
        title={bonvihMessage.title}
        message={bonvihMessage.message}
      />
    </>
  );
};

export default BonvihNotificationCard;
