import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { Notification } from '../../../types/Notification';
import BonvihNotificationCard from '../../../components/MyCards/BonvihNotificationCard';
import PropositionNotificationCard from '../../../components/MyCards/PropositionNotificationCard';
import Spinner from '../../../components/Spinner';
import { SomethingWentWrongFeedback } from '../../../components/Feedback';
import { card, borderRadius } from '../../../theme/theme';

type NotificationsProps = {
  notifications: Notification[] | null;
  loading: boolean;
  error: boolean;
  handleGetMoreNotifications: () => void;
  more: boolean;
};

const Notifications = ({
  notifications,
  loading,
  error,
  handleGetMoreNotifications,
  more,
}: NotificationsProps): JSX.Element => {
  if (loading) return <Spinner />;

  if (error) return <SomethingWentWrongFeedback />;

  if (notifications === null) return <Spinner />;

  if (notifications.length === 0)
    return (
      <Box>
        <Box padding="32px">
          <Box
            sx={{
              maxWidth: 'calc(400px)',
              paddingLeft: '32px',
              paddingRight: '32px',
              marginTop: '32px',
              marginBottom: '32px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: '31px !important',
                fontWeight: 'bold',
                marginBottom: '8px',
              }}
            >
              Nothing to see here yet
            </Typography>

            <Typography variant="body1" sx={{ fontSize: '15px' }}>
              When you receive a notification, you’ll find it here.
            </Typography>
          </Box>
        </Box>
      </Box>
    );

  return (
    <InfiniteScroll
      dataLength={notifications.length}
      next={handleGetMoreNotifications}
      hasMore={more}
      loader={<Spinner />}
      endMessage={<div />}
      style={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <Box>
        {notifications
          .sort(
            (a, b) =>
              new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime(),
          )
          .map((n, idx) => (
            <Box
              key={n.ID.toString()}
              sx={{
                '& .MuiPaper-root': {
                  backgroundColor: n.seen
                    ? 'unset'
                    : card.backgrounColor.unseen,
                  borderRadius: n.seen ? borderRadius : '0px',
                },
                '& .MuiPaper-root:hover': {
                  backgroundColor: n.seen
                    ? card.backgrounColor.hover
                    : `${card.backgrounColor.unseen} !important`,
                },
              }}
            >
              {n.proposition && (
                <Box
                  sx={{
                    borderBottom:
                      idx === notifications.length - 1
                        ? 'unset'
                        : `1px solid ${card.border.color}`,
                    background: '#FFFFFF',
                  }}
                >
                  <PropositionNotificationCard proposition={n.proposition} />
                </Box>
              )}

              {n.bonvihMessage && (
                <Box
                  sx={{
                    borderBottom:
                      idx === notifications.length - 1
                        ? 'unset'
                        : `1px solid ${card.border.color}`,
                    background: '#FFFFFF',
                  }}
                >
                  <BonvihNotificationCard bonvihMessage={n.bonvihMessage} />
                </Box>
              )}
            </Box>
          ))}
      </Box>
    </InfiniteScroll>
  );
};

export default Notifications;
