import React, { useState, useEffect, useRef } from 'react';
import useFetch from 'use-http';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import getServerSocket from '../../socket-io/socket';
import { State } from '../../store/types';
import { Profile } from '../../types/Profile';
import { Message } from '../../types/Message';
import { Proposition } from '../../types/Proposition';
import { BASE_URL } from '../../constants';
import useBadgeCount from '../../hooks/useBadgeCount';
import logger from '../../logger';

import ChatDialog from './ChatDialog';

type ChatDialogContainerProps = {
  open: boolean;
  onClose: () => void;
  proposition: Proposition;
  onMessagesLoaded?: () => void;
  onNewMessage?: (newMessage: Message) => void;
};

const ChatDialogContainer = ({
  open,
  onClose,
  proposition,
  onMessagesLoaded,
  onNewMessage,
}: ChatDialogContainerProps): JSX.Element => {
  const badgeCount = useBadgeCount();
  const [chatError, setChatError] = useState(false);
  const socketRef = useRef<ReturnType<typeof getServerSocket> | null>(null);
  const profile = useSelector<State, Profile | null>((state) => state.profile);
  const [messages, setMessages] = useState<Message[] | null>(null);
  const messagesRef = useRef<Message[]>([]);
  const [chatterTyping, setChatterTyping] = useState(false);
  const meTypingRef = useRef(false);
  const [chatterOnline, setChatterOnline] = useState(false);
  const myRef = useRef();

  const { get, cache, response, loading, error } = useFetch(BASE_URL);

  const {
    get: markAsSeenGet,
    error: markAsSeenError,
    response: markAsSeenResponse,
  } = useFetch(BASE_URL);

  if (socketRef.current === null) {
    socketRef.current = getServerSocket();
  }

  function getChatterID() {
    return (profile as Profile).ID === proposition.need.profile.ID
      ? proposition.item.profile.ID
      : proposition.need.profile.ID;
  }

  async function markUnseenMessagesAsSeen() {
    await markAsSeenGet(
      `/propositions/${proposition.ID}/profiles/${profile?.ID}/mark_as_seen`,
    );

    if (markAsSeenResponse.ok) {
      cache.clear();
      if (badgeCount.reload) {
        badgeCount.reload();
      }
    }
  }

  function markIncomingMessageAsSeen() {
    markUnseenMessagesAsSeen();
  }

  function handleIncomingMessage(message: Message) {
    messagesRef.current.push(message);
    setMessages([...messagesRef.current]);
    markIncomingMessageAsSeen();
    if (onNewMessage) {
      onNewMessage(message);
    }
  }

  function handleChatterTyping(typing: boolean) {
    setChatterTyping(typing);
  }

  function handleChatterOnlineStatus(online: boolean) {
    setChatterOnline(online);
  }

  function handleMeTyping(typing: boolean) {
    if (typing !== meTypingRef.current) {
      if (socketRef.current !== null) {
        socketRef.current.emit('typing', typing, proposition.ID.toString());
        meTypingRef.current = typing;
      }
    }
  }

  // Handle case when user starts typing and leaves immediately
  useEffect(
    () => () => {
      handleMeTyping(false);
    },
    [],
  );

  useEffect((): (() => void) => {
    if (socketRef.current !== null) {
      socketRef.current.on('connect', () => {
        if (socketRef.current !== null) {
          socketRef.current.emit('join_room', proposition.ID.toString());
          socketRef.current.emit('join_room', getChatterID().toString());
          socketRef.current.emit(
            'get_online_status',
            getChatterID().toString(),
          );
        }
      });

      socketRef.current.on('private_message', (message) => {
        handleIncomingMessage(message);
      });

      socketRef.current.on('typing', (typing) => {
        handleChatterTyping(typing);
      });

      socketRef.current.on('online_status', (online) => {
        handleChatterOnlineStatus(online);
      });

      socketRef.current.on('connect_error', () => {
        logger.log({ message: `Socket connection error` });
        setChatError(true);
      });

      socketRef.current.on('disconnect', (reason) => {
        if (reason !== 'io client disconnect') {
          logger.log({
            message: `Socket disconnected`,
            reason: reason as string,
          });
          window.location.reload();
        }
      });

      socketRef.current.on('internal_server_error', () => {
        setChatError(true);
      });
    }

    return () => {
      if (socketRef.current !== null) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  function createMessage(text: string): Message {
    if (profile !== null) {
      return {
        profile,
        proposition,
        seen: false,
        text,
        CreatedAt: new Date(),
      };
    }

    logger.log({
      message: 'Failed to create chat message',
      reason: 'Profile is null',
    });
    throw new Error('');
  }

  function sendMessage(message: Message) {
    if (socketRef.current !== null) {
      socketRef.current.emit(
        'private_message',
        message,
        getChatterID().toString(),
        proposition.ID.toString(),
      );
      cache.clear();
      messagesRef.current.push(message);
      setMessages([...messagesRef.current]);
      if (onNewMessage) {
        onNewMessage(message);
      }
    }
  }

  function handleSendIconClick(text: string) {
    sendMessage(createMessage(text));
  }

  async function getChatMessages() {
    const data = await get(`/propositions/${proposition.ID}/messages`);
    if (response.ok) {
      messagesRef.current.push(...data);
      setMessages([...messagesRef.current]);

      markUnseenMessagesAsSeen();

      if (onMessagesLoaded) {
        onMessagesLoaded();
      }
    }
  }

  useEffect(() => {
    getChatMessages();
  }, []);

  return (
    <Box ref={myRef}>
      <ChatDialog
        open={open}
        onClose={onClose}
        profile={profile}
        proposition={proposition}
        loading={loading}
        error={chatError || Boolean(error) || Boolean(markAsSeenError)}
        messages={messages}
        handleSendIconClick={handleSendIconClick}
        handleSenderTyping={handleMeTyping}
        recipientTyping={chatterTyping}
        recipientOnline={chatterOnline}
      />
    </Box>
  );
};

ChatDialogContainer.defaultProps = {
  onMessagesLoaded: undefined,
  onNewMessage: undefined,
};

export default ChatDialogContainer;
