import { State, Action } from './types';

const initialState: State = {
  profile: null,
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'SAVE_PROFILE':
      return { ...state, profile: action.payload };

    case 'REMOVE_PROFILE':
      return { ...state, profile: null };

    default:
      return state;
  }
};

export default reducer;
