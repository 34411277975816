import { createTheme } from '@mui/material';

export const bar = {
  height: '53px',
  padding: '0 16px',
};

export const borderRadius = '8px';

export const colors = {
  black: '#333333',
  grey: '#999999',
  backdrop: '#f7f7f7',
  // primary: '#1DF020',
  // primary: '#2e7d32',
  // primary: 'rgb(155, 240, 29)', // change cardHoverBackgournColorUnseen
  primary: '#00d64f', // change cardHoverBackgournColorUnseen,
  secondary: '#FB60C4',
  warning: 'rgb(255, 212, 0)',
  error: '#e60000',
  success: 'rgb(0, 186, 124)',
  info: 'rgb(29, 155, 240)',
  border: '#ebebeb',
  borderDarker: '#ebebeb',
  icon: '#333333',
  colorLight: '#999999',
  iconHoverBackgroundColor: '#999999',
  cardHoverBackgournColor: '#FFFFFF',
  cardHoverBackgournColorUnseen: 'rgba(0, 214, 79, 0.3)',
};

export const dialog = {
  input: {
    padding: '12px 16px',
  },
};

export const social = {
  icon: {
    color: colors.black,
  },
};

export const input = {
  border: {
    color: colors.borderDarker,
  },
  minHeight: '56px',
};

export const select = {
  option: {
    padding: '16px',
  },
  border: {
    color: colors.borderDarker,
  },
};

export const checkbox = {
  border: {
    color: colors.colorLight,
  },
};

export const pageSection = {
  title: {
    padding: '12px 16px',
  },
};

export const card = {
  padding: '12px 16px',
  backgrounColor: {
    normal: '#FFFFFF',
    hover: colors.cardHoverBackgournColor,
    unseen: colors.cardHoverBackgournColorUnseen,
  },
  border: {
    radius: borderRadius,
    color: colors.border,
  },
};

export const divider = {
  margin: '4px 0',
  color: colors.border,
};

export const locationFilter = {
  input: {
    padding: '16px',
  },
  slider: {
    padding: '16px',
  },
  cta: {
    padding: '16px',
  },
};

export const icon = {
  fontSize: {
    xsmall: '16px',
    small: '18.75px',
    medium: '20px',
    large: '22.5px',
    xlarge: '26.25px',
    xxlarge: '40px',
  },
  color: colors.icon,
  colorLight: colors.colorLight,
};

export const badge = {
  color: '#FFFFFF',
  padding: '8px',
};

export const drawer = {
  item: {
    link: {
      padding: '4px 0',
    },
    content: {
      padding: {
        mediumDevice: '12px',
        smallDevice: '16px',
      },
    },
  },
  divider: {
    margin: '4px 0',
    borderColor: colors.border,
  },
};

export const pageTitleBar = {
  padding: '12px 16px',
};

export const appBar = {
  height: bar.height,
  padding: bar.padding,
  iconWrapper: {
    minWidth: '56px',
  },
  spacingBetweenIcons: '4px',
  spacingBetweenSearchInputAndCTA: '22px',
  spacingBetweenCTAAndIcons: '12px',
  menuIconNegativeMargin: '-8px',
  borderColor: colors.border,
};

export const tabs = {
  height: bar.height,
  tab: {
    padding: bar.padding,
    color: colors.grey,
  },
};

export const button = {
  minHeight: {
    small: '32px',
    medium: '36px',
    large: '52px',
  },
  fontSize: {
    small: '14px',
    medium: '16px',
    large: '18px',
  },
  padding: '0 16px',
  borderRadius,
  border: {
    color: colors.borderDarker,
    radius: borderRadius,
  },
};

export const footer = {
  padding: '12px 0',
  spacingBetweenLinks: '16px',
  link: {
    margin: '4px 0',
  },
  border: {
    color: colors.border,
  },
};

export const menu = {
  item: {
    padding: '16px',
  },
};

export const searchInput = {
  borderColor: colors.border,
  minHeight: '56px',
};

export const chat = {
  bubble: {
    border: {
      radius: borderRadius,
    },
    margin: '2px auto',
    padding: '12px 16px',
    user: {
      backgroundColor: colors.primary,
    },
    chattee: {
      backgroundColor: colors.grey,
    },
  },
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 990,
      xl: 1140,
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    warning: {
      main: colors.warning,
    },
    error: {
      main: colors.error,
    },
    info: {
      main: colors.info,
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontSize: 16,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        disableElevation: true,
      },
    },
  },
});

theme.typography.h1 = {
  fontSize: '6rem !important',
  fontWeight: 700,
  color: colors.black,
};

theme.typography.h2 = {
  fontSize: '31px !important',
  fontWeight: 700,
  color: colors.black,
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem !important',
  },
  // [theme.breakpoints.up('lg')]: {
  //   fontSize: '4rem !important',
  // },
};

theme.typography.h3 = {
  fontSize: '31px !important',
  fontWeight: 700,
  color: colors.black,
};

theme.typography.h4 = {
  fontSize: '23px !important',
  fontWeight: 700,
  color: colors.black,
};

theme.typography.h5 = {
  fontSize: '20px !important',
  fontWeight: 700,
  color: colors.black,
};

theme.typography.h6 = {
  fontSize: '15px !important',
  fontWeight: 700,
  color: colors.black,
};

theme.typography.body1 = {
  fontSize: '15px !important',
  color: colors.black,
};

theme.typography.body2 = {
  fontSize: '13px !important',
  color: colors.black,
};

theme.typography.button = {
  fontSize: '13px !important',
  color: colors.black,
};

export default theme;
