import React, { ReactNode } from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ReplayIcon from '@mui/icons-material/Replay';
import MyButton from '../MyButton';
import { colors, icon } from '../../theme/theme';

function handleReload() {
  window.location.reload();
}

type FeedbackProps = {
  renderIcon?: () => ReactNode;
  title?: string;
  subtitle?: string;
  renderAction?: () => ReactNode;
};

const Feedback = ({
  renderIcon,
  title,
  subtitle,
  renderAction,
}: FeedbackProps): JSX.Element => (
  <Box padding="32px">
    <Box
      sx={{
        maxWidth: 'calc(400px)',
        paddingLeft: '32px',
        paddingRight: '32px',
        marginTop: '32px',
        marginBottom: '32px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      {renderIcon && (
        <Box marginBottom="20px">
          <Box display="flex" justifyContent="center">
            {renderIcon()}
          </Box>
        </Box>
      )}

      {title && (
        <Box marginBottom="8px">
          <Typography variant="h3" variantMapping={{ h3: 'p' }}>
            <span className="feedback-title">{title}</span>
          </Typography>
        </Box>
      )}

      {subtitle && (
        <Box>
          <Typography variant="body1" sx={{ color: colors.grey }}>
            {subtitle}
          </Typography>
        </Box>
      )}

      {renderAction && (
        <Box marginBottom="20px" marginTop="28px">
          {renderAction()}
        </Box>
      )}
    </Box>
  </Box>
);

Feedback.defaultProps = {
  renderIcon: undefined,
  title: '',
  subtitle: '',
  renderAction: undefined,
};

type AlertFeedbackProps = {
  renderIcon?: () => ReactNode;
  title?: string;
  subtitle?: string;
  renderAction?: () => ReactNode;
};

export const AlertFeedback = ({
  renderIcon,
  title,
  subtitle,
  renderAction,
}: AlertFeedbackProps): JSX.Element => (
  <Box padding="32px">
    <Box
      sx={{
        maxWidth: 'calc(400px)',
        paddingLeft: '32px',
        paddingRight: '32px',
        marginTop: '32px',
        marginBottom: '32px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      {renderIcon && (
        <Box marginBottom="20px">
          <Box display="flex" justifyContent="center">
            {renderIcon()}
          </Box>
        </Box>
      )}

      {title && (
        <Box marginBottom="8px">
          <Typography variant="h3" variantMapping={{ h3: 'p' }} align="center">
            <span className="feedback-title">{title}</span>
          </Typography>
        </Box>
      )}

      {subtitle && (
        <Box>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: colors.grey }}
          >
            {subtitle}
          </Typography>
        </Box>
      )}

      {renderAction && (
        <Box marginBottom="20px" marginTop="20px">
          <Box display="flex" justifyContent="center">
            {renderAction()}
          </Box>
        </Box>
      )}
    </Box>
  </Box>
);

AlertFeedback.defaultProps = {
  renderIcon: undefined,
  title: '',
  subtitle: '',
  renderAction: undefined,
};

export const SomethingWentWrongFeedback = (): JSX.Element => (
  <AlertFeedback
    subtitle="Something went wrong. Try reloading."
    renderAction={() => (
      <MyButton fullWidth={false} onClick={handleReload} color="primary">
        <ReplayIcon
          sx={{
            color: '#FFFFFF',
            marginRight: '4px',
            fontSize: `${icon.fontSize.medium} !important`,
          }}
        />
        Retry
      </MyButton>
    )}
  />
);

export const LostConnectionFeedback = (): JSX.Element => (
  <AlertFeedback
    renderIcon={() => <CloudOffIcon sx={{ fontSize: icon.fontSize.xxlarge }} />}
    subtitle="Looks like you lost yout connection. Please check it and try again"
    renderAction={() => (
      <MyButton fullWidth={false} onClick={handleReload} color="primary">
        <ReplayIcon
          sx={{
            color: '#FFFFFF',
            marginRight: '4px',
            fontSize: `${icon.fontSize.medium} !important`,
          }}
        />
        Retry
      </MyButton>
    )}
  />
);

export default Feedback;
