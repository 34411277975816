import React, { useState, MouseEvent } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SortIcon from '@mui/icons-material/Sort';
import SearchIcon from '@mui/icons-material/Search';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MyMenu, { MyMenuItem } from '../../components/MyMenu';
import MyIconButton from '../../components/MyIconButton';
import { icon, colors, pageTitleBar } from '../../theme/theme';
import NeedTitleFilter from './NeedTitleFilter';
import LocationFilter, {
  FilterByLocationOptions,
  BiasPoint,
} from './LocationFilter';
import NeedTitleFilterDialog from './NeedTitleFilterDialog';
import LocationFilterDialog from './LocationFilterDialog';
import SortByDialog from './SortByDialog';

const iconStyles = {
  fontSize: `${icon.fontSize.medium} !important`,
  cursor: 'pointer',
};

const filterIconStyles = {
  fontSize: `${icon.fontSize.small} !important`,
  cursor: 'pointer',
  marginRight: '12px',
  color: colors.grey,
};

type TitleBarProps = {
  title: string;
  needTitle?: string;
  handleFilterByNeedTitle?: (needTitle: string) => void;
  radiusIndex: number;
  biasPoint: BiasPoint | null;
  handleFilterByLocation: (newOptions: FilterByLocationOptions) => void;
  selectedSortOption?: string;
  sortOptions?: string[];
  handleSortOptionChange?: (sortOption: string) => void;
  filtersActivated: boolean;
  onSearchFilterException: () => void;
};

const TitleBar = ({
  title,
  needTitle,
  handleFilterByNeedTitle,
  radiusIndex,
  biasPoint,
  handleFilterByLocation,
  selectedSortOption,
  handleSortOptionChange,
  sortOptions,
  filtersActivated,
  onSearchFilterException,
}: TitleBarProps): JSX.Element => {
  const theme = useTheme();
  const isMediumDeviceUp = useMediaQuery(theme.breakpoints.up('md'));

  const [locationFilterDialogOpen, setLocationFilterDialogOpen] =
    useState(false);
  const [placeLabel, setPlaceLabel] = useState('');

  const [neeedTitleFilterDialogOpen, setNeedTitleFilterDialogOpen] =
    useState(false);

  const [needTitleMenuAnchorEl, setNeedTitleMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const needTitleMenuOpen = Boolean(needTitleMenuAnchorEl);

  function handleNeedTitleIconClick(e: MouseEvent<HTMLButtonElement>) {
    if (isMediumDeviceUp) {
      setNeedTitleMenuAnchorEl(e.currentTarget);
    } else {
      setNeedTitleFilterDialogOpen(true);
    }
  }

  function handleNeedTitleMenuClose() {
    setNeedTitleMenuAnchorEl(null);
  }

  const [locationMenuAnchorEl, setLocationMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const locationMenuOpen = Boolean(locationMenuAnchorEl);

  function handleLocationIconClick(e: MouseEvent<HTMLButtonElement>) {
    if (isMediumDeviceUp) {
      setLocationMenuAnchorEl(e.currentTarget);
    } else {
      setLocationFilterDialogOpen(true);
    }
  }

  function handleLocationMenuClose() {
    setLocationMenuAnchorEl(null);
  }

  const [sortByDialogOpen, setSortByDialogOpen] = useState(false);

  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const sortMenuOpen = Boolean(sortMenuAnchorEl);

  function handleSortIconClick(e: MouseEvent<HTMLButtonElement>) {
    if (isMediumDeviceUp) {
      setSortMenuAnchorEl(e.currentTarget);
    } else {
      setSortByDialogOpen(true);
    }
  }

  function handleSortMenuClose() {
    setSortMenuAnchorEl(null);
  }

  function handleSortOptionClick(sortOption: string) {
    (
      window as {
        scroll: ({ top, left }: { top: number; left: number }) => void;
      }
    ).scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth',
    });
    if (handleSortOptionChange) {
      handleSortOptionChange(sortOption);
    }
    handleSortMenuClose();
  }

  const [filtersMenuAnchorEl, setFiltersMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const filtersMenuOpen = Boolean(filtersMenuAnchorEl);

  function handleFiltersIconClick(e: MouseEvent<HTMLButtonElement>) {
    setFiltersMenuAnchorEl(e.currentTarget);
  }

  function handleFiltersMenuClose() {
    setFiltersMenuAnchorEl(null);
  }

  const locationFilterColor =
    radiusIndex !== 17 || biasPoint !== null ? colors.primary : colors.black;
  const needTitleFilterColor = needTitle !== '' ? colors.primary : colors.black;
  const sortFilterColor =
    selectedSortOption !== '' ? colors.primary : colors.black;
  const tuneFilterColor = [
    locationFilterColor,
    needTitleFilterColor,
    sortFilterColor,
  ].includes(colors.primary)
    ? colors.primary
    : colors.black;

  return (
    <Box padding={pageTitleBar.padding}>
      <Grid
        container
        justifyContent={filtersActivated ? 'space-between' : 'flex-start'}
        alignItems="center"
        flexWrap="nowrap"
      >
        <Grid item>
          <Typography variant="h5" variantMapping={{ h5: 'h1' }}>
            {title}
          </Typography>
        </Grid>
        {filtersActivated && (
          <Grid item>
            {isMediumDeviceUp && (
              <Grid container flexWrap="nowrap">
                <Grid item>
                  <MyIconButton onClick={handleLocationIconClick}>
                    <LocationOnOutlinedIcon
                      sx={{
                        ...iconStyles,
                        color: locationFilterColor,
                      }}
                    />
                  </MyIconButton>
                  <MyMenu
                    id="location-menu"
                    anchorEl={locationMenuAnchorEl}
                    open={locationMenuOpen}
                    onClose={handleLocationMenuClose}
                  >
                    <LocationFilter
                      intitialRadius={radiusIndex}
                      initialBiasPoint={biasPoint}
                      initialPlaceLabel={placeLabel}
                      onApply={(data) => {
                        handleFilterByLocation(data);
                        handleLocationMenuClose();
                        setPlaceLabel(data.placeLabel);
                      }}
                      onPlaceSearchException={onSearchFilterException}
                    />
                  </MyMenu>
                </Grid>
                {needTitle !== undefined && handleFilterByNeedTitle && (
                  <Grid item>
                    <MyIconButton onClick={handleNeedTitleIconClick}>
                      <SearchIcon
                        sx={{
                          ...iconStyles,
                          color: needTitleFilterColor,
                        }}
                      />
                    </MyIconButton>
                    <MyMenu
                      id="need-title-menu"
                      anchorEl={needTitleMenuAnchorEl}
                      open={needTitleMenuOpen}
                      onClose={handleNeedTitleMenuClose}
                    >
                      <NeedTitleFilter
                        initialValue={needTitle}
                        onApply={(newNeedTitle: string) => {
                          handleFilterByNeedTitle(newNeedTitle);
                          handleNeedTitleMenuClose();
                        }}
                      />
                    </MyMenu>
                  </Grid>
                )}
                {sortOptions && sortOptions.length > 0 && (
                  <Grid item>
                    <MyIconButton onClick={handleSortIconClick}>
                      <SortIcon
                        sx={{
                          ...iconStyles,
                          color: sortFilterColor,
                        }}
                      />
                    </MyIconButton>
                    <MyMenu
                      id="sort-menu"
                      anchorEl={sortMenuAnchorEl}
                      open={sortMenuOpen}
                      onClose={handleSortMenuClose}
                    >
                      <MyMenuItem
                        selected={selectedSortOption === ''}
                        onClick={() => handleSortOptionClick('')}
                      >
                        Closest first
                      </MyMenuItem>
                      {sortOptions.map((sortOption) => (
                        <MyMenuItem
                          key={sortOption}
                          selected={sortOption === selectedSortOption}
                          onClick={() => handleSortOptionClick(sortOption)}
                        >
                          {sortOption}
                        </MyMenuItem>
                      ))}
                    </MyMenu>
                  </Grid>
                )}
              </Grid>
            )}
            {!isMediumDeviceUp && (
              <Box>
                <MyIconButton onClick={handleFiltersIconClick}>
                  <TuneOutlinedIcon
                    sx={{
                      ...iconStyles,
                      color: tuneFilterColor,
                    }}
                  />
                </MyIconButton>
                <MyMenu
                  id="filters-menu"
                  anchorEl={filtersMenuAnchorEl}
                  open={filtersMenuOpen}
                  onClose={handleFiltersMenuClose}
                >
                  <MyMenuItem
                    onClick={() => {
                      setLocationFilterDialogOpen(true);
                      handleFiltersMenuClose();
                    }}
                  >
                    <Box component="span" display="flex" alignItems="center">
                      <LocationOnOutlinedIcon
                        sx={{
                          ...filterIconStyles,
                          color: locationFilterColor,
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: locationFilterColor,
                        }}
                      >
                        Location
                      </Typography>
                    </Box>
                  </MyMenuItem>
                  <MyMenuItem
                    onClick={() => {
                      setNeedTitleFilterDialogOpen(true);
                      handleFiltersMenuClose();
                    }}
                  >
                    <Box component="span" display="flex" alignItems="center">
                      <SearchOutlinedIcon
                        sx={{
                          ...filterIconStyles,
                          color: needTitleFilterColor,
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ color: needTitleFilterColor }}
                      >
                        Advanced search
                      </Typography>
                    </Box>
                  </MyMenuItem>
                  <MyMenuItem
                    onClick={() => {
                      setSortByDialogOpen(true);
                      handleFiltersMenuClose();
                    }}
                  >
                    <Box component="span" display="flex" alignItems="center">
                      <SortIcon
                        sx={{
                          ...filterIconStyles,
                          color: sortFilterColor,
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: sortFilterColor,
                        }}
                      >
                        Sort by
                      </Typography>
                    </Box>
                  </MyMenuItem>
                </MyMenu>
              </Box>
            )}
          </Grid>
        )}
      </Grid>

      {locationFilterDialogOpen && (
        <LocationFilterDialog
          open
          onClose={() => setLocationFilterDialogOpen(false)}
          intitialRadius={radiusIndex}
          initialBiasPoint={biasPoint}
          initialPlaceLabel={placeLabel}
          onApply={(data) => {
            handleFilterByLocation(data);
            setPlaceLabel(data.placeLabel);
            setLocationFilterDialogOpen(false);
          }}
          onPlaceSearchException={onSearchFilterException}
        />
      )}

      {neeedTitleFilterDialogOpen &&
        needTitle !== undefined &&
        handleFilterByNeedTitle && (
          <NeedTitleFilterDialog
            open
            onClose={() => setNeedTitleFilterDialogOpen(false)}
            initialValue={needTitle}
            onApply={(newNeedTitle: string) => {
              handleFilterByNeedTitle(newNeedTitle);
              handleNeedTitleMenuClose();
              setNeedTitleFilterDialogOpen(false);
            }}
          />
        )}

      {sortByDialogOpen && sortOptions && (
        <SortByDialog
          open
          onClose={() => setSortByDialogOpen(false)}
          options={sortOptions}
          selectedOption={selectedSortOption}
          onSelectOption={(option) => {
            if (handleSortOptionChange) {
              handleSortOptionChange(option);
            }
            setSortByDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
};

TitleBar.defaultProps = {
  selectedSortOption: '',
  sortOptions: [],
  handleSortOptionChange: undefined,
  needTitle: undefined,
  handleFilterByNeedTitle: undefined,
};

export default TitleBar;
