import React, { useEffect, useState } from 'react';
import { PrivacySettings } from '../../types/PrivacySettings';
import PageLayout from '../../components/PageLayout';
import PrivacyNSafety from '../SettingsPage/PrivacyNSafety';

const AnonymousUserPrivacyNSafetyPage = (): JSX.Element => {
  const [privacySettings, setPrivacySettings] =
    useState<PrivacySettings | null>(null);
  const [error, setError] = useState(false);

  function getPrivacySettings() {
    const data = localStorage.getItem('privacySettings');
    if (data === null) {
      setError(true);
    } else {
      setPrivacySettings(JSON.parse(data));
    }
  }

  function updatePrivacySettings(updated: PrivacySettings) {
    localStorage.setItem('privacySettings', JSON.stringify(updated));
    setPrivacySettings(updated);
  }

  useEffect(() => {
    getPrivacySettings();
  }, []);

  return (
    <PageLayout>
      <PrivacyNSafety
        privacySettings={privacySettings}
        loading={false}
        error={!!error}
        updatePrivacySettings={updatePrivacySettings}
      />
    </PageLayout>
  );
};

export default AnonymousUserPrivacyNSafetyPage;
