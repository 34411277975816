import { GeoLocation } from '../types/GeoLocation';
import logger from '../logger';

const useGeoLocation = (): GeoLocation => {
  const geoLocationString = sessionStorage.getItem('GeoLocation');
  if (!geoLocationString) {
    logger.log({
      message: 'Failed to use GeoLocation',
      reason: 'Missing Geolocation data in Storage',
    });
    throw new Error('Missing Geolocation data in Storage');
  }

  const geoLocation = JSON.parse(geoLocationString);

  return geoLocation as GeoLocation;
};

export default useGeoLocation;
