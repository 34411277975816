import { createContext } from 'react';
import { Item } from '../../types/Item';
import { Need } from '../../types/Need';
import { Profile } from '../../types/Profile';
import { Message } from '../../types/Message';
import { Proposition } from '../../types/Proposition';

export type DialogsManager = {
  openCreateNewItemDialog?: (callback?: (created: Item) => void) => void;
  closeCreateNewItemDialog?: () => void;
  openCreateNewNeedDialog?: (callback?: (created: Need) => void) => void;
  closeCreateNewNeedDialog?: () => void;
  openEditNeedDialog?: (need: Need, callback?: (edited: Need) => void) => void;
  closeEditNeedDialog?: () => void;
  openSearchMoneyDialog?: (callback?: () => void) => void;
  closeSearchMoneyDialog?: () => void;
  openSearchMoneyByProductDialog?: (callback?: () => void) => void;
  closeSearchMoneyByProductDialog?: () => void;
  openEditProfileDialog?: (profile: Profile, callback?: () => void) => void;
  closeEditProfileDialog?: () => void;
  openProposeItemDialog?: (need: Need, callback?: () => void) => void;
  closeProposeItemDialog?: () => void;
  openLoginDialog?: () => void;
  closeLoginDialog?: () => void;
  openSignupDialog?: () => void;
  closeSignupDialog?: () => void;
  openPasswordResetDialog?: () => void;
  closePasswordResetDialog?: () => void;
  openChatDialog?: (
    proposition: Proposition,
    onMessagesLoaded?: () => void,
    onNewMessage?: (newMessage: Message) => void,
  ) => void;
  closeChatDialog?: () => void;
};

const dialogsManagerContext = createContext<DialogsManager>({});

export default dialogsManagerContext;
