import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import { State } from '../../store/types';
import { Profile } from '../../types/Profile';
import useSecurity from '../../hooks/useSecurity';
import useDialogsManager from '../../hooks/useDialogsManager';
import { Proposition } from '../../types/Proposition';
import { getTimeFromNow } from '../../utils/date';
import { MyCard, MyCardHeader, MyCardContent } from './MyCards';
import CardHover from './CardHover';
import ImageGallery from './ImageGallery';
import ProposingTo from './ProposingTo';
import MyIconButton from '../MyIconButton';
import { colors, icon } from '../../theme/theme';
import { ButtonHover } from './SearchNeedCard';

const Action = ({
  name,
  Icon,
  onClick,
}: {
  name: string;
  Icon: typeof ChatBubbleOutlineIcon;
  onClick: () => void;
}): JSX.Element => (
  <ButtonHover>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box marginRight="4px">
        <MyIconButton
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          <Icon
            sx={{
              fontSize: icon.fontSize.small,
              color: colors.grey,
            }}
          />
        </MyIconButton>
      </Box>
      <Typography
        variant="button"
        sx={{ color: colors.grey }}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        {name}
      </Typography>
    </Box>
  </ButtonHover>
);

type SmallPropositionCardProps = {
  proposition: Proposition;
  handleNotInterested: () => void;
};

const SmallPropositionCard = ({
  proposition,
  handleNotInterested,
}: SmallPropositionCardProps): JSX.Element => {
  const history = useHistory();
  const { item } = proposition;
  const me = useSelector<State, Profile>((state) => state.profile as Profile);
  const { loggedIn } = useSecurity();
  const { openChatDialog } = useDialogsManager();

  function iAmProposer(): boolean {
    if (!loggedIn) return false;
    if (proposition !== null) return proposition.item.profile.ID === me.ID;
    return false;
  }

  function iAmNeeder(): boolean {
    if (!loggedIn) return false;
    if (proposition !== null) return proposition.need.profile.ID === me.ID;
    return false;
  }

  return (
    <Box
      onClick={() => history.push(`/proposition/${proposition.ID}`)}
      sx={{ cursor: 'pointer' }}
    >
      <Box sx={{ cursor: 'pointer' }}>
        {/* <CardTitle colorBlack>{item.name}</CardTitle> */}
        <CardHover>
          <MyCard>
            <MyCardHeader
              title={
                <Typography variant="h6" variantMapping={{ h6: 'div' }}>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/profile/${proposition.item.profile.ID}`);
                    }}
                    sx={{
                      display: 'inline-block',
                      cursor: 'pointer !important',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    <span className="card-username">
                      {item.profile.firstname}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: 'inline-block',
                      fontWeight: 'normal !important',
                      color: `${colors.grey} !important`,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'inline-block',
                        fontWeight: 'normal !important',
                        color: `${colors.grey} !important`,
                        padding: '0 4px',
                      }}
                    >
                      ·
                    </Box>
                    {getTimeFromNow(proposition.CreatedAt)}
                  </Box>
                </Typography>
              }
              subheader={<ProposingTo proposition={proposition} />}
            />
            <MyCardContent>
              <Box marginTop="12px">
                <Typography variant="body1">{item.description}</Typography>
              </Box>
              <Box marginTop="12px">
                <Box onClick={(e) => e.stopPropagation()}>
                  <ImageGallery photos={proposition.item.photos} />
                </Box>
              </Box>
              {(iAmProposer() || iAmNeeder()) && (
                <Box marginTop="12px">
                  <Grid container>
                    <Grid item xs>
                      <Action
                        name="Chat"
                        Icon={ChatBubbleOutlineIcon}
                        onClick={() => {
                          if (openChatDialog) {
                            openChatDialog(proposition);
                          }
                        }}
                      />
                    </Grid>
                    {iAmNeeder() && (
                      <Grid item xs>
                        <Action
                          name="Not interested"
                          Icon={NotInterestedOutlinedIcon}
                          onClick={handleNotInterested}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </MyCardContent>
          </MyCard>
        </CardHover>
      </Box>
    </Box>
  );
};

export default SmallPropositionCard;
