import React from 'react';
import isEqual from 'lodash/isEqual';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MyButton from '../../components/MyButton';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import { calculateValue, valueLabelFormat } from './miles';
import { locationFilter, divider, colors } from '../../theme/theme';

export type BiasPoint = {
  latitude: number;
  longitude: number;
  countryISOCode: string;
};

export type FilterByLocationOptions = {
  radius: number;
  biasPoint: BiasPoint | null;
  placeLabel: string;
};

type LocationFilterProps = {
  intitialRadius: number;
  initialBiasPoint: BiasPoint | null;
  initialPlaceLabel: string;
  onPlaceSearchException: () => void;
  onApply: (newOptions: FilterByLocationOptions) => void;
};

const LocationFilter = ({
  intitialRadius,
  initialBiasPoint,
  initialPlaceLabel,
  onPlaceSearchException,
  onApply,
}: LocationFilterProps): JSX.Element => {
  const [placeSearchInputValue, setPlaceSearchInputValue] = React.useState('');
  const [placeLabel, setPlaceLabel] = React.useState(initialPlaceLabel);
  const [biasPoint, setBiasPoint] = React.useState<BiasPoint | null>(
    initialBiasPoint,
  );
  const [radius, setRadius] = React.useState<
    number | string | Array<number | string>
  >(intitialRadius);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setRadius(newValue);
  };

  return (
    <Box width="320px">
      <Box padding={locationFilter.input.padding}>
        <PlaceSearchInput
          inputLabel="Enter Zip-Code or City"
          initialInputValue={initialPlaceLabel}
          onPlaceDetailsAvailable={(place) => {
            setBiasPoint({
              latitude: place.GeometryLatitude,
              longitude: place.GeometryLongitude,
              countryISOCode: place.Country,
            });
            setPlaceLabel(place.Label);
          }}
          onExceptionCaught={onPlaceSearchException}
          onInputValueChange={(newInputValue) => {
            if (newInputValue === '') {
              setBiasPoint(null);
              setPlaceLabel('');
            }
            setPlaceSearchInputValue(newInputValue);
          }}
        />
      </Box>
      <Box padding={locationFilter.slider.padding}>
        <Box marginBottom="4px">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography id="input-slider" variant="body1">
                Radius
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                id="input-slider"
                variant="body1"
                sx={{ color: colors.grey }}
              >
                {valueLabelFormat(calculateValue(radius as number))}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Slider
          value={typeof radius === 'number' ? radius : 17}
          min={1}
          step={1}
          max={17}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          valueLabelDisplay="off"
        />
      </Box>
      <Divider sx={{ borderColor: divider.color }} />
      <Box padding={locationFilter.cta.padding}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Box marginLeft="16px">
              <MyButton
                size="small"
                color="primary"
                disabled={
                  radius === intitialRadius &&
                  isEqual(biasPoint, initialBiasPoint)
                }
                onClick={() => {
                  onApply({
                    radius: radius as number,
                    biasPoint: placeSearchInputValue === '' ? null : biasPoint,
                    placeLabel: placeSearchInputValue === '' ? '' : placeLabel,
                  });
                }}
              >
                Apply
              </MyButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LocationFilter;
