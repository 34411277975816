import indefinite from 'indefinite';
import pluralize from 'pluralize';

function getArticle(subject: string): string {
  if (pluralize(subject) === subject) {
    return '';
  }

  return indefinite(subject, { articleOnly: true });
}

export default getArticle;
