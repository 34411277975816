import useGeoLocation from './useGeoLocation';

type Locale = {
  locale: string;
  currency: string;
};

const useLocale = (): Locale => {
  const { countryISOCode, registeredCountryIsInEuropeanUnion } =
    useGeoLocation();

  let locale = '';
  let currency = '';

  if (registeredCountryIsInEuropeanUnion) {
    locale = 'fr-FR';
    currency = 'EUR';
  } else {
    if (countryISOCode === 'GB') {
      locale = 'en-GB';
      currency = 'GBP';
    }

    if (countryISOCode === 'US') {
      locale = 'en-US';
      currency = 'USD';
    }
  }

  return {
    locale,
    currency,
  };
};

export default useLocale;
