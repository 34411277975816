import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CreateYourAccount from './CreateYourAccount';
import VerifyEmail from './VerifyEmail';
import ChoosePassword from './ChoosePassword';
import { SignupData, Step } from '../../types/SignupData';
import Spinner from '../../components/Spinner';

type SignupDialogProps = {
  onClose: () => void;
};

const SignupDialog = ({ onClose }: SignupDialogProps): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState<Step['name'] | null>(null);

  function lastCompletedStep(data: SignupData): Step['name'] {
    if (data.steps.some((step) => step.name === 'choose_password')) {
      return 'choose_password';
    }
    if (data.steps.some((step) => step.name === 'verify_email')) {
      return 'verify_email';
    }
    return 'create_account';
  }

  function getSignupDataFromCache(): SignupData | null {
    const signupData = localStorage.getItem('signupData');
    if (signupData === null) {
      return null;
    }

    return JSON.parse(signupData);
  }

  function removeSignupDataFromCache() {
    localStorage.removeItem('signupData');
  }

  function resume() {
    const signupData = getSignupDataFromCache();

    if (signupData === null) {
      setCurrentStep('create_account');
    } else {
      const expiration = localStorage.getItem('signupDataExpiration');
      if (expiration === null) {
        setCurrentStep('create_account');
      } else {
        const now = new Date();
        if (now.getTime() > (JSON.parse(expiration) as number)) {
          removeSignupDataFromCache();
          localStorage.removeItem('signupDataExpiration');
          setCurrentStep('create_account');
        } else {
          const lastCompleted = lastCompletedStep(signupData);
          if (lastCompleted === 'create_account') {
            setCurrentStep('verify_email');
          } else if (lastCompleted === 'verify_email') {
            setCurrentStep('choose_password');
          }
        }
      }
    }

    setLoading(false);
  }

  function goNext() {
    if (currentStep === 'create_account') {
      setCurrentStep('verify_email');
      return;
    }
    if (currentStep === 'verify_email') {
      setCurrentStep('choose_password');
      return;
    }
    if (currentStep === 'choose_password') {
      removeSignupDataFromCache();
      history.push('/login');
      onClose();
    }
  }

  function goBack() {
    if (currentStep === 'create_account') {
      return;
    }
    if (currentStep === 'verify_email') {
      setCurrentStep('create_account');
      return;
    }
    if (currentStep === 'choose_password') {
      setCurrentStep('verify_email');
    }
  }

  useEffect(() => {
    resume();
  }, []);

  return (
    <>
      {loading && <Spinner />}
      {currentStep === 'create_account' && (
        <CreateYourAccount goNext={goNext} onClose={onClose} />
      )}
      {currentStep === 'verify_email' && (
        <VerifyEmail goNext={goNext} goBack={goBack} />
      )}
      {currentStep === 'choose_password' && (
        <ChoosePassword goNext={goNext} onClose={onClose} />
      )}
    </>
  );
};

export default SignupDialog;
