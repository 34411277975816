import React, { useState } from 'react';
import useFetch from 'use-http';
import useDialogsManager from '../../hooks/useDialogsManager';
import { BASE_URL } from '../../constants';
import logger from '../../logger';

type PassedComponentProps = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (email: string, password: string) => void;
  handleForgotPasswordButtonClick: () => void;
  showLoginErrorAlert: boolean;
  loading: boolean;
  error: boolean;
};

type ReturnedComponentProps = {
  open: boolean;
  onClose: () => void;
};

const withLoginContainer = (
  PassedComponent: (props: PassedComponentProps) => JSX.Element,
): ((props: ReturnedComponentProps) => JSX.Element) =>
  function ReturnedComponent({ open, onClose }: ReturnedComponentProps) {
    const { openPasswordResetDialog } = useDialogsManager();
    const [showLoginErrorAlert, setShowLoginErrorAlert] = useState(false);
    const [tokenError, setTokenError] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const { post, loading, error, response } = useFetch(BASE_URL);

    async function login(email: string, password: string) {
      const data = await post(`/accounts/login`, {
        email,
        password,
      });
      if (response.ok) {
        // Keeps the Spinner spinning
        setRedirecting(true);

        if (data.token && data.profile) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('profile', JSON.stringify(data.profile));
          localStorage.setItem('isLoggedIn', 'true');
          window.location.assign('/');
        } else {
          logger.log({
            message: 'Failed to set jwt-token or profile after login',
          }); // eslint-disable-line
          setTokenError(true);
          setRedirecting(false);
        }
      } else if (response.status === 403) {
        setShowLoginErrorAlert(true);
      }
    }

    const handleSubmit = (email: string, password: string) => {
      login(email, password);
    };

    const handleForgotPasswordButtonClick = () => {
      if (openPasswordResetDialog) {
        openPasswordResetDialog();
      }
    };

    return (
      <PassedComponent
        open={open}
        onClose={onClose}
        handleSubmit={handleSubmit}
        handleForgotPasswordButtonClick={handleForgotPasswordButtonClick}
        showLoginErrorAlert={showLoginErrorAlert}
        loading={loading || redirecting}
        error={(Boolean(error) || tokenError) && response.status !== 403}
      />
    );
  };

export default withLoginContainer;
