import React, { ReactNode } from 'react';
import { Box } from '@mui/system';
import { card } from '../../theme/theme';

const CardHover = ({ children }: { children: ReactNode }): JSX.Element => (
  <Box
    sx={{
      '& .MuiPaper-root:hover': {
        backgroundColor: card.backgrounColor.hover,
      },
    }}
  >
    {children}
  </Box>
);

export default CardHover;
