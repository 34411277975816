import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MyList, {
  MyListItem,
  MyListItemText,
  MyListItemButton,
} from '../../components/MyList';
import PageSection from '../../components/PageSection';
import { colors } from '../../theme/theme';

const SettingsList = (): JSX.Element => (
  <PageSection title="Settings" backgroundColor>
    <Box>
      <MyList>
        <Link
          to="/settings/privacy-safety"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <MyListItemButton>
            <MyListItem
              secondaryAction={
                <ArrowForwardIosIcon
                  sx={{ fontSize: '20px', color: colors.icon }}
                />
              }
            >
              <MyListItemText>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 'inherit',
                    fontWeight: 'normal',
                  }}
                >
                  Privacy & safety
                </Typography>
              </MyListItemText>
            </MyListItem>
          </MyListItemButton>
        </Link>
        <Link
          to="/settings/email-notifications"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <MyListItemButton>
            <MyListItem
              secondaryAction={
                <ArrowForwardIosIcon
                  sx={{ fontSize: '20px', color: colors.icon }}
                />
              }
            >
              <MyListItemText>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 'inherit',
                    fontWeight: 'normal',
                  }}
                >
                  Email notifications
                </Typography>
              </MyListItemText>
            </MyListItem>
          </MyListItemButton>
        </Link>
      </MyList>
    </Box>
  </PageSection>
);

export default SettingsList;
