import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { io } from 'socket.io-client';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { Provider as UseFetchProvider } from 'use-http';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IncomingOptions } from 'use-http/dist/cjs/types';
import store from './store';
import DialogsProvider from './contexts/dialogsManager/Provider';
import SecureRoute from './components/SecureRoute';
import NotSecureRoute from './components/NotSecureRoute';
import GeoLocator from './components/GeoLocator';
import ErrorFallback, { ErrorHandler } from './components/ErrorFallback';
import SecurityProvider, { handleLogout } from './contexts/security/Provider';
import BadgeCountProvider from './contexts/badgeCount/Provider';
import MyPropositionsProvider from './contexts/myPropositions/Provider';
import NeedPage from './pages/NeedPage';
import PropositionPage from './pages/PropositionPage';
import SearchExplorePage from './pages/SearchExplorePage';
import LoginPage from './pages/LoginPage';
import OauthCallbackPage from './pages/OauthCallbackPage';
import ProfilePage from './pages/ProfilePage';
import HomePage from './pages/HomePage';
import ExplorePage from './pages/ExplorePage';
import SettingsPage from './pages/SettingsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsNConditionsPage from './pages/TermsNConditionsPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import AnonymousUserPrivacyNSafetyPage from './pages/AnonymousUserPrivacyNSafetyPage';
import ContactPage from './pages/ContactPage';
import theme from './theme/theme';
import { Profile } from './types/Profile';
import ScrollToTop from './components/ScrollToTop';
import MyCookiesBanner from './components/MyCookiesBanner';
import { CHAT_SERVER_URL } from './constants';

function App(): JSX.Element {
  const [cookiesBannerOpen, setCookiesBannerOpen] = useState(false);

  function loadProfileFromLocalStorage(): Profile | null {
    const value = localStorage.getItem('profile');
    if (value === null) return null;
    return JSON.parse(value) as Profile;
  }

  function isLoggedIn(): boolean {
    return localStorage.getItem('isLoggedIn') !== null;
  }

  let token: string | null = null;

  if (isLoggedIn()) {
    token = localStorage.getItem('token');
  }

  const profile = loadProfileFromLocalStorage();

  if (isLoggedIn() && profile) {
    const socket = io(CHAT_SERVER_URL, {
      auth: {
        token,
      },
    });
    socket.on('connect', () => {
      socket.emit('set_profile', profile);
      socket.emit('join_room', profile.ID.toString());
      socket.emit('online_status', true);
    });

    store.dispatch({
      type: 'SAVE_PROFILE',
      payload: profile,
    });
  }

  const fetchOptions: IncomingOptions = {
    interceptors: {
      request: async ({ options, route }) => {
        if (
          options.method &&
          ['POST', 'PUT', 'DELETE'].includes(options.method) &&
          route &&
          !['/onboarding', '/password_reset', '/accounts/login'].includes(route)
        ) {
          // eslint-disable-next-line
          options.body = JSON.stringify({
            token,
            payload: options.body
              ? JSON.parse(options.body as unknown as string)
              : null,
          }) as unknown as BodyInit;
        }

        return options
      },
      response: async ({ response }) => {
        if (response.status === 403 && isLoggedIn()) {
          handleLogout();
        }
        return response;
      },
    },
    };

  function handleCookiesBanner() {
    const cookiesBannerSeen = localStorage.getItem('cookiesBannerSeen');
    if (cookiesBannerSeen === null) {
      setCookiesBannerOpen(true);
    }
  }

  function closeCookiesBanner() {
    localStorage.setItem('cookiesBannerSeen', 'true');
    setCookiesBannerOpen(false);
  }

  function handleAnonymousUserPrivacySettings() {
    const data = localStorage.getItem('privacySettings');
    if (data === null) {
      localStorage.setItem(
        'privacySettings',
        JSON.stringify({
          personalisedAdsFromBonvih: true,
          personalisedAdsOnBonvih: true,
        }),
      );
    }
  }

  useEffect(() => {
    handleCookiesBanner();

    if (!isLoggedIn()) {
      handleAnonymousUserPrivacySettings();
    } else {
      localStorage.removeItem('privacySettings');
    }
  }, []);

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={ErrorHandler}>
        <GeoLocator>
          <UseFetchProvider options={fetchOptions}>
            <Provider store={store}>
              <Router>
                <ScrollToTop />
                <ThemeProvider theme={theme}>
                  <SecurityProvider isLoggedIn={isLoggedIn()}>
                    <MyPropositionsProvider>
                      <BadgeCountProvider>
                        <DialogsProvider>
                          <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route
                              exact
                              path="/explore"
                              component={ExplorePage}
                            />
                            <NotSecureRoute
                              path="/login"
                              component={LoginPage}
                            />
                            <Route
                              path="/oauth-callback/:email"
                              component={OauthCallbackPage}
                            />
                            <Route
                              path="/search"
                              component={SearchExplorePage}
                            />
                            <Route path="/need/:id" component={NeedPage} />
                            <Route
                              path="/proposition/:id"
                              component={PropositionPage}
                            />
                            <Route
                              exact
                              path="/profile/:id"
                              component={ProfilePage}
                            />
                            <SecureRoute
                              path="/profile/:id/notifications"
                              component={ProfilePage}
                            />
                            <SecureRoute
                              path="/profile/:id/chats"
                              component={ProfilePage}
                            />
                            <Route
                              path="/profile/:id/spending"
                              component={ProfilePage}
                            />
                            <Route
                              path="/profile/:id/spent"
                              component={ProfilePage}
                            />
                            <SecureRoute
                              path="/profile/:id/proposing"
                              component={ProfilePage}
                            />
                            <SecureRoute
                              path="/settings"
                              component={SettingsPage}
                            />
                            <Route path="/contact" component={ContactPage} />
                            <Route
                              path="/terms-conditions"
                              component={TermsNConditionsPage}
                            />
                            <Route
                              path="/privacy-policy"
                              component={PrivacyPolicyPage}
                            />
                            <Route
                              path="/cookie-policy"
                              component={CookiePolicyPage}
                            />
                            <Route
                              path="/privacy-safety"
                              component={AnonymousUserPrivacyNSafetyPage}
                            />

                            <Route component={() => <div>Error 404</div>} />
                          </Switch>
                        </DialogsProvider>
                      </BadgeCountProvider>
                    </MyPropositionsProvider>
                  </SecurityProvider>
                </ThemeProvider>

                {cookiesBannerOpen && (
                  <MyCookiesBanner onClose={closeCookiesBanner} />
                )}
              </Router>
            </Provider>
          </UseFetchProvider>
        </GeoLocator>
      </ErrorBoundary>
    </div>
  );
}

export default App;
