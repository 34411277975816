import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useFetch from 'use-http';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyButton from '../../components/MyButton';
import MyDialog from '../../components/MyDialog';
import MyIconButton from '../../components/MyIconButton';
import MyTextField from '../../components/MyTextField';
import MyCloseIcon from '../../components/MyCloseIcon';
import { Item, PhotoMetadata } from '../../types/Item';
import { Profile } from '../../types/Profile';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { storeItemPhoto } from '../../aws/s3';
import Spinner from '../../components/Spinner';
import { BASE_URL } from '../../constants';
import { dialog, icon, colors, input } from '../../theme/theme';
import logger from '../../logger';

type FormValues = {
  name: string;
  description: string;
};

type PhotoObject = {
  fileObject: FileObject;
  width: number;
  height: number;
};

type CreateNewItemDialogProps = {
  open: boolean;
  onClose: () => void;
  callback?: (created: Item) => void;
  profile: Profile;
  isSmallDeviceDown: boolean;
};

const CreateNewItemDialog = ({
  open,
  onClose,
  callback,
  profile,
  isSmallDeviceDown,
}: CreateNewItemDialogProps): JSX.Element => {
  const [photoObjects, setPhotoObjects] = useState<PhotoObject[]>([]);
  const [addPhotoError, setAddPhotoError] = useState(false);
  const [creating, setCreating] = useState(false);
  const [creatingError, setCreatingError] = useState(false);
  const photoObjectsRef = useRef<PhotoObject[]>([]);

  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { errors, isDirty, isValid } = formState;

  const { post, error, response } = useFetch(BASE_URL);

  function addPhotoDimensions(photoObject: PhotoObject) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(photoObject.fileObject.file);
    fileReader.addEventListener('load', (e) => {
      const loadedFileUrl = e.target?.result;
      const img = document.createElement('img');
      img.src = loadedFileUrl as string;
      img.addEventListener('load', () => {
        const found = photoObjectsRef.current.find((p) => p === photoObject);
        if (found) {
          found.width = img.width;
          found.height = img.height;

          photoObjectsRef.current = photoObjectsRef.current.filter(
            (p) => p !== photoObject,
          );

          photoObjectsRef.current.push(found);
          setPhotoObjects([...photoObjectsRef.current]);
        } else {
          logger.log({
            message: 'PhotoObject not found',
            reason: 'Maybe reference problem',
          });
          setAddPhotoError(true);
        }
      });
    });
  }

  function handleAddFile(newFiles: FileObject[]) {
    if (photoObjects.length < 4) {
      // use this for compression
      const newPhotoObjects: PhotoObject[] = newFiles.map((fo) => ({
        fileObject: fo,
        width: 0,
        height: 0,
      }));

      setPhotoObjects([...photoObjects, ...newPhotoObjects]);
      photoObjectsRef.current = [
        ...photoObjectsRef.current,
        ...newPhotoObjects,
      ];
      newPhotoObjects.forEach(addPhotoDimensions);
    }
  }

  function handleDeleteFile(photoObject: PhotoObject) {
    photoObjectsRef.current = photoObjectsRef.current.filter(
      (po) => po !== photoObject,
    );
    setPhotoObjects([...photoObjectsRef.current]);
  }

  async function createItem(item: {
    name: string;
    description: string;
    photos: PhotoMetadata[];
    profile: Profile;
    sold: boolean;
  }) {
    const created = await post('/items', item);
    if (response.ok) {
      if (callback) {
        callback(created as Item);
      }
      onClose();
    } else {
      setCreating(false);
      setCreatingError(true);
    }
  }

  async function storePhoto(photoObject: PhotoObject) {
    const url = await storeItemPhoto(photoObject.fileObject.file);
    return url;
  }

  async function createPhotoMetadataFromFile(photoObject: PhotoObject) {
    const url = await storePhoto(photoObject);
    return {
      filename: photoObject.fileObject.file.name,
      type: photoObject.fileObject.file.type,
      width: photoObject.width,
      height: photoObject.height,
      url,
    };
  }

  async function handleCreateButtonClick() {
    setCreating(true);

    const { name, description } = getValues();

    try {
      const photoMetadatas = await Promise.all(
        photoObjects.map((po) => createPhotoMetadataFromFile(po)),
      );
      createItem({
        name: name.trim(),
        description: description.trim(),
        photos: photoMetadatas as PhotoMetadata[],
        profile,
        sold: false,
      });
    } catch (err) {
      logger.log({ message: `Failed creating item`, error: err as Error });
      setCreating(false);
      setCreatingError(true);
    }
  }

  function handleCloseIconClick() {
    onClose();
  }

  return (
    <MyDialog open={open} onClose={onClose}>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography variant="h5" variantMapping={{ h5: 'h1' }}>
            Create new item or service
          </Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            {!error && !creatingError && !addPhotoError && (
              <MyButton
                size="small"
                disabled={
                  !isDirty || !isValid || photoObjects.length === 0 || creating
                  // !isDirty || !isValid || creating
                }
                onClick={handleCreateButtonClick}
              >
                Create
              </MyButton>
            )}
          </Box>
        )}
      />

      {creating && !error && !creatingError && !addPhotoError && <Spinner />}

      {!creating && (Boolean(error) || creatingError || addPhotoError) && (
        <SomethingWentWrongFeedback />
      )}

      {!creating && !error && !creatingError && !addPhotoError && (
        <>
          <Box padding={dialog.input.padding}>
            <Box
              sx={{
                position: 'relative',
                '& .MuiDropzoneArea-root': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  minHeight: '100%',
                  borderColor: input.border.color,
                },
                '& .MuiDropzoneArea-text': {
                  marginTop: '0',
                  marginBottom: '0',
                },
              }}
            >
              <Box paddingTop="33.3333%" />
              <Box
                sx={{
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  width: '100%',
                }}
              >
                <DropzoneAreaBase
                  fileObjects={photoObjects.map((po) => po.fileObject)}
                  maxFileSize={5000000}
                  filesLimit={4}
                  acceptedFiles={['image/jpeg']}
                  dropzoneText=""
                  showPreviewsInDropzone={false}
                  showAlerts={['error']}
                  Icon={
                    ((): JSX.Element => (
                      <MyIconButton>
                        <AddAPhotoIcon
                          sx={{
                            fontSize: icon.fontSize.medium,
                            color: icon.colorLight,
                          }}
                        />
                      </MyIconButton>
                    )) as unknown as undefined
                  }
                  onAdd={handleAddFile}
                />
              </Box>
            </Box>
          </Box>
          <Box padding="0 16px 12px 16px">
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: isSmallDeviceDown
                  ? 'repeat(3, 1fr)'
                  : 'repeat(5, 1fr)',
                gridAutoRows: '1fr',
                gap: '12px',
                width: '100%',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {photoObjects.map((po, idx) => (
                <Box
                  key={po.fileObject.file.name + idx} // eslint-disable-line react/no-array-index-key
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={
                      <IconButton
                        sx={{
                          width: '30px',
                          height: '30px',
                          backgroundColor: colors.black,
                          '&:hover': {
                            backgroundColor: colors.black,
                          },
                        }}
                        onClick={() => handleDeleteFile(po)}
                      >
                        <CloseIcon
                          sx={{
                            fontSize: icon.fontSize.small,
                            color: '#FFFFFF',
                          }}
                        />
                      </IconButton>
                    }
                    sx={{
                      '& .MuiBadge-badge': {
                        top: '17px',
                        right: 'unset',
                        left: '-25px',
                      },
                    }}
                  >
                    <Avatar
                      alt={po.fileObject.file.name}
                      src={po.fileObject.data as string}
                      sx={{
                        height: '96px',
                        width: '100%',
                        borderRadius: '4px',
                      }}
                    />
                  </Badge>
                </Box>
              ))}
            </Box>
          </Box>
          <Box padding={dialog.input.padding}>
            <Controller
              control={control}
              rules={{
                required: 'Name of the item or service is required.',
                maxLength: {
                  value: 70,
                  message: 'Too many characters... Limit is 70',
                },
              }}
              name="name"
              defaultValue=""
              render={({ field }) => (
                <MyTextField
                  id="name-input"
                  label="What is the name of the item or service?"
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
          </Box>
          <Box padding={dialog.input.padding}>
            <Controller
              control={control}
              rules={{
                required: 'Description of the item is required.',
                maxLength: {
                  value: 140,
                  message: 'Too many characters... Limit is 140',
                },
              }}
              name="description"
              defaultValue=""
              render={({ field }) => (
                <MyTextField
                  id="description-input"
                  label="Add informations about it like condition, color..."
                  multiline
                  rows={5}
                  error={!!errors.description}
                  helperText={errors.description && errors.description.message}
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
          </Box>
          <Box marginTop="64px" />
        </>
      )}
    </MyDialog>
  );
};

CreateNewItemDialog.defaultProps = {
  callback: undefined,
};

export default CreateNewItemDialog;
