import {
  LocationClient,
  SearchPlaceIndexForSuggestionsCommand,
  SearchPlaceIndexForTextCommand,
} from '@aws-sdk/client-location';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { Geocode } from '../types/Geocode';
import {
  AWS_LOCATION_REGION,
  AWS_LOCATION_INDEX,
  AWS_COGNITO_REGION,
  AWS_COGNITO_IDENTITY_POOL_ID,
} from '../constants';
import logger from '../logger';

const client = new LocationClient({
  region: AWS_LOCATION_REGION,
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: AWS_COGNITO_REGION },
    identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID as string,
  }),
});

type BiasPoint = {
  longitude: number;
  latitude: number;
};

export async function getLocationSuggestions(
  text: string,
  country: string,
  biasPoint: BiasPoint,
): Promise<readonly string[]> {
  const command = new SearchPlaceIndexForSuggestionsCommand({
    Text: text,
    IndexName: AWS_LOCATION_INDEX,
    // FilterCountries: [country],
    BiasPosition: [biasPoint.longitude, biasPoint.latitude],
  });

  const { Results } = await client.send(command);

  if (!Results) {
    logger.log({
      message: 'Failed to get location suggestions',
      reason: 'locationSuggestions results type is undefined',
    });
    throw new Error('locationSuggestions results type is undefined');
  }

  return Array.from(new Set(Results.map((r) => (r.Text ? r.Text : ''))));
}

export async function getPlaceDetails(
  text: string,
  country: string,
  biasPoint: BiasPoint,
): Promise<Geocode> {
  const command = new SearchPlaceIndexForTextCommand({
    Text: text,
    IndexName: AWS_LOCATION_INDEX,
    // FilterCountries: [country],
    BiasPosition: [biasPoint.longitude, biasPoint.latitude],
  });

  const { Results } = await client.send(command);

  if (!Results) {
    logger.log({
      message: 'Failed to get Place Details',
      reason: 'locationSuggestions results type is undefined',
    });
    throw new Error('locationSuggestions results type is undefined');
  }

  return Results.map((r) => {
    if (!r.Place || !r.Place.Geometry || !r.Place.Geometry.Point) {
      logger.log({
        message: 'Failed to get Place Details',
        reason: 'locationSuggestions results type is undefined',
      });
      throw new Error('PlaceDetails type is undefined');
    }

    return {
      AddressNumber: r.Place.AddressNumber as string,
      Label: r.Place.Label as string,
      Municipality: r.Place.Municipality as string,
      Neighborhood: r.Place.Neighborhood as string,
      PostalCode: r.Place.PostalCode as string,
      Region: r.Place.Region as string,
      Street: r.Place.Street as string,
      Country: r.Place.Country as string,
      SubRegion: r.Place.SubRegion as string,
      GeometryLongitude: r.Place.Geometry.Point[0] as number,
      GeometryLatitude: r.Place.Geometry.Point[1] as number,
    };
  })[0];
}

export default null;
