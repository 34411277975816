import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from 'use-http';
import useSecurity from '../../hooks/useSecurity';
import useDialogsManager from '../../hooks/useDialogsManager';
import { BASE_URL } from '../../constants';
import { Need } from '../../types/Need';
import NeedPage from './NeedPage';

const NeedPageContainer = (): JSX.Element => {
  const { loggedIn } = useSecurity();
  const { openEditNeedDialog, openProposeItemDialog, openLoginDialog } =
    useDialogsManager();
  const [need, setNeed] = useState<Need | null>(null);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const [reloadPropositions, setReloadPropositions] = useState(false);
  const reloadPropositionsRef = useRef(false);
  const [propositionsCount, setPropositionsCount] = useState<number | null>(
    null,
  );

  const {
    get: propositionsCountGet,
    error: propositionsCountError,
    response: propositionsCountResponse,
  } = useFetch(BASE_URL);

  const getPropositionsCount = async () => {
    const count = await propositionsCountGet(
      `/needs/${(need as Need).ID}/propositions_count`,
    );

    if (propositionsCountResponse.ok) {
      setPropositionsCount(count);
    }
  };

  const { get, cache, response, loading, error } = useFetch<Need>(BASE_URL);

  const {
    put,
    response: putResponse,
    error: putError,
  } = useFetch<Need>(BASE_URL);

  async function getNeed(): Promise<void> {
    const data = await get(`/needs/${id}`);
    if (response.ok) {
      setNeed(data);
      if (isFirstTimeLoading) {
        setIsFirstTimeLoading(false);
      }
    }
  }

  async function updateNeed(updated: Need): Promise<void> {
    await put(`/needs/${id}`, updated);
    if (putResponse.ok) {
      cache.clear();
      setNeed(updated);
    }
  }

  function actualizePropositions() {
    setReloadPropositions(!reloadPropositionsRef.current);
    reloadPropositionsRef.current = !reloadPropositionsRef.current;
  }

  function handleProposeClick() {
    if (loggedIn) {
      if (openProposeItemDialog) {
        openProposeItemDialog(need as Need, actualizePropositions);
      }
    } else if (openLoginDialog) {
      openLoginDialog();
    }
  }

  function handleEditClick() {
    if (openEditNeedDialog) {
      openEditNeedDialog(need as Need, (edited: Need) => {
        setNeed(edited);
      });
    }
  }

  function handleFulfilledClick() {
    updateNeed({
      ...(need as Need),
      fulfilled: !(need as Need).fulfilled,
    });
  }

  useEffect(() => {
    getNeed();

    if (!isFirstTimeLoading) {
      setReloadPropositions(!reloadPropositions);
    }
  }, [id]);

  useEffect(() => {
    if (need) {
      getPropositionsCount();
    }
  }, [need, reloadPropositions]);

  function deletePropositionCallback() {
    actualizePropositions();
  }

  return (
    <NeedPage
      need={need}
      propositionsCount={propositionsCount}
      handleProposeClick={handleProposeClick}
      handleEditClick={handleEditClick}
      handleFulfilledClick={handleFulfilledClick}
      loading={loading}
      error={
        Boolean(error) || Boolean(propositionsCountError) || Boolean(putError)
      }
      needNotFound={response.status === 404}
      reloadPropositions={reloadPropositions}
      deletePropositionCallback={deletePropositionCallback}
    />
  );
};

export default NeedPageContainer;
