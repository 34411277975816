import React, { useState } from 'react';
import dialogsManagerContext from './context';
import { Item } from '../../types/Item';
import { Need } from '../../types/Need';
import { Profile } from '../../types/Profile';
import { Proposition } from '../../types/Proposition';
import { Message } from '../../types/Message';
import CreateNewItemDialog from '../../dialogs/CreateNewItem';
import CreateNewNeedDialog from '../../dialogs/CreateNewNeed';
import EditNeedDialog from '../../dialogs/EditNeed';
import EditProfileDialog from '../../dialogs/EditProfile';
import ProposeItemDialog from '../../dialogs/ProposeItem';
import SearchMoneyDialog from '../../components/SearchField/SearchMoneyDialog';
import SearchMoneyByProductDialog from '../../components/SearchField/SearchMoneyByProductDialog';
import LoginDialog from '../../dialogs/Login';
import SignupDialog from '../../dialogs/Signup';
import PasswordResetDialog from '../../dialogs/PasswordReset';
import ChatDialog from '../../dialogs/Chat';

type CreateNewItemDialogProps = {
  open: boolean;
  callback?: (created: Item) => void;
};

type CreateNewNeedDialogProps = {
  open: boolean;
  callback?: (created: Need) => void;
};

type EditNeedDialogProps = {
  need: Need | null;
  open: boolean;
  callback?: (edited: Need) => void;
};

type SearchMoneyDialogProps = {
  open: boolean;
  callback?: () => void;
};

type SearchMoneyByProductDialogProps = {
  open: boolean;
  callback?: () => void;
};

type EditProfileDialogProps = {
  profile: Profile | null;
  open: boolean;
  callback?: () => void;
};

type ProposeItemDialogProps = {
  need: Need | null;
  open: boolean;
  callback?: () => void;
};

type LoginDialogProps = {
  open: boolean;
};

type SignupDialogProps = {
  open: boolean;
};

type PasswordResetDialogProps = {
  open: boolean;
};

type ChatDialogProps = {
  open: boolean;
  proposition: Proposition | null;
  onMessagesLoaded?: () => void;
  onNewMessage?: (newMessage: Message) => void;
};

type DialogsManagerProviderProps = {
  children: React.ReactNode;
};

const DialogsManagerProvider = ({
  children,
}: DialogsManagerProviderProps): JSX.Element => {
  const [createNewItemDialogProps, setCreateNewItemDialogProps] =
    useState<CreateNewItemDialogProps>({
      open: false,
    });

  const [createNewNeedDialogProps, setCreateNewNeedDialogProps] =
    useState<CreateNewNeedDialogProps>({
      open: false,
    });

  const [editNeedDialogProps, setEditNeedDialogProps] =
    useState<EditNeedDialogProps>({
      need: null,
      open: false,
    });

  const [searchMoneyDialogProps, setSearchMoneyDialogProps] =
    useState<SearchMoneyDialogProps>({
      open: false,
    });

  const [searchMoneyByProductDialogProps, setSearchMoneyByProductDialogProps] =
    useState<SearchMoneyByProductDialogProps>({
      open: false,
    });

  const [editProfileDialogProps, setEditProfileDialogProps] =
    useState<EditProfileDialogProps>({
      profile: null,
      open: false,
    });

  const [proposeItemDialogProps, setProposeItemDialogProps] =
    useState<ProposeItemDialogProps>({
      need: null,
      open: false,
    });

  const [loginDialogProps, setLoginDialogProps] = useState<LoginDialogProps>({
    open: false,
  });

  const [signupDialogProps, setSignupDialogProps] = useState<SignupDialogProps>(
    {
      open: false,
    },
  );

  const [passwordResetDialogProps, setPasswordResetDialogProps] =
    useState<PasswordResetDialogProps>({
      open: false,
    });

  const [chatDialogProps, setChatDialogProps] = useState<ChatDialogProps>({
    open: false,
    proposition: null,
  });

  function closeCreateNewItemDialog() {
    setCreateNewItemDialogProps({ ...createNewItemDialogProps, open: false });
  }

  function closeSearchMoneyDialog() {
    setSearchMoneyDialogProps({ ...searchMoneyDialogProps, open: false });
  }

  function closeSearchMoneyByProductDialog() {
    setSearchMoneyByProductDialogProps({
      ...searchMoneyByProductDialogProps,
      open: false,
    });
  }

  function closeCreateNewNeedDialog() {
    setCreateNewNeedDialogProps({ ...createNewNeedDialogProps, open: false });
  }

  function closeEditNeedDialog() {
    setEditNeedDialogProps({ ...editNeedDialogProps, open: false });
  }

  function closeEditProfileDialog() {
    setEditProfileDialogProps({ ...editProfileDialogProps, open: false });
  }

  function closeProposeItemDialog() {
    setProposeItemDialogProps({ ...proposeItemDialogProps, open: false });
  }

  function closeLoginDialog() {
    setLoginDialogProps({ ...loginDialogProps, open: false });
  }

  function closeSignupDialog() {
    setSignupDialogProps({ ...signupDialogProps, open: false });
  }

  function closePasswordResetDialog() {
    setPasswordResetDialogProps({ ...passwordResetDialogProps, open: false });
  }

  function closeChatDialog() {
    setChatDialogProps({ ...chatDialogProps, open: false });
  }

  function closeOpenedDialog() {
    if (createNewItemDialogProps.open) closeCreateNewItemDialog();
    if (createNewNeedDialogProps.open) closeCreateNewNeedDialog();
    if (searchMoneyDialogProps.open) closeSearchMoneyDialog();
    if (searchMoneyByProductDialogProps.open) closeSearchMoneyByProductDialog();
    if (editNeedDialogProps.open) closeEditNeedDialog();
    if (editProfileDialogProps.open) closeEditProfileDialog();
    if (proposeItemDialogProps.open) closeProposeItemDialog();
    if (loginDialogProps.open) closeLoginDialog();
    if (signupDialogProps.open) closeSignupDialog();
    if (chatDialogProps.open) closeChatDialog();
  }

  function openCreateNewItemDialog(callback?: (created: Item) => void) {
    closeOpenedDialog();
    setCreateNewItemDialogProps({ open: true, callback });
  }

  function openSearchMoneyDialog(callback?: () => void) {
    closeOpenedDialog();
    setSearchMoneyDialogProps({ open: true, callback });
  }

  function openSearchMoneyByProductDialog(callback?: () => void) {
    closeOpenedDialog();
    setSearchMoneyByProductDialogProps({ open: true, callback });
  }

  function openCreateNewNeedDialog(callback?: (created: Need) => void) {
    closeOpenedDialog();
    setCreateNewNeedDialogProps({ open: true, callback });
  }

  function openEditNeedDialog(need: Need, callback?: (edited: Need) => void) {
    closeOpenedDialog();
    setEditNeedDialogProps({ need, open: true, callback });
  }

  function openEditProfileDialog(profile: Profile, callback?: () => void) {
    closeOpenedDialog();
    setEditProfileDialogProps({ profile, open: true, callback });
  }

  function openProposeItemDialog(need: Need, callback?: () => void) {
    closeOpenedDialog();
    setProposeItemDialogProps({ need, open: true, callback });
  }

  function openLoginDialog() {
    closeOpenedDialog();
    setLoginDialogProps({ open: true });
  }

  function openSignupDialog() {
    closeOpenedDialog();
    setSignupDialogProps({ open: true });
  }

  function openPasswordResetDialog() {
    closeOpenedDialog();
    setPasswordResetDialogProps({ open: true });
  }

  function openChatDialog(
    proposition: Proposition,
    onMessagesLoaded?: () => void,
    onNewMessage?: (newMessage: Message) => void,
  ) {
    closeOpenedDialog();
    setChatDialogProps({
      open: true,
      proposition,
      onMessagesLoaded,
      onNewMessage,
    });
  }

  return (
    <dialogsManagerContext.Provider
      value={{
        openCreateNewItemDialog,
        closeCreateNewItemDialog,
        openCreateNewNeedDialog,
        closeCreateNewNeedDialog,
        openEditNeedDialog,
        closeEditNeedDialog,
        openEditProfileDialog,
        closeEditProfileDialog,
        openProposeItemDialog,
        closeProposeItemDialog,
        openLoginDialog,
        closeLoginDialog,
        openSignupDialog,
        closeSignupDialog,
        openPasswordResetDialog,
        closePasswordResetDialog,
        openChatDialog,
        closeChatDialog,
        openSearchMoneyDialog,
        closeSearchMoneyDialog,
        openSearchMoneyByProductDialog,
        closeSearchMoneyByProductDialog,
      }}
    >
      {children}
      {createNewItemDialogProps.open && (
        <CreateNewItemDialog
          open={createNewItemDialogProps.open}
          onClose={closeCreateNewItemDialog}
          callback={createNewItemDialogProps.callback}
        />
      )}
      {createNewNeedDialogProps.open && (
        <CreateNewNeedDialog
          open={createNewNeedDialogProps.open}
          onClose={closeCreateNewNeedDialog}
          callback={createNewNeedDialogProps.callback}
        />
      )}
      {editNeedDialogProps.open && (
        <EditNeedDialog
          need={editNeedDialogProps.need as Need}
          callback={editNeedDialogProps.callback}
          open={editNeedDialogProps.open}
          onClose={closeEditNeedDialog}
        />
      )}
      {searchMoneyDialogProps.open && (
        <SearchMoneyDialog
          callback={searchMoneyDialogProps.callback}
          open={searchMoneyDialogProps.open}
          onClose={closeSearchMoneyDialog}
        />
      )}
      {searchMoneyByProductDialogProps.open && (
        <SearchMoneyByProductDialog
          callback={searchMoneyByProductDialogProps.callback}
          open={searchMoneyByProductDialogProps.open}
          onClose={closeSearchMoneyByProductDialog}
        />
      )}
      {editProfileDialogProps.open && (
        <EditProfileDialog
          profile={editProfileDialogProps.profile as Profile}
          callback={editProfileDialogProps.callback}
          open={editProfileDialogProps.open}
          onClose={closeEditProfileDialog}
        />
      )}
      {proposeItemDialogProps.open && (
        <ProposeItemDialog
          need={proposeItemDialogProps.need as Need}
          callback={proposeItemDialogProps.callback}
          open={proposeItemDialogProps.open}
          onClose={closeProposeItemDialog}
        />
      )}
      {loginDialogProps.open && (
        <LoginDialog open={loginDialogProps.open} onClose={closeLoginDialog} />
      )}
      {signupDialogProps.open && (
        <SignupDialog
          open={signupDialogProps.open}
          onClose={closeSignupDialog}
        />
      )}
      {passwordResetDialogProps.open && (
        <PasswordResetDialog
          open={passwordResetDialogProps.open}
          onClose={closePasswordResetDialog}
        />
      )}
      {chatDialogProps.open && (
        <ChatDialog
          open={chatDialogProps.open}
          onClose={closeChatDialog}
          proposition={chatDialogProps.proposition as Proposition}
          onMessagesLoaded={chatDialogProps.onMessagesLoaded}
          onNewMessage={chatDialogProps.onNewMessage}
        />
      )}
    </dialogsManagerContext.Provider>
  );
};

export default DialogsManagerProvider;
