import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import useFetch from 'use-http';
import useDialogsManager from '../../hooks/useDialogsManager';
import { BASE_URL } from '../../constants';
import { Profile, ProfileStats } from '../../types/Profile';
import { State } from '../../store/types';
import ProfilePage from './ProfilePage';

const ProfilePageContainer = (): JSX.Element => {
  const { openEditProfileDialog } = useDialogsManager();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [stats, setStats] = useState<ProfileStats | null>(null);
  const me = useSelector<State, Profile | null>((state) => state.profile);
  const [reload, setReload] = useState(false);

  const { get, error, response } = useFetch(BASE_URL);

  async function getProfile() {
    const data = await get(`/profiles/${id}`);

    if (response.ok) {
      setProfile(data);
    }
  }

  const {
    get: statsGet,
    error: statsError,
    response: statsResponse,
  } = useFetch(BASE_URL);

  async function getProfileStats() {
    const data = await statsGet(`/profiles/${id}/stats`);

    if (statsResponse.ok) {
      setStats(data);
    }
  }

  function itsMe(): boolean {
    if (me !== null && profile !== null) return me.ID === profile.ID;

    return false;
  }

  useEffect(() => {
    getProfile();
  }, [id, reload]);

  useEffect(() => {
    getProfileStats();
  }, [id]);

  function handleEditClick() {
    if (openEditProfileDialog) {
      openEditProfileDialog(profile as Profile, () => setReload(!reload));
    }
  }

  return (
    <ProfilePage
      profile={profile}
      itsMe={itsMe}
      stats={stats}
      loading={false}
      error={Boolean(error) || Boolean(statsError)}
      notFound={response.status === 404}
      handleEditClick={handleEditClick}
    />
  );
};

export default ProfilePageContainer;
