import React from 'react';
import { Box } from '@mui/system';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { FaRegHandshake, FaHandshake } from 'react-icons/fa';
import { RiHandCoinLine, RiHandCoinFill } from 'react-icons/ri';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import useSecurity from '../../hooks/useSecurity';
import { BadgeCount } from '../../contexts/badgeCount/context';
import Logo from '../Logo';
import MyAppBar from '../MyAppBar';
import MyBadge from '../MyBadge';
import AppBarIconWrapper from '../AppBarIconWrapper';
import MyIconButton from '../MyIconButton';
import { Profile } from '../../types/Profile';
import MyDivider from './components/MyDivider';
import MyList from './components/MyList';
import MyListItem from './components/MyListItem';
import MyListItemText from './components/MyListItemText';
import MyListItemIcon from './components/MyListItemIcon';
import { icon } from '../../theme/theme';

type MyDrawerProps = {
  open: boolean;
  onClose: DrawerProps['onClose'];
  anchor: DrawerProps['anchor'];
  profile: Profile | null;
  handleLinkClick: (
    link: string,
  ) => (e: React.MouseEvent<HTMLLIElement>) => void;
  handleSignupLinkClick: React.MouseEventHandler<HTMLLIElement>;
  handleLoginLinkClick: React.MouseEventHandler<HTMLLIElement>;
  handleLogoutLinkClick: React.MouseEventHandler<HTMLLIElement>;
  handleCreateNeedLinkClick: React.MouseEventHandler<HTMLLIElement>;
  handleSearchButtonClick: React.MouseEventHandler<HTMLLIElement>;
  handleSearchByProductButtonClick: React.MouseEventHandler<HTMLLIElement>;
  isMediumDevice: boolean;
  path: string;
  badgeCount: BadgeCount;
};

const MyDrawer = ({
  open,
  onClose,
  anchor,
  profile,
  handleLinkClick,
  handleSignupLinkClick,
  handleLoginLinkClick,
  handleLogoutLinkClick,
  handleCreateNeedLinkClick,
  handleSearchButtonClick,
  handleSearchByProductButtonClick,
  isMediumDevice,
  path,
  badgeCount,
}: MyDrawerProps): JSX.Element => {
  const { loggedIn } = useSecurity();

  const FaIconWrapper = ({
    children, // eslint-disable-line react/prop-types
  }: {
    children: React.ReactNode;
  }): JSX.Element => (
    <Box
      component="span"
      sx={{
        '& svg': {
          width: isMediumDevice ? icon.fontSize.xlarge : icon.fontSize.medium,
          height: isMediumDevice ? icon.fontSize.xlarge : icon.fontSize.medium,
          transform: 'translate(0px, 2px)',
        },
      }}
    >
      {children}
    </Box>
  );

  const handleCloseIconClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    if (onClose) onClose(e, 'backdropClick');
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      PaperProps={{
        sx: {
          minWidth: isMediumDevice ? '275px' : '248px',
          padding: isMediumDevice ? '0px 12px' : '0px',
        },
      }}
    >
      <MyAppBar
        middleRender={() => <Logo />}
        rightSideRender={() => (
          <AppBarIconWrapper justifyContent="flex-end">
            <MyIconButton
              sx={{ marginRight: '-8px' }}
              onClick={handleCloseIconClick}
            >
              <CloseIcon
                sx={{ fontSize: icon.fontSize.medium, color: icon.color }}
              />
            </MyIconButton>
          </AppBarIconWrapper>
        )}
      />

      {!loggedIn && (
        <>
          <MyList>
            <MyListItem onClick={handleSignupLinkClick}>
              <MyListItemIcon>
                <PersonAddAltIcon />
              </MyListItemIcon>
              <MyListItemText>Signup</MyListItemText>
            </MyListItem>
            <MyListItem onClick={handleLoginLinkClick}>
              <MyListItemIcon>
                <LoginIcon />
              </MyListItemIcon>
              <MyListItemText>Login</MyListItemText>
            </MyListItem>
          </MyList>
          <MyDivider />
        </>
      )}

      <MyList>
        <MyListItem onClick={handleLinkClick(`/`)} selected={path === `/`}>
          <MyListItemIcon>
            {path === `/` ? <HomeIcon /> : <HomeOutlinedIcon />}
          </MyListItemIcon>
          <MyListItemText>Home</MyListItemText>
        </MyListItem>
        <MyListItem
          onClick={handleSearchButtonClick}
          selected={path === `/create-need`}
        >
          <MyListItemIcon>
            {path === `/create-need` ? (
              <LocalOfferOutlinedIcon />
            ) : (
              <LocalOfferOutlinedIcon />
            )}
          </MyListItemIcon>
          <MyListItemText>Get an offer now!</MyListItemText>
        </MyListItem>
        <MyListItem
          onClick={handleLinkClick(`/explore`)}
          selected={path === `/explore`}
          sx={{ display: 'none' }}
        >
          <MyListItemIcon>
            {path === `/explore` ? <ExploreIcon /> : <ExploreOutlinedIcon />}
          </MyListItemIcon>
          <MyListItemText>Explore offers</MyListItemText>
        </MyListItem>
        {false && (
          <MyListItem
            onClick={handleLinkClick(`/explore`)}
            selected={path === `/explore`}
          >
            <MyListItemIcon>
              {path === `/explore` ? <ExploreIcon /> : <ExploreOutlinedIcon />}
            </MyListItemIcon>
            <MyListItemText>Explore by category</MyListItemText>
          </MyListItem>
        )}
        <MyListItem
          onClick={handleCreateNeedLinkClick}
          selected={path === `/create-need`}
        >
          <MyListItemIcon>
            {path === `/create-need` ? <MonetizationOnIcon /> : <MonetizationOnOutlinedIcon />}
          </MyListItemIcon>
          <MyListItemText>For buyers</MyListItemText>
        </MyListItem>
        {false && (
          <MyListItem
            onClick={handleSearchByProductButtonClick}
            selected={path === `/create-need`}
          >
            <MyListItemIcon>
              {path === `/create-need` ? (
                <SearchOutlinedIcon />
              ) : (
                <SearchOutlinedIcon />
              )}
            </MyListItemIcon>
            <MyListItemText>Search by product</MyListItemText>
          </MyListItem>
        )}
      </MyList>

      {loggedIn && (
        <>
          <MyDivider />
          <MyList>
            <MyListItem
              onClick={handleLinkClick(`/profile/${profile?.ID}`)}
              selected={path === `/profile/:id`}
            >
              <MyListItemIcon>
                {path === `/profile/:id` ? (
                  <PersonIcon />
                ) : (
                  <PersonOutlineIcon />
                )}
              </MyListItemIcon>
              <MyListItemText>Profile</MyListItemText>
            </MyListItem>
            <MyListItem
              onClick={handleLinkClick(`/profile/${profile?.ID}/notifications`)}
              selected={path === `/profile/:id/notifications`}
            >
              <MyListItemIcon>
                {badgeCount.unseenNotificationsCount > 0 && (
                  <MyBadge
                    badgeContent={badgeCount.unseenNotificationsCount}
                    color="primary"
                  >
                    {path === `/profile/:id/notifications` ? (
                      <NotificationsIcon />
                    ) : (
                      <NotificationsNoneIcon />
                    )}
                  </MyBadge>
                )}
                {badgeCount.unseenNotificationsCount === 0 && (
                  <>
                    {path === `/profile/:id/notifications` ? (
                      <NotificationsIcon />
                    ) : (
                      <NotificationsNoneIcon />
                    )}
                  </>
                )}
              </MyListItemIcon>
              <MyListItemText>Notifications</MyListItemText>
            </MyListItem>
            <MyListItem
              onClick={handleLinkClick(`/profile/${profile?.ID}/chats`)}
              selected={path === `/profile/:id/chats`}
            >
              <MyListItemIcon>
                {badgeCount.unseenMessagesCount > 0 && (
                  <MyBadge
                    badgeContent={badgeCount.unseenMessagesCount}
                    color="primary"
                  >
                    {path === `/profile/:id/chats` ? (
                      <ChatBubbleIcon />
                    ) : (
                      <ChatBubbleOutlineIcon />
                    )}
                  </MyBadge>
                )}
                {badgeCount.unseenMessagesCount === 0 && (
                  <>
                    {path === `/profile/:id/chats` ? (
                      <ChatBubbleIcon />
                    ) : (
                      <ChatBubbleOutlineIcon />
                    )}
                  </>
                )}
              </MyListItemIcon>
              <MyListItemText>Chats</MyListItemText>
            </MyListItem>
            <MyListItem
              onClick={handleLinkClick(`/profile/${profile?.ID}/spending`)}
              selected={path === `/profile/:id/spending`}
            >
              <MyListItemIcon>
                <FaIconWrapper>
                  {path === `/profile/:id/spending` ? (
                    <RiHandCoinFill style={{ color: icon.color }} />
                  ) : (
                    <RiHandCoinLine style={{ color: icon.color }} />
                  )}
                </FaIconWrapper>
              </MyListItemIcon>
              <MyListItemText>Spending</MyListItemText>
            </MyListItem>
            <MyListItem
              onClick={handleLinkClick(`/profile/${profile?.ID}/spent`)}
              selected={path === `/profile/:id/spent`}
            >
              <MyListItemIcon>
                <FaIconWrapper>
                  {path === `/profile/:id/spent` ? (
                    <FaHandshake style={{ color: icon.color }} />
                  ) : (
                    <FaRegHandshake style={{ color: icon.color }} />
                  )}
                </FaIconWrapper>
              </MyListItemIcon>
              <MyListItemText>Spent</MyListItemText>
            </MyListItem>
            <MyListItem
              onClick={handleLinkClick(`/profile/${profile?.ID}/proposing`)}
              selected={path === `/profile/:id/proposing`}
            >
              <MyListItemIcon>
                {path === `/profile/:id/proposing` ? (
                  <ReplyIcon />
                ) : (
                  <ReplyOutlinedIcon />
                )}
              </MyListItemIcon>
              <MyListItemText>Proposing</MyListItemText>
            </MyListItem>
          </MyList>
          <MyDivider />
          <MyList>
            <MyListItem
              onClick={handleLinkClick(`/settings`)}
              selected={path === `/settings`}
            >
              <MyListItemIcon>
                {path === `/settings` ? (
                  <SettingsIcon />
                ) : (
                  <SettingsOutlinedIcon />
                )}
              </MyListItemIcon>
              <MyListItemText>Settings</MyListItemText>
            </MyListItem>
          </MyList>
        </>
      )}

      {loggedIn && (
        <>
          <MyDivider />
          <MyList>
            <MyListItem onClick={handleLogoutLinkClick}>
              <MyListItemIcon>
                <LogoutIcon />
              </MyListItemIcon>
              <MyListItemText>Logout</MyListItemText>
            </MyListItem>
          </MyList>
        </>
      )}
      <Box marginTop="64px" />
    </Drawer>
  );
};
export default MyDrawer;
