import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import Hero2 from '../../../../assets/images/HeroPurpleBgDarkNoRadient.jpg';
import { borderRadius, colors } from '../../../../theme/theme';

const Hero = (): JSX.Element => (
  <Box
    sx={{
      position: 'relative',
      paddingTop: '56.25%',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
      }}
    >
      <Image
        height="100%"
        width="100%"
        fit="cover"
        duration={300}
        src={Hero2}
        bgColor={colors.secondary}
      />
    </Box>

    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius,
      }}
    >
      <Box
        sx={{
          padding: '12px 16px',
        }}
      >
        <Box marginBottom="0px">
          <Typography
            variant="h2"
            variantMapping={{
              h2: 'h1',
            }}
            align="center"
            sx={{
              color: '#FFFFFF',
              fontWeight: 'bold !important',
              // fontSize: isMediumDevice // eslint-disable-line no-nested-ternary
              //   ? // ? '64px !important'
              //     '40px !important'
              //   : isSmallDevice
              //   ? '40px !important'
              //   : // ?  '31px !important'
              //     '23px !important',
              // lineHeight: isMediumDevice // eslint-disable-line no-nested-ternary
              //   ? // ? '84px !important'
              //     '52px !important'
              //   : isSmallDevice
              //   ? '52px !important'
              //   : // ? '36px !important'
              //     '28px !important',
            }}
          >
            <Box component="span" sx={{ display: { xs: 'block', sm: 'none' } }}>
              Find local
              <br />
              buyers you
              <br />can trust
            </Box>
            <Box component="span" sx={{ display: { xs: 'none', sm: 'block' } }}>
              Find local buyers <br />
              you can trust
            </Box>
          </Typography>
        </Box>
        {/* <Typography
            variant="h4"
            align="center"
            sx={{
              fontWeight: 'bold !important',
              fontSize: isMediumDevice ? '31px !important' : '23px !important',
              lineHeight: isMediumDevice
                ? '36px !important'
                : '28px !important',
            }}
          >
            Get you need
          </Typography> */}
      </Box>
    </Box>
  </Box>
);

export default Hero;
