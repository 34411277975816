const acronyms = ['GBP', 'EUR', 'USD'] as const;
const symbols = ['£', '€', '$'] as const;

export type Acronym = typeof acronyms[number];
type CurrencySymbol = typeof symbols[number];

const mappings: Record<Acronym, CurrencySymbol> = {
  GBP: '£',
  EUR: '€',
  USD: '$',
};

function getAcronyms(): typeof acronyms {
  return acronyms;
}

function getSymbol(acronym: Acronym): CurrencySymbol {
  return mappings[acronym];
}

export default {
  getAcronyms,
  getSymbol,
};
