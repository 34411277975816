import React, { useState, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import useFetch from 'use-http';
import { Box } from '@mui/system';
import { useTheme, InputBaseComponentProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import MySelect from '../../components/MySelect';
import MyCloseIcon from '../../components/MyCloseIcon';
import MyTextField from '../../components/MyTextField';
import MyDialog from '../../components/MyDialog';
import MyNumberFormat from '../../components/MyNumberFormat';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { Need } from '../../types/Need';
import { Geocode } from '../../types/Geocode';
import {
  getAllCategoriesTree,
  searchCategoryNode,
  CategoryNode,
} from '../../utils/categories';
import currencies from '../../utils/currencies';
import { BASE_URL } from '../../constants';
import { dialog } from '../../theme/theme';
import SearchPlaceDialog from '../CreateNewNeed/SearchPlaceDialog';
import CategoriesDialog from '../CreateNewNeed/CategoriesDialog';
import context from '../CreateNewNeed/createNewNeedContext';

type FormValues = {
  title: string;
  description: string;
  category: string;
  offer: string;
  currency: string;
};

type EditNeedDialogProps = {
  need: Need;
  callback?: (edited: Need) => void;
  open: boolean;
  onClose: () => void;
};

const EditNeedDialog = ({
  need,
  callback,
  open,
  onClose,
}: EditNeedDialogProps): JSX.Element => {
  const theme = useTheme();
  const [geocode, setGeocode] = useState<Geocode | null>(null);
  const [isDirtyGeocode, setIsDirtyGeocode] = useState(false);
  const [placeSearchException, setPlaceSearchException] = useState(false);
  const [categoriesDialogOpen, setCategoriesDialogOpen] = useState(false);
  const [searchPlaceDialogOpen, setSearchPlaceDialogOpen] = useState(false);
  const [selectedCategoryNode, setSelectedCategoryNode] =
    useState<CategoryNode | null>(null);
  const [currentCategoryNode, setCurrentCategoryNode] =
    useState<CategoryNode | null>(null);
  const [isDirtySelectedCategoryNode, setIsDirtySelectedCategoryNode] =
    useState(false);

  useEffect(() => {
    const categoriesTreeRootNode = getAllCategoriesTree();
    const found = searchCategoryNode(categoriesTreeRootNode, need.category);
    setCurrentCategoryNode(found);
    setSelectedCategoryNode(found);

    setGeocode(need.geocode);
  }, []);

  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { errors, isValid, isDirty } = formState;

  const { put, response, cache, loading, error } = useFetch<Need>(BASE_URL);

  const currency = useWatch({
    control,
    name: 'currency',
    defaultValue: need.currency,
  });

  async function saveNeed(edited: Need) {
    await put(`/needs/${need.ID}`, edited);
    if (response.ok) {
      cache.clear();
      if (callback) {
        callback(edited);
      }
      onClose();
    }
  }

  function buildCategoryName(): string {
    let categoryName = (selectedCategoryNode as CategoryNode).name;
    let node = (selectedCategoryNode as CategoryNode).parent;

    while (node !== null) {
      categoryName = `${node.name}${
        node.name !== '' ? ' > ' : ''
      }${categoryName}`;
      node = node.parent;
    }

    return categoryName;
  }

  function handleSaveButtonClick() {
    const { offer, ...rest } = getValues();
    saveNeed({
      ...need,
      ...rest,
      offer: Number.parseInt(offer, 10),
      category: buildCategoryName(),
      geocode: { ...need.geocode, ...(geocode as Geocode) },
    });
  }

  function handleCloseIconClick() {
    onClose();
  }

  function handleSelectedCategoryNodeChange(selected: CategoryNode) {
    setSelectedCategoryNode(selected);
    setCategoriesDialogOpen(false);
    if (!isDirtySelectedCategoryNode) {
      setIsDirtySelectedCategoryNode(true);
    }
  }

  function handleGeocodeChange(newGeocode: Geocode) {
    setGeocode(newGeocode);
    setSearchPlaceDialogOpen(false);
    if (!isDirtyGeocode) {
      setIsDirtyGeocode(true);
    }
  }

  function handleCurrentCategoryNodeChange(newCurrent: CategoryNode) {
    setCurrentCategoryNode(newCurrent);
  }

  function handleCategorySelectClick() {
    setCategoriesDialogOpen(true);
  }

  function handleLocationSelectClick() {
    setSearchPlaceDialogOpen(true);
  }

  return (
    <context.Provider value={{ currency }}>
      <MyDialog open={open} onClose={onClose}>
        <MyAppBar
          leftSideRender={() => (
            <Box marginLeft="0">
              <AppBarIconWrapper justifyContent="flex-start">
                <MyIconButton onClick={handleCloseIconClick}>
                  <MyCloseIcon />
                </MyIconButton>
              </AppBarIconWrapper>
            </Box>
          )}
          middleRender={() => (
            <Typography variant="h5" variantMapping={{ h5: 'h1' }}>
              Edit offer
            </Typography>
          )}
          rightSideRender={() => (
            <Box marginRight="0">
              <MyButton
                size="small"
                disabled={
                  !(isDirty || isDirtySelectedCategoryNode || isDirtyGeocode) ||
                  !isValid ||
                  loading ||
                  Boolean(error) ||
                  placeSearchException
                }
                onClick={handleSaveButtonClick}
              >
                Save
              </MyButton>
            </Box>
          )}
        />

        {(error || placeSearchException) && <SomethingWentWrongFeedback />}

        {!error && !placeSearchException && (
          <>
            <Box sx={{ padding: dialog.input.padding }}>
                <Controller
                  control={control}
                  rules={{
                    required: `Product or Service name is required.`,
                    maxLength: {
                      value: 70,
                      message: 'Too many characters... Limit is 70',
                    },
                  }}
                  name="title"
                  defaultValue={need.title}
                  render={({ field }) => (
                    <MyTextField
                      id="title-input"
                      label="What product or service do you want to buy?"
                      error={!!errors.title}
                      helperText={errors.title && errors.title.message}
                      {...field} // eslint-disable-line react/jsx-props-no-spreading
                    />
                  )}
                />
            </Box>
            <Box sx={{ padding: dialog.input.padding }}>
              <Grid container>
                <Grid
                  item
                  sx={{
                    minWidth: '96px',
                  }}
                >
                  <Box marginRight="12px">
                    <Controller
                      control={control}
                      rules={{ required: 'Currency is required.' }}
                      name="currency"
                      defaultValue={need.currency}
                      render={({ field }) => (
                        <MyTextField
                          id="currency-input"
                          select
                          label="Currency"
                          error={!!errors.currency}
                          helperText={
                            errors.currency && errors.currency.message
                          }
                          {...field} // eslint-disable-line react/jsx-props-no-spreading
                        >
                          {currencies.getAcronyms().map((c) => (
                            <MenuItem key={c} value={c}>
                              {currencies.getSymbol(c)}
                            </MenuItem>
                          ))}
                        </MyTextField>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item flexGrow={1}>
                  <Controller
                    control={control}
                    rules={{ required: 'Amount is required.' }}
                    name="offer"
                    defaultValue={need.offer.toString()}
                    render={({ field }) => (
                      <MyTextField
                        id="offer-input"
                        label="How much do you want to spend?"
                        error={!!errors.offer}
                        helperText={errors.offer && errors.offer.message}
                        {...field} // eslint-disable-line react/jsx-props-no-spreading
                        InputProps={{
                          inputComponent:
                            MyNumberFormat as unknown as React.ElementType<InputBaseComponentProps>,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding: dialog.input.padding }}>
              <Controller
                control={control}
                rules={{
                  required: `Product or service description is required.`,
                  maxLength: {
                    value: 140,
                    message: 'Too many characters... Limit is 140',
                  },
                }}
                name="description"
                defaultValue={need.description}
                render={({ field }) => (
                  <MyTextField
                    id="description-input"
                    label="Add your requirements about it like condition, color..."
                    multiline
                    rows={3}
                    error={!!errors.description}
                    helperText={
                      errors.description && errors.description.message
                    }
                    {...field} // eslint-disable-line react/jsx-props-no-spreading
                  />
                )}
              />
            </Box>
            <Box sx={{ padding: dialog.input.padding }}>
              <Box position="relative">
                <FormControl fullWidth>
                  <InputLabel
                    id="category-label"
                    sx={{ fontSize: theme.typography.body1.fontSize }}
                  >
                    Category
                  </InputLabel>
                  <MySelect
                    labelId="category-label"
                    id="category-select"
                    label="Country"
                    value={
                      selectedCategoryNode !== null
                        ? selectedCategoryNode.name
                        : ''
                    }
                    inputProps={{
                      sx: {
                        fontSize: theme.typography.body1.fontSize,
                      },
                    }}
                    renderValue={() => (
                      <span>
                        {selectedCategoryNode !== null
                          ? selectedCategoryNode.name
                          : ''}
                      </span>
                    )}
                  >
                    <MenuItem
                      value={
                        selectedCategoryNode !== null
                          ? selectedCategoryNode.name
                          : ''
                      }
                    >
                      {selectedCategoryNode !== null
                        ? selectedCategoryNode.name
                        : ''}
                    </MenuItem>
                  </MySelect>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0',
                      bottom: '0',
                      left: '0',
                      right: '0',
                      width: '100%',
                      zIndex: '99',
                    }}
                    onClick={handleCategorySelectClick}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ padding: dialog.input.padding }}>
              <Box position="relative">
                <FormControl fullWidth>
                  <InputLabel id="location-label">
                    Choose where to list your offer
                  </InputLabel>
                  <MySelect
                    labelId="location-label"
                    id="location-select"
                    label="Choose where to list your offer"
                    value={geocode !== null ? geocode.Label : ''}
                    renderValue={() => (
                      <span>{geocode !== null ? geocode.Label : ''}</span>
                    )}
                  >
                    <MenuItem value={geocode !== null ? geocode.Label : ''}>
                      {geocode !== null ? geocode.Label : ''}
                    </MenuItem>
                  </MySelect>

                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0',
                      bottom: '0',
                      left: '0',
                      right: '0',
                      width: '100%',
                      zIndex: '99',
                    }}
                    onClick={handleLocationSelectClick}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box marginTop="64px" />
          </>
        )}
        <CategoriesDialog
          open={categoriesDialogOpen}
          onClose={() => setCategoriesDialogOpen(false)}
          selectedCategoryNode={selectedCategoryNode}
          handleSelectedCategoryNodeChange={handleSelectedCategoryNodeChange}
          currentCategoryNode={currentCategoryNode as CategoryNode}
          handleCurrentCategoryNodeChange={handleCurrentCategoryNodeChange}
        />
        {searchPlaceDialogOpen && (
          <SearchPlaceDialog
            open
            onClose={() => setSearchPlaceDialogOpen(false)}
            onPlaceDetailsAvailable={(data) => handleGeocodeChange(data)}
            onExceptionCaught={() => {
              setPlaceSearchException(true);
              setSearchPlaceDialogOpen(false);
            }}
          />
        )}
      </MyDialog>
    </context.Provider>
  );
};

EditNeedDialog.defaultProps = {
  callback: undefined,
};

export default EditNeedDialog;
