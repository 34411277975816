import React from 'react';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MyButton from '../../components/MyButton';
import PageLayout from '../../components/PageLayout';
import Spinner from '../../components/Spinner';
import PageSection from '../../components/PageSection';
import {
  AlertFeedback,
  SomethingWentWrongFeedback,
} from '../../components/Feedback';
import { Profile, ProfileStats } from '../../types/Profile';
import { getDate } from '../../utils/date';
import { icon, colors, borderRadius } from '../../theme/theme';
import Tabs from './Tabs';

type ProfilePageProps = {
  profile: Profile | null;
  itsMe: () => boolean;
  stats: ProfileStats | null;
  loading: boolean;
  error: boolean;
  notFound: boolean;
  handleEditClick: () => void;
};

const ProfilePage = ({
  profile,
  itsMe,
  stats,
  loading,
  error,
  notFound,
  handleEditClick,
}: ProfilePageProps): JSX.Element => {
  const canShowSpinner = () =>
    (loading || profile === null || stats === null) && !error && !notFound;
  const canShowError = () => error && !notFound;
  const canShowNotFound = () => error && notFound;
  const canShowProfile = () =>
    !error && !notFound && !loading && stats !== null && profile !== null;

  return (
    <PageLayout>
      {canShowSpinner() && <Spinner />}

      {canShowError() && <SomethingWentWrongFeedback />}

      {canShowNotFound() && (
        <AlertFeedback
          renderIcon={() => (
            <SentimentVeryDissatisfiedIcon
              sx={{ fontSize: icon.fontSize.xxlarge }}
            />
          )}
          title="Profile not found"
          subtitle="The profile you requested does not exist."
        />
      )}

      {canShowProfile() && (
        <PageSection title="Profile" backButtonEnabled backgroundColor>
          <Box
            sx={{
              borderColor: '#FFFFFF',
              borderWidth: 'thin !important',
              borderStyle: 'solid',
              borderRadius,
              backgroundColor: '#FFFFFF',
            }}
          >
            <Box marginBottom="16px">
              <Box padding="12px 16px 0 16px">
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  height="48px"
                >
                  <Grid item>
                    {/* <UserAvatar src={profile?.avatar} /> */}
                    <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
                      {(profile as Profile).firstname}
                    </Typography>
                  </Grid>
                  {itsMe() && (
                    <Grid item>
                      <MyButton variant="outlined" onClick={handleEditClick}>
                        Edit profile
                      </MyButton>
                    </Grid>
                  )}
                </Grid>
                <Box marginTop="4px" />
                {/* <Box marginBottom="12px">
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: '20px !important',
                      fontWeight: 'bold !important',
                    }}
                  >
                    {(profile as Profile).firstname}
                  </Typography>
                </Box> */}
                <Box marginBottom="12px">
                  <Box display="flex">
                    {/* <Typography
                      variant="body1"
                      sx={{
                        marginRight: '12px !important',
                        color: 'rgba(0, 0, 0, 0.6) !important',
                      }}
                    >
                      TBD
                    </Typography> */}
                    <Typography
                      variant="body1"
                      sx={{
                        marginRight: '12px !important',
                        color: `${colors.grey} !important`,
                      }}
                    >
                      Joined {getDate((profile as Profile).CreatedAt)}
                    </Typography>
                  </Box>
                </Box>
                {stats !== null && (
                  <Box display="flex">
                    <Typography
                      variant="body1"
                      sx={{
                        marginRight: '20px !important',
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {stats.needing}
                      </span>{' '}
                      <span
                        style={{
                          color: colors.grey,
                        }}
                      >
                        Offering
                      </span>
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        marginRight: '20px',
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {stats.fulfilled}
                      </span>{' '}
                      <span
                        style={{
                          color: colors.grey,
                        }}
                      >
                        Taken
                      </span>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Tabs profile={profile as Profile} itsMe={itsMe} />
        </PageSection>
      )}
    </PageLayout>
  );
};
export default ProfilePage;
