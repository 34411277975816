import * as React from 'react';
import countries from 'i18n-iso-countries';
import throttle from 'lodash/throttle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { MyMenuItem } from '../MyMenu';
import { getLocationSuggestions, getPlaceDetails } from '../../aws/location';
import { Geocode } from '../../types/Geocode';
import useGeoLocation from '../../hooks/useGeoLocation';
import {
  input as themeInput,
  icon,
  colors,
  borderRadius,
} from '../../theme/theme';

type PlaceSearchInputProps = {
  inputLabel: string;
  initialInputValue: string;
  onInputValueChange?: (newInputValue: string) => void;
  onPlaceDetailsAvailable: (data: Geocode) => void;
  onExceptionCaught: () => void;
};

const PlaceSearchInput = ({
  inputLabel,
  initialInputValue,
  onPlaceDetailsAvailable,
  onExceptionCaught,
  onInputValueChange,
}: PlaceSearchInputProps): JSX.Element => {
  const [value, setValue] = React.useState<string | null>(initialInputValue);
  const [inputValue, setInputValue] = React.useState(initialInputValue);
  const [options, setOptions] = React.useState<readonly string[]>([]);

  const { countryISOCode, locationLatitude, locationLongitude } =
    useGeoLocation();
  let country = countryISOCode;
  if (country.length === 2) {
    country = countries.alpha2ToAlpha3(country);
  }

  const biasPoint = {
    latitude: locationLatitude,
    longitude: locationLongitude,
  };

  const getPlaceSuggestions = (input: string): Promise<readonly string[]> =>
    getLocationSuggestions(input, country, biasPoint);

  const fetch = React.useMemo(() => throttle(getPlaceSuggestions, 200), []);

  React.useEffect(() => {
    if (value !== null && value !== '') {
      getPlaceDetails(value, country, biasPoint)
        .then((data) => {
          onPlaceDetailsAvailable(data);
        })
        .catch(() => {
          onExceptionCaught();
        });
    }
  }, [value]);

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue)
      ?.then((results?: readonly string[]) => {
        if (active) {
          let newOptions: readonly string[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      })
      .catch(() => {
        onExceptionCaught();
      });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="location-autocomplete"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue: string | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (onInputValueChange) {
          onInputValueChange(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={inputLabel}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& > fieldset': {
                borderColor: themeInput.border.color,
                borderRadius,
              },
            },
            '& .MuiOutlinedInput-root:hover': {
              '& > fieldset': {
                borderColor: themeInput.border.color,
                borderRadius,
              },
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: `${colors.primary} !important`,
                borderRadius,
              },
            },
          }}
          fullWidth
        />
      )}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MyMenuItem {...props}>{option}</MyMenuItem>
      )}
      clearIcon={
        <CloseIcon sx={{ fontSize: icon.fontSize.medium, color: icon.color }} />
      }
      sx={{ width: '100%' }}
    />
  );
};

PlaceSearchInput.defaultProps = {
  onInputValueChange: undefined,
};

export default PlaceSearchInput;
