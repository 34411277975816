import React from 'react';
import { useTheme } from '@mui/material';
import List, { ListProps } from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import ListItemAvatar, {
  ListItemAvatarProps,
} from '@mui/material/ListItemAvatar';
import { card } from '../../theme/theme';

const MyList = (props: ListProps): JSX.Element => (
  <List
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      padding: '0',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      margin: '0',
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
    }}
  />
);

export const MyListItem = (props: ListItemProps): JSX.Element => (
  <ListItem
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      padding: '0',
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
      margin: '0',
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
    }}
  />
);

export const MyCategoryListItem = (props: ListItemProps): JSX.Element => (
  <ListItem
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      padding: '0',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '16px',
      paddingRight: '16px',
      margin: '0',
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
    }}
  />
);

export const MyListItemText = (props: ListItemTextProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ListItemText
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      primaryTypographyProps={{
        sx: {
          fontSize: theme.typography.body1.fontSize,
          fontWeight: 'bold !important',
        },
      }}
      sx={{
        padding: '0',
        paddingTop: '0',
        paddingBottom: '0',
        paddingLeft: '0',
        paddingRight: '0',
        margin: '0',
        marginTop: '0',
        marginBottom: '0',
        marginLeft: '0',
        marginRight: '0',
      }}
    />
  );
};

export const MyListItemAvatar = (props: ListItemAvatarProps): JSX.Element => (
  <ListItemAvatar
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      padding: '0',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      margin: '0',
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '16px',
      minWidth: '0',
    }}
  />
);

export const MyListItemButton = (props: ListItemButtonProps): JSX.Element => (
  <ListItemButton
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      padding: '0',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      margin: '0',
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0px',
      minWidth: '0',
      '&:hover': {
        backgroundColor: card.backgrounColor.hover,
      },
    }}
  />
);

export default MyList;
