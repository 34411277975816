import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../store/types';
import { Need } from '../../types/Need';
import { Profile } from '../../types/Profile';
import CreateNewNeedDialog from './CreateNewNeedDialog';

type CreateNewNeedDialogContainerProps = {
  open: boolean;
  onClose: () => void;
  callback?: (created: Need) => void;
};

const CreateNewNeedDialogContainer = ({
  open,
  onClose,
  callback,
}: CreateNewNeedDialogContainerProps): JSX.Element => {
  const profile = useSelector<State, Profile>(
    (state) => state.profile as Profile,
  );

  return (
    <CreateNewNeedDialog
      open={open}
      onClose={onClose}
      callback={callback}
      profile={profile}
    />
  );
};

CreateNewNeedDialogContainer.defaultProps = {
  callback: undefined,
};

export default CreateNewNeedDialogContainer;
