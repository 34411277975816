import React, { useState } from 'react';
import context from './context';

export function handleLogout(): void {
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('profile');
  localStorage.removeItem('token');
  window.location.assign('/');
}

type SecurityProviderProps = {
  children: React.ReactNode;
  isLoggedIn: boolean;
};

const SecurityProvider = ({
  children,
  isLoggedIn,
}: SecurityProviderProps): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState(isLoggedIn);

  const updateLoggedIn = (newValue: boolean) => {
    if (!newValue) {
      handleLogout();
    } else {
      setLoggedIn(newValue);
    }
  };

  return (
    <context.Provider value={{ loggedIn, updateLoggedIn }}>
      {children}
    </context.Provider>
  );
};

export default SecurityProvider;
