import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import MyUnstyledButton from '../../components/MyButton/MyUnstyledButton';
import MyTextField, { MyPasswordField } from '../../components/MyTextField';
import MyDialog from '../../components/MyDialog';
import Spinner from '../../components/Spinner';
import MyCloseIcon from '../../components/MyCloseIcon';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import withLoginContainer from '../../pages/LoginPage/withLoginContainer';
import useDialogsManager from '../../hooks/useDialogsManager';
import { divider } from '../../theme/theme';
import {
  MyGoogleLoginButton,
  MyFacebookLoginButton,
} from '../../components/SocialLoginButtons';

type FormValues = {
  email: string;
  password: string;
};

type LoginDialogProps = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (email: string, password: string) => void;
  handleForgotPasswordButtonClick: () => void;
  showLoginErrorAlert: boolean;
  loading: boolean;
  error: boolean;
};

const LoginDialog = ({
  open,
  onClose,
  handleSubmit,
  handleForgotPasswordButtonClick,
  showLoginErrorAlert,
  loading,
  error,
}: LoginDialogProps): JSX.Element => {
  const theme = useTheme();
  const { openSignupDialog } = useDialogsManager();
  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { errors, isDirty, isValid } = formState;

  function handleCloseIconClick() {
    onClose();
  }

  function handleSignupButtonClick() {
    if (openSignupDialog) {
      openSignupDialog();
    }
  }

  return (
    <MyDialog open={open} onClose={onClose}>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => <div />}
        rightSideRender={() => <div />}
      />

      {loading && <Spinner />}

      {error && !loading && <SomethingWentWrongFeedback />}

      {!error && !loading && (
        <Box width="100%" maxWidth="300px" margin="0 auto" paddingBottom="48px">
          <Box padding="16px 0">
            <Typography variant="h4" align="center">
              Sign in to WCBMS
            </Typography>
          </Box>
          <Box marginBottom="16px" />

          {showLoginErrorAlert && (
            <Box sx={{ padding: '12px 0' }}>
              <Alert
                severity="error"
                sx={{
                  minHeight: 'unset !important',
                  fontSize: theme.typography.body1.fontSize,
                }}
              >
                The account details you entered are incorrect. Please try again.
              </Alert>
            </Box>
          )}

          <Box margin="12px 0">
            <MyGoogleLoginButton text="Continue with Google" />
          </Box>
          <Box margin="12px 0">
            <MyFacebookLoginButton text="Continue with Facebook" />
          </Box>
          <Box margin="0px 20px">
            <Divider
              sx={{
                '&:before, &:after': {
                  borderColor: divider.color,
                },
              }}
            >
              or
            </Divider>
          </Box>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              const { email, password } = getValues();
              handleSubmit(email, password);
            }}
          >
            <Box sx={{ padding: '12px 0' }}>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "What's your email?",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.',
                  },
                }}
                defaultValue=""
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <MyTextField
                      label="Email"
                      id="email-input"
                      error={!!errors.email}
                      helperText={errors.email && errors.email.message}
                      inputRef={ref}
                      {...rest} // eslint-disable-line react/jsx-props-no-spreading
                    />
                  );
                }}
              />
            </Box>
            <Box sx={{ padding: '12px 0' }}>
              <Controller
                control={control}
                name="password"
                rules={{ required: "What's your password?" }}
                defaultValue=""
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <MyPasswordField
                      label="Password"
                      id="password-input"
                      error={!!errors.password}
                      // helperText={errors.password && errors.password.message}
                      ref={ref}
                      {...rest} // eslint-disable-line react/jsx-props-no-spreading
                    />
                  );
                }}
              />
            </Box>

            <Box padding="12px 0">
              <MyButton
                size="large"
                disabled={!isDirty || !isValid}
                type="submit"
                color="primary"
              >
                Submit
              </MyButton>
            </Box>
          </form>

          <Box padding="12px 0">
            <MyButton
              size="large"
              variant="outlined"
              onClick={handleForgotPasswordButtonClick}
            >
              Forgot password?
            </MyButton>
          </Box>

          <Box sx={{ marginTop: '40px' }}>
            <Typography variant="body1" align="center">
              Don&apos;t have an account?{' '}
              <MyUnstyledButton onClick={handleSignupButtonClick}>
                Sign up
              </MyUnstyledButton>
            </Typography>
          </Box>
          <Box marginTop="64px" />
        </Box>
      )}
    </MyDialog>
  );
};

export default withLoginContainer(LoginDialog);
