import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import MyTabs, { MyTab } from '../../components/MyTabs';
import { Profile } from '../../types/Profile';
import Needing from './Needing';
import Fulfilled from './Fulfilled';
import Notifications from './Notifications';
import Items from './Items';
import Chats from './Chats';
import { divider } from '../../theme/theme';

type TabsProps = {
  itsMe: () => boolean;
  profile: Profile;
};

const Tabs = ({ itsMe, profile }: TabsProps): JSX.Element => {
  const history = useHistory();
  const { path } = useRouteMatch<{ id: string }>();

  function getTabIndex(): number {
    const splited = path.split(`/profile/:id`);

    if (splited.length === 1) return 0;

    const nestedPath = splited[1];

    if (itsMe()) {
      if (nestedPath === '/notifications') return 0;
      if (nestedPath === '/chats') return 1;
      if (nestedPath === '/spending') return 2;
      if (nestedPath === '/spent') return 3;
      if (nestedPath === '/proposing') return 4;
    }

    if (!itsMe()) {
      if (nestedPath === '/spending') return 0;
      if (nestedPath === '/spent') return 1;
      if (nestedPath === '/proposing') return 2;
    }
    return 0;
  }

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabIndex(getTabIndex());
  }, [path]);

  return (
    <>
      <MyTabs
        value={tabIndex}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          // setTabIndex(newValue);
          if (itsMe()) {
            if (newValue === 0) {
              history.push(`/profile/${profile.ID}/notifications`);
            } else if (newValue === 1) {
              history.push(`/profile/${profile.ID}/chats`);
            } else if (newValue === 2) {
              history.push(`/profile/${profile.ID}/spending`);
            } else if (newValue === 3) {
              history.push(`/profile/${profile.ID}/spent`);
            } else if (newValue === 4) {
              history.push(`/profile/${profile.ID}/proposing`);
            }
          } else if (newValue === 0) {
            history.push(`/profile/${profile.ID}/spending`);
          } else if (newValue === 1) {
            history.push(`/profile/${profile.ID}/spent`);
          }
        }}
      >
        {itsMe() && <MyTab label="Notifications" />}
        {itsMe() && <MyTab label="Chats" />}
        <MyTab label="Buying" />
        <MyTab label="Bought" />
        {itsMe() && <MyTab label="Selling" />}
      </MyTabs>
      <Divider sx={{ margin: '0', borderColor: divider.color }} />
      <Box>
        {itsMe() && (
          <>
            {tabIndex === 0 && <Notifications profile={profile} />}
            {tabIndex === 1 && <Chats profile={profile} />}
            {tabIndex === 2 && <Needing profile={profile} itsMe={itsMe} />}
            {tabIndex === 3 && <Fulfilled profile={profile} itsMe={itsMe} />}
            {tabIndex === 4 && <Items profile={profile} itsMe={itsMe} />}
          </>
        )}
        {!itsMe() && (
          <>
            {tabIndex === 0 && <Needing profile={profile} itsMe={itsMe} />}
            {tabIndex === 1 && <Fulfilled profile={profile} itsMe={itsMe} />}
            {tabIndex === 2 && <Items profile={profile} itsMe={itsMe} />}
          </>
        )}
      </Box>
    </>
  );
};

export default Tabs;
