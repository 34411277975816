import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import MyButton from '../../components/MyButton';
import MyTextField, { MyPasswordField } from '../../components/MyTextField';
import PageLayout from '../../components/PageLayout';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import Spinner from '../../components/Spinner';
import MyUnstyledButton from '../../components/MyButton/MyUnstyledButton';
import {
  MyGoogleLoginButton,
  MyFacebookLoginButton,
} from '../../components/SocialLoginButtons';
import useDialogsManager from '../../hooks/useDialogsManager';
import withLoginContainer from './withLoginContainer';
import { divider, dialog } from '../../theme/theme';

type FormValues = {
  email: string;
  password: string;
};

type LoginPageProps = {
  handleSubmit: (email: string, password: string) => void;
  handleForgotPasswordButtonClick: () => void;
  showLoginErrorAlert: boolean;
  loading: boolean;
  error: boolean;
};

const LoginPage = ({
  handleSubmit,
  handleForgotPasswordButtonClick,
  showLoginErrorAlert,
  loading,
  error,
}: LoginPageProps): JSX.Element => {
  const { openSignupDialog } = useDialogsManager();
  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { errors, isDirty, isValid } = formState;

  function handleSignupButtonClick() {
    if (openSignupDialog) {
      openSignupDialog();
    }
  }

  const canShowSpinner = () => loading && !error;
  const canShowError = () => !loading && error;
  const canShowForm = () => !loading && !error;

  return (
    <PageLayout>
      <Box sx={{ maxWidth: '600px', margin: '6px auto' }}>
        <Box padding="16px">
          <Typography variant="h4" variantMapping={{ h4: 'h1' }}>
            Sign in to WCBMS
          </Typography>
        </Box>

        {canShowSpinner() && <Spinner />}

        {canShowError() && <SomethingWentWrongFeedback />}

        {canShowForm() && (
          <Box>
            <Box margin={dialog.input.padding}>
              <MyGoogleLoginButton text="Continue with Google" />
            </Box>
            <Box margin={dialog.input.padding}>
              <MyFacebookLoginButton text="Continue with Facebook" />
            </Box>
            <Box margin="0px 20px">
              <Divider
                sx={{
                  '&:before, &:after': {
                    borderColor: divider.color,
                  },
                }}
              >
                or
              </Divider>
            </Box>

            {showLoginErrorAlert && (
              <Box sx={{ padding: '12px 16px' }}>
                <Alert
                  severity="error"
                  sx={{
                    minHeight: 'unset !important',
                    fontSize: '15px important',
                  }}
                >
                  The account details you entered are incorrect. Please try
                  again.
                </Alert>
              </Box>
            )}

            <form
              onSubmit={(e) => {
                e.preventDefault();
                const { email, password } = getValues();
                handleSubmit(email, password);
              }}
            >
              <Box sx={{ padding: '12px 16px' }}>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: "What's your email?",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email.',
                    },
                  }}
                  defaultValue=""
                  render={({ field }) => {
                    const { ref, ...rest } = field;
                    return (
                      <MyTextField
                        label="Email"
                        id="email-input"
                        error={!!errors.email}
                        helperText={errors.email && errors.email.message}
                        inputRef={ref}
                        {...rest} // eslint-disable-line react/jsx-props-no-spreading
                      />
                    );
                  }}
                />
              </Box>

              <Box sx={{ padding: '12px 16px' }}>
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: "What's your password?" }}
                  defaultValue=""
                  render={({ field }) => {
                    const { ref, ...rest } = field;
                    return (
                      <MyPasswordField
                        label="Password"
                        id="password-input"
                        error={!!errors.password}
                        // helperText={errors.password && errors.password.message}
                        ref={ref}
                        {...rest} // eslint-disable-line react/jsx-props-no-spreading
                      />
                    );
                  }}
                />
              </Box>

              <Box padding={dialog.input.padding}>
                <MyButton
                  size="large"
                  disabled={!isDirty || !isValid}
                  type="submit"
                  color="primary"
                >
                  Submit
                </MyButton>
              </Box>
            </form>

            <Box padding={dialog.input.padding}>
              <MyButton
                size="large"
                variant="outlined"
                onClick={handleForgotPasswordButtonClick}
              >
                Forgot password?
              </MyButton>
            </Box>

            <Box sx={{ padding: '0 16px', marginTop: '40px' }}>
              <Typography variant="body1">
                Don&apos;t have an account?{' '}
                <MyUnstyledButton onClick={handleSignupButtonClick}>
                  Sign up
                </MyUnstyledButton>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

export default withLoginContainer(LoginPage);
