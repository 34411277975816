import React from 'react';
import isEqual from 'lodash/isEqual';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyDialog from '../../components/MyDialog';
import MyButton from '../../components/MyButton';
import MyCloseIcon from '../../components/MyCloseIcon';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import { dialog, colors } from '../../theme/theme';
import { BiasPoint, FilterByLocationOptions } from './LocationFilter';
import { calculateValue, valueLabelFormat } from './miles';

type LocationFilterDialogProps = {
  open: boolean;
  onClose: () => void;
  intitialRadius: number;
  initialBiasPoint: BiasPoint | null;
  initialPlaceLabel: string;
  onPlaceSearchException: () => void;
  onApply: (newOptions: FilterByLocationOptions) => void;
};

const LocationFilterDialog = ({
  open,
  onClose,
  intitialRadius,
  initialPlaceLabel,
  initialBiasPoint,
  onPlaceSearchException,
  onApply,
}: LocationFilterDialogProps): JSX.Element => {
  const [placeSearchInputValue, setPlaceSearchInputValue] = React.useState('');
  const [placeLabel, setPlaceLabel] = React.useState(initialPlaceLabel);
  const [biasPoint, setBiasPoint] = React.useState<BiasPoint | null>(
    initialBiasPoint,
  );

  const [radius, setRadius] = React.useState<
    number | string | Array<number | string>
  >(intitialRadius);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setRadius(newValue);
  };

  function handleCloseIconClick() {
    onClose();
  }

  function handleApplyButtonClick() {
    onApply({
      radius: radius as number,
      biasPoint: placeSearchInputValue === '' ? null : biasPoint,
      placeLabel: placeSearchInputValue === '' ? '' : placeLabel,
    });
  }

  return (
    <MyDialog open={open} onClose={handleCloseIconClick}>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => <Typography variant="h5">Location</Typography>}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              color="primary"
              onClick={handleApplyButtonClick}
              disabled={
                radius === intitialRadius &&
                isEqual(biasPoint, initialBiasPoint)
              }
            >
              Apply
            </MyButton>
          </Box>
        )}
      />

      <Box className="LocationFilterDialog">
        <Box sx={{ padding: dialog.input.padding }}>
          <PlaceSearchInput
            inputLabel="Enter Zip-Code or City"
            initialInputValue={initialPlaceLabel}
            onPlaceDetailsAvailable={(place) => {
              setBiasPoint({
                latitude: place.GeometryLatitude,
                longitude: place.GeometryLongitude,
                countryISOCode: place.Country,
              });
              setPlaceLabel(place.Label);
            }}
            onExceptionCaught={onPlaceSearchException}
            onInputValueChange={(newInputValue) => {
              if (newInputValue === '') {
                setBiasPoint(null);
                setPlaceLabel('');
              }
              setPlaceSearchInputValue(newInputValue);
            }}
          />
        </Box>
        <Box sx={{ padding: dialog.input.padding }}>
          <Box marginBottom="4px">
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography id="input-slider" variant="body1">
                  Radius
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id="input-slider"
                  variant="body1"
                  sx={{ color: colors.grey }}
                >
                  {valueLabelFormat(calculateValue(radius as number))}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Slider
            value={typeof radius === 'number' ? radius : 17}
            min={1}
            step={1}
            max={17}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            valueLabelDisplay="off"
          />
        </Box>
      </Box>
    </MyDialog>
  );
};

export default LocationFilterDialog;
