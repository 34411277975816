import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import {
  AlertFeedback,
  SomethingWentWrongFeedback,
} from '../../components/Feedback';
import PageLayout from '../../components/PageLayout';
import Spinner from '../../components/Spinner';
import NeedCard from '../../components/MyCards/NeedCard';
import PageSection from '../../components/PageSection';
import { Need } from '../../types/Need';
import currencies, { Acronym } from '../../utils/currencies';
import getArticle from '../../utils/getArticle';
import { borderRadius } from '../../theme/theme';
import PropositionsContainer from './PropositionsContainer';

type NeedPageProps = {
  need: Need | null;
  propositionsCount: number | null;
  handleProposeClick: () => void;
  handleEditClick: () => void;
  handleFulfilledClick: () => void;
  loading: boolean;
  error: boolean;
  needNotFound: boolean;
  reloadPropositions: boolean;
  deletePropositionCallback: () => void;
};

const NeedPage = ({
  need,
  propositionsCount,
  handleProposeClick,
  handleEditClick,
  handleFulfilledClick,
  loading,
  error,
  needNotFound,
  reloadPropositions,
  deletePropositionCallback,
}: NeedPageProps): JSX.Element => {
  const canShowSpinner = () =>
    (loading || need === null) && !error && !needNotFound;
  const canShowError = () => error && !needNotFound && !loading;
  const canShowNotFound = () => error && needNotFound && !loading;
  const canShowNeed = () =>
    !error && !needNotFound && !loading && need !== null;

  return (
    <PageLayout>
      <Helmet>
        <title>Money | Bonvih</title>
      </Helmet>
      <PageSection
        title="Offer"
        backButtonEnabled={!loading}
        backgroundColor
      >
        {canShowSpinner() && <Spinner />}

        {canShowError() && <SomethingWentWrongFeedback />}

        {canShowNotFound() && (
          <AlertFeedback
            renderIcon={() => (
              <SentimentVeryDissatisfiedIcon sx={{ fontSize: '40px' }} />
            )}
            title="Ad not found"
            subtitle="The ad you requested does not exist."
          />
        )}

        {canShowNeed() && need && (
          <Box>
            <Helmet>
              <title>
                {`${currencies.getSymbol(
                  need.currency as Acronym,
                )}${need.offer.toString()} to spend on ${getArticle(
                  need.title,
                )} ${need.title}`}
              </title>
            </Helmet>
            <Box
              sx={{
                background: '#FFFFFF',
                borderRadius,
              }}
            >
              <NeedCard
                need={need as Need}
                handleProposeClick={handleProposeClick}
                handleEditClick={handleEditClick}
                handleFulfilledClick={handleFulfilledClick}
              />
            </Box>
          </Box>
        )}
      </PageSection>
      {need && propositionsCount !== null && !loading && (
        <PageSection
          title={`${propositionsCount} Proposition${
            propositionsCount > 1 ? 's' : ''
          }`}
          backgroundColor
        >
          <PropositionsContainer
            need={need}
            reloadPropositions={reloadPropositions}
            deletePropositionCallback={deletePropositionCallback}
          />
        </PageSection>
      )}
    </PageLayout>
  );
};

export default NeedPage;
