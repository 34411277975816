import React from 'react';
import {
  ListItemText,
  ListItemTextProps,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { drawer } from '../../../theme/theme';

const MyListItemText = (props: ListItemTextProps): JSX.Element => {
  const theme = useTheme();
  const isMediumDevice = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <ListItemText
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        margin: '0px',
      }}
      primaryTypographyProps={{
        padding: isMediumDevice
          ? drawer.item.content.padding.mediumDevice
          : drawer.item.content.padding.smallDevice,
        fontSize: isMediumDevice
          ? theme.typography.h5.fontSize
          : theme.typography.body1.fontSize,
      }}
    />
  );
};

export default MyListItemText;
