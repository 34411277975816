import { BonvihMessage } from '../types/BonvihMessage';
import { Profile } from '../types/Profile';
import profilesSamples from './profiles';

const bonvihMessages: BonvihMessage[] = [
  {
    ID: "4568324",
    title: 'Welcome to Bonvih!',
    message:
      'Welcome to Bonvih! We are happy you are here. This is the best place to fulfill your needs. Advertise your needs or Find some needs to fulfill.',
    seen: false,
    profile: profilesSamples.find((p) => p.firstname === 'Max') as Profile,
    CreatedAt: new Date(),
  },
  {
    ID: "89942",
    title: 'Welcome to Bonvih!',
    message:
      'Welcome to Bonvih! We are happy you are here. This is the best place to fulfill your needs. Advertise your needs or Find some needs to fulfill.',
    seen: false,
    profile: profilesSamples.find((p) => p.firstname === 'Frank') as Profile,
    CreatedAt: new Date(),
  },
];

export default bonvihMessages;
