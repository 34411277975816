function formatMoney(amount: number, currency: string): string {
  let locale = 'en-GB';

  if (currency === 'EUR') {
    locale = 'fr-FR';
  } else if (currency === 'USD') {
    locale = 'en-US';
  }

  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return numberFormat.format(amount);
}

export default formatMoney;
