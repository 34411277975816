import React, { useState } from 'react';
import { Box } from '@mui/material';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import { borderRadius, colors } from '../../theme/theme';

export const MyAvatar = (props: AvatarProps): JSX.Element => {
  const { sx, ...rest } = props;
  return (
    <Avatar
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        ...sx,
        width: '48px !important',
        height: '48px !important',
        cursor: 'pointer !important',
        '& > .MuiSvgIcon-root': {
          width: '30px',
          height: '30px',
        },
      }}
    />
  );
};

export const MySmallAvatar = (props: AvatarProps): JSX.Element => {
  const { sx, ...rest } = props;
  return (
    <Avatar
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        ...sx,
        width: '32px !important',
        height: '32px !important',
        cursor: 'pointer !important',
        fontSize: '13px !important',
        '& > .MuiSvgIcon-root': {
          width: '20px',
          height: '20px',
        },
      }}
    />
  );
};

export const MyExtraSmallAvatar = (props: AvatarProps): JSX.Element => {
  const { sx, ...rest } = props;
  return (
    <Avatar
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        ...sx,
        width: '16px !important',
        height: '16px !important',
        cursor: 'pointer !important',
        fontSize: '13px !important',
        '& > .MuiSvgIcon-root': {
          width: '10px',
          height: '10px',
        },
      }}
    />
  );
};

export const UserAvatar = (props: AvatarProps): JSX.Element => (
  <MyAvatar
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  />
);

export const SmallUserAvatar = (props: AvatarProps): JSX.Element => (
  <MySmallAvatar
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  />
);

export const ExtraSmallUserAvatar = (props: AvatarProps): JSX.Element => (
  <MyExtraSmallAvatar
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  />
);

export const ItemAvatar = (props: AvatarProps): JSX.Element => {
  const [imgLoaded, setImgLoaded] = useState(false);
  return (
    <Box
      sx={{
        position: 'relative',
        width: '48px !important',
        height: '48px !important',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius,
          backgroundColor: colors.grey,
          zIndex: imgLoaded ? -1 : 1,
          '& > .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
            color: '#FFFFFF',
          },
        }}
      >
        <PhotoOutlinedIcon />
      </Box>
      <MyAvatar
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        sx={{
          borderRadius: '4px !important',
        }}
        imgProps={{
          onLoad: () => setImgLoaded(true),
        }}
      />
    </Box>
  );
};
