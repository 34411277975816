import { rest } from 'msw';
import { Item } from '../../types/Item';
import { Need } from '../../types/Need';
import { Profile } from '../../types/Profile';
import { Proposition } from '../../types/Proposition';
import { SignupData, Step } from '../../types/SignupData';
import { Account } from '../../types/Account';
import { BonvihMessage } from '../../types/BonvihMessage';
import needsSample from '../../samples/needs';
import itemsSample from '../../samples/items';
import profilesSample from '../../samples/profiles';
import accountsSample from '../../samples/accounts';
import propositionsSample from '../../samples/propositions';
import bonvihMessagesSample from '../../samples/bonvih-messages';
import { Notification } from '../../types/Notification';

function generateCode(): string {
  return Math.floor(100000 + Math.random() * 900000).toString();
}

const profiles = [...profilesSample];
const accounts = [...accountsSample];
let needs = [...needsSample];
let items = [...itemsSample];
let propositions: Proposition[] = [...propositionsSample];
const bonvihMessages: BonvihMessage[] = [...bonvihMessagesSample];
const signupDataCache = new Map<string, SignupData>();
const verificationCodeCache = new Map<string, string>();

const baseUrl = `http://${process.env.REACT_APP_BONVIH_API_HOST}:4000`;

const handleEmailAvailableRequest = rest.get(
  `${baseUrl}/api/accounts/email_available`,
  (req, res, ctx) => {
    const email = req.url.searchParams.get('email');
    if (accounts.some((a) => a.email === email)) {
      return res(ctx.status(200), ctx.json({ emailTaken: true }));
    }

    return res(ctx.status(200), ctx.json({ emailTaken: false }));
  },
);

const handleLoginRequest = rest.post<{ email: string; password: string }>(
  `${baseUrl}/api/accounts/login`,
  (req, res, ctx) => {
    const { email, password } = req.body;
    const account = accounts.find((a) => a.email === email);
    if (account && password === account.password) {
      return res(ctx.status(200), ctx.json(account.profile));
    }

    return res(ctx.status(403));
  },
);

const handlePropositionCreateRequest = rest.post<Proposition>(
  `${baseUrl}/api/propositions`,
  (req, res, ctx) => {
    const proposition = req.body;
    proposition.ID = generateCode();
    propositions.push(proposition);
    return res(ctx.status(201), ctx.json(proposition));
  },
);

const handleItemCreateRequest = rest.post<Item>(
  `${baseUrl}/api/items`,
  (req, res, ctx) => {
    const item = req.body;
    item.ID = generateCode();
    items.push(item);
    return res(ctx.status(201), ctx.json(item));
  },
);

const handleNeedCreateRequest = rest.post<Need>(
  `${baseUrl}/api/needs`,
  (req, res, ctx) => {
    const need = req.body;
    need.ID = generateCode();
    needs.push(need);
    return res(ctx.status(201), ctx.json(need));
  },
);

const handleNeedPropositionsGetRequest = rest.get(
  `${baseUrl}/api/needs/:id/propositions`,
  (req, res, ctx) => {
    const { id } = req.params;
    const found = propositions.filter((p) => p.need.ID.toString() === id);

    return res(ctx.status(200), ctx.json(found));
  },
);

const handleProfileStatsGetRequest = rest.get(
  `${baseUrl}/api/profiles/:id/stats`,
  (req, res, ctx) => {
    const { id } = req.params;
    const profile = profiles.find((p) => p.ID.toString() === id);
    const needingCount = needs.filter(
      (n) => n.profile.ID.toString() === id && !n.fulfilled,
    ).length;

    const fulfilledCount = needs.filter(
      (n) => n.profile.ID.toString() === id && n.fulfilled,
    ).length;
    // const itemsCount = items.filter(
    //   (i) => i.profile.ID.toString() === id,
    // ).length;
    // const propositionsCount = propositions.filter(
    //   (p) => p.item.profile.ID.toString() === id,
    // ).length;
    return res(
      ctx.status(200),
      ctx.json({
        profile,
        needing: needingCount,
        fulfilled: fulfilledCount,
      }),
    );
  },
);

const handleProfileGetNotificationsCount = rest.get(
  `${baseUrl}/api/profiles/:id/notifications_count`,
  (req, res, ctx) => {
    let count = 0;
    const { id } = req.params;

    const unseenPropositions = propositions.filter(
      (p) => p.need.profile.ID.toString() === id && p.seen === false,
    );

    const unseenBonvihMessages = bonvihMessages.filter(
      (b) => b.profile.ID.toString() === id && b.seen === false,
    );

    count = unseenPropositions.length + unseenBonvihMessages.length;

    return res(ctx.status(200), ctx.json(count));
  },
);

const handleProfileGetNotifications = rest.get(
  `${baseUrl}/api/profiles/:id/notifications`,
  (req, res, ctx) => {
    const notifications: Notification[] = [];
    const { id } = req.params;

    const unseenPropositions = propositions.filter(
      (p) => p.need.profile.ID.toString() === id && p.seen === false,
    );

    const unseenBonvihMessages = bonvihMessages.filter(
      (b) => b.profile.ID.toString() === id && b.seen === false,
    );

    unseenPropositions.forEach(() => {
      // notifications.push({
      //   type: 'proposition',
      //   proposition: p,
      //   bonvihMessage: null,
      //   seen: false,
      //   CreatedAt: new Date(),
      // });
    });

    unseenBonvihMessages.forEach(() => {
      // notifications.push({
      //   type: 'bonvih-message',
      //   proposition: null,
      //   seen: false,
      //   bonvihMessage: b,
      //   CreatedAt: new Date(),
      // });
    });

    return res(ctx.status(200), ctx.json(notifications));
  },
);

const handleMarkBonvihMessageAsSeen = rest.get(
  `${baseUrl}/api/bonvih-messages/:id/seen`,
  (req, res, ctx) => {
    const { id } = req.params;

    const found = bonvihMessages.find((b) => b.ID.toString() === id);

    if (found) {
      found.seen = true;
    }

    return res(ctx.status(200));
  },
);

const handleProfileItemsGetRequest = rest.get(
  `${baseUrl}/api/profiles/:id/items`,
  (req, res, ctx) => {
    const { id } = req.params;
    const found = items.filter((i) => i.profile.ID.toString() === id);

    return res(ctx.status(200), ctx.json(found));
  },
);

const handleProfileNeedsGetRequest = rest.get(
  `${baseUrl}/api/profiles/:id/needs`,
  (req, res, ctx) => {
    const { id } = req.params;
    const found = needs.filter((n) => n.profile.ID.toString() === id);

    return res(ctx.status(200), ctx.json(found));
  },
);

const handleProfileNeedingGetRequest = rest.get(
  `${baseUrl}/api/profiles/:id/spending`,
  (req, res, ctx) => {
    const { id } = req.params;
    const found = needs.filter(
      (n) => n.profile.ID.toString() === id && !n.fulfilled,
    );

    return res(ctx.status(200), ctx.json(found));
  },
);

const handleProfileFulfilledNeedsGetRequest = rest.get(
  `${baseUrl}/api/profiles/:id/spent`,
  (req, res, ctx) => {
    const { id } = req.params;
    const found = needs.filter(
      (n) => n.profile.ID.toString() === id && n.fulfilled,
    );

    return res(ctx.status(200), ctx.json(found));
  },
);

const handleProfilePropositionsGetRequest = rest.get(
  `${baseUrl}/api/profiles/:id/propositions`,
  (req, res, ctx) => {
    const { id } = req.params;
    const found = propositions.filter(
      (p) => p.item.profile.ID.toString() === id,
    );

    return res(ctx.status(200), ctx.json(found));
  },
);

const handleItemGetRequest = rest.get(
  `${baseUrl}/api/items/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    const item = items.find((i) => i.ID.toString() === id);

    return res(ctx.status(200), ctx.json(item));
  },
);

const handleItemDeleteRequest = rest.delete(
  `${baseUrl}/api/items/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    items = items.filter((i) => i.ID !== id);

    return res(ctx.status(200));
  },
);

const handleItemUpdateRequest = rest.put<Item>(
  `${baseUrl}/api/items/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    const updated = req.body;
    const item = items.find((i) => i.ID.toString() === id) as Item;
    item.ID = updated.ID;
    item.description = updated.description;
    item.name = updated.name;
    item.photos = updated.photos;
    item.profile = updated.profile;
    item.sold = updated.sold;

    return res(ctx.status(200));
  },
);

const handleProfileGetRequest = rest.get(
  `${baseUrl}/api/profiles/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    const profile = profiles.find((p) => p.ID.toString() === id);
    if (profile) return res(ctx.status(200), ctx.json(profile));

    return res(ctx.status(404));
  },
);

const handleProfileUpdateRequest = rest.put<Profile>(
  `${baseUrl}/api/profiles/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    const updated = req.body;
    const profile = profiles.find((p) => p.ID.toString() === id) as Profile;
    profile.ID = updated.ID;
    profile.avatar = updated.avatar;
    // profile.city = updated.city;
    // profile.country = updated.country;
    profile.firstname = updated.firstname;
    profile.CreatedAt = updated.CreatedAt;

    return res(ctx.status(200));
  },
);

const handlePropositionGetRequest = rest.get(
  `${baseUrl}/api/propositions/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    const proposition = propositions.find((p) => p.ID.toString() === id);
    if (proposition) return res(ctx.status(200), ctx.json(proposition));
    return res(ctx.status(404));
  },
);

const handlePropositionDeleteRequest = rest.delete(
  `${baseUrl}/api/propositions/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    propositions = propositions.filter((p) => p.ID !== id);
    return res(ctx.status(200));
  },
);

const handlePropositionUpdateRequest = rest.put<Proposition>(
  `${baseUrl}/api/propositions/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    const updated = req.body;
    const proposition = propositions.find(
      (n) => n.ID.toString() === id,
    ) as Proposition;
    proposition.ID = updated.ID;
    proposition.done = updated.done;
    proposition.item = updated.item;
    proposition.need = updated.need;
    proposition.seen = updated.seen;

    return res(ctx.status(200));
  },
);

const handleNeedGetRequest = rest.get(
  `${baseUrl}/api/needs/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    const need = needs.find((n) => n.ID.toString() === id);
    if (need) return res(ctx.status(200), ctx.json(need));

    return res(ctx.status(404));
  },
);

const handleNeedUpdateRequest = rest.put<Need>(
  `${baseUrl}/api/needs/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    const updated = req.body;
    const need = needsSample.find((n) => n.ID.toString() === id) as Need;
    need.ID = updated.ID;
    need.category = updated.category;
    need.description = updated.description;
    need.currency = updated.currency;
    need.fulfilled = updated.fulfilled;
    need.offer = updated.offer;
    need.profile = updated.profile;
    need.title = updated.title;
    need.UpdatedAt = updated.UpdatedAt;

    return res(ctx.status(200));
  },
);

const handleNeedDeleteRequest = rest.delete(
  `${baseUrl}/api/needs/:id`,
  (req, res, ctx) => {
    const { id } = req.params;
    needs = needs.filter((n) => n.ID !== id);

    return res(ctx.status(200));
  },
);

function orderNeeds(unordered: Need[], orderBy: string) {
  if (orderBy === 'offer desc') {
    unordered.sort((a, b) => b.offer - a.offer);
  }
  if (orderBy === 'offer asc') {
    unordered.sort((a, b) => a.offer - b.offer);
  }
  if (orderBy === 'updated_at desc') {
    unordered.sort((a, b) => b.UpdatedAt.getTime() - a.UpdatedAt.getTime());
  }
  if (orderBy === 'updated_at asc') {
    unordered.sort((a, b) => a.UpdatedAt.getTime() - b.UpdatedAt.getTime());
  }
}

function filterNeeds(
  unfiltered: Need[],
  title: string,
  category: string,
): Need[] {
  if (title === '') {
    if (category === '') {
      return unfiltered;
    }
    return unfiltered.filter((need) => need.category.includes(category));
  }

  if (category === '') {
    return unfiltered.filter((need) =>
      title
        .toLowerCase()
        .split(' ')
        .every((word) => need.title.toLowerCase().includes(word)),
    );
  }

  return unfiltered.filter(
    (need) =>
      title
        .toLowerCase()
        .split(' ')
        .every((word) => need.title.toLowerCase().includes(word)) &&
      need.category.includes(category),
  );
}

const handleNeedSearchRequest = rest.get(
  `${baseUrl}/api/search/needs`,
  (req, res, ctx) => {
    const title = req.url.searchParams.get('title') as string;
    const category = req.url.searchParams.get('category') as string;
    const orderBy = req.url.searchParams.get('orderBy') as string;

    const found = filterNeeds(needs, title, category);
    orderNeeds(found, orderBy);

    return res(ctx.status(200), ctx.json(found));
  },
);

function cacheSignupData(data: SignupData) {
  signupDataCache.set(data.token, data);
}

function getCurrentStep(data: SignupData): Step['name'] {
  if (data.steps.some((step) => step.name === 'choose_password')) {
    return 'choose_password';
  }
  if (data.steps.some((step) => step.name === 'verify_email')) {
    return 'verify_email';
  }
  return 'create_account';
}

function getEmailFromSignupData(data: SignupData): string {
  return (data.steps.find((step) => step.name === 'create_account') as Step)
    .inputs.email as string;
}

function geFirstnameFromSignupData(data: SignupData): string {
  return (data.steps.find((step) => step.name === 'create_account') as Step)
    .inputs.firstname as string;
}

// function geCityFromSignupData(data: SignupData): string {
//   return (data.steps.find((step) => step.name === 'create_account') as Step)
//     .inputs.city as string;
// }

// function getCountryFromSignupData(data: SignupData): string {
//   return (data.steps.find((step) => step.name === 'create_account') as Step)
//     .inputs.country as string;
// }

function getPasswordFromSignupData(data: SignupData): string {
  return (data.steps.find((step) => step.name === 'choose_password') as Step)
    .inputs.password as string;
}

function cacheVerificationCode(email: string, code: string) {
  verificationCodeCache.set(email, code);
}

function getVerificationCodeFromCache(email: string): string {
  return verificationCodeCache.get(email) as string;
}

function getVerificationCodeFromSignupData(data: SignupData): string {
  return (data.steps.find((step) => step.name === 'verify_email') as Step)
    .inputs.code as string;
}

function getProfileFromSignupData(data: SignupData): Profile {
  return {
    ID: generateCode(),
    avatar: '',
    firstname: geFirstnameFromSignupData(data),
    // city: geCityFromSignupData(data),
    // country: getCountryFromSignupData(data),
    CreatedAt: new Date(),
    UpdatedAt: new Date(),
  };
}

function getAccountFromSignupData(data: SignupData): Account {
  return {
    ID: generateCode(),
    email: getEmailFromSignupData(data),
    password: getPasswordFromSignupData(data),
    profile: getProfileFromSignupData(data),
  };
}

const handleSignupRequest = rest.post<SignupData>(
  `${baseUrl}/api/onboarding`,
  (req, res, ctx) => {
    const signupData = req.body;
    const currentStep = getCurrentStep(signupData);
    if (currentStep === 'create_account') {
      cacheSignupData(signupData);
      const code = generateCode();
      const email = getEmailFromSignupData(signupData);
      cacheVerificationCode(email, code);

      return res(ctx.status(200));
    }
    if (currentStep === 'verify_email') {
      const submittedCode = getVerificationCodeFromSignupData(signupData);
      const email = getEmailFromSignupData(signupData);
      const actualCode = getVerificationCodeFromCache(email);
      if (submittedCode === actualCode) {
        cacheSignupData(signupData);

        return res(ctx.status(200));
      }
      return res(ctx.status(403));
    }
    if (currentStep === 'choose_password') {
      cacheSignupData(signupData);
      const profile = getProfileFromSignupData(signupData);
      const account = getAccountFromSignupData(signupData);
      profiles.push(profile);
      accounts.push(account);

      return res(ctx.status(200));
    }

    return res(ctx.status(400));
  },
);

export default [
  handleEmailAvailableRequest,
  handleSignupRequest,
  handleLoginRequest,
  handleNeedCreateRequest,
  handleNeedGetRequest,
  handleNeedUpdateRequest,
  handleNeedDeleteRequest,
  handleNeedSearchRequest,
  handleNeedPropositionsGetRequest,
  handlePropositionCreateRequest,
  handlePropositionGetRequest,
  handlePropositionUpdateRequest,
  handlePropositionDeleteRequest,
  handleItemCreateRequest,
  handleItemGetRequest,
  handleItemUpdateRequest,
  handleItemDeleteRequest,
  handleProfileGetRequest,
  handleProfileUpdateRequest,
  handleProfileStatsGetRequest,
  handleProfileItemsGetRequest,
  handleProfileNeedsGetRequest,
  handleProfileNeedingGetRequest,
  handleProfileFulfilledNeedsGetRequest,
  handleProfilePropositionsGetRequest,
  handleProfileGetNotificationsCount,
  handleProfileGetNotifications,
  handleMarkBonvihMessageAsSeen,
];
