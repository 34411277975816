import React from 'react';
import Spinner from '../../../components/Spinner';
import Feedback, {
  SomethingWentWrongFeedback,
} from '../../../components/Feedback';
import SearchNeedCard from '../../../components/MyCards/SearchNeedCard';
import { Need } from '../../../types/Need';
import { Profile } from '../../../types/Profile';
import Results from '../../SearchNeedsPage/Results';

type FulfilledProps = {
  needs: Need[] | null;
  loading: boolean;
  error: boolean;
  itsMe: () => boolean;
  profile: Profile;
};

const Fulfilled = ({
  needs,
  loading,
  error,
  itsMe,
  profile,
}: FulfilledProps): JSX.Element => {
  if (loading) return <Spinner />;

  if (error) return <SomethingWentWrongFeedback />;

  if (needs === null) return <Spinner />;

  if (needs.length === 0) {
    if (itsMe()) {
      return (
        <Feedback
          title="You have not spent any money yet"
          subtitle="When you do, it will show up here."
        />
      );
    }

    return (
      <Feedback
        title={`${profile.firstname} has not spent any money yet`}
        subtitle="When it is the case, it will show up here."
      />
    );
  }

  return (
    <Results
      items={needs}
      renderItem={(need: Need) => <SearchNeedCard need={need} />}
      more={false}
    />
  );
};

export default Fulfilled;
