import React from 'react';
import { Dialog, DialogProps, useMediaQuery, useTheme } from '@mui/material';
import { borderRadius } from '../../theme/theme';

const MyDialog = (props: DialogProps): JSX.Element => {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          padding: '0',
          height: isMobileDevice ? '100%' : '650px',
          minHeight: isMobileDevice ? '100%' : '500px',
          maxHeight: isMobileDevice ? 'unset' : '90vh',
          minWidth: isMobileDevice ? '100%' : '540px',
          maxWidth: isMobileDevice ? '100%' : '600px',
          borderRadius,
        },
      }}
    />
  );
};

export default MyDialog;
