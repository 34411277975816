import React from 'react';
import DialogWithImportantMessage from '../DialogWithImportantMessage';
import logger from '../../logger';

export const ErrorHandler = (
  error: Error,
  info: { componentStack: string },
): void => {
  logger.log({ message: 'ErrorBoundary', error, reason: info.componentStack });
};

function ErrorFallback(): JSX.Element {
  return (
    <DialogWithImportantMessage
      open
      onButtonClick={() => window.location.reload()}
      buttonText="Refresh"
      loading={false}
      error={false}
      title="Error"
      message="Something went wrong, but it's not your fault. Let's try again or retry later."
    />
  );
}
export default ErrorFallback;
