import { createContext } from 'react';
import { Proposition } from '../../types/Proposition';

export type MyPropositions = {
  propositions: Proposition[] | null;
  loading: boolean;
  error: boolean;
  reload?: () => void;
};

const context = createContext<MyPropositions>({
  propositions: [],
  loading: false,
  error: false,
});

export default context;
