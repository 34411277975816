import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Box } from '@mui/system';
import {
  useTheme,
  useMediaQuery,
} from '@mui/material';
// import { styled, alpha } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { icon, input, borderRadius } from '../../theme/theme';
import MyTextField from '../MyTextField';
import MyButton from '../MyButton';

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderColor: searchInput.borderColor,
//   borderWidth: 'thin !important',
//   borderStyle: 'solid',
//   borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   '& .MuiInputBase-root': {
//     width: '100%',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: '9px 8px 10px 8px',
//     // vertical padding + font size from searchIcon
//     // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     fontSize: theme.typography.body1.fontSize,
//   },
// }));

const SearchField = (): JSX.Element => {
  const theme = useTheme();
  const isSmallDeviceup = useMediaQuery(theme.breakpoints.up('sm'));

  let defaultMinAmount = '';
  let defaultMaxAmount = '';

  const searchParams = new URLSearchParams(useLocation().search);
  const { path } = useRouteMatch();

  if (path === '/search') {
    defaultMinAmount = searchParams.get('minAmount') as string;
    defaultMaxAmount = searchParams.get('maxAmount') as string;
  }

  const { control } = useForm<{
    minAmount: string;
    maxAmount: string;
    itemName: string;
  }>({
    mode: 'onChange',
  });

  const minAmount = useWatch({
    control,
    name: 'minAmount',
    defaultValue: defaultMinAmount,
  });
  const maxAmount = useWatch({
    control,
    name: 'maxAmount',
    defaultValue: defaultMaxAmount,
  });
  const itemName = useWatch({
    control,
    name: 'itemName',
    defaultValue: '',
  });

  function handleSubmitButtonClick() {
    if (
      Boolean(minAmount) &&
      Boolean(maxAmount) &&
      Number.parseInt(minAmount, 10) > Number.parseInt(maxAmount, 10)
    ) {
      return;
    }

    window.location.assign(
      `/search?minAmount=${minAmount || '0'}&maxAmount=${
        maxAmount || ''
      }&title=${itemName}&orderBy=`,
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitButtonClick();
      }}
    >
      <Box
        sx={{
          maxWidth: '400px',
          '& .MuiInputBase-root': {
            minHeight: '42px !important',
          },
          '& .MuiInputBase-input': {
            padding: '9px 8px 10px 8px',
          },
          '& .MuiInputLabel-root': {
            top: '-6px',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            top: '0px',
          },
          '& .MuiInputLabel-root.MuiFormLabel-filled': {
            top: '0px',
          },
        }}
      >
        <Box display="flex" alignItems="center">
          {/* <Box flexGrow={1}>
            <Controller
              control={control}
              name="minAmount"
              defaultValue={minAmount}
              render={({ field }) => (
                <MyTextField
                  id="minAmount-input"
                  label="Min amount"
                  placeholder="0"
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                  InputProps={{
                    inputComponent:
                      MyNumberFormat as unknown as React.ElementType<InputBaseComponentProps>,
                  }}
                />
              )}
            />
          </Box>
          <Box marginLeft="4px" flexGrow={0} />
          <Box flexGrow={1}>
            <Controller
              control={control}
              name="maxAmount"
              defaultValue={maxAmount}
              render={({ field }) => (
                <MyTextField
                  id="maxAmount-input"
                  label="Max amount"
                  placeholder="No limit"
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                  InputProps={{
                    inputComponent:
                      MyNumberFormat as unknown as React.ElementType<InputBaseComponentProps>,
                  }}
                />
              )}
            />
          </Box>
          <Box marginLeft="4px" flexGrow={0} /> */}
          <Box flexGrow={1}>
            <Controller
              control={control}
              name="itemName"
              render={({ field }) => (
                <MyTextField
                  id="itemName-input"
                  label="What are you selling?"
                  placeholder=""
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
          </Box>
          <Box marginLeft="4px" flexGrow={0} />
          <Box flexGrow={0}>
            {isSmallDeviceup ? (
              <Box
                sx={{
                  '& .MuiButton-root': {
                    minHeight: '42px !important',
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                <MyButton
                  type="submit"
                  size="small"
                  variant="outlined"
                  disabled={
                    Boolean(minAmount) &&
                    Boolean(maxAmount) &&
                    Number.parseInt(minAmount, 10) >
                      Number.parseInt(maxAmount, 10)
                  }
                >
                  Get offers
                </MyButton>
              </Box>
            ) : (
              <Box
                onClick={handleSubmitButtonClick}
                sx={{
                  minHeight: '40px',
                  borderColor: input.border.color,
                  borderWidth: '1px !important',
                  borderStyle: 'solid',
                  borderRadius,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0 8px',
                }}
              >
                <SearchOutlinedIcon
                  sx={{
                    fontSize: `${icon.fontSize.small} !important`,
                    cursor: 'pointer',
                    color: icon.color,
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default SearchField;
