import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import MyList, { MyListItem, MyListItemText } from '../../components/MyList';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import PageSection from '../../components/PageSection';
import Spinner from '../../components/Spinner';
import { PrivacySettings } from '../../types/PrivacySettings';
import { colors, borderRadius } from '../../theme/theme';

type PrivacyNSafetyProps = {
  privacySettings: PrivacySettings | null;
  loading: boolean;
  error: boolean;
  updatePrivacySettings: (updated: PrivacySettings) => void;
};

const PrivacyNSafety = ({
  privacySettings,
  error,
  loading,
  updatePrivacySettings,
}: PrivacyNSafetyProps): JSX.Element => {
  const canShowSpinner = () =>
    !privacySettings && (loading || !loading) && !error;
  const canShowErrorMessage = () => !privacySettings && !loading && error;
  const canShowPrivacySettings = () => Boolean(privacySettings);

  return (
    <Box>
      {canShowSpinner() && <Spinner />}
      {canShowErrorMessage() && <SomethingWentWrongFeedback />}
      {canShowPrivacySettings() && (
        <Box>
          <Helmet>
            <title>Privacy and safety | Bonvih</title>
          </Helmet>
          <PageSection
            title="Privacy and safety"
            backButtonEnabled
            backgroundColor
          >
            <Box
              sx={{
                paddingBottom: '12px',
                backgroundColor: '#FFFFFF',
                borderRadius,
              }}
            >
              <MyList>
                <MyListItem
                  secondaryAction={
                    <Switch
                      inputProps={{ 'aria-label': 'Switch demo' }}
                      disabled
                      defaultChecked
                      onChange={() => {
                        updatePrivacySettings(
                          privacySettings as PrivacySettings,
                        );
                      }}
                    />
                  }
                >
                  <MyListItemText>
                    <Typography variant="body1">Strictly necessary</Typography>
                  </MyListItemText>
                </MyListItem>
                <Box padding="0 16px" marginTop="-4px" marginBottom="16px">
                  <Typography variant="body2" sx={{ color: colors.grey }}>
                    We work with a select number of trusted partners to provide
                    us with services that help ensure our platforms function as
                    expected. When using the Bonvih platform we will share data
                    about your activities with these platforms to carry out
                    essential services such as logging in or saving settings and
                    minimise any bugs or crashes.
                  </Typography>
                </Box>
                {/* <MyListItem
                  secondaryAction={
                    <Switch
                      inputProps={{ 'aria-label': 'Switch demo' }}
                      checked={
                        (privacySettings as PrivacySettings)
                          .personalisedAdsFromBonvih
                      }
                      onChange={(e) => {
                        updatePrivacySettings({
                          ...(privacySettings as PrivacySettings),
                          personalisedAdsFromBonvih: e.target.checked,
                        });
                      }}
                    />
                  }
                >
                  <MyListItemText>
                    <Typography variant="body1">
                      Personalised ads from Bonvih
                    </Typography>
                  </MyListItemText>
                </MyListItem>
                <Box padding="0 16px" marginTop="-4px" marginBottom="16px">
                  <Typography variant="body2" sx={{ color: colors.grey }}>
                    We work with partners we trust to help promote the Bonvih
                    platform and services to you. By accepting, you consent to
                    us sharing data based on your online activity with these
                    partners to show you personalised ads for Bonvih elsewhere
                    online. If you don&apos;t consent, you may still see ads
                    from Bonvih but they may not be as relevant or useful.
                  </Typography>
                </Box>
                <MyListItem
                  secondaryAction={
                    <Switch
                      inputProps={{ 'aria-label': 'Switch demo' }}
                      checked={
                        (privacySettings as PrivacySettings)
                          .personalisedAdsOnBonvih
                      }
                      onChange={(e) => {
                        updatePrivacySettings({
                          ...(privacySettings as PrivacySettings),
                          personalisedAdsOnBonvih: e.target.checked,
                        });
                      }}
                    />
                  }
                >
                  <MyListItemText>
                    <Typography variant="body1">
                      Personalised ads on Bonvih
                    </Typography>
                  </MyListItemText>
                </MyListItem>
                <Box padding="0 16px" marginTop="-4px" marginBottom="16px">
                  <Typography variant="body2" sx={{ color: colors.grey }}>
                    We work with partners we trust to show you ads for things we
                    think you&apos;ll like. By accepting, you consent to us
                    sharing data based on your online activity with these
                    partners to show you personalised ads in the Bonvih app and
                    elsewhere online. If you don&apos;t consent, you&apos;ll
                    still see ads but they may not be as relevant or useful.
                  </Typography>
                </Box> */}
              </MyList>
            </Box>
          </PageSection>
        </Box>
      )}
    </Box>
  );
};

export default PrivacyNSafety;
