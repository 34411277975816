import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import useFetch from 'use-http';
import { Action } from '../../store/types';
import { Profile } from '../../types/Profile';
import { BASE_URL } from '../../constants';
import EditProfileDialog from './EditProfileDialog';

type EditProfileDialogContainerProps = {
  profile: Profile;
  open: boolean;
  callback?: () => void;
  onClose: () => void;
};

const EditProfileDialogContainer = ({
  profile,
  open,
  callback,
  onClose,
}: EditProfileDialogContainerProps): JSX.Element => {
  const dispatch = useDispatch<Dispatch<Action>>();

  const { put, cache, error, response } = useFetch(BASE_URL);

  async function saveProfile(updated: Profile) {
    await put(`/profiles/${profile.ID}`, updated);

    if (response.ok) {
      dispatch({
        type: 'SAVE_PROFILE',
        payload: updated,
      });

      localStorage.setItem('profile', JSON.stringify(updated));
      cache.clear();
      if (callback) {
        callback();
      }
      onClose();
    }
  }

  function handleSaveButtonClick(firstname: string) {
    saveProfile({
      ...profile,
      firstname,
    });
  }

  return (
    <EditProfileDialog
      open={open}
      onClose={onClose}
      profile={profile}
      handleSaveButtonClick={handleSaveButtonClick}
      error={Boolean(error)}
    />
  );
};

EditProfileDialogContainer.defaultProps = {
  callback: undefined,
};

export default EditProfileDialogContainer;
