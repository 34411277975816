import { Account } from '../types/Account';
import { Profile } from '../types/Profile';
import profilesSample from './profiles';

const accounts: Account[] = [
  {
    ID: "676873123",
    email: 'toundaherve@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Herve') as Profile,
  },
  {
    ID: "671830098484",
    email: 'ctounda@hotmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Sonia') as Profile,
  },
  {
    ID: "83081913",
    email: 'patricia1_n@outlook.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Patou') as Profile,
  },
  {
    ID: "31688183813",
    email: 'divinaessomba@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Divine') as Profile,
  },
  {
    ID: "86237919313",
    email: 'andretounda@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Andre') as Profile,
  },
  {
    ID: "938482424",
    email: 'jumbo@live.co.uk',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Jean-Yves') as Profile,
  },
  {
    ID: "424124114",
    email: 'meghan@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Meghan') as Profile,
  },
  {
    ID: "8617391391",
    email: 'emma101965@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Emma') as Profile,
  },
  {
    ID: "90893271",
    email: 'sarah@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Sarah') as Profile,
  },
  {
    ID: "1386813",
    email: 'james@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'James') as Profile,
  },
  {
    ID: "7647242",
    email: 'fiona@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Fiona') as Profile,
  },
  {
    ID: "4441927346",
    email: 'eric@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Eric') as Profile,
  },
  {
    ID: "494892042",
    email: 'frank@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Frank') as Profile,
  },
  {
    ID: "247892421",
    email: 'max@gmail.com',
    password: '123456789',
    profile: profilesSample.find((p) => p.firstname === 'Max') as Profile,
  },
];

export default accounts;
