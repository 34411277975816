export const values = [
  1,

  2,

  3,

  4,

  5,

  7,

  10,

  15,

  20,

  30,

  60,

  100,

  300,

  400,

  500,

  1000,

  -1,
];

export function valuetext(value: number): string {
  return `${value}`;
}

export function valueLabelFormat(value: number): string {
  if (value < 0) return 'Everywhere';
  return `${value} mi`;
}

export function calculateValue(value: number): number {
  return values[value - 1];
}
