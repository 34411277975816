type LogMessage = {
  message: string;
  reason?: string;
  error?: Error | string;
};

// eslint-disable-next-line
async function logForProduction(message: LogMessage) {
  await fetch(
    'https://jyqg4a2r25.execute-api.eu-west-2.amazonaws.com/default/logFrontendAppMessage',
    {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'no-cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(message), // body data type must match "Content-Type" header
    },
  );
}

// eslint-disable-next-line
function log(message: LogMessage): void {
  if (process.env.NODE_ENV === 'production') {
    logForProduction(message);
  } else {
    console.log(message); // eslint-disable-line
  }
}

const logger = { log };

export default logger;
