import React, { useState } from 'react';
import useSearchNeeds from '../../hooks/useSearchNeeds';
import { CategoryNode } from '../../utils/categories';
import TabView from './TabView';
import { getQueryParamFromSortOption } from './sortOptions';
import { calculateValue } from './miles';
import { BiasPoint } from './LocationFilter';

type TabViewContainerProps = {
  searchedItemTitle: string;
  minAmount: number;
  maxAmount: number;
  categoryNode: CategoryNode;
  sortOption: string;
  radiusIndex?: number;
  radiusOn?: boolean;
  biasPoint?: BiasPoint | null;
};

const TabViewContainer = ({
  searchedItemTitle,
  minAmount,
  maxAmount,
  categoryNode,
  sortOption,
  radiusIndex = 17,
  radiusOn = false,
  biasPoint,
}: TabViewContainerProps): JSX.Element => {
  const [firstLoadingDone, setFirstLoadingDone] = useState(false);

  function buildCategoryName(): string {
    let categoryName = categoryNode.name;
    let node = categoryNode.parent;

    while (node !== null) {
      categoryName = `${node.name}${
        node.name !== '' ? ' > ' : ''
      }${categoryName}`;
      node = node.parent;
    }

    return categoryName;
  }

  const categoryFullName = buildCategoryName();

  const { needs, loading, error, searchMore, hasMore } = useSearchNeeds(
    {
      title: searchedItemTitle,
      minAmount,
      maxAmount,
      category: encodeURIComponent(categoryFullName),
      orderBy: encodeURIComponent(getQueryParamFromSortOption(sortOption)),
      radius: calculateValue(radiusIndex),
      biasPoint,
      limit: 10,
    },
    [sortOption, radiusIndex, biasPoint],
  );

  if (loading && !firstLoadingDone) {
    setFirstLoadingDone(true);
  }

  return (
    <TabView
      needs={needs}
      loading={loading}
      firstLoadingDone={firstLoadingDone}
      more={hasMore}
      error={error}
      searchedItemTitle={searchedItemTitle}
      handleFetchMore={searchMore}
      radiusIndex={radiusIndex}
      radiusOn={radiusOn}
    />
  );
};

TabViewContainer.defaultProps = {
  radiusIndex: 17,
  radiusOn: false,
  biasPoint: null,
};

export default TabViewContainer;
