import React from 'react';
import { List, ListProps } from '@mui/material';

const MyList = (props: ListProps): JSX.Element => (
  <List
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    disablePadding
  />
);

export default MyList;
