import React from 'react';
import { Box } from '@mui/system';
import { useHistory } from 'react-router';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import { Proposition } from '../../types/Proposition';
import { getDateAndTime } from '../../utils/date';
import useDialogsManager from '../../hooks/useDialogsManager';
import MyIconButton from '../MyIconButton';
import { MyCard, MyCardHeader, MyCardContent } from './MyCards';
import ImageGallery from './ImageGallery';
import ProposingTo from './ProposingTo';
import { CTA, iconStyles } from './NeedCard';
import { colors, divider } from '../../theme/theme';

type PropositionCardProps = {
  proposition: Proposition;
  iAmProposer: boolean;
  iAmNeeder: boolean;
  handleNotInterested: () => void;
};

const PropositionCard = ({
  proposition,
  iAmProposer,
  iAmNeeder,
  handleNotInterested,
}: PropositionCardProps): JSX.Element => {
  const history = useHistory();

  const { item } = proposition;
  const { openChatDialog } = useDialogsManager();

  const ctas = (
    <Grid container>
      <Grid item xs>
        <CTA
          Icon={() => (
            <MyIconButton>
              <ChatBubbleOutlineIcon sx={iconStyles} />
            </MyIconButton>
          )}
          Text={() => (
            <Typography
              variant="button"
              sx={{ color: `${colors.grey} !important` }}
            >
              Chat
            </Typography>
          )}
          onClick={() => {
            if (openChatDialog) {
              openChatDialog(proposition);
            }
          }}
        />
      </Grid>
      {iAmNeeder && (
        <Grid item xs>
          <CTA
            Icon={() => (
              <MyIconButton>
                <NotInterestedOutlinedIcon sx={iconStyles} />
              </MyIconButton>
            )}
            Text={() => (
              <Typography
                variant="button"
                sx={{ color: `${colors.grey} !important` }}
              >
                Not interested
              </Typography>
            )}
            onClick={handleNotInterested}
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {/* <CardTitle colorBlack>{item.name}</CardTitle> */}
      <MyCard>
        <MyCardHeader
          title={
            <Typography variant="h6" variantMapping={{ h6: 'div' }}>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/profile/${proposition.item.profile.ID}`);
                }}
                sx={{
                  display: 'inline-block',
                  cursor: 'pointer !important',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <span className="card-username">
                  {item.profile.firstname} <br />
                </span>
              </Box>
            </Typography>
          }
        />
        <MyCardContent>
          <Box sx={{ marginTop: '12px' }} />
          <Grid container columnSpacing={{ xs: 0, md: 0 }}>
            <Grid item xs={12} md={12}>
              <Box sx={{ marginBottom: '12px' }}>
                <ProposingTo proposition={proposition} />
              </Box>
              <Box marginBottom="12px">
                <Typography
                  variant="h4"
                  variantMapping={{ h4: 'p' }}
                  sx={{ fontWeight: 'normal' }}
                >
                  {item.description}
                </Typography>
              </Box>

              <Box>
                <ImageGallery photos={item.photos} />
              </Box>

              <Box margin="16px 0">
                <Typography variant="body1">
                  {getDateAndTime(proposition.CreatedAt)}
                </Typography>
              </Box>
            </Grid>

            {(iAmProposer || iAmNeeder) && (
              <Grid item xs={12} md={12}>
                <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                  <Divider sx={{ borderColor: divider.color }} />
                  {ctas}
                  <Box marginBottom="-12px" />
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'none',
                      borderTop: `1px solid ${colors.grey}`,
                      borderBottom: `1px solid ${colors.grey}`,
                    },
                  }}
                >
                  {ctas}
                </Box>
              </Grid>
            )}
          </Grid>
        </MyCardContent>
      </MyCard>
    </>
  );
};

export default PropositionCard;
