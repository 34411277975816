const API_SERVER_HOST = process.env.REACT_APP_BONVIH_API_HOST;
const CHAT_SERVER_HOST = process.env.REACT_APP_CHAT_SERVER_HOST;
export const GEOIP_ENDPOINT = process.env.REACT_APP_GEOIP_ENDPOINT;
export const AWS_COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION;
export const AWS_LOCATION_INDEX = process.env.REACT_APP_AWS_LOCATION_INDEX;
export const AWS_LOCATION_REGION = process.env.REACT_APP_AWS_LOCATION_REGION;
export const AWS_S3_REGION = process.env.REACT_APP_AWS_S3_REGION;
export const AWS_S3_ITEM_PHOTOS_BUCKET_NAME =
  process.env.REACT_APP_AWS_S3_ITEM_PHOTOS_BUCKET_NAME;

let SCHEME = 'http';
if (process.env.NODE_ENV === 'production') {
  SCHEME = 'https';
}

export const BASE_URL = `${SCHEME}://${API_SERVER_HOST}`;
export const CHAT_SERVER_URL = `${SCHEME}://${CHAT_SERVER_HOST}`;

export function environmentVariablesOK(): boolean {
  if (
    !BASE_URL ||
    !CHAT_SERVER_URL ||
    !GEOIP_ENDPOINT ||
    !AWS_COGNITO_IDENTITY_POOL_ID ||
    !AWS_COGNITO_REGION ||
    !AWS_LOCATION_INDEX ||
    !AWS_LOCATION_REGION ||
    !AWS_S3_REGION ||
    !AWS_S3_ITEM_PHOTOS_BUCKET_NAME
  )
    return false;

  return true;
}

export default {};
