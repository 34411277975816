import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { Need } from '../../../types/Need';
import { Profile } from '../../../types/Profile';
import { BASE_URL } from '../../../constants';
import Fulfilled from './Fulfilled';

type FulfilledContainerProps = {
  profile: Profile;
  itsMe: () => boolean;
};

const FulfilledContainer = ({
  profile,
  itsMe,
}: FulfilledContainerProps): JSX.Element => {
  const [needs, setNeeds] = useState<Need[] | null>(null);
  const { get, loading, error, response } = useFetch(BASE_URL);

  async function getMyNeeds() {
    const data = await get(`/profiles/${profile.ID}/spent`);

    if (response.ok) {
      setNeeds(data);
    }
  }

  useEffect(() => {
    getMyNeeds();
  }, []);

  return (
    <Fulfilled
      needs={needs}
      loading={loading}
      error={!!error}
      itsMe={itsMe}
      profile={profile}
    />
  );
};

export default FulfilledContainer;
