import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useFetch from 'use-http';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import MyTextField from '../../components/MyTextField';
import Spinner from '../../components/Spinner';
import MyCloseIcon from '../../components/MyCloseIcon';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { SignupData } from '../../types/SignupData';
import { BASE_URL } from '../../constants';
import { dialog } from '../../theme/theme';

type FormValues = {
  firstname: string;
  email: string;
};

type SignupDialogProps = {
  goNext: () => void;
  onClose: () => void;
};

const SignupDialog = ({ goNext, onClose }: SignupDialogProps): JSX.Element => {
  const [emailAvailability, setEmailAvailability] = useState({
    availabilityChecked: false,
    emailTaken: false,
  });

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const { control, formState, getValues, setValue, trigger } =
    useForm<FormValues>({
      mode: 'onChange',
    });
  const { errors, isDirty, isValid } = formState;

  const { post, loading, error, response } = useFetch(BASE_URL);

  const {
    get: emailTakenGet,
    error: emailTakenError,
    response: emailTakenResponse,
  } = useFetch(BASE_URL);

  function getSignupDataFromCache(): SignupData | null {
    const signupData = localStorage.getItem('signupData');
    if (signupData === null) {
      return null;
    }

    return JSON.parse(signupData);
  }

  function storeSignupDataInCache(data: SignupData) {
    localStorage.setItem('signupData', JSON.stringify(data));
  }

  async function sendSignupData(data: SignupData) {
    await post('/onboarding', data);
    if (response.ok) {
      storeSignupDataInCache(data);

      const now = new Date();
      const expiration = now.getTime() + 2 * 60 * 60 * 1000; // 2 hours
      localStorage.setItem('signupDataExpiration', JSON.stringify(expiration));

      goNext();
    }
  }

  async function checkEmailTaken(email: string) {
    const data = await emailTakenGet(
      `/accounts/email_available?email=${email}`,
    );
    if (emailTakenResponse.ok)
      setEmailAvailability({
        availabilityChecked: true,
        emailTaken: !(data as { available: boolean }).available,
      });
  }

  function emailValid(email: string): boolean {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    );
  }

  function startTimeout(callback: () => void) {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(callback, 750);
  }

  function restoreValues() {
    const signupData = getSignupDataFromCache();
    if (signupData !== null) {
      const inputs = signupData.steps.find(
        (step) => step.name === 'create_account',
      )?.inputs;
      setValue('firstname', inputs.firstname, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('email', inputs.email, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setEmailAvailability({
        availabilityChecked: true,
        emailTaken: false,
      });
    }
  }

  function generateFlowToken(): string {
    return uuidv4();
  }

  function buildSignupData(): SignupData {
    let signupData = getSignupDataFromCache();

    let { firstname } = getValues();
    firstname = firstname.trim();

    if (signupData === null) {
      signupData = {
        token: generateFlowToken(),
        steps: [
          {
            name: 'create_account',
            inputs: { ...getValues(), firstname },
          },
        ],
      };
    } else {
      signupData.steps = [
        {
          name: 'create_account',
          inputs: { ...getValues(), firstname },
        },
      ];
    }
    return signupData;
  }

  function handleEmailChange(value: string) {
    if (emailValid(value)) checkEmailTaken(value);
  }

  function handleNextClick() {
    const signupData = buildSignupData();
    sendSignupData(signupData);
  }

  function handleCloseIconClick() {
    onClose();
  }

  useEffect(() => {
    restoreValues();
  }, []);

  return (
    <>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography variant="h5">Create your account</Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              disabled={
                !isDirty ||
                !isValid ||
                !emailAvailability.availabilityChecked ||
                emailAvailability.emailTaken
              }
              onClick={handleNextClick}
            >
              Next
            </MyButton>
          </Box>
        )}
      />

      {loading && !error && !emailTakenError && <Spinner />}

      {error && !loading && !emailTakenError && <SomethingWentWrongFeedback />}

      {!error && !loading && !emailTakenError && (
        <>
          <Box sx={{ padding: dialog.input.padding }}>
            <Controller
              control={control}
              name="firstname"
              defaultValue=""
              rules={{
                required: 'Name is required',
                maxLength: {
                  value: 50,
                  message: 'Too many characters... Limit is 50',
                },
              }}
              render={({ field }) => (
                <MyTextField
                  id="input-firstname"
                  label="Name"
                  error={!!errors.firstname}
                  helperText={errors.firstname && errors.firstname.message}
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
          </Box>

          <Box sx={{ padding: dialog.input.padding }}>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              rules={{
                required: "What's your email?",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email.',
                },
              }}
              render={({ field }) => (
                <MyTextField
                  id="input-email"
                  label="Email"
                  error={!!errors.email || emailAvailability.emailTaken}
                  helperText={
                    (errors.email && errors.email.message) ||
                    (emailAvailability.emailTaken &&
                      'Email has already been taken.')
                  }
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setValue('email', value, {
                      shouldValidate: false,
                      shouldDirty: false,
                    });
                    startTimeout(() => {
                      trigger('email').then((ok) => {
                        if (ok) {
                          handleEmailChange(value as string);
                        }
                      });
                    });
                  }}
                />
              )}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default SignupDialog;
