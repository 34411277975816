import React, { useState } from 'react';
import useFetch from 'use-http';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import { MyPasswordField } from '../../components/MyTextField';
import Spinner from '../../components/Spinner';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { PasswordResetData } from '../../types/PasswordResetData';
import { BASE_URL } from '../../constants';
import { dialog, colors } from '../../theme/theme';
import MyCloseIcon from '../../components/MyCloseIcon';
import logger from '../../logger';

type FormValues = {
  password1: string;
  password2: string;
};

type ResetPasswordProps = {
  goNext: () => void;
  onClose: () => void;
};

const ResetPassword = ({
  goNext,
  onClose,
}: ResetPasswordProps): JSX.Element => {
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;

  const { post, loading, error, response } = useFetch(BASE_URL);

  function getPasswordResetDataFromCache(): PasswordResetData | null {
    const passwordResetData = localStorage.getItem('passwordResetData');
    if (passwordResetData === null) {
      return null;
    }

    return JSON.parse(passwordResetData);
  }

  function storePasswordResetDataInCache(data: PasswordResetData) {
    localStorage.setItem('passwordResetData', JSON.stringify(data));
  }

  async function sendPasswordResetData(data: PasswordResetData) {
    await post('/password_reset', data);
    if (response.ok) {
      storePasswordResetDataInCache(data);
      goNext();
    }
  }

  function buildPasswordResetData(): PasswordResetData {
    const passwordResetData = getPasswordResetDataFromCache();
    if (passwordResetData === null) {
      logger.log({
        message: 'Failed to reset password during Password Reset',
        reason: 'No password reset data in localstorage',
      });
      throw new Error('');
    }

    passwordResetData.steps.push({
      name: 'reset_password',
      inputs: {
        password: getValues().password1,
      },
    });

    return passwordResetData;
  }

  function handleNextClick() {
    const { password1, password2 } = getValues();

    if (password1 !== password2) {
      setPasswordMismatch(true);
      return;
    }

    const passwordResetData = buildPasswordResetData();
    sendPasswordResetData(passwordResetData);
  }

  function handleCloseIconClick() {
    onClose();
  }

  return (
    <>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography
            variant="h5"
            sx={{
              textOverflow: 'ellipsis',
            }}
          >
            Reset your password
          </Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              disabled={!isDirty || !isValid}
              onClick={handleNextClick}
            >
              Reset
            </MyButton>
          </Box>
        )}
      />

      {loading && !error && <Spinner />}

      {error && !loading && <SomethingWentWrongFeedback />}

      {!error && !loading && (
        <>
          <Box sx={{ padding: dialog.input.padding }}>
            <Typography
              variant="body1"
              sx={{
                color: colors.grey,
              }}
            >
              Make sure it&apos;s 8 characters or more. Strong passwords include
              numbers, letters, and punctuation marks.
            </Typography>
          </Box>

          <Box sx={{ padding: dialog.input.padding }}>
            <Controller
              control={control}
              name="password1"
              rules={{
                required: true,
                minLength: 8,
                maxLength: 64,
              }}
              defaultValue=""
              render={({ field }) => (
                <MyPasswordField
                  id="password-input"
                  label="Enter your new password"
                  // type="password"
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
          </Box>
          <Box sx={{ padding: dialog.input.padding }}>
            <Controller
              control={control}
              name="password2"
              rules={{
                required: true,
                minLength: 8,
                maxLength: 64,
              }}
              defaultValue=""
              render={({ field }) => (
                <MyPasswordField
                  id="password-input"
                  label="Enter your password one more time"
                  error={passwordMismatch}
                  // type="password"
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
            {passwordMismatch && (
              <FormHelperText error>Passwords do not match.</FormHelperText>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default ResetPassword;
