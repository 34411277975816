import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import MyDialog from '../../components/MyDialog';
import FindAccount from './FindAccount';
import CheckEmail from './CheckEmail';
import ResetPassword from './ResetPassword';
import { PasswordResetData, Step } from '../../types/PasswordResetData';
import Spinner from '../../components/Spinner';

type PasswordResetDialogProps = {
  open: boolean;
  onClose: () => void;
};

const PasswordResetDialog = ({
  open,
  onClose,
}: PasswordResetDialogProps): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState<Step['name'] | null>(null);

  function lastCompletedStep(data: PasswordResetData): Step['name'] {
    if (data.steps.some((step) => step.name === 'reset_password')) {
      return 'reset_password';
    }
    if (data.steps.some((step) => step.name === 'check_email')) {
      return 'check_email';
    }
    return 'find_account';
  }

  function getPasswordResetDataFromCache(): PasswordResetData | null {
    const passwordResetData = localStorage.getItem('passwordResetData');
    if (passwordResetData === null) {
      return null;
    }

    return JSON.parse(passwordResetData);
  }

  function removePasswordResetDataFromCache() {
    localStorage.removeItem('passwordResetData');
  }

  function resume() {
    const passwordResetData = getPasswordResetDataFromCache();

    if (passwordResetData === null) {
      setCurrentStep('find_account');
    } else {
      const lastCompleted = lastCompletedStep(passwordResetData);
      if (lastCompleted === 'find_account') {
        setCurrentStep('check_email');
      } else if (lastCompleted === 'check_email') {
        setCurrentStep('reset_password');
      }
    }

    setLoading(false);
  }

  function goNext() {
    if (currentStep === 'find_account') {
      setCurrentStep('check_email');
      return;
    }
    if (currentStep === 'check_email') {
      setCurrentStep('reset_password');
      return;
    }
    if (currentStep === 'reset_password') {
      removePasswordResetDataFromCache();
      history.push('/login');
      onClose();
    }
  }

  useEffect(() => {
    resume();
  }, []);

  return (
    <MyDialog open={open} onClose={onClose}>
      {loading && <Spinner />}
      {currentStep === 'find_account' && (
        <FindAccount goNext={goNext} onClose={onClose} />
      )}
      {currentStep === 'check_email' && (
        <CheckEmail goNext={goNext} onClose={onClose} />
      )}
      {currentStep === 'reset_password' && (
        <ResetPassword goNext={goNext} onClose={onClose} />
      )}
    </MyDialog>
  );
};

export default PasswordResetDialog;
