import React, { useState } from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import MyAppBar from '../MyAppBar';
import AppBarIconWrapper from '../AppBarIconWrapper';
import MyIconButton from '../MyIconButton';
import MyDialog from '../MyDialog';
import MyButton from '../MyButton';
import MyCloseIcon from '../MyCloseIcon';
import MyTextField from '../MyTextField';
import { dialog, icon } from '../../theme/theme';

type SearchMoneyByProductDialogProps = {
  open: boolean;
  onClose: () => void;
  callback?: () => void;
};

const SearchMoneyByProductDialog = ({
  open,
  onClose,
  callback,
}: SearchMoneyByProductDialogProps): JSX.Element => {
  const [value, setValue] = useState('');
  const [inputFocused, setInputFocused] = useState<boolean | undefined>(
    undefined,
  );

  function handleCloseIconClick() {
    onClose();
  }

  function handleSubmitButtonClick() {
    window.location.assign(
      `/search?minAmount=0&maxAmount=&title=${value.trim()}&orderBy=`,
    );

    if (callback) {
      callback();
    }
    onClose();
  }

  return (
    <MyDialog open={open} onClose={handleCloseIconClick}>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography variant="h5">Search by product</Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              color="primary"
              onClick={handleSubmitButtonClick}
              disabled={value === ''}
            >
              Search
            </MyButton>
          </Box>
        )}
      />

      <Box className="SearchMoneyByProductDialog">
        <Box sx={{ padding: dialog.input.padding }}>
          <MyTextField
            value={value}
            label="Enter product's name"
            onChange={(e) => setValue(e.target.value)}
            focused={inputFocused}
            InputProps={{
              inputRef: (input) => input && inputFocused && input.focus(),
              endAdornment: value ? (
                <Box marginRight="-8px">
                  <MyIconButton
                    onClick={() => {
                      setValue('');
                      setInputFocused(true);
                    }}
                  >
                    <CloseIcon
                      sx={{ fontSize: icon.fontSize.medium, color: icon.color }}
                    />
                  </MyIconButton>
                </Box>
              ) : undefined,
            }}
          />
        </Box>
      </Box>
    </MyDialog>
  );
};

SearchMoneyByProductDialog.defaultProps = {
  callback: undefined,
};

export default SearchMoneyByProductDialog;
