import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetch from 'use-http';
import { PrivacySettings } from '../../types/PrivacySettings';
import { Profile } from '../../types/Profile';
import { State } from '../../store/types';
import { BASE_URL } from '../../constants';
import PrivacyNSafety from './PrivacyNSafety';

const PrivacyNSafetyContainer = (): JSX.Element => {
  const [reload, setReload] = useState(false);
  const profile = useSelector<State, Profile | null>((state) => state.profile);
  const [privacySettings, setPrivacySettings] =
    useState<PrivacySettings | null>(null);

  const { get, put, cache, response, loading, error } =
    useFetch<PrivacySettings>(BASE_URL);

  async function getPrivacySettings() {
    const data = await get(`/profiles/${profile?.ID}/privacy_settings`);
    if (response.ok) {
      setPrivacySettings(data);
    }
  }

  async function updatePrivacySettings(updated: PrivacySettings) {
    await put(`/profiles/${profile?.ID}/privacy_settings`, updated);
    if (response.ok) {
      cache.clear();
      setReload(!reload);
    }
  }

  useEffect(() => {
    getPrivacySettings();
  }, [reload]);

  return (
    <PrivacyNSafety
      privacySettings={privacySettings}
      loading={loading}
      error={Boolean(error)}
      updatePrivacySettings={updatePrivacySettings}
    />
  );
};

export default PrivacyNSafetyContainer;
