import { createContext } from 'react';

export type SecurityType = {
  loggedIn: boolean;
  updateLoggedIn?: (loggedIn: boolean) => void;
};

const securityContext = createContext<SecurityType>({
  loggedIn: false,
});

export default securityContext;
