import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import getArticle from '../../utils/getArticle';
import { getDateAndTime } from '../../utils/date';
import { Need } from '../../types/Need';
import { Item } from '../../types/Item';
import MyIconButton from '../MyIconButton';
import { State } from '../../store/types';
import { Profile } from '../../types/Profile';
import useSecurity from '../../hooks/useSecurity';
import useMyPropositions from '../../hooks/useMyPropositions';
import { card, colors, icon } from '../../theme/theme';
import formatMoney from '../../utils/formatMoney';
import { MyCard, MyCardHeader, MyCardContent } from './MyCards';
import Hashtag from './Hashtag';
import {
  formatLocation,
  ButtonHover,
  YouTookIt,
  Taken,
} from './SearchNeedCard';

export const iconStyles = {
  fontSize: icon.fontSize.large,
  color: colors.grey,
};

export const CTA = ({
  Icon,
  Text,
  onClick,
}: {
  Icon: React.FunctionComponent;
  Text: React.FunctionComponent;
  onClick?: () => void | undefined;
}): JSX.Element => (
  <Box
    sx={{
      height: '48px',
      minHeight: '48px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box sx={{ cursor: 'pointer' }} onClick={onClick}>
        <ButtonHover>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ marginRight: '4px' }}>
              <Icon />
            </Box>
            <Text />
          </Box>
        </ButtonHover>
      </Box>
    </Box>
  </Box>
);

CTA.defaultProps = {
  onClick: undefined,
};

type NeedCardProps = {
  need: Need;
  handleProposeClick: () => void;
  handleEditClick: () => void;
  handleFulfilledClick: () => void;
};

const NeedCard = ({
  need,
  handleProposeClick,
  handleEditClick,
  handleFulfilledClick,
}: NeedCardProps): JSX.Element => {
  const history = useHistory();

  const { loggedIn } = useSecurity();
  const { propositions } = useMyPropositions();
  const me = useSelector<State, Profile>((state) => state.profile as Profile);
  const { description, offer, currency, fulfilled, UpdatedAt: updated } = need;

  function itsMe(): boolean {
    if (!loggedIn) return false;
    return need.profile.ID === me.ID;
  }

  const proposedItems: Item[] = [];

  if (propositions) {
    propositions.forEach((proposition) => {
      if (proposition.need.ID === need.ID) {
        proposedItems.push(proposition.item);
      }
    });
  }

  const ctas = (
    <>
      {!itsMe() && !fulfilled && (
        <CTA
          Icon={() => (
            <MyIconButton>
              <ReplyOutlinedIcon sx={iconStyles} />
            </MyIconButton>
          )}
          Text={() => (
            <Typography variant="button" sx={{ color: colors.grey }}>
              Accept
            </Typography>
          )}
          onClick={handleProposeClick}
        />
      )}
      {itsMe() && (
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <CTA
              Icon={() => (
                <MyIconButton>
                  <EditOutlinedIcon sx={iconStyles} />
                </MyIconButton>
              )}
              Text={() => (
                <Typography variant="button" sx={{ color: colors.grey }}>
                  Edit
                </Typography>
              )}
              onClick={handleEditClick}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: { xs: 'none', sm: 'none', md: 'none' } }}
            md={12}
          >
            <Divider />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <CTA
              Icon={() => (
                <MyIconButton>
                  {fulfilled ? (
                    <ThumbDownOutlinedIcon sx={iconStyles} />
                  ) : (
                    <ThumbUpOutlinedIcon sx={iconStyles} />
                  )}
                </MyIconButton>
              )}
              Text={() => (
                <Typography variant="button" sx={{ color: colors.grey }}>
                  {fulfilled ? 'Not taken' : 'Taken'}
                </Typography>
              )}
              onClick={handleFulfilledClick}
            />
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <>
      {/* <CardTitle>{formatMoney(offer, currency)}</CardTitle> */}
      <MyCard>
        {proposedItems.length > 0 && (
          <Box marginBottom="4px">
            <YouTookIt items={proposedItems} />
          </Box>
        )}
        {fulfilled && (
          <Box marginBottom="4px">
            <Taken />
          </Box>
        )}
        <MyCardHeader
          title={
            <Typography variant="h6" variantMapping={{ h6: 'div' }}>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/profile/${need.profile.ID}`);
                }}
                sx={{
                  display: 'inline-block',
                  cursor: 'pointer !important',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <span className="card-username">{need.profile.firstname}</span>
              </Box>
            </Typography>
          }
          subheader={formatLocation(need.geocode)}
        />
        <MyCardContent>
          <Box sx={{ marginTop: '12px' }} />
          <Grid container columnSpacing={{ xs: 0, md: 0 }}>
            <Grid item xs={12} md={12}>
              <Box sx={{ marginBottom: '12px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: `${colors.grey} !important`,
                  }}
                >
                  💸 Offering {formatMoney(offer, currency)} for{' '}
                  {getArticle(need.title)} <Hashtag need={need} />
                </Typography>
              </Box>
              <Typography
                variant="h4"
                variantMapping={{ h4: 'p' }}
                sx={{ fontWeight: 'normal' }}
              >
                {description}
              </Typography>
              <Box margin="16px 0">
                <Typography variant="body1">
                  {getDateAndTime(updated)}
                </Typography>
              </Box>
              {!itsMe() && fulfilled && <Box marginTop="-16px" />}
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                {((!itsMe() && !fulfilled) || itsMe()) && (
                  <Divider sx={{ borderColor: card.border.color }} />
                )}
                {ctas}
                <Box marginBottom="-12px" />
              </Box>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'none',
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                    borderBottom: '1px solid rgba(0,0,0,0.12)',
                  },
                }}
              >
                {ctas}
              </Box>
            </Grid>
          </Grid>
        </MyCardContent>
      </MyCard>
    </>
  );
};

export default NeedCard;
