import React from 'react';
import Divider, { DividerProps } from '@mui/material/Divider';
import { divider } from '../../theme/theme';

const MyDivider = (props: DividerProps): JSX.Element => (
  <Divider
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{ margin: divider.margin, borderColor: '#f7f7f7' }}
    light
  />
);

export default MyDivider;
