import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import MuiLink from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import useSecurity from '../../hooks/useSecurity';
import { footer, social } from '../../theme/theme';

const MyLink = ({ name, url }: { name: string; url: string }) => (
  <Link
    to={url}
    data-testid="legal-link"
    color="inherit"
    style={{
      paddingRight: footer.spacingBetweenLinks,
      margin: footer.link.margin,
      textDecoration: 'none',
      color: '#000000',
    }}
  >
    <Typography variant="body2">{name}</Typography>
  </Link>
);

const MyALink = ({ name, url }: { name: string; url: string }) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    data-testid="legal-link"
    color="inherit"
    style={{
      paddingRight: footer.spacingBetweenLinks,
      margin: footer.link.margin,
      textDecoration: 'none',
      color: '#000000',
    }}
  >
    <Typography variant="body2">{name}</Typography>
  </a>
);

const Footer = (): JSX.Element => {
  const { loggedIn } = useSecurity();
  const theme = useTheme();
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Box sx={{ backgroundColor: '#FFFFFF' }}>
      <Container disableGutters maxWidth={!isLargeDevice ? 'sm' : 'lg'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            padding: footer.padding,
          }}
        >
          <MyLink name="Contact" url="/contact" />
          <MyALink
            name="Terms & Conditions"
            url="https://app.termly.io/document/terms-of-use-for-website/ad9d2c05-745b-40a4-a1fb-f8713445eb71"
          />
          <MyALink
            name="Privacy Policy"
            url="https://app.termly.io/document/privacy-policy/90788b60-5827-4e61-b537-968dcfcba74c"
          />
          <MyALink
            name="Cookie Policy"
            url="https://app.termly.io/document/cookie-policy/cb56b853-b2e2-420d-a1b0-6b8831949084"
          />
          <MyLink
            name="Privacy settings"
            url={loggedIn ? '/settings/privacy-safety' : '/privacy-safety'}
          />
          <MyLink name="© 2022 Bonvih, Inc." url="/" />
        </Box>
      </Container>
      <Divider sx={{ borderColor: footer.border.color }} />
      <Container disableGutters maxWidth={!isLargeDevice ? 'sm' : 'lg'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '12px 0',
          }}
        >
          <MuiLink href="www.facebook.com" underline="none" color="inherit">
            <FacebookIcon
              data-testid="social-icon"
              sx={{
                color: social.icon.color,
              }}
            />
          </MuiLink>

          <Box
            sx={{
              display: 'inline-block',
              marginLeft: '12px',
              marginBottom: '16px',
            }}
          />

          <MuiLink href="www.twitter.com" underline="none" color="inherit">
            <TwitterIcon
              data-testid="social-icon"
              sx={{
                color: social.icon.color,
              }}
            />
          </MuiLink>

          <Box
            sx={{
              display: 'inline-block',
              marginLeft: '12px',
              marginBottom: '16px',
            }}
          />

          <MuiLink href="www.instagram.com" underline="none" color="inherit">
            <InstagramIcon
              data-testid="social-icon"
              sx={{
                color: social.icon.color,
              }}
            />
          </MuiLink>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
