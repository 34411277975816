import React, { useEffect, useState, useRef } from 'react';
import useFetch from 'use-http';
import { Notification } from '../../../types/Notification';
import { Profile } from '../../../types/Profile';
import useBadgeCount from '../../../hooks/useBadgeCount';
import { BASE_URL } from '../../../constants';
import Notifications from './Notifications';

type NotificationsContainerProps = {
  profile: Profile;
};

const NotificationsContainer = ({
  profile,
}: NotificationsContainerProps): JSX.Element => {
  const badgeCount = useBadgeCount();
  const [notifications, setNotifications] = useState<Notification[] | null>(
    null,
  );
  const notificationsRef = useRef<Notification[] | null>(null);
  const [more, setMore] = useState(true);
  const [reload, setReload] = useState(false);
  const reloadRef = useRef(false);

  const LIMIT = 10;
  const MORELIMIT = 5;

  const {
    get: markAsSeenGet,
    cache,
    error: markAsSeenError,
    response: markAsSeenResponse,
  } = useFetch(BASE_URL);

  async function markUnseenNotificationsAsSeen() {
    let unseen: Notification | undefined;

    if (notificationsRef.current) {
      const found = notificationsRef.current.find((n) => n.seen === false);
      if (found) {
        unseen = found;
      }
    }

    if (unseen) {
      await markAsSeenGet(`/profiles/${profile.ID}/notifications_seen`);
      if (markAsSeenResponse.ok) {
        // Update unseen notifications counts in BadgeCount
        cache.clear();
        if (badgeCount.reload) {
          badgeCount.reload();
        }
      }
    }
  }

  const { get, loading, error, response } = useFetch(BASE_URL);

  async function getProfileNotifications() {
    const data = await get(
      `/profiles/${profile.ID}/notifications?limit=${LIMIT}&offset=0`,
    );
    if (response.ok) {
      if (data.length < LIMIT) {
        setMore(false);
      }

      notificationsRef.current = data;
      setNotifications(data);

      markUnseenNotificationsAsSeen();
    }
  }

  useEffect(() => {
    getProfileNotifications();
  }, [reload]);

  useEffect(() => {
    if (badgeCount.onNewNonEmptyNotificationsCount) {
      badgeCount.onNewNonEmptyNotificationsCount(() => {
        setReload(!reloadRef.current);
        reloadRef.current = !reloadRef.current;
      });
    }

    return () => {
      if (badgeCount.clearNewNonEmptyNotificationsCountListeners) {
        badgeCount.clearNewNonEmptyNotificationsCountListeners();
      }
    };
  }, []);

  const {
    get: moreGet,
    error: moreError,
    response: moreResponse,
  } = useFetch(BASE_URL);

  const getMoreNotifications = async () => {
    const data = await moreGet(
      `/profiles/${profile.ID}/notifications?limit=${MORELIMIT}&offset=${
        (notifications as Notification[]).length
      }`,
    );

    if (moreResponse.ok) {
      if (data.length < MORELIMIT) {
        setMore(false);
      }

      if (notifications) {
        notificationsRef.current = [...notifications, ...data];
        setNotifications([...notifications, ...data]);
      }
    }
  };

  function handleGetMoreNotifications() {
    getMoreNotifications();
  }

  return (
    <Notifications
      notifications={notifications}
      loading={loading}
      error={Boolean(error) || Boolean(markAsSeenError) || Boolean(moreError)}
      handleGetMoreNotifications={handleGetMoreNotifications}
      more={more}
    />
  );
};

export default NotificationsContainer;
