import { Proposition } from '../types/Proposition';
import itemsSample from './items';
import needsSample from './needs';
import { Item } from '../types/Item';
import { Need } from '../types/Need';

const propositions: Proposition[] = [
  {
    ID: "8312445",
    done: false,
    seen: false,
    item: itemsSample.find((i) => i.ID === "324294924") as Item,
    need: needsSample.find((n) => n.ID === "4280") as Need,
    CreatedAt: new Date(),
    UpdatedAt: new Date(),
  },
  {
    ID: "833141",
    done: false,
    seen: false,
    item: itemsSample.find((i) => i.ID === "2479244") as Item,
    need: needsSample.find((n) => n.ID === "89348141") as Need,
    CreatedAt: new Date(),
    UpdatedAt: new Date(),
  },
  {
    ID: "3141314",
    done: false,
    seen: false,
    item: itemsSample.find((i) => i.ID === "42792424") as Item,
    need: needsSample.find((n) => n.ID === "1938141") as Need,
    CreatedAt: new Date(),
    UpdatedAt: new Date(),
  },
];

export default propositions;
