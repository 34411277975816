import React, { useState } from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyDialog from '../../components/MyDialog';
import MyButton from '../../components/MyButton';
import MyCloseIcon from '../../components/MyCloseIcon';
import MyTextField from '../../components/MyTextField';
import { dialog, icon } from '../../theme/theme';

type NeedTitleFilterDialogProps = {
  open: boolean;
  onClose: () => void;
  initialValue: string;
  onApply: (value: string) => void;
};

const NeedTitleFilterDialog = ({
  open,
  onClose,
  initialValue,
  onApply,
}: NeedTitleFilterDialogProps): JSX.Element => {
  const [value, setValue] = useState(initialValue);
  const [inputFocused, setInputFocused] = useState<boolean | undefined>(
    undefined,
  );

  function handleCloseIconClick() {
    onClose();
  }

  function handleApplyButtonClick() {
    onApply(value);
  }

  return (
    <MyDialog open={open} onClose={handleCloseIconClick}>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography variant="h5">Advanced search</Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              color="primary"
              onClick={handleApplyButtonClick}
              disabled={value === initialValue}
            >
              Apply
            </MyButton>
          </Box>
        )}
      />

      <Box className="NeedTitleFilterDialog">
        <Box sx={{ padding: dialog.input.padding }}>
          <MyTextField
            value={value}
            label="This exact product or service"
            onChange={(e) => setValue(e.target.value)}
            focused={inputFocused}
            InputProps={{
              inputRef: (input) => input && inputFocused && input.focus(),
              endAdornment: value ? (
                <Box marginRight="-8px">
                  <MyIconButton
                    onClick={() => {
                      setValue('');
                      setInputFocused(true);
                    }}
                  >
                    <CloseIcon
                      sx={{ fontSize: icon.fontSize.medium, color: icon.color }}
                    />
                  </MyIconButton>
                </Box>
              ) : undefined,
            }}
          />
        </Box>
      </Box>
    </MyDialog>
  );
};

export default NeedTitleFilterDialog;
