import React, { useState, ReactNode } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@mui/material';
import useDialogsManager from '../../hooks/useDialogsManager';
import useSecurity from '../../hooks/useSecurity';
import useBadgeCount from '../../hooks/useBadgeCount';
import MyDrawer from '../MyDrawer';
import { Profile } from '../../types/Profile';
import { Need } from '../../types/Need';
import { State } from '../../store/types';
import PageLayout from './PageLayout';

type PageLayoutContainerProps = {
  children: ReactNode;
};

const PageLayoutContainer = ({
  children,
}: PageLayoutContainerProps): JSX.Element => {
  const badgeCount = useBadgeCount();
  const { path } = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();
  const isMediumDeviceUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeDeviceUp = useMediaQuery(theme.breakpoints.up('lg'));
  const { loggedIn } = useSecurity();
  const profile = useSelector<State, Profile | null>((state) => state.profile);
  const { openCreateNewNeedDialog, openLoginDialog, openSearchMoneyDialog } =
    useDialogsManager();
  const [drawerOpen, setDrawerOpen] = useState(false);

  function handleLoginButtonClick() {
    if (openLoginDialog) {
      openLoginDialog();
    }
  }

  function handleMenuIconClick() {
    setDrawerOpen(true);
  }

  function handleNotificationsIconClick() {
    history.push(`/profile/${profile?.ID}/notifications`);
  }

  function handleChatIconClick() {
    history.push(`/profile/${profile?.ID}/chats`);
  }

  function handleCreateNeedButtonClick() {
    if (openCreateNewNeedDialog) {
      openCreateNewNeedDialog((created: Need) =>
        history.push(`/need/${created.ID}`),
      );
    }
  }

  function handleSearchIconClick() {
    if (openSearchMoneyDialog) {
      openSearchMoneyDialog();
    }
  }

  function handleCreateIconClick() {
    handleCreateNeedButtonClick();
  }

  function handleHomeIconClick() {
    history.push('/');
  }

  return (
    <>
      <PageLayout
        loggedIn={loggedIn}
        profile={profile}
        isMediumDeviceUp={isMediumDeviceUp}
        isLargeDeviceUp={isLargeDeviceUp}
        handleMenuIconClick={handleMenuIconClick}
        handleNotificationsIconClick={handleNotificationsIconClick}
        handleChatIconClick={handleChatIconClick}
        handleCreateNeedButtonClick={handleCreateNeedButtonClick}
        handleLoginButtonClick={handleLoginButtonClick}
        handleSearchIconClick={handleSearchIconClick}
        handleHomeIconClick={handleHomeIconClick}
        handleCreateIconClick={handleCreateIconClick}
        badgeCount={badgeCount}
        path={path}
      >
        {children}
      </PageLayout>
      <MyDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
      />
    </>
  );
};

export default PageLayoutContainer;
