import React, { forwardRef, useContext } from 'react';
import NumberFormat from 'react-number-format';
import createNewNeedContext from '../../dialogs/CreateNewNeed/createNewNeedContext';

const currenciesWithCommaAsThousandSeparator = ['GBP', 'USD'];
const currenciesWithSpaceAsThousandSeparator = ['EUR'];

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};
// tslint:disable-next-line
const MyNumberFormat = forwardRef<NumberFormat, CustomProps>((props, ref) => {
  const { onChange, ...other } = props; // eslint-disable-line react/prop-types

  const { currency } = useContext(createNewNeedContext);

  let thousandSeparator: string | boolean = false;

  if (currency) {
    if (currenciesWithCommaAsThousandSeparator.includes(currency)) {
      thousandSeparator = ',';
    } else if (currenciesWithSpaceAsThousandSeparator.includes(currency)) {
      thousandSeparator = ' ';
    }
  }

  return (
    <NumberFormat
      {...other} // eslint-disable-line react/jsx-props-no-spreading
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name, // eslint-disable-line react/prop-types
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      isNumericString
      // decimalScale={2}
      fixedDecimalScale
    />
  );
});

MyNumberFormat.displayName = 'MyNumberFormat';

export default MyNumberFormat;
