import React, { useRef, useState, useEffect } from 'react';
import { buildCategoryTree, CategoryNode } from '../../utils/categories';
import useExploreNeeds from '../../hooks/useExploreNeeds';
import ExplorePage from './ExplorePage';
import {
  FilterByLocationOptions,
  BiasPoint,
} from '../SearchNeedsPage/LocationFilter';
import { calculateValue } from '../SearchNeedsPage/miles';

const ExplorePageContainer = (): JSX.Element => {
  const [selectedCategoryNode, setSelectedCategoryNode] =
    useState<CategoryNode | null>(null);
  const radiusIndexRef = useRef(17);
  const biasPointRef = useRef<BiasPoint | null>(null);
  const radiusOnRef = useRef(false);

  const {
    loading,
    error,
    data = [],
    reload,
  } = useExploreNeeds({
    title: '',
    radius: calculateValue(radiusIndexRef.current),
    biasPoint: biasPointRef.current,
  });

  // const extractCategories = (needs: Need[]): string[] =>
  //   needs.map((need) => need.category).filter((v, i, a) => a.indexOf(v) === i);

  const handleCategoryNodeChange = (selected: CategoryNode) => {
    setSelectedCategoryNode(selected);
  };

  useEffect(() => {
    if (data.length > 0) {
      const rootCategoryNode = buildCategoryTree(
        data.map((need) => need.category),
      );
      setSelectedCategoryNode(rootCategoryNode);
    }
  }, [data]);

  const handleFilterByLocation = (newOptions: FilterByLocationOptions) => {
    radiusIndexRef.current = newOptions.radius;
    if (!radiusOnRef.current) {
      radiusOnRef.current = true;
    }

    biasPointRef.current = newOptions.biasPoint;

    (
      window as {
        scroll: ({ top, left }: { top: number; left: number }) => void;
      }
    ).scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth',
    });

    reload();
  };

  return (
    <ExplorePage
      needs={data}
      selectedCategoryNode={selectedCategoryNode}
      handleCategoryNodeChange={handleCategoryNodeChange}
      handleFilterByLocation={handleFilterByLocation}
      loading={loading}
      error={Boolean(error)}
      biasPoint={biasPointRef.current}
      radiusIndex={radiusIndexRef.current}
      radiusOn={radiusOnRef.current}
    />
  );
};

export default ExplorePageContainer;
