import { useState } from 'react';
import useFetch from 'use-http';
import countries from 'i18n-iso-countries';
import useGeoLocation from './useGeoLocation';
import { BASE_URL } from '../constants';
import { Need } from '../types/Need';
import { BiasPoint } from '../pages/SearchNeedsPage/LocationFilter';

type Props = {
  title: string;
  minAmount?: number;
  maxAmount?: number;
  radius: number;
  biasPoint: BiasPoint | null;
};

const useExploreNeeds = ({
  title = '',
  minAmount = 0,
  maxAmount = -1,
  radius = -1,
  biasPoint = null,
}: Props): {
  data: Need[];
  loading: boolean;
  error: Error | undefined;
  reload: () => void;
} => {
  const [reload, setReload] = useState(false);

  let biasPointLatitude: number;
  let biasPointLongitude: number;
  let biasPointCountryISOCode: string;

  const { countryISOCode, locationLatitude, locationLongitude } =
    useGeoLocation();

  if (!biasPoint) {
    biasPointLatitude = locationLatitude;
    biasPointLongitude = locationLongitude;
    biasPointCountryISOCode = countryISOCode;
  } else {
    biasPointLatitude = biasPoint.latitude;
    biasPointLongitude = biasPoint.longitude;
    biasPointCountryISOCode = biasPoint.countryISOCode;
  }

  if (biasPointCountryISOCode.length === 2) {
    biasPointCountryISOCode = countries.alpha2ToAlpha3(biasPointCountryISOCode);
  }

  const {
    loading,
    error,
    data = [],
  } = useFetch<Need[]>(
    `${BASE_URL}/explore/needs?minAmount=${minAmount}&maxAmount=${maxAmount}&title=${title}&countryISOCode=${biasPointCountryISOCode}&radius=${radius}&locationLatitude=${biasPointLatitude}&locationLongitude=${biasPointLongitude}`,
    {},
    [reload],
  );

  return { data, loading, error, reload: () => setReload(!reload) };
};

export default useExploreNeeds;
