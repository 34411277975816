import React from 'react';
import { Box } from '@mui/system';
import MoneyIcon from '@mui/icons-material/Money';
import { colors, borderRadius } from '../../theme/theme';

const LogoSVG = (): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.primary,
      borderRadius,
      width: '34px',
      height: '34px',
    }}
  >
    <MoneyIcon
      sx={{
        fontSize: '24px',
        color: '#FFFFFF',
      }}
    />
  </Box>
);

export default LogoSVG;
