import React from 'react';
import { Box } from '@mui/system';
import { FcGoogle } from 'react-icons/fc';
import { BsFacebook, BsApple } from 'react-icons/bs';
import {
  GoogleLoginButton,
  AppleLoginButton,
  FacebookLoginButton,
  createButton,
} from 'react-social-login-buttons';
import { BASE_URL } from '../../constants';

const styles = {
  wrapper: {
    padding: '2px 0px',
    '& button>div>div:nth-of-type(1)': {
      minWidth: '0px !important',
    },
    '& button>div>div:nth-of-type(2)': {
      width: '0px !important',
    },
  },
  loginButton: {
    fontSize: '15px',
    borderRadius: '20px',
    border: '1px solid rgba(0,0,0,0.12)',
    boxShadow: 'unset',
    margin: '0px',
    width: '100%',
    height: '40px',
  },
  loginButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const buttonConfig = {
  text: '',
  icon: 'google',
  iconFormat: (name: string) => `fa fa-${name}`,
  style: {
    ...styles.loginButton,
    color: '#000000',
    // fontWeight: 'bold',
    transition: 'background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
  },
  activeStyle: {},
};

const ForgotPasswordButton = createButton(buttonConfig);

type LoginButtonProps = {
  text: string;
  onClick: () => void;
};

export const MyForgotPasswordButton = ({
  text,
  onClick,
}: LoginButtonProps): JSX.Element => (
  <Box sx={styles.wrapper}>
    <ForgotPasswordButton align="center" onClick={onClick}>
      {text}
    </ForgotPasswordButton>
  </Box>
);

const EmailLoginButton = createButton(buttonConfig);

export const MyEmailLoginButton = ({
  text,
  onClick,
}: LoginButtonProps): JSX.Element => (
  <Box sx={styles.wrapper}>
    <EmailLoginButton align="center" onClick={onClick}>
      {text}
    </EmailLoginButton>
  </Box>
);

export const MyGoogleLoginButton = ({
  text,
}: {
  text: string;
}): JSX.Element => (
  <a
    href={`${BASE_URL}/oauth/google`}
    style={{
      textDecoration: 'none',
    }}
  >
    <Box sx={styles.wrapper}>
      <GoogleLoginButton align="center" style={styles.loginButton} icon=".">
        <Box sx={styles.loginButtonContent}>
          <FcGoogle
            style={{ width: '18px', height: '18px', marginRight: '8px' }}
          />
          {text}
        </Box>
      </GoogleLoginButton>
    </Box>
  </a>
);

export const MyAppleLoginButton = ({ text }: { text: string }): JSX.Element => (
  <Box sx={styles.wrapper}>
    <AppleLoginButton align="center" style={styles.loginButton} icon=".">
      <Box sx={styles.loginButtonContent}>
        <BsApple
          style={{ width: '20px', height: '20px', marginRight: '8px' }}
        />
        {text}
      </Box>
    </AppleLoginButton>
  </Box>
);

export const MyFacebookLoginButton = ({
  text,
}: {
  text: string;
}): JSX.Element => (
  <a
    href={`${BASE_URL}/oauth/facebook`}
    style={{
      textDecoration: 'none',
    }}
  >
    <Box sx={styles.wrapper}>
      <FacebookLoginButton align="center" style={styles.loginButton} icon=".">
        <Box sx={styles.loginButtonContent}>
          <BsFacebook
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          />
          {text}
        </Box>
      </FacebookLoginButton>
    </Box>
  </a>
);
