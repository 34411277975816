import React from 'react';
import {
  ListItemIcon,
  ListItemIconProps,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { icon } from '../../../theme/theme';

const MyListItemIcon = (props: ListItemIconProps): JSX.Element => {
  const theme = useTheme();
  const isMediumDevice = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <ListItemIcon
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        margin: '0px !important',
        marginLeft: isMediumDevice ? '12px !important' : '16px !important',
        marginRight: isMediumDevice ? '4px !important' : '0 !important',
        minWidth: '0 !important',
        '& .MuiSvgIcon-root': {
          fontSize: isMediumDevice
            ? `${icon.fontSize.xlarge} !important`
            : `${icon.fontSize.medium} !important`,
          color: icon.color,
        },
      }}
    />
  );
};

export default MyListItemIcon;
