import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MyAppBar from '../MyAppBar';
import AppBarIconWrapper from '../AppBarIconWrapper';
import MyIconButton from '../MyIconButton';
import MyButton from '../MyButton';
import MyTextField from '../MyTextField';
import MyDialog from '../MyDialog';
import MyCloseIcon from '../MyCloseIcon';
import { dialog } from '../../theme/theme';

type SearchMoneyDialogProps = {
  open: boolean;
  onClose: () => void;
  callback?: () => void;
};

const SearchMoneyDialog = ({
  open,
  onClose,
  callback,
}: SearchMoneyDialogProps): JSX.Element => {
  const { control } = useForm<{
    minAmount: string;
    maxAmount: string;
    itemName: string;
  }>({
    mode: 'onChange',
  });

  const minAmount = useWatch({ control, name: 'minAmount' });
  const maxAmount = useWatch({ control, name: 'maxAmount' });
  const itemName = useWatch({ control, name: 'itemName' });

  function handleCloseIconClick() {
    onClose();
  }

  function handleSubmitButtonClick() {
    window.location.assign(
      `/search?minAmount=${minAmount || '0'}&maxAmount=${
        maxAmount || ''
      }&title=${itemName}&orderBy=`,
    );

    if (callback) {
      callback();
    }
    onClose();
  }

  return (
    <MyDialog open={open} onClose={handleCloseIconClick}>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => <Typography variant="h5">Selling got easier</Typography>}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              color="primary"
              onClick={handleSubmitButtonClick}
              disabled={!itemName}
            >
              Get offers
            </MyButton>
          </Box>
        )}
      />

      <Box sx={{ padding: dialog.input.padding }}>
        <Grid container wrap="nowrap">
          <Grid item flexGrow={1}>
              <Controller
                control={control}
                name="itemName"
                defaultValue=""
                render={({ field }) => (
                  <MyTextField
                    id="itemName-input"
                    label="What are you selling?"
                    placeholder=""
                    {...field} // eslint-disable-line react/jsx-props-no-spreading
                  />
                )}
              />
          </Grid>
          {/* <Grid item flexGrow={1}>
            <Controller
              control={control}
              name="minAmount"
              defaultValue=""
              render={({ field }) => (
                <MyTextField
                  id="minAmount-input"
                  label="Min amount"
                  placeholder="0"
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                  InputProps={{
                    inputComponent:
                      MyNumberFormat as unknown as React.ElementType<InputBaseComponentProps>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item flexGrow={0}>
            <Box marginRight="12px" />
          </Grid>
          <Grid item flexGrow={1}>
            <Controller
              control={control}
              name="maxAmount"
              defaultValue=""
              render={({ field }) => (
                <MyTextField
                  id="maxAmount-input"
                  label="Max amount"
                  placeholder="No limit"
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                  InputProps={{
                    inputComponent:
                      MyNumberFormat as unknown as React.ElementType<InputBaseComponentProps>,
                  }}
                />
              )}
            />
          </Grid> */}
        </Grid>
      </Box>
    </MyDialog>
  );
};

SearchMoneyDialog.defaultProps = {
  callback: undefined,
};

export default SearchMoneyDialog;
