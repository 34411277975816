import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyDialog from '../../components/MyDialog';
import MyCloseIcon from '../../components/MyCloseIcon';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import { Geocode } from '../../types/Geocode';
import { dialog } from '../../theme/theme';

type SearchPlaceDialogProps = {
  open: boolean;
  onClose: () => void;
  onPlaceDetailsAvailable: (geocode: Geocode) => void;
  onExceptionCaught: () => void;
};

const SearchPlaceDialog = ({
  open,
  onClose,
  onPlaceDetailsAvailable,
  onExceptionCaught,
}: SearchPlaceDialogProps): JSX.Element => {
  function handleCloseIconClick() {
    onClose();
  }

  return (
    <MyDialog open={open} onClose={handleCloseIconClick}>
      <MyAppBar
        leftSideRender={() => <div />}
        middleRender={() => <Typography variant="h5">Location</Typography>}
        rightSideRender={() => (
          <AppBarIconWrapper justifyContent="flex-end">
            <MyIconButton
              sx={{ marginRight: '-8px' }}
              onClick={handleCloseIconClick}
            >
              <MyCloseIcon />
            </MyIconButton>
          </AppBarIconWrapper>
        )}
      />

      <Box className="SearchPlaceDialog">
        <Box sx={{ padding: dialog.input.padding }}>
          <PlaceSearchInput
            inputLabel="Enter Zip-Code or City"
            initialInputValue=""
            onPlaceDetailsAvailable={onPlaceDetailsAvailable}
            onExceptionCaught={onExceptionCaught}
          />
        </Box>
      </Box>
    </MyDialog>
  );
};

export default SearchPlaceDialog;
