import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { Item } from '../../../types/Item';
import { Profile } from '../../../types/Profile';
import { BASE_URL } from '../../../constants';
import Items from './Items';

type ItemsContainerProps = {
  profile: Profile;
  itsMe: () => boolean;
};

const ItemsContainer = ({
  profile,
  itsMe,
}: ItemsContainerProps): JSX.Element => {
  const [items, setItems] = useState<Item[] | null>(null);
  const [reload, setReload] = useState(false);

  const { get, cache, loading, error, response } = useFetch<Item[]>(BASE_URL);

  const {
    del,
    error: putError,
    response: putResponse,
  } = useFetch<Item[]>(BASE_URL);

  async function getMyItems() {
    const data = await get(`/profiles/${profile.ID}/proposing`);

    if (response.ok) {
      setItems(data);
    }
  }

  async function deleteItem(item: Item) {
    await del(`/items/${item.ID}`);

    if (putResponse.ok) {
      cache.clear();

      if (items) {
        const filtered = items.filter((i) => i.ID !== item.ID);
        setItems([...filtered]);
      } else {
        setReload(!reload);
      }
    }
  }

  const handleDeleteIconClick = (item: Item) => {
    deleteItem(item);
  };

  useEffect(() => {
    getMyItems();
  }, [reload]);

  return (
    <Items
      items={items}
      loading={loading}
      error={Boolean(error) || Boolean(putError)}
      profile={profile}
      itsMe={itsMe}
      handleDeleteIconClick={handleDeleteIconClick}
    />
  );
};

export default ItemsContainer;
