import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { checkbox } from '../../theme/theme';

const MyCheckbox = (props: CheckboxProps): JSX.Element => (
  <Checkbox
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{ width: '20px', height: '20px', color: checkbox.border.color }}
    inputProps={{ 'aria-label': 'Checkbox demo' }}
  />
);

export default MyCheckbox;
