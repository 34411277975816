import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { useTheme } from '@mui/material';
import { menu, borderRadius } from '../../theme/theme';

const MyMenu = (props: MenuProps): JSX.Element => {
  const { sx, ...rest } = props;
  return (
    <Menu
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        '& .MuiPaper-root': {
          borderRadius,
        },
      }}
      MenuListProps={{
        sx: {
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
          ...sx,
        },
      }}
    />
  );
};

export const MyMenuItem = (props: MenuItemProps): JSX.Element => {
  const theme = useTheme();
  const { sx, ...rest } = props;
  return (
    <MenuItem
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        whiteSpace: 'initial',
        padding: `${menu.item.padding} !important`,
        fontSize: theme.typography.body1.fontSize,
        ...sx,
      }}
    />
  );
};

export default MyMenu;
