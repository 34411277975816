import React from 'react';
import { useHistory } from 'react-router';
import { Box } from '@mui/system';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import { icon, borderRadius, pageSection } from '../../theme/theme';
import AppBarIconWrapper from '../AppBarIconWrapper';
import MyDivider from '../MyDivider';
import MyIconButton from '../MyIconButton';

type PageSectionProps = {
  title?: string;
  TitleComponent?: React.FunctionComponent;
  backButtonEnabled?: boolean;
  centered?: boolean;
  backgroundColor?: boolean;
  children: React.ReactNode;
};

const PageSection = ({
  title,
  TitleComponent,
  backButtonEnabled,
  centered,
  backgroundColor,
  children,
}: PageSectionProps): JSX.Element => {
  const history = useHistory();
  return (
    <>
      <Box
        sx={{
          backgroundColor: backgroundColor ? '#FFFFFF' : 'unset',
          borderRadius,
        }}
      >
        {title && (
          <Box padding={pageSection.title.padding}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={centered ? 'center' : 'flex-start'}
            >
              {backButtonEnabled && (
                <AppBarIconWrapper justifyContent="flex-start">
                  <MyIconButton onClick={() => history.goBack()}>
                    <ArrowBackIosIcon
                      sx={{
                        fontSize: `${icon.fontSize.medium} !important`,
                        color: icon.color,
                      }}
                    />
                  </MyIconButton>
                </AppBarIconWrapper>
              )}
              <Typography variant="h5" variantMapping={{ h5: 'h1' }}>
                {TitleComponent ? <TitleComponent /> : title}
              </Typography>
            </Box>
          </Box>
        )}
        <Box>{children}</Box>
      </Box>
      <MyDivider />
    </>
  );
};
PageSection.defaultProps = {
  title: undefined,
  TitleComponent: undefined,
  backButtonEnabled: false,
  centered: undefined,
  backgroundColor: undefined,
};

export default PageSection;
