import React from 'react';
import { Box } from '@mui/system';
import Feedback, {
  SomethingWentWrongFeedback,
} from '../../components/Feedback';
import Spinner from '../../components/Spinner';
import SmallPropositionCard from '../../components/MyCards/SmallPropositionCard';
import { Proposition } from '../../types/Proposition';
import Results from '../SearchNeedsPage/Results';

type PropositionsProps = {
  propositions: Proposition[] | null;
  loading: boolean;
  error: boolean;
  handleGetMorePropositions: () => void;
  handleNotInterested: (proposition: Proposition) => void;
  more: boolean;
};

const Propositions = ({
  propositions,
  loading,
  error,
  handleGetMorePropositions,
  handleNotInterested,
  more,
}: PropositionsProps): JSX.Element => {
  const canShowSpinner = () =>
    loading || (!loading && !error && propositions === null);
  const canShowError = () => error;
  const canShowNoPropositions = () =>
    propositions !== null && propositions.length === 0;
  const canShowPropositions = () =>
    !loading && !error && propositions !== null && propositions.length > 0;

  return (
    <Box>
      {canShowSpinner() && <Spinner />}

      {canShowError() && <SomethingWentWrongFeedback />}

      {canShowNoPropositions() && (
        <Feedback
          title="Nothing has been proposed — yet"
          subtitle="When something is proposed, it will show up here."
        />
      )}

      {canShowPropositions() && (
        <Results
          items={propositions as Proposition[]}
          renderItem={(proposition: Proposition) => (
            <SmallPropositionCard
              proposition={proposition}
              handleNotInterested={() => handleNotInterested(proposition)}
            />
          )}
          more={more}
          handleFetchMore={handleGetMorePropositions}
        />
      )}
    </Box>
  );
};

export default Propositions;
