import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyList from '../../components/MyList';
import MyButton from '../../components/MyButton';
import MyDialog from '../../components/MyDialog';
import MyIconButton from '../../components/MyIconButton';
import Spinner from '../../components/Spinner';
import MyCloseIcon from '../../components/MyCloseIcon';
import Feedback, {
  SomethingWentWrongFeedback,
} from '../../components/Feedback';
import { Item } from '../../types/Item';
import { Need } from '../../types/Need';
import { icon } from '../../theme/theme';
import ProposeItemContainer from './ProposeItemContainer';

function allItemsSold(items: Item[]): boolean {
  return items.every((item) => item.sold);
}

type ProposeItemDialogProps = {
  open: boolean;
  onClose: () => void;
  items: Item[] | null;
  need: Need;
  handleAddIconClick: () => void;
  loading: boolean;
  error: boolean;
  callback?: () => void;
  onProposeError: () => void;
};

const ProposeItemDialog = ({
  open,
  onClose,
  items,
  need,
  handleAddIconClick,
  loading,
  error,
  onProposeError,
  callback,
}: ProposeItemDialogProps): JSX.Element => {
  function handleCloseIconClick() {
    onClose();
  }

  function renderItems() {
    if (items) {
      return (
        <MyList>
          {items
            .filter((item) => !item.sold)
            .map((item) => (
              <ProposeItemContainer
                key={item.ID}
                item={item}
                need={need}
                onError={onProposeError}
                callback={callback}
              />
            ))}
        </MyList>
      );
    }

    return null;
  }

  return (
    <MyDialog open={open} onClose={onClose}>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography variant="h5">Propose your item or service</Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <AppBarIconWrapper justifyContent="flex-end">
              <MyIconButton onClick={handleAddIconClick}>
                <AddIcon
                  sx={{
                    fontSize: icon.fontSize.medium,
                    color: icon.color,
                  }}
                />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
      />

      {(loading || !loading) && !error && items === null && <Spinner />}

      {!loading && error && <SomethingWentWrongFeedback />}

      {!loading && !error && items !== null && (
        <>
          {items.length === 0 && (
            <Feedback
              title="You haven’t created any item yet"
              subtitle="When you create Items, it will show up here."
              renderAction={() => (
                <MyButton
                  size="large"
                  onClick={handleAddIconClick}
                  color="primary"
                >
                  Create an item
                </MyButton>
              )}
            />
          )}

          {items.length > 0 && allItemsSold(items) ? (
            <Feedback
              title="Nothing to propose"
              subtitle="All your items are sold. Create a new one."
              renderAction={() => (
                <MyButton
                  size="large"
                  onClick={handleAddIconClick}
                  color="primary"
                >
                  Create an item
                </MyButton>
              )}
            />
          ) : (
            renderItems()
          )}
        </>
      )}
    </MyDialog>
  );
};

ProposeItemDialog.defaultProps = {
  callback: undefined,
};

export default ProposeItemDialog;
