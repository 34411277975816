import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import { Proposition } from '../../types/Proposition';
import { State } from '../../store/types';
import { Profile } from '../../types/Profile';
import useSecurity from '../../hooks/useSecurity';
import useMyPropositions from '../../hooks/useMyPropositions';
import { BASE_URL } from '../../constants';
import PropositionPage from './PropositionPage';

const PropositionPageContainer = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { error: propositionsError } = useMyPropositions();
  const me = useSelector<State, Profile>((state) => state.profile as Profile);
  const [proposition, setProposition] = useState<Proposition | null>(null);
  const { loggedIn } = useSecurity();
  const [hasNewMessages, setHasNewMessages] = useState(false);
  const history = useHistory();

  const { get, del, cache, response, loading, error } =
    useFetch<Proposition>(BASE_URL);

  function iAmProposer(): boolean {
    if (!loggedIn) return false;
    if (proposition !== null) return proposition.item.profile.ID === me.ID;
    return false;
  }

  function iAmNeeder(): boolean {
    if (!loggedIn) return false;
    if (proposition !== null) return proposition.need.profile.ID === me.ID;
    return false;
  }

  async function getProposition() {
    const data = await get(`/propositions/${id}`);
    if (response.ok) setProposition(data);
  }

  const updateHasNewMessages = (newValue: boolean) => {
    setHasNewMessages(newValue);
  };

  useEffect(() => {
    getProposition();
  }, []);

  async function handleNotInterested() {
    await del(`/propositions/${id}`);

    if (response.ok) {
      cache.clear();
      history.goBack();
    }
  }

  return (
    <PropositionPage
      proposition={proposition as Proposition}
      loading={loading}
      error={Boolean(error) || Boolean(propositionsError)}
      propositionNotFound={response.status === 404}
      iAmProposer={iAmProposer()}
      iAmNeeder={iAmNeeder()}
      hasNewMessages={hasNewMessages}
      updateHasNewMessages={updateHasNewMessages}
      handleNotInterested={handleNotInterested}
    />
  );
};

export default PropositionPageContainer;
