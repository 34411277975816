import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { Proposition } from '../../types/Proposition';
import { colors } from '../../theme/theme';

type ProposingToProps = {
  proposition: Proposition;
};

const ProposingTo = ({ proposition }: ProposingToProps): JSX.Element => {
  const theme = useTheme();
  const { need, item } = proposition;
  return (
    <Typography
      variant="body1"
      sx={{
        color: `${colors.grey} !important`,
        '& a': {
          textDecoration: 'none !important',
          color: theme.palette.primary.main,
        },
        '& a:hover': {
          textDecoration: 'underline !important',
        },
      }}
    >
      Proposing {item.name} to{' '}
      <Link
        to={`/profile/${need.profile.ID}`}
        onClick={(e) => e.stopPropagation()}
      >
        @{need.profile.firstname}
      </Link>
    </Typography>
  );
};

export default ProposingTo;
