import React from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import Spinner from '../../components/Spinner';
import {
  AlertFeedback,
  SomethingWentWrongFeedback,
} from '../../components/Feedback';
import SearchNeedCard from '../../components/MyCards/SearchNeedCard';
import PageLayout from '../../components/PageLayout';
import PageSection from '../../components/PageSection';
import MyButton from '../../components/MyButton';
import Hero from './component/Hero2/Hero';
import { Need } from '../../types/Need';
import Results from '../SearchNeedsPage/Results';
import { icon, colors } from '../../theme/theme';

type HomePageProps = {
  needs: Need[];
  loading: boolean;
  error: boolean;
  handleCreateNeedButtonClick: () => void;
  handleExploreButtonClick: () => void;
  handleSearchButtonClick: () => void;
  handleSearchByProductButtonClick: () => void;
};

const HomePage = ({
  needs,
  loading,
  error,
  handleCreateNeedButtonClick,
  handleExploreButtonClick,
  handleSearchButtonClick,
  handleSearchByProductButtonClick,
}: HomePageProps): JSX.Element => {

  const canShowSpinner = () => loading && !error;
  const canShowErrorMessage = () => !loading && error;
  const canShowEmptyMessage = () => !loading && !error && needs.length === 0;
  const canShowNeeds = () => !loading && !error && needs.length > 0;

  return (
    <PageLayout>
      <PageSection>
        <Hero />
      </PageSection>
      <PageSection title="Things to do" centered backgroundColor>
        <Box
          sx={{
            padding: '8px',
          }}
        >
          <Box
            sx={{
              '& .MuiGrid-root > .MuiButton-root': {
                minHeight: '38px !important',
                whiteSpace: 'nowrap',
                backgroundColor: '#FFFFFF !important',
                border: `1px solid ${colors.border} !important`,
              },
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs>
                <MyButton variant="outlined" onClick={handleSearchButtonClick}>
                  <Box component="span" display="flex" alignItems="center">
                    <LocalOfferOutlinedIcon
                      sx={{
                        fontSize: icon.fontSize.small,
                        color: colors.primary,
                        marginRight: '12px',
                      }}
                    />
                    <span>Get an offer now!</span>
                  </Box>
                </MyButton>
              </Grid>
              <Grid item xs sx={{ display: 'none' }}>
                <MyButton
                  variant="outlined"
                  onClick={handleSearchByProductButtonClick}
                >
                  Search money by product
                </MyButton>
              </Grid>
              <Grid item xs >
                <MyButton variant="outlined" onClick={handleExploreButtonClick}>
                  <Box component="span" display="flex" alignItems="center">
                      <ExploreOutlinedIcon
                        sx={{
                          fontSize: icon.fontSize.small,
                          color: colors.primary,
                          marginRight: '12px',
                        }}
                      />
                      <span>Explore offers</span>
                  </Box>
                </MyButton>
              </Grid>
              <Grid item xs sx={{ display: 'none' }}>
                <MyButton variant="outlined" onClick={handleExploreButtonClick}>
                  Explore money by category
                </MyButton>
              </Grid>
              <Grid item xs>
                <MyButton
                  variant="outlined"
                  onClick={handleCreateNeedButtonClick}
                >
                  <Box component="span" display="flex" alignItems="center">
                    <MonetizationOnOutlinedIcon
                      sx={{
                        fontSize: icon.fontSize.small,
                        color: colors.primary,
                        marginRight: '12px',
                      }}
                    />
                    <span>For buyers</span>
                  </Box>
                </MyButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </PageSection>
      <PageSection title="Latest offers" centered backgroundColor>
        {canShowSpinner() && <Spinner />}

        {canShowErrorMessage() && <SomethingWentWrongFeedback />}

        {canShowEmptyMessage() && (
          <AlertFeedback
            // renderIcon={() => (
            //   <SentimentVeryDissatisfiedIcon
            //     sx={{ fontSize: '40px !important' }}
            //   />
            // )}
            title="Come back later"
            subtitle="All the users needs are fulfilled for now."
          />
        )}

        {canShowNeeds() && (
          <Results
            items={needs}
            renderItem={(need: Need) => <SearchNeedCard need={need} />}
            more={false}
          />
        )}
      </PageSection>

      {/* This allows the page not to scroll when users clicks on a tab with only one or two cards by filling the gab with a heighted component */}
      {/* <Box sx={{ minHeight: 'calc(100vh - 154px - 146px)' }} /> */}
    </PageLayout>
  );
};

export default HomePage;
