import React, { ReactNode } from 'react';
import { useTheme, useMediaQuery, useScrollTrigger } from '@mui/material';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import { appBar } from '../../theme/theme';

function HideOnScroll({
  children,
  disabled,
}: {
  children: React.ReactElement;
  disabled: boolean;
}) {
  const trigger = useScrollTrigger();

  return disabled ? (
    children
  ) : (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

type MyAppBarProps = {
  leftSideRender?: () => ReactNode;
  middleRender?: () => ReactNode;
  rightSideRender?: () => ReactNode;
  bottom?: boolean;
  top?: string;
  borderBottomDisabled?: boolean;
  hideOnScroll?: boolean;
};

const MyAppBar = ({
  leftSideRender,
  middleRender,
  rightSideRender,
  bottom,
  top,
  borderBottomDisabled,
  hideOnScroll,
}: MyAppBarProps): JSX.Element => {
  const theme = useTheme();
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'));

  const borderBottomValue = () => (borderBottomDisabled ? '0px' : 'thin');

  return (
    <HideOnScroll disabled={!hideOnScroll}>
      <AppBar
        position={bottom ? 'fixed' : 'sticky'}
        elevation={0}
        sx={{
          backgroundColor: '#FFFFFF',
          color: '#000000',
          borderColor: appBar.borderColor,
          borderLeft: '0',
          borderRight: '0',
          borderTop: bottom ? appBar.borderColor : '0',
          borderBottomWidth: bottom ? 'inherit' : borderBottomValue(),
          borderTopWidth: bottom ? 'thin !important' : 'inherit',
          borderStyle: 'solid',
          zIndex: 10,
          top: bottom ? 'auto' : top,
          bottom: bottom ? '0' : 'auto',
        }}
      >
        <Container disableGutters maxWidth={!isLargeDevice ? 'sm' : 'lg'}>
          <Toolbar
            sx={{
              padding: `${appBar.padding} !important`,
              minHeight: `${appBar.height} !important`,
            }}
          >
            <Grid container alignItems="center" flexWrap="nowrap">
              {leftSideRender && (
                <Grid item xs="auto">
                  {leftSideRender()}
                </Grid>
              )}

              {middleRender && (
                <Grid item flexGrow={1} sx={{ margin: '0 4px' }}>
                  {middleRender()}
                </Grid>
              )}

              {rightSideRender && (
                <Grid item xs="auto">
                  {rightSideRender()}
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
};

MyAppBar.defaultProps = {
  leftSideRender: undefined,
  middleRender: undefined,
  rightSideRender: undefined,
  bottom: false,
  top: '0',
  borderBottomDisabled: false,
  hideOnScroll: false,
};

export default MyAppBar;
