import React, { useState, useRef, useEffect } from 'react';
import useFetch from 'use-http';
import { Item } from '../../types/Item';
import { Need } from '../../types/Need';
import { Proposition } from '../../types/Proposition';
import ProposeItem from './ProposeItem';
import useMyPropositions from '../../hooks/useMyPropositions';
import { BASE_URL } from '../../constants';
import logger from '../../logger';

type ProposeItemDialogContainerProps = {
  item: Item;
  need: Need;
  onError: () => void;
  callback?: () => void;
};

const ProposeItemDialogContainer = ({
  item,
  need,
  onError,
  callback,
}: ProposeItemDialogContainerProps): JSX.Element => {
  const proposingRef = useRef<boolean | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {
    propositions,
    error: propositionsError,
    reload: reloadMyPropositions,
  } = useMyPropositions();

  const { post, del, cache, error, response } = useFetch(BASE_URL);

  if (error || propositionsError) {
    if (error) {
      logger.log({
        message: 'Failed to propose item',
        error,
      });
    } else {
      logger.log({
        message: 'Failed to propose item',
        reason: 'Coming from useMyPropositions()',
      });
    }

    onError();
  }

  async function proposeItem() {
    const proposition: {
      need: Need;
      item: Item;
      done: boolean;
      seen: boolean;
      CreatedAt: Date;
      UpdatedAt: Date;
    } = {
      item,
      need,
      done: false,
      seen: false,
      CreatedAt: new Date(),
      UpdatedAt: new Date(),
    };

    await post(`/propositions`, proposition);

    if (response.ok) {
      cache.clear();

      if (reloadMyPropositions) {
        reloadMyPropositions();
      }

      if (callback) {
        callback();
      }
    }
  }

  async function unproposeItem() {
    if (propositions !== null) {
      const proposition = propositions.find(
        (p) => p.need.ID === need.ID && p.item.ID === item.ID,
      ) as Proposition;

      await del(`/propositions/${proposition.ID}`);

      if (response.ok) {
        cache.clear();

        if (reloadMyPropositions) {
          reloadMyPropositions();
        }

        if (callback) {
          callback();
        }
      }
    }
  }

  function isProposing(): boolean {
    if (propositions !== null) {
      return propositions.some(
        (proposition) =>
          proposition.need.ID === need.ID && proposition.item.ID === item.ID,
      );
    }

    return false;
  }

  const proposing = isProposing();

  if (proposingRef.current === null) {
    proposingRef.current = proposing;
  }

  useEffect(() => {
    if (proposingRef.current !== proposing) {
      proposingRef.current = proposing;
      setButtonDisabled(false);
    }
  });

  function handleProposeButtonClick() {
    setButtonDisabled(true);

    if (!proposing) {
      proposeItem();
    } else {
      unproposeItem();
    }
  }

  return (
    <ProposeItem
      item={item}
      proposing={proposing}
      buttonDisabled={buttonDisabled}
      onPropose={handleProposeButtonClick}
    />
  );
};

ProposeItemDialogContainer.defaultProps = {
  callback: undefined,
};

export default ProposeItemDialogContainer;
