import React, { useState } from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import MyButton from '../../components/MyButton';
import { locationFilter, divider, icon } from '../../theme/theme';
import MyTextField from '../../components/MyTextField';
import MyIconButton from '../../components/MyIconButton';

type NeedTitleFilterProps = {
  initialValue: string;
  onApply: (value: string) => void;
};

const NeedTitleFilter = ({
  initialValue,
  onApply,
}: NeedTitleFilterProps): JSX.Element => {
  const [value, setValue] = useState(initialValue);
  const [inputFocused, setInputFocused] = useState<boolean | undefined>(
    undefined,
  );

  return (
    <Box width="320px">
      <Box padding={locationFilter.input.padding}>
        <MyTextField
          value={value}
          label="This exact product or service"
          onChange={(e) => setValue(e.target.value)}
          focused={inputFocused}
          InputProps={{
            inputRef: (input) => input && inputFocused && input.focus(),
            endAdornment: value ? (
              <Box marginRight="-8px">
                <MyIconButton
                  onClick={() => {
                    setValue('');
                    setInputFocused(true);
                  }}
                >
                  <CloseIcon
                    sx={{ fontSize: icon.fontSize.medium, color: icon.color }}
                  />
                </MyIconButton>
              </Box>
            ) : undefined,
          }}
        />
      </Box>
      <Divider sx={{ borderColor: divider.color }} />
      <Box padding={locationFilter.cta.padding}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Box marginLeft="16px">
              <MyButton
                size="small"
                color="primary"
                disabled={value === initialValue}
                onClick={() => {
                  onApply(value);
                }}
              >
                Apply
              </MyButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NeedTitleFilter;
