import React, { useState, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import useFetch from 'use-http';
import { Box } from '@mui/system';
import { InputBaseComponentProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import MyTextField from '../../components/MyTextField';
import MyDialog from '../../components/MyDialog';
import MyCloseIcon from '../../components/MyCloseIcon';
import MyNumberFormat from '../../components/MyNumberFormat';
import Spinner from '../../components/Spinner';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { Need } from '../../types/Need';
import { Profile } from '../../types/Profile';
import currencies from '../../utils/currencies';
import { BASE_URL } from '../../constants';
import { getAllCategoriesTree, CategoryNode } from '../../utils/categories';
import MySelect from '../../components/MySelect';
import { dialog } from '../../theme/theme';
import { Geocode } from '../../types/Geocode';
import useLocale from '../../hooks/useLocale';
import CategoriesDialog from './CategoriesDialog';
import SearchPlaceDialog from './SearchPlaceDialog';
import context from './createNewNeedContext';

type FormValues = {
  title: string;
  description: string;
  category: string;
  offer: string;
  currency: string;
};

type CreateNewNeedDialogProps = {
  callback?: (created: Need) => void;
  open: boolean;
  onClose: () => void;
  profile: Profile;
};

const CreateNewNeedDialog = ({
  callback,
  open,
  onClose,
  profile,
}: CreateNewNeedDialogProps): JSX.Element => {
  const locale = useLocale();
  const defaultCurrency = locale.currency || 'GBP';
  const [geocode, setGeocode] = useState<Geocode | null>(null);
  const [placeSearchException, setPlaceSearchException] = useState(false);
  const [categoriesDialogOpen, setCategoriesDialogOpen] = useState(false);
  const [searchPlaceDialogOpen, setSearchPlaceDialogOpen] = useState(false);
  const [selectedCategoryNode, setSelectedCategoryNode] =
    useState<CategoryNode | null>(null);
  const [currentCategoryNode, setCurrentCategoryNode] =
    useState<CategoryNode | null>(null);

  useEffect(() => {
    const categoriesTreeRootNode = getAllCategoriesTree();
    setCurrentCategoryNode(categoriesTreeRootNode);
  }, []);

  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { errors, isDirty, isValid } = formState;

  const { post, response, cache, loading, error } = useFetch<Need>(BASE_URL);

  const currency = useWatch({
    control,
    name: 'currency',
    defaultValue: defaultCurrency,
  });

  async function createNeed(need: {
    title: string;
    description: string;
    category: string;
    offer: number;
    currency: string;
    fulfilled: boolean;
    geocode: Geocode;
    profile: Profile;
    UpdatedAt: Date;
    CreatedAt: Date;
  }) {
    const created = await post(`/needs`, need);

    if (response.ok) {
      cache.clear();
      if (callback) {
        callback(created as Need);
      }
      // history.push(`/need/${(created as Need).ID}`);
      onClose();
    }
  }

  function buildCategoryName(): string {
    let categoryName = (selectedCategoryNode as CategoryNode).name;
    let node = (selectedCategoryNode as CategoryNode).parent;

    while (node !== null) {
      categoryName = `${node.name}${
        node.name !== '' ? ' > ' : ''
      }${categoryName}`;
      node = node.parent;
    }

    return categoryName;
  }

  function handleSubmitButtonClick() {
    const { offer, title, description, ...rest } = getValues();
    createNeed({
      title: title.trim(),
      description: description.trim(),
      ...rest,
      category: buildCategoryName(),
      offer: Number.parseInt(offer, 10),
      fulfilled: false,
      geocode: geocode as Geocode,
      profile,
      UpdatedAt: new Date(),
      CreatedAt: new Date(),
    });
  }

  function handleSelectedCategoryNodeChange(selected: CategoryNode) {
    setSelectedCategoryNode(selected);
    setCategoriesDialogOpen(false);
  }

  function handleCurrentCategoryNodeChange(newCurrent: CategoryNode) {
    setCurrentCategoryNode(newCurrent);
  }

  function handleCategorySelectClick() {
    setCategoriesDialogOpen(true);
  }

  function handleLocationSelectClick() {
    setSearchPlaceDialogOpen(true);
  }

  const canShowSpinner = () => !error && !placeSearchException && loading;
  const canShowErrorMessage = () => (error || placeSearchException) && !loading;
  const canShowForm = () => !error && !placeSearchException && !loading;

  function handleCloseIconClick() {
    onClose();
  }

  return (
    <context.Provider value={{ currency }}>
      <MyDialog open={open} onClose={onClose}>
        <MyAppBar
          leftSideRender={() => (
            <Box marginLeft="0">
              <AppBarIconWrapper justifyContent="flex-start">
                <MyIconButton onClick={handleCloseIconClick}>
                  <MyCloseIcon />
                </MyIconButton>
              </AppBarIconWrapper>
            </Box>
          )}
          middleRender={() => (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold !important',
                textOverflow: 'ellipsis !important',
              }}
            >
              Buying got easier
            </Typography>
          )}
          rightSideRender={() => (
            <Box marginRight="0">
              <MyButton
                size="small"
                color="primary"
                disabled={
                  !isDirty ||
                  !isValid ||
                  loading ||
                  !selectedCategoryNode ||
                  !geocode ||
                  canShowErrorMessage()
                }
                onClick={handleSubmitButtonClick}
              >
                List now
              </MyButton>
            </Box>
          )}
        />

        {canShowSpinner() && <Spinner />}

        {canShowErrorMessage() && <SomethingWentWrongFeedback />}

        {canShowForm() && (
          <form onSubmit={handleSubmitButtonClick}>
            <Box sx={{ padding: dialog.input.padding }}>
              <Controller
                control={control}
                rules={{
                  required: `Product or Service name is required.`,
                  maxLength: {
                    value: 70,
                    message: 'Too many characters... Limit is 70',
                  },
                }}
                name="title"
                defaultValue=""
                render={({ field }) => (
                  <MyTextField
                    id="title-input"
                    label="What product or service do you want to buy?"
                    error={!!errors.title}
                    helperText={errors.title && errors.title.message}
                    {...field} // eslint-disable-line react/jsx-props-no-spreading
                  />
                )}
              />
            </Box>
            <Box sx={{ padding: dialog.input.padding }}>
              <Grid container>
                <Grid
                  item
                  sx={{
                    minWidth: '96px',
                  }}
                >
                  <Box marginRight="12px">
                    <Controller
                      control={control}
                      rules={{ required: 'Currency is required.' }}
                      name="currency"
                      defaultValue={defaultCurrency}
                      render={({ field }) => (
                        <MyTextField
                          id="currency-input"
                          select
                          label="Currency"
                          error={!!errors.currency}
                          helperText={
                            errors.currency && errors.currency.message
                          }
                          {...field} // eslint-disable-line react/jsx-props-no-spreading
                        >
                          {currencies.getAcronyms().map((c) => (
                            <MenuItem key={c} value={c}>
                              {currencies.getSymbol(c)}
                            </MenuItem>
                          ))}
                        </MyTextField>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item flexGrow={1}>
                  <Controller
                    control={control}
                    rules={{ required: 'Amount is required.' }}
                    name="offer"
                    defaultValue=""
                    render={({ field }) => (
                      <MyTextField
                        id="offer-input"
                        label="How much do you want to spend?"
                        error={!!errors.offer}
                        helperText={errors.offer && errors.offer.message}
                        {...field} // eslint-disable-line react/jsx-props-no-spreading
                        InputProps={{
                          inputComponent:
                            MyNumberFormat as unknown as React.ElementType<InputBaseComponentProps>,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding: dialog.input.padding }}>
              <Controller
                control={control}
                rules={{
                  required: `Product or service description is required.`,
                  maxLength: {
                    value: 140,
                    message: 'Too many characters... Limit is 140',
                  },
                }}
                name="description"
                defaultValue=""
                render={({ field }) => (
                  <MyTextField
                    id="description-input"
                    label="Add your requirements about it like condition, color..."
                    multiline
                    rows={5}
                    error={!!errors.description}
                    helperText={
                      errors.description && errors.description.message
                    }
                    {...field} // eslint-disable-line react/jsx-props-no-spreading
                  />
                )}
              />
            </Box>
            <Box sx={{ padding: dialog.input.padding }}>
              <Box position="relative">
                <FormControl fullWidth>
                  <InputLabel id="category-label">Choose a category</InputLabel>
                  <MySelect
                    labelId="category-label"
                    id="category-select"
                    label="Choose a category"
                    value={
                      selectedCategoryNode !== null
                        ? selectedCategoryNode.name
                        : ''
                    }
                    renderValue={() => (
                      <span>
                        {selectedCategoryNode !== null
                          ? selectedCategoryNode.name
                          : ''}
                      </span>
                    )}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0',
                      bottom: '0',
                      left: '0',
                      right: '0',
                      width: '100%',
                      zIndex: '99',
                    }}
                    onClick={handleCategorySelectClick}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ padding: dialog.input.padding }}>
              <Box position="relative">
                <FormControl fullWidth>
                  <InputLabel id="location-label">
                    Choose where to list your offer
                  </InputLabel>
                  <MySelect
                    labelId="location-label"
                    id="location-select"
                    label="Choose where to list your offer"
                    value={geocode !== null ? geocode.Label : ''}
                    renderValue={() => (
                      <span>{geocode !== null ? geocode.Label : ''}</span>
                    )}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0',
                      bottom: '0',
                      left: '0',
                      right: '0',
                      width: '100%',
                      zIndex: '99',
                    }}
                    onClick={handleLocationSelectClick}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box marginTop="64px" />
          </form>
        )}
      </MyDialog>
      <CategoriesDialog
        open={categoriesDialogOpen}
        onClose={() => setCategoriesDialogOpen(false)}
        selectedCategoryNode={selectedCategoryNode}
        handleSelectedCategoryNodeChange={handleSelectedCategoryNodeChange}
        currentCategoryNode={currentCategoryNode as CategoryNode}
        handleCurrentCategoryNodeChange={handleCurrentCategoryNodeChange}
      />
      {searchPlaceDialogOpen && (
        <SearchPlaceDialog
          open
          onClose={() => setSearchPlaceDialogOpen(false)}
          onPlaceDetailsAvailable={(data) => {
            setGeocode(data);
            setSearchPlaceDialogOpen(false);
          }}
          onExceptionCaught={() => {
            setPlaceSearchException(true);
            setSearchPlaceDialogOpen(false);
          }}
        />
      )}
    </context.Provider>
  );
};

CreateNewNeedDialog.defaultProps = {
  callback: undefined,
};

export default CreateNewNeedDialog;
