import React from 'react';
import {
  ListItem,
  ListItemProps,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { drawer } from '../../../theme/theme';

const MyListItem = (props: ListItemProps): JSX.Element => {
  const theme = useTheme();
  const isMediumDevice = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <ListItem
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        cursor: 'pointer',
        padding: isMediumDevice ? drawer.item.link.padding : '0px',
        '&.Mui-selected': {
          backgroundColor: 'unset',
        },
        '&.Mui-selected .MuiTypography-root': {
          fontWeight: 'bold',
        },
      }}
    />
  );
};

export default MyListItem;
