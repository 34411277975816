import React from 'react';
import { Divider } from '@mui/material';
import { drawer } from '../../../theme/theme';

const MyDivider = (): JSX.Element => (
  <Divider
    sx={{
      margin: drawer.divider.margin,
      borderColor: drawer.divider.borderColor,
    }}
  />
);

export default MyDivider;
