import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import {
  PageTitle,
  ParagraphTitle1,
  Paragraph,
} from '../PrivacyPolicyPage/PrivacyPolicyPage';
import PageLayout from '../../components/PageLayout';

const TermsNConditionsPage = (): JSX.Element => (
  <PageLayout>
    <Helmet>
      <title>Terms and conditions | Bonvih</title>
    </Helmet>
    <Box padding="0 16px">
      <PageTitle>Terms and conditions</PageTitle>
      <ParagraphTitle1>§ 1 An Introduction to Shpock</ParagraphTitle1>
      <Paragraph>Welcome to Shpock - The Joy of Selling!</Paragraph>
      <Paragraph>
        Shpock is a mobile-first marketplace encouraging people to find the joy
        in selling. The declutterers. The wallet boosters. The eco-conscious.
        Turn electronics, fashion and almost anything else into cash in your
        pocket!
      </Paragraph>
      <Paragraph>
        Shpock is registered under finderly GmbH, Gertrude-Fröhlich-Sandner
        Straße 2, SPACES Icon Tower, Turm 9, 1100 Vienna, Austria (“Shpock”).
        Shpock operates websites “shpock.com” and other country specific
        top-level domains such as “.co.uk”, “.de”, & “.at”. Shpock also offers
        mobile apps for Android and iOS, which operate under the name “Shpock”.
        Websites, mobile apps and all services made available by these platforms
        are hereinafter referred to as “Shpock” or “Shpock Services”.
      </Paragraph>
      <Paragraph>
        These terms and conditions (the “Terms”) apply to the use of all Shpock
        Services. If using Shpock the user accepts these terms and shall be
        obliged to adhere to them.
      </Paragraph>
      <Paragraph>
        Shpock can be used by downloading the app free of charge from the Google
        Play Store or the Apple App store, or via the Shpock website
        (shpock.com, .co.uk, .de, .at).
      </Paragraph>
      <ParagraphTitle1>§ 2 Offered Services</ParagraphTitle1>
      <Paragraph>
        As a registered user, you can make offers and buy and sell a variety of
        goods and services (“products”). This is permitted providing it doesn’t
        break any laws, is not against good morals, our Terms, or our Prohibited
        Items list.
      </Paragraph>
      <Paragraph>
        Shpock’s basic service is free of charge. Additional products such as
        Bump along with our Premium Membership (“premium features”) are subject
        to an additional charge and can be purchased exclusively through the
        Apple App Store and Google Play Store. The prices are clearly displayed
        prior to purchase and are inclusive of VAT and any applicable statutory
        rates. Shpock reserves the right to change these prices at any given
        time, and may offer varying premium features across the Android App, iOS
        app and website due to different stages of development. In case Shpock
        increases the price of premium features users are entitled to terminate
        their subscription with effect before the increasement.
      </Paragraph>
      <Paragraph>
        Once the user has confirmed their purchase the user agrees that Shpock,
        concluding the contract, delivers the service in its entirety
        immediately.
      </Paragraph>
      <Paragraph>
        Shpock is not responsible for content generated by users and there is no
        right for publication of content. Shpock reserves the right to suspend
        or remove a user from the platform without reason. It is also prohibited
        to create another account under a different name and/or e-mail address
        if you have previously been blocked from using Shpock.
      </Paragraph>
      <Paragraph>
        Shpock Services are provided for users in Europe only.
      </Paragraph>
      <ParagraphTitle1>§ 3 Contractual conditions</ParagraphTitle1>
      <Paragraph>
        Shpock only provides the framework for users to buy and sell. Posts for
        buying and selling are not legally binding offers by Shpock and Shpock
        is not a contracting party. The contract is between the users of the
        marketplace and the fulfillment of these contracts is concluded via the
        Shpock Services but carried out exclusively among the users.
      </Paragraph>
      <Paragraph>
        Shpock offers a variety of premium features. Once the user selects the
        premium feature and pays for it, the feature is activated and Shpock has
        fulfilled their contractual obligation for the premium feature.
      </Paragraph>
      <ParagraphTitle1>
        § 4 Eligibility, registration, and deletion of the account
      </ParagraphTitle1>
      <Paragraph>
        Registration is free of charge. You must be over 18 to use Shpock. If
        you are aged 14-17 you can use Shpock providing you have approval from
        your parent or legal guardian.
      </Paragraph>
      <Paragraph>
        Commercial use is forbidden unless individual approval by Shpock has
        been granted. Examples (although not an exhaustive list) could include
        Shpock+ customers.
      </Paragraph>
      <Paragraph>
        Registering for a Shpock account may be completed via Facebook, Google,
        Apple, or e-mail. The specified profile data provided by the user must
        be correct. Multiple registrations and accounts are not permitted.
      </Paragraph>
      <Paragraph>
        The user is responsible for taking the appropriate measures to keep
        their login credentials confidential. If a third party has accessed the
        user’s login details, then the user must change them.
      </Paragraph>
      <Paragraph>
        By registering, the user agrees to receiving the Shpock newsletter and
        will receive regular information from Shpock. The user can unsubscribe
        at any time by disabling the subscription newsletter in their profile,
        or by clicking the unsubscribe link in the newsletter.
      </Paragraph>
      <Paragraph>
        Users can delete their account at any time, without reason. Further
        information can be found in our Privacy Policy.
      </Paragraph>
      <ParagraphTitle1>§ 5 Withdrawal and termination</ParagraphTitle1>
      <Paragraph>
        The cancellation policy applies to consumers who have purchased certain
        premium Shpock features that they have paid for.
      </Paragraph>
      <Paragraph>Right to cancel premium features:</Paragraph>
      <Paragraph>
        After purchase of a premium service at a cost of more than EUR 50.00,
        the consumer has 14 calendar days to withdraw from the contractual
        agreement. This must be done in writing (e-mail or letter) and can be
        done without reason. Cancellation requests must be sent in writing to:
      </Paragraph>
      <Paragraph>
        finderly GmbH Gertrudee-Fröhlich-Sandner Straße 2 SPACES Icon Tower,
        Turm 9 A-1100 Vienna, Austria
      </Paragraph>
      <Paragraph>Or via e-mail to cancellation@shpock.com.</Paragraph>
      <Paragraph>
        Shpock will then delete the premium feature and repay the money, if
        already paid by the consumer.
      </Paragraph>
      <Paragraph>
        If the user themself deletes the premium feature product after it was
        applied/used, it’s considered fully consumed even if the feature is
        time-dependent and the period hasn’t expired (e.g. deleting a post with
        a highlight-premium-feature, deleting a premium search agent, deleting
        the user-account).
      </Paragraph>
      <ParagraphTitle1>§ 6 Payment using PayPal</ParagraphTitle1>
      <Paragraph>
        Payment via PayPal is eligible in specific countries. The seller must
        connect their PayPal account to their Shpock account. The buyer does not
        have to connect their PayPal account. Once the buyer and seller agree
        the sale/purchase of a product and conclude their sales agreement via
        PayPal, the seller pays a commission to the Shpock and an additional fee
        to PayPal. The PayPal fee will be dependent on the set-up of their
        PayPal account (see PayPal’s terms of use). The buyer does not pay any
        fees and only pays the agreed price of the purchased item. If a refund
        is processed the seller will not receive a refund of the fees paid to
        Shpock.
      </Paragraph>
      <Paragraph>
        If PayPal is the method of payment, PayPal’s Buyer and Seller Protection
        may apply. Dispute resolution can be initiated by the buyer or seller
        using PayPal’s Dispute Resolution Centre. To find out more visit PayPal.
      </Paragraph>
      <ParagraphTitle1>§ 7 Delivery</ParagraphTitle1>
      <Paragraph>
        The door-to-door collection & delivery service is provided either by
        Parcel2Go or Royal Mail and only available in the UK.
      </Paragraph>
      <Paragraph>
        If an item is available for delivery, the buyer will be made aware at
        the time of making an offer, and will be able to select delivery as an
        option. The price the buyer pays will include the item, the Buyer
        Protection fee and also the price of delivery. The buyer will also need
        to provide their seller with additional information, i.e their delivery
        address.
      </Paragraph>
      <Paragraph>
        The seller will initiate the delivery process by purchasing a delivery
        label via the Parcel2Go or Royal Mail. Shpock does not take any
        ownership or responsibility for the performance of the delivery service.
        Please refer to Parcel2Go’s T&Cs or Royal Mail’s T&Cs for more
        information.
      </Paragraph>
      <Paragraph>
        To make the delivery service possible, Shpock will share with Parcel2Go
        or Royal Mail the following data of the buyer and seller: Contact name,
        e-mail, phone number and full address.
      </Paragraph>
      <Paragraph>
        Sellers may be offered or can also use alternative delivery providers.
        Sellers may transfer necessary personal data of buyers to such delivery
        providers to fulfil their contractual obligations versus the buyer.
      </Paragraph>
      <ParagraphTitle1>
        § 8 Vouchers, codes and other Customer Retention Programs
      </ParagraphTitle1>
      <Paragraph>
        From time to time Shpock may offer Customer Retention Programs to Shpock
        users. Such programs are offered voluntarily and may be altered,
        amended, withdrawn or ended any time by Shpock. Rules and policies of
        such programs are published in the Shpock App, on the Shpock website or
        in individual notifications to the user.
      </Paragraph>
      <Paragraph>
        Vouchers and codes may be offered by Shpock as discounts or as credits
        for performing defined actions. Users may collect and use vouchers and
        codes on their user accounts to receive a rebate by Shpock when making
        payments or to utilize a certain service. Vouchers and codes have a
        defined value or may be exchanged to get a defined service, as published
        from time to time on the Shpock platform. Getting a rebate with vouchers
        or codes is only possible when using our payment service provider
        partner Adyen.
      </Paragraph>
      <Paragraph>
        Vouchers or codes can never be withdrawn as cash, or transferred to or
        exchanged with other users; they also may invalidate after a defined
        period. Vouchers and codes are not refunded or compensated, even if a
        Customer Retention Program is terminated before the user has used their
        vouchers and codes. If a user abuses such program, Shpock is entitled to
        cancel such user’s individual vouchers and codes or Customer Retention
        program advantages without compensation.
      </Paragraph>
      <ParagraphTitle1>
        § 9 Our Buyer Protection Program and payments using Adyen
      </ParagraphTitle1>
      <Paragraph>
        The Buyer Protection program is available for users in the UK only. When
        listing an item, sellers must enable the item for Buyer Protection in
        order for it to be eligible. When enabling this feature, the seller
        agrees to the Buyer Protection terms.
      </Paragraph>
      <Paragraph>
        When an item is eligible for Buyer Protection, the buyer has to make the
        payment in-app to be covered by Buyer Protection.
      </Paragraph>
      <Paragraph>
        Payments will then be carried out via our independent payment service
        provider partner, Adyen. Users enter into a separate contractual
        relationship with Adyen regarding the payment services. Shpock does not
        perform any payment services directly and is therefore not liable for
        Adyen when performing payment services. When Buyer Protection is
        enabled, the users agree to be bound by the Adyen terms and conditions
        as well as the Shpock Buyer Protection terms.
      </Paragraph>
      <Paragraph>
        Once the buyer uses the pays for their item that is eligible for Buyer
        Protection, they have confirmed the deal and the Buyer Protection terms
        and the seller will receive their funds into their Shpock Wallet.
        Depending on the payment method, payouts may take up to several working
        days. Users designate Shpock as an authorised representative towards
        Adyen and grant Shpock the power to act on their behalf towards Adyen.
      </Paragraph>
      <Paragraph>
        The Shpock Wallet is an electronic money account that allows users to
        send and receive payments within Shpock. To set up the Shpock Wallet,
        our payment service provider, Adyen, will require some additional
        information from users to comply with applicable regulatory provisions,
        such as KYC (know your customer) procedures. Adyen will request at least
        the following data for Shpock Wallet users: Full name, e-mail address,
        date of birth, nationality, country of residence & bank account details.
        After the Shpock Wallet is configured, the money can be transferred any
        time from the Shpock Wallet to the user’s personal bank account. After
        receiving their first Secure Delivery payment via the Shpock Wallet, the
        seller has 150 days to complete the setup before the money is returned
        back to the buyer.
      </Paragraph>
      <Paragraph>
        For more information on the buyer protection service, please see our
        Buyer Protection terms.
      </Paragraph>
      <ParagraphTitle1>§ 10 Listings, images and copyright</ParagraphTitle1>
      <Paragraph>Each post must be written and created by the user.</Paragraph>
      <Paragraph>It is strictly prohibited to publish anything that:</Paragraph>
      <Paragraph>
        <li>is illegal, obscene, offensive or discriminatory</li>
        <li>contains pornographic images or content</li>
        <li>infringes personal rights</li>
        <li>attacks the reputation of a person or a business</li>
        <li>
          is seen as harassment through mass messages (spam), or any other kind
        </li>
        <li>Is designed to electronically hack Shpock or spread a virus</li>
      </Paragraph>
      <Paragraph>
        Any of this behaviour will be reported to the relevant authority and
        your account will be blocked. It is also strictly prohibited to list
        anything on our Prohibited Item List.
      </Paragraph>
      <Paragraph>
        Shpock reserves the right to remove posts, disable or delete any user’s
        account.
      </Paragraph>
      <Paragraph>
        When publishing classified ads, the user must ensure that the images do
        not break copyright law. Images must not contain watermarks or any other
        copyright marks. The user must own the images they are using or have the
        right to publish them from the copyright owner. Shpock reserves the
        right to limit the maximum size of images and to modify or remove
        images.
      </Paragraph>
      <ParagraphTitle1>§ 11 No sale of living creatures</ParagraphTitle1>
      <Paragraph>
        Shpock respects the rights and dignity of living creatures. In most
        countries, the selling of living creatures is regulated by special laws.
        You may not offer, sell or exchange animals via Shpock.
      </Paragraph>
      <Paragraph>
        For more information please refer to our Prohibited Item List.
      </Paragraph>
      <ParagraphTitle1>
        § 12 Granting of rights and liability for users
      </ParagraphTitle1>
      <Paragraph>
        The user permits the Shpock a world-wide, fully paid, non-exclusive and
        transferable licence to use, modify, copy, publicly perform, publicly
        display, reformat, translate, excerpt (in whole or in part) and
        distribute the content created by the user for any purpose, commercial,
        advertising, or otherwise, or in connection with Shpock or any other
        product or service of the Shpock or the promotion thereof.
      </Paragraph>
      <Paragraph>
        This includes full and restricted online and offline use of content.
        These rights remain indefinitely with Shpock, even if the user is no
        longer registered with Shpock.
      </Paragraph>
      <Paragraph>
        Should Shpock be held liable, due to a violation of the Terms or
        applicable law by the user, the user obliges himself to release Shpock
        from any legal consequences (e.g. claims of third parties). The
        indemnity includes but is not limited to payable fines, damages, as well
        as all costs of legal defence and legal representation.
      </Paragraph>
      <ParagraphTitle1>§ 13 Transactions between Users</ParagraphTitle1>
      <Paragraph>
        Other Shpock users can view posts and submit offers to purchase. The
        seller can accept the offer or submit a counter offer. If the seller and
        potential buyer agree on a price (“purchase price”) and both parties
        confirm, a binding sales contract is entered. The buyer is obliged to
        pay the purchase price and pick up from the seller, if no other delivery
        is agreed upon. The seller is obliged to sell the classified ad as shown
        and described.
      </Paragraph>
      <Paragraph>
        Offers can be cancelled by the seller or the buyer before the other
        party has confirmed.
      </Paragraph>
      <Paragraph>
        The contract between the seller and buyer is exclusively between the
        users themselves and must be closed on the Shpock platform. Shpock does
        not offer or receive any offers or submit or receive bids and therefore
        is not a party in the sales contract.
      </Paragraph>
      <Paragraph>
        Shpock is not obliged to verify the transmitted or stored information or
        to actively search for circumstances which may indicate illegal
        activity.
      </Paragraph>
      <Paragraph>
        Should the Shpock somehow become aware of a specific unlawful act of an
        individual user or receive information within the Shpock services, the
        information will immediately be removed or the access to this content
        will be blocked.
      </Paragraph>
      <ParagraphTitle1>§ 14 Liability</ParagraphTitle1>
      <Paragraph>
        The seller holds liability for the correctness of the information,
        images and comments on the classified ads. Shpock cannot be held liable.
      </Paragraph>
      <Paragraph>
        Shpock is not liable for any damage caused by errors, delays or
        interruptions in the transmission, malfunctions of the technical
        systems, loss or deletion of data, viruses or any other damage which can
        occur during the use of Shpock, unless they are caused intentionally or
        grossly negligently by Shpock or the damage concerns the injury of life,
        body or health. Shpock is liable only for the amount which corresponds
        with contract-typical foreseeable damages. There is no liability for
        loss of profits, savings, damage from claims of third parties, and for
        other direct and indirect consequential damage.
      </Paragraph>
      <Paragraph>
        The foregoing limitations of liability include claims against employees
        or representatives of Shpock as well as the licensors of Shpock and
        their licensors or the licensees of Shpock and their licensees. Shpock
        Services might be subject to an underlying licensing agreement. The
        liability of the licensor is determined by the respective licensing
        agreement.
      </Paragraph>
      <Paragraph>
        Moreover, Shpock is not liable for the content, accuracy, legality and
        functionality of third-party internet pages, which reference from or to
        the platform of Shpock via a link.
      </Paragraph>
      <Paragraph>
        The Shpock services are only accessible for the user with an active
        Internet connection. Shpock neither assumes responsibility that the
        Shpock services are continuously usable and/or accessible, nor is Shpock
        liable for technical transmission delays or failures. Shpock excludes
        any warranty for the functionality of the Shpock services. Shpock is not
        liable for the unauthorized knowledge attainment of personal user data
        by third parties such as hackers.
      </Paragraph>
      <Paragraph>
        The user will inform theirself about the tax and / or fee obligations
        which may occur in his country of origin. In case that national taxes
        and / or fees for the use of Shpock services are due, these must be paid
        by the user.
      </Paragraph>
      <ParagraphTitle1>§ 15 Data protection</ParagraphTitle1>
      <Paragraph>
        Protection of your personal data is very important to us. To see our
        specific Privacy rules, see our Privacy Policy.
      </Paragraph>
      <ParagraphTitle1>§ 16 Shpock trademark rights</ParagraphTitle1>
      <Paragraph>
        Shpock and other trademarks owned may not be used without express
        written consent. The only exclusion is the use of press photos provided
        by Shpock on the Shpock website for press and marketing purposes.
      </Paragraph>
      <ParagraphTitle1>§ 17 Violations of the Terms</ParagraphTitle1>
      <Paragraph>
        If these terms are violated, Shpock is entitled to temporarily or
        permanently block or delete the affected user and remove their posts,
        classified ads and any other content provided by this user.
      </Paragraph>
      <Paragraph>
        If a user receives a permanent ban from all Shpock services (including
        classified ads), all purchase premium features forfeit without
        compensation. If a user receives a temporary ban, the right to use the
        premium features will not be extended, and they will forfeit without
        compensation.
      </Paragraph>
      <Paragraph>
        The enforcement of any other claims, especially for damage compensation,
        remains expressly reserved for Shpock.
      </Paragraph>
      <Paragraph>
        If users violate the rights of third parties or the Terms, we ask to use
        the “Report” function on the respective product.
      </Paragraph>
      <ParagraphTitle1>§ 18 Amendment of the Terms</ParagraphTitle1>
      <Paragraph>
        Shpock reserves the right to change and amend the Terms at any time. An
        explicit reference to the change of Terms will not be made, and
        therefore the user must review the T&Cs periodically. If the user
        doesn’t agree to any changes they may exercise their Right to Deletion
        under GDPR and close their account.
      </Paragraph>
      <ParagraphTitle1>§ 19 Final provisions</ParagraphTitle1>
      <Paragraph>
        The Terms shall be subject to Austrian law, excluding the UN Convention
        of International Sale of Goods and the reference norms (IPRG, EVÜ,
        etc.); in case that the contracting party is a private user, the law of
        the user&apos;s habitual residence country applies, if more favourable
        to the user. The exclusive place of jurisdiction is the relevant court
        in Vienna, Austria. For possible cases, where a lawsuit against a
        private user shall be filed; it has to be filed at the user’s general
        place of jurisdiction.
      </Paragraph>
      <Paragraph>
        With a view to reaching an out of court settlement between Shpock and
        the user, the user may contact the online platform provided by the EU.
        The ADR platform is available here: http://ec.europa.eu/consumers/odr/.
      </Paragraph>
      <Paragraph>
        If individual stipulations of the Terms should be or become ineffective,
        invalid or unworkable, this shall not touch the legal effectiveness of
        the other stipulations of the Terms. Instead a valid and workable
        regulation shall apply. The above stipulations apply accordingly in the
        event that the contract is shown to be incomplete.
      </Paragraph>
      <Paragraph>
        Terms and conditions version: 2021-02-10 finderly GmbH, Vienna, Austria
      </Paragraph>
    </Box>
  </PageLayout>
);

export default TermsNConditionsPage;
