import React, { useState, useRef, useEffect, ReactNode } from 'react';
import useFetch from 'use-http';
import { useSelector } from 'react-redux';
import useSecurity from '../../hooks/useSecurity';
import { Profile } from '../../types/Profile';
import { State } from '../../store/types';
import { BASE_URL } from '../../constants';
import badgeCountContext from './context';

type BadgeCountProviderProps = {
  children: ReactNode;
};

type Listener = () => void;

const BadgeCountProvider = ({
  children,
}: BadgeCountProviderProps): JSX.Element => {
  const newNonEmptyChatMessagesCountListerners = useRef<Listener[]>([]);
  const newNonEmptyNotificationsCountListerners = useRef<Listener[]>([]);
  const [reloadBadge, setReloadBadge] = useState(false);
  const profile = useSelector<State, Profile | null>((state) => state.profile);
  const { loggedIn } = useSecurity();
  const [badgeCount, setBadgeCount] = useState({
    unseenNotificationsCount: 0,
    unseenMessagesCount: 0,
  });
  const badgeCountRef = useRef(badgeCount);
  const pollingCountRef = useRef(0);

  const { get, response, cache } = useFetch(BASE_URL);

  async function getBadgeCount() {
    pollingCountRef.current += 1;

    const data = await get(
      `/profiles/${profile?.ID}/badge_count?polling=${pollingCountRef.current}`,
    );

    if (response.ok) {
      if (
        badgeCountRef.current.unseenNotificationsCount !==
          data.unseenNotificationsCount ||
        badgeCountRef.current.unseenMessagesCount !== data.unseenMessagesCount
      ) {
        badgeCountRef.current = data;
        cache.clear();
        setBadgeCount(data);
      }
    }
  }

  function reload() {
    setReloadBadge(!reloadBadge);
  }

  function onNewNonEmptyChatMessagesCount(listener: () => void) {
    newNonEmptyChatMessagesCountListerners.current.push(listener);
  }

  function clearNewNonEmptyChatMessagesCountListeners() {
    if (newNonEmptyChatMessagesCountListerners.current.length > 0) {
      newNonEmptyChatMessagesCountListerners.current = [];
    }
  }

  function onNewNonEmptyNotificationsCount(listener: () => void) {
    newNonEmptyNotificationsCountListerners.current.push(listener);
  }

  function clearNewNonEmptyNotificationsCountListeners() {
    if (newNonEmptyNotificationsCountListerners.current.length > 0) {
      newNonEmptyNotificationsCountListerners.current = [];
    }
  }

  useEffect(() => {
    if (badgeCount.unseenMessagesCount > 0) {
      newNonEmptyChatMessagesCountListerners.current.forEach((listener) =>
        listener(),
      );
    }

    if (badgeCount.unseenNotificationsCount > 0) {
      newNonEmptyNotificationsCountListerners.current.forEach((listener) =>
        listener(),
      );
    }
  }, [badgeCount]);

  useEffect(() => {
    if (loggedIn && profile) {
      getBadgeCount();
    }
  }, [reloadBadge]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Polling
    if (loggedIn && profile) {
      const timer = setInterval(() => {
        getBadgeCount();
      }, 30000);

      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  return (
    <badgeCountContext.Provider
      value={{
        ...badgeCount,
        reload,
        onNewNonEmptyChatMessagesCount,
        clearNewNonEmptyChatMessagesCountListeners,
        onNewNonEmptyNotificationsCount,
        clearNewNonEmptyNotificationsCountListeners,
      }}
    >
      {children}
    </badgeCountContext.Provider>
  );
};

export default BadgeCountProvider;
