import {
  UserAvatar as UserAvatarOriginal,
  SmallUserAvatar as SmallUserAvatarOriginal,
  ExtraSmallUserAvatar as ExtraSmallUserAvatarOriginal,
  ItemAvatar as ItemAvatarOriginal,
} from './MyAvatars';

export const SmallUserAvatar = SmallUserAvatarOriginal;
export const ExtraSmallUserAvatar = ExtraSmallUserAvatarOriginal;
export const UserAvatar = UserAvatarOriginal;

export const ItemAvatar = ItemAvatarOriginal;
