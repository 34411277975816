import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

const MyIconButton = (props: IconButtonProps): JSX.Element => (
  <IconButton
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      width: '34px',
      height: '34px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  />
);

export default MyIconButton;
