import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { appBar } from '../../theme/theme';

type AppBarIconWrapperProps = {
  children: ReactNode;
  justifyContent: string;
};

const AppBarIconWrapper = ({
  children,
  justifyContent,
}: AppBarIconWrapperProps): JSX.Element => (
  <Box sx={{ minWidth: appBar.iconWrapper.minWidth }}>
    <Grid container justifyContent={justifyContent} alignItems="center">
      <Grid item>{children}</Grid>
    </Grid>
  </Box>
);

export default AppBarIconWrapper;
