import React, { useEffect, useRef, useState } from 'react';
import { ChatFeed, Message as ChatMessage } from 'react-chat-ui';
import { Box } from '@mui/system';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useHistory } from 'react-router';
import { Profile } from '../../types/Profile';
import Feedback, {
  SomethingWentWrongFeedback,
} from '../../components/Feedback';
import Spinner from '../../components/Spinner';
import MyDialog from '../../components/MyDialog';
import MyAppBar from '../../components/MyAppBar';
import MyButton from '../../components/MyButton';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyCloseIcon from '../../components/MyCloseIcon';
import MyIconButton from '../../components/MyIconButton';
import { Message } from '../../types/Message';
import { Proposition } from '../../types/Proposition';
import InputField from './InputField';
import { MyCardHeader } from '../../components/MyCards/MyCards';
import { SmallUserAvatar } from '../../components/MyAvatars';
import OnlineStatusBadge from '../../components/OnlineStatusBadge';
import { chat } from '../../theme/theme';

type ChatDialogProps = {
  open: boolean;
  onClose: () => void;
  profile: Profile | null;
  proposition: Proposition;
  error: boolean;
  loading: boolean;
  messages: Message[] | null;
  handleSendIconClick: (text: string) => void;
  handleSenderTyping: (typing: boolean) => void;
  recipientTyping: boolean;
  recipientOnline: boolean;
};

const ChatDialog = ({
  open,
  onClose,
  profile,
  proposition,
  error,
  loading,
  messages,
  handleSendIconClick,
  handleSenderTyping,
  recipientTyping,
  recipientOnline,
}: ChatDialogProps): JSX.Element => {
  const dialogContentRef = useRef<HTMLDivElement | null>(null);
  const [dialogContentHeight, setDialogContentHeight] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (dialogContentRef.current) {
      setDialogContentHeight(dialogContentRef.current.offsetHeight);
    }
  });

  const theme = useTheme();
  const history = useHistory();

  const isMe = (msg: Message): boolean => {
    if (msg.profile.ID === profile?.ID) return true;
    return false;
  };

  const createChatMessage = (msg: Message): ChatMessage =>
    new ChatMessage({
      id: isMe(msg) ? 0 : 1,
      message: msg.text,
      senderName: 'Sender name',
    });

  let iAmProposer = false;

  if (profile) {
    iAmProposer = proposition.item.profile.ID === profile.ID;
  }

  const canShowSpinner = () =>
    !error && (!loading || loading) && messages === null;
  const canShowError = () => error && !loading;
  const canShowChat = () => !error && !loading && messages !== null;

  function handleCloseIconClick() {
    onClose();
  }

  function handleViewPropositionButtonClick() {
    history.push(`/proposition/${proposition.ID}`);
    onClose();
  }

  return (
    <MyDialog open={open} onClose={onClose}>
      <DialogTitle sx={{ padding: '0', position: 'sticky' }}>
        <MyAppBar
          leftSideRender={(): JSX.Element => (
            <Box marginLeft="0">
              <AppBarIconWrapper justifyContent="flex-start">
                <MyIconButton onClick={handleCloseIconClick}>
                  <MyCloseIcon />
                </MyIconButton>
              </AppBarIconWrapper>
            </Box>
          )}
          middleRender={() => (
            <MyCardHeader
              title={
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: '20px !important',
                    fontWeight: 'bold !important',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {(profile as Profile).ID === proposition.need.profile.ID
                    ? proposition.item.profile.firstname
                    : proposition.need.profile.firstname}
                </Typography>
              }
              avatar={
                <>
                  {recipientOnline && (
                    <OnlineStatusBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <SmallUserAvatar
                        alt={
                          (profile as Profile).ID ===
                          proposition.need.profile.ID
                            ? proposition.item.profile.firstname
                            : proposition.need.profile.firstname
                        }
                        src={
                          (profile as Profile).ID ===
                          proposition.need.profile.ID
                            ? proposition.item.profile.avatar
                            : proposition.need.profile.avatar
                        }
                      />
                    </OnlineStatusBadge>
                  )}
                  {!recipientOnline && (
                    <SmallUserAvatar
                      alt={
                        (profile as Profile).ID === proposition.need.profile.ID
                          ? proposition.item.profile.firstname
                          : proposition.need.profile.firstname
                      }
                      src={
                        (profile as Profile).ID === proposition.need.profile.ID
                          ? proposition.item.profile.avatar
                          : proposition.need.profile.avatar
                      }
                    />
                  )}
                </>
              }
            />
          )}
          rightSideRender={() => (
            <Box marginRight="0">
              <MyButton
                size="small"
                variant="outlined"
                onClick={() => handleViewPropositionButtonClick()}
              >
                View proposition
              </MyButton>
            </Box>
          )}
        />
      </DialogTitle>

      {error && <SomethingWentWrongFeedback />}

      {!error && (
        <>
          <DialogContent sx={{ padding: '0' }} ref={dialogContentRef}>
            <Box sx={{ height: '100%' }}>
              {canShowSpinner() && <Spinner />}

              {canShowError() && <SomethingWentWrongFeedback />}

              {canShowChat() && (
                <>
                  {(messages as Message[]).length === 0 && iAmProposer ? (
                    <Feedback
                      title="Pending message request"
                      subtitle={`To send messages to ${proposition.need.profile.firstname}, he/she needs to accept the request.`}
                    />
                  ) : (
                    <Box height="100%">
                      <Box
                        sx={{
                          height: '100%',
                          '& .chat-messages>div': {
                            marginTop: '12px !important',
                            marginBottom: '12px !important',
                            paddingLeft: '16px !important',
                            paddingRight: '16px !important',
                          },
                          '& h5': {
                            fontFamily: theme.typography.fontFamily,
                          },
                          '& p': {
                            fontSize: theme.typography.body1.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          },
                        }}
                      >
                        <ChatFeed
                          messages={(messages as Message[]).map(
                            createChatMessage,
                          )}
                          maxHeight={
                            dialogContentHeight
                              ? `${dialogContentHeight}px`
                              : '100%'
                          }
                          // showSenderName
                          isTyping={recipientTyping}
                          bubbleStyles={{
                            text: {
                              fontSize: theme.typography.body1.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            },
                            userBubble: {
                              borderRadius: chat.bubble.border.radius,
                              margin: chat.bubble.margin,
                              padding: chat.bubble.padding,
                              backgroundColor: chat.bubble.user.backgroundColor,
                            },
                            chatbubble: {
                              borderRadius: chat.bubble.border.radius,
                              margin: chat.bubble.margin,
                              padding: chat.bubble.padding,
                              backgroundColor:
                                chat.bubble.chattee.backgroundColor,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </DialogContent>
          {canShowChat() &&
            !((messages as Message[]).length === 0 && iAmProposer) && (
              <DialogActions
                disableSpacing
                sx={{
                  padding: '0',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                }}
              >
                <Divider sx={{ marginBottom: '2px' }} />
                <Box sx={{ padding: '4px' }}>
                  <InputField
                    onSend={handleSendIconClick}
                    onTyping={handleSenderTyping}
                  />
                </Box>
              </DialogActions>
            )}
        </>
      )}
    </MyDialog>
  );
};

export default ChatDialog;
