import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useFetch from 'use-http';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import MyTextField from '../../components/MyTextField';
import Spinner from '../../components/Spinner';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { PasswordResetData } from '../../types/PasswordResetData';
import { BASE_URL } from '../../constants';
import { dialog } from '../../theme/theme';
import MyCloseIcon from '../../components/MyCloseIcon';

type FormValues = {
  email: string;
};

type FindAccountPropos = {
  goNext: () => void;
  onClose: () => void;
};

const FindAccount = ({ goNext, onClose }: FindAccountPropos): JSX.Element => {
  const [emailNotFound, setEmailNotFound] = useState(false);
  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { errors, isDirty, isValid } = formState;

  const { post, loading, error, response } = useFetch(BASE_URL);

  function storePasswordResetDataInCache(data: PasswordResetData) {
    localStorage.setItem('passwordResetData', JSON.stringify(data));
  }

  async function sendPasswordResetData(data: PasswordResetData) {
    await post('/password_reset', data);
    if (response.ok) {
      storePasswordResetDataInCache(data);
      goNext();
    } else if (response.status === 400) {
      setEmailNotFound(true);
    }
  }

  function generateFlowToken(): string {
    return uuidv4();
  }

  function buildPasswordResetData(): PasswordResetData {
    let { email } = getValues();
    email = email.trim();

    const passwordResetData = {
      token: generateFlowToken(),
      steps: [
        {
          name: 'find_account',
          inputs: { email },
        },
      ],
    } as PasswordResetData;

    return passwordResetData;
  }

  function handleNextClick() {
    const passwordResetData = buildPasswordResetData();
    sendPasswordResetData(passwordResetData);
  }

  function handleCloseIconClick() {
    onClose();
  }

  return (
    <>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleCloseIconClick}>
                <MyCloseIcon />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography
            variant="h5"
            sx={{
              textOverflow: 'ellipsis',
            }}
          >
            Find your Bonvih account
          </Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              disabled={!isDirty || !isValid}
              onClick={handleNextClick}
            >
              Search
            </MyButton>
          </Box>
        )}
      />

      {loading && !error && !emailNotFound && <Spinner />}

      {!loading && error && !emailNotFound && <SomethingWentWrongFeedback />}

      {((!loading && !error && !emailNotFound) ||
        (!loading && error && emailNotFound)) && (
        <>
          <Box sx={{ padding: dialog.input.padding }}>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              rules={{
                required: "What's your email?",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email.',
                },
              }}
              render={({ field }) => (
                <MyTextField
                  id="input-email"
                  label="Email"
                  error={!!errors.email || emailNotFound}
                  helperText={
                    (errors.email && errors.email.message) ||
                    (emailNotFound && 'Sorry, we could not find your account')
                  }
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default FindAccount;
