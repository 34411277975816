import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { Proposition } from '../../types/Proposition';
import { BASE_URL } from '../../constants';
import { Need } from '../../types/Need';
import Propositions from './Propositions';

type PropositionsContainerProps = {
  need: Need;
  reloadPropositions: boolean;
  deletePropositionCallback: () => void;
};

const PropositionsContainer = ({
  need,
  reloadPropositions,
  deletePropositionCallback,
}: PropositionsContainerProps): JSX.Element => {
  const [propositions, setPropositions] = useState<Proposition[] | null>(null);
  const [hasMore, setHasMore] = useState(true);

  const LIMIT = 5;

  const { get, loading, error, response } = useFetch(BASE_URL);

  const {
    del,
    cache,
    error: delError,
    response: delResponse,
  } = useFetch(BASE_URL);

  const getPropositions = async () => {
    const data = await get(
      `/needs/${need.ID}/propositions?limit=${LIMIT}&offset=0`,
    );

    if (response.ok) {
      if (data.length < LIMIT) {
        setHasMore(false);
      }

      setPropositions(data);
    }
  };

  useEffect(() => {
    setHasMore(true);
    getPropositions();
  }, [reloadPropositions, need.ID]);

  const {
    get: getMore,
    error: getMoreError,
    response: getMoreReponse,
  } = useFetch(BASE_URL);

  const getMorePropositions = async () => {
    const data = await getMore(
      `/needs/${need.ID}/propositions?limit=${LIMIT}&offset=${
        (propositions as Proposition[]).length
      }`,
    );

    if (getMoreReponse.ok) {
      if (data.length < LIMIT) {
        setHasMore(false);
      }

      if (propositions) {
        setPropositions([...propositions, ...data]);
      }
    }
  };

  function handleGetMorePropositions() {
    getMorePropositions();
  }

  async function handleNotInterested(proposition: Proposition) {
    await del(`/propositions/${proposition.ID}`);

    if (delResponse.ok) {
      cache.clear();

      if (propositions) {
        setPropositions(propositions.filter((p) => p.ID !== proposition.ID));
      }

      deletePropositionCallback();
    }
  }

  return (
    <Propositions
      propositions={propositions}
      loading={loading}
      error={Boolean(error) || Boolean(getMoreError) || Boolean(delError)}
      handleGetMorePropositions={handleGetMorePropositions}
      handleNotInterested={handleNotInterested}
      more={hasMore}
    />
  );
};

export default PropositionsContainer;
