import React from 'react';
import { useHistory } from 'react-router';
import Spinner from '../../../components/Spinner';
import Feedback, {
  SomethingWentWrongFeedback,
} from '../../../components/Feedback';
import SearchNeedCard from '../../../components/MyCards/SearchNeedCard';
import MyButton from '../../../components/MyButton';
import { Need } from '../../../types/Need';
import { Profile } from '../../../types/Profile';
import useDialogsManager from '../../../hooks/useDialogsManager';
import Results from '../../SearchNeedsPage/Results';

type NeedingProps = {
  needs: Need[] | null;
  loading: boolean;
  error: boolean;
  itsMe: () => boolean;
  profile: Profile;
};

const Needing = ({
  needs,
  loading,
  error,
  itsMe,
  profile,
}: NeedingProps): JSX.Element => {
  const { openCreateNewNeedDialog } = useDialogsManager();
  const history = useHistory();

  function handleCreateButtonClick() {
    if (openCreateNewNeedDialog) {
      openCreateNewNeedDialog((created) => history.push(`/need/${created.ID}`));
    }
  }

  if (loading) return <Spinner />;

  if (error) return <SomethingWentWrongFeedback />;

  if (needs === null) return <Spinner />;

  if (needs.length === 0) {
    if (itsMe()) {
      return (
        <Feedback
          title="You are not spending any money now"
          subtitle="When you are, it will show up here."
          renderAction={() => (
            <MyButton size="large" onClick={handleCreateButtonClick}>
              List your money
            </MyButton>
          )}
        />
      );
    }

    return (
      <Feedback
        title={`${profile.firstname} is not spending anything now`}
        subtitle="When it is the case, it will show up here."
      />
    );
  }

  return (
    <Results
      items={needs}
      renderItem={(need: Need) => <SearchNeedCard need={need} />}
      more={false}
    />
  );
};

export default Needing;
