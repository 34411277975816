import { useState, useEffect, DependencyList } from 'react';
import useFetch, { Cache } from 'use-http';
import countries from 'i18n-iso-countries';
import useGeoLocation from './useGeoLocation';
import { BASE_URL } from '../constants';
import { Need } from '../types/Need';
import { BiasPoint } from '../pages/SearchNeedsPage/LocationFilter';

function url(
  title: string,
  minAmount: number,
  maxAmount: number,
  category: string,
  orderBy: string,
  countryISOCode: string,
  cityGeonameID: number,
  locationLatitude: number,
  locationLongitude: number,
  radius: number,
  limit: number,
  offset: number,
) {
  return `${BASE_URL}/search/needs?minAmount=${minAmount}&maxAmount=${maxAmount}&title=${title}&category=${category}&orderBy=${orderBy}&countryISOCode=${countryISOCode}&cityGeonameID=${cityGeonameID}&locationLatitude=${locationLatitude}&locationLongitude=${locationLongitude}&radius=${radius}&limit=${limit}&offset=${offset}`;
}

type Params = {
  title?: string;
  minAmount?: number;
  maxAmount?: number;
  category?: string;
  orderBy?: string;
  cityGeonameID?: number;
  radius?: number;
  biasPoint?: BiasPoint | null;
  limit?: number;
  offset?: number;
};

const useSearchNeeds = (
  params?: Params,
  deps?: DependencyList,
): {
  needs: Need[];
  loading: boolean;
  error: boolean;
  searchMore: () => void;
  loadingMore: boolean;
  hasMore: boolean;
  cache: Cache;
} => {
  let biasPointLatitude: number;
  let biasPointLongitude: number;
  let biasPointCountryISOCode: string;

  const { countryISOCode, locationLatitude, locationLongitude } =
    useGeoLocation();
  const [needs, setNeeds] = useState<Need[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentRadius, setCurrentRadius] = useState(-1);
  const [currentBiasPoint, setCurrentBiasPoint] = useState<BiasPoint | null>(
    null,
  );
  const [currentOrderBy, setCurrentOrderBy] = useState('');

  const defaultParams = {
    title: '',
    minAmount: 0,
    maxAmount: -1,
    category: '',
    orderBy: '',
    cityGeonameID: 0,
    radius: -1,
    biasPoint: null as BiasPoint | null,
    limit: 1,
    offset: 0,
  };

  let finalParams: typeof defaultParams;

  if (params !== undefined) {
    finalParams = {
      ...defaultParams,
      ...params,
    };
  } else {
    finalParams = {
      ...defaultParams,
    };
  }

  const {
    title,
    minAmount,
    maxAmount,
    category,
    orderBy,
    cityGeonameID,
    radius,
    biasPoint,
    limit,
    offset,
  } = finalParams;

  if (currentRadius !== radius) {
    setCurrentRadius(radius);
    setHasMore(true);
  }

  if (currentBiasPoint !== biasPoint) {
    setCurrentBiasPoint(biasPoint);
    setHasMore(true);
  }

  if (currentOrderBy !== orderBy) {
    setCurrentOrderBy(orderBy);
    setHasMore(true);
  }

  if (!biasPoint) {
    biasPointLatitude = locationLatitude;
    biasPointLongitude = locationLongitude;
    biasPointCountryISOCode = countryISOCode;
  } else {
    biasPointLatitude = biasPoint.latitude;
    biasPointLongitude = biasPoint.longitude;
    biasPointCountryISOCode = biasPoint.countryISOCode;
  }

  if (biasPointCountryISOCode.length === 2) {
    biasPointCountryISOCode = countries.alpha2ToAlpha3(biasPointCountryISOCode);
  }

  const { get, loading, error, response, cache } = useFetch<Need[]>(
    url(
      title,
      minAmount,
      maxAmount,
      category,
      orderBy,
      biasPointCountryISOCode,
      cityGeonameID,
      biasPointLatitude,
      biasPointLongitude,
      radius,
      limit,
      offset,
    ),
    {},
  );

  useEffect(() => {
    (async () => {
      const data = await get('');

      if (response.ok) {
        if (data.length < limit) {
          setHasMore(false);
        }

        setNeeds(data);
      }
    })();
  }, deps);

  const MORE_LIMIT = 1;

  const {
    get: getMore,
    loading: loadingMore,
    error: getMoreError,
    response: getMoreResponse,
  } = useFetch<Need[]>(
    url(
      title,
      minAmount,
      maxAmount,
      category,
      orderBy,
      biasPointCountryISOCode,
      cityGeonameID,
      biasPointLatitude,
      biasPointLongitude,
      radius,
      MORE_LIMIT,
      (needs as Need[]).length,
    ),
    {},
  );

  async function searchMore() {
    const data = await getMore('');

    if (getMoreResponse.ok) {
      if (data.length < MORE_LIMIT) {
        setHasMore(false);
      }

      setNeeds([...needs, ...data]);
    }
  }

  return {
    needs,
    loading,
    error: Boolean(error) || Boolean(getMoreError),
    searchMore,
    loadingMore,
    hasMore,
    cache,
  };
};

export default useSearchNeeds;
