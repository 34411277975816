import React, { forwardRef } from 'react';
import {
  TextField,
  TextFieldProps,
  OutlinedInputProps,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  input as themeInput,
  icon,
  colors,
  select,
  borderRadius,
} from '../../theme/theme';

export const MyPasswordField = forwardRef<Ref, OutlinedInputProps>(
  (props, ref): JSX.Element => {
    const theme = useTheme();

    const [values, setValues] = React.useState({
      amount: '',
      password: '',
      weight: '',
      weightRange: '',
      showPassword: false,
    });

    const handleClickShowPassword = () => {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    };

    return (
      <FormControl
        variant="outlined"
        sx={{
          margin: '0px',
          width: '100%',
          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              borderColor: themeInput.border.color,
              borderRadius,
            },
          },
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: themeInput.border.color,
              borderRadius,
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: `${colors.primary}`,
              borderRadius,
            },
          },
          '& .MuiOutlinedInput-root.Mui-error': {
            '& > fieldset': {
              borderColor: `${theme.palette.error.main}`,
              borderRadius,
            },
          },
        }}
      >
        <InputLabel
          htmlFor="outlined-adornment-password"
          sx={{ fontSize: theme.typography.body1.fontSize }}
        >
          {/* eslint-disable-next-line */}
          {props.label}
        </InputLabel>
        <OutlinedInput
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          type={values.showPassword ? 'text' : 'password'}
          inputRef={ref}
          inputProps={{
            padding: '15.5px 14px !important',
          }}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                edge="end"
              >
                {values.showPassword ? (
                  <VisibilityOff
                    sx={{
                      fontSize: icon.fontSize.medium,
                      color: icon.colorLight,
                    }}
                  />
                ) : (
                  <Visibility
                    sx={{
                      fontSize: icon.fontSize.medium,
                      color: icon.colorLight,
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );
  },
);

MyPasswordField.displayName = 'MyPasswordField';

type Ref = HTMLInputElement;

const MyTextField = forwardRef<Ref, TextFieldProps>(
  (props, ref): JSX.Element => {
    const theme = useTheme();
    return (
      <TextField
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        sx={{
          '& .MuiInputBase-root': {
            minHeight: themeInput.minHeight,
          },
          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              borderColor: themeInput.border.color,
              borderRadius,
            },
          },
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: themeInput.border.color,
              borderRadius,
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: `${colors.primary}`,
              borderRadius,
            },
          },
          '& .MuiOutlinedInput-root.Mui-error': {
            '& > fieldset': {
              borderColor: `${theme.palette.error.main}`,
              borderRadius,
            },
          },
        }}
        InputLabelProps={{
          sx: {
            fontSize: theme.typography.body1.fontSize,
          },
        }}
        inputProps={{
          sx: {
            fontSize: theme.typography.body1.fontSize,
            color: colors.black,
          },
        }}
        SelectProps={{
          MenuProps: {
            sx: {
              '& .MuiList-root': {
                paddingTop: '0 !important',
                paddingBottom: '0 !important',
              },
              '& .MuiMenuItem-root': {
                fontSize: theme.typography.body1.fontSize,
                padding: `${select.option.padding} !important`,
              },
            },
          },
        }}
        inputRef={ref}
        variant="outlined"
        fullWidth
      />
    );
  },
);

MyTextField.displayName = 'MyTextField';

export default MyTextField;
