import React from 'react';
import {
  Card,
  CardProps,
  CardHeader,
  CardHeaderProps,
  CardContent,
  CardContentProps,
} from '@mui/material';
import { card, borderRadius } from '../../theme/theme';

export const MyCard = (props: CardProps): JSX.Element => (
  <Card
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      padding: card.padding,
      boxShadow: 'unset',
      borderRadius,
    }}
  />
);

export const MyCardHeader = (props: CardHeaderProps): JSX.Element => (
  <CardHeader
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    sx={{
      padding: '0px',
      '& .MuiCardHeader-title': {
        fontWeight: 'bold',
        fontSize: '15px',
      },
      '& .MuiCardHeader-avatar': {
        marginRight: '12px',
      },
    }}
  />
);

export const MyCardContent = (props: CardContentProps): JSX.Element => {
  const { sx } = props;
  return (
    <CardContent
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      sx={{
        ...sx,
        padding: '0px',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        // paddingLeft: '60px !important',
      }}
    />
  );
};

export default MyCard;
