import { createContext } from 'react';

export type BadgeCount = {
  unseenNotificationsCount: number;
  unseenMessagesCount: number;
  reload?: () => void;
  onNewNonEmptyChatMessagesCount?: (listener: () => void) => void;
  clearNewNonEmptyChatMessagesCountListeners?: () => void;
  onNewNonEmptyNotificationsCount?: (listener: () => void) => void;
  clearNewNonEmptyNotificationsCountListeners?: () => void;
};

const badgeCountContext = createContext<BadgeCount>({
  unseenNotificationsCount: 0,
  unseenMessagesCount: 0,
});

export default badgeCountContext;
