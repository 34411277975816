import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import { Typography } from '@mui/material';

import { MyCard, MyCardHeader } from './MyCards';
import CardHover from './CardHover';
import { Proposition } from '../../types/Proposition';
import { colors } from '../../theme/theme';

type ProposisitionNotificationCardProps = {
  proposition: Proposition;
};

const ProposisitionNotificationCard = ({
  proposition,
}: ProposisitionNotificationCardProps): JSX.Element => (
  <Link
    to={`/proposition/${proposition.ID.toString()}`}
    style={{ textDecoration: 'none' }}
  >
    <Box
      sx={{
        '& .MuiCardHeader-root': {
          alignItems: 'stretch !important',
        },
        '& .MuiCardHeader-avatar': {
          flexBasis: '48px',
          justifyContent: 'flex-end',
        },
      }}
    >
      <CardHover>
        <MyCard>
          <MyCardHeader
            title={
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 'bold !important',
                  }}
                >
                  {proposition.item.profile.firstname}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      fontWeight: 'normal',
                    }}
                  >
                    &nbsp;proposed {proposition.item.name}
                  </Typography>
                </Typography>
                <Box marginTop="12px">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'normal !important',
                      color: colors.grey,
                    }}
                  >
                    {proposition.item.description}
                  </Typography>
                </Box>
              </Box>
            }
            avatar={
              <StarIcon sx={{ fontSize: '30px', color: colors.primary }} />
            }
          />
        </MyCard>
      </CardHover>
    </Box>
  </Link>
);

export default ProposisitionNotificationCard;
