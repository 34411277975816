export const NEWEST_FIRST = 'Newest first';
export const OFFER_HIGH_TO_LOW = 'Money high to low';
export const OFFER_LOW_TO_HIGH = 'Money low to high';

export const sortOptionMappings = {
  [NEWEST_FIRST]: 'updated_at desc',
  [OFFER_HIGH_TO_LOW]: 'offer desc',
  [OFFER_LOW_TO_HIGH]: 'offer asc',
};

export const findCorrespondingSortOption = (sortValue: string): string => {
  const sortOption = Object.keys(sortOptionMappings).find(
    (key) =>
      sortOptionMappings[key as keyof typeof sortOptionMappings] === sortValue,
  );
  if (sortOption) {
    return sortOption;
  }
  return '';
};

export const getQueryParamFromSortOption = (sortOption: string): string => {
  switch (sortOption) {
    case NEWEST_FIRST:
      return sortOptionMappings[NEWEST_FIRST];

    case OFFER_HIGH_TO_LOW:
      return sortOptionMappings[OFFER_HIGH_TO_LOW];

    case OFFER_LOW_TO_HIGH:
      return sortOptionMappings[OFFER_LOW_TO_HIGH];

    default:
      return '';
  }
};
