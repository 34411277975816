import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { State } from '../../store/types';
import { Profile } from '../../types/Profile';
import CreateNewItemDialog from './CreateNewItemDialog';
import { Item } from '../../types/Item';

type CreateNewItemDialogContainerProps = {
  open: boolean;
  onClose: () => void;
  callback?: (created: Item) => void;
};

const CreateNewItemDialogContainer = ({
  open,
  onClose,
  callback,
}: CreateNewItemDialogContainerProps): JSX.Element => {
  const profile = useSelector<State, Profile>(
    (state) => state.profile as Profile,
  );

  const theme = useTheme();
  const isSmallDeviceDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CreateNewItemDialog
      open={open}
      onClose={onClose}
      callback={callback}
      profile={profile}
      isSmallDeviceDown={isSmallDeviceDown}
    />
  );
};

CreateNewItemDialogContainer.defaultProps = {
  callback: undefined,
};

export default CreateNewItemDialogContainer;
