import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import countries from 'i18n-iso-countries';
import { Need } from '../../types/Need';
import useGeoLocation from '../../hooks/useGeoLocation';
import { BASE_URL } from '../../constants';
import WhatsHappening from './WhatsHappening';

const WhatsHappeningContainer = (): JSX.Element => {
  const [needs, setNeeds] = useState<Need[] | null>(null);

  const { get, loading, error, response } = useFetch(BASE_URL);

  const { countryISOCode } = useGeoLocation();

  let country = countryISOCode;

  if (country.length === 2) {
    country = countries.alpha2ToAlpha3(country);
  }

  const getWhatsHappening = async () => {
    const data = await get(`/whatshappening?country=${country}`);

    if (response.ok) {
      setNeeds(data);
    }
  };

  useEffect(() => {
    getWhatsHappening();
  }, []);

  return (
    <WhatsHappening needs={needs} loading={loading} error={Boolean(error)} />
  );
};

export default WhatsHappeningContainer;
