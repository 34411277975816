import React, { useState } from 'react';
import { Box } from '@mui/system';
import Divider from '@mui/material/Divider';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Spinner from '../../components/Spinner';
import {
  AlertFeedback,
  SomethingWentWrongFeedback,
} from '../../components/Feedback';
import MyTabs, { MyTab } from '../../components/MyTabs';
import PageLayout from '../../components/PageLayout';
import { CategoryNode } from '../../utils/categories';
import { Need } from '../../types/Need';
import TitleBar from '../SearchNeedsPage/TitleBar';
import {
  FilterByLocationOptions,
  BiasPoint,
} from '../SearchNeedsPage/LocationFilter';
import TabViewContainer from '../SearchNeedsPage/TabViewContainer';
import { bar, divider, icon } from '../../theme/theme';
import { calculateValue } from '../SearchNeedsPage/miles';

type ExplorePageProps = {
  needs: Need[];
  selectedCategoryNode: CategoryNode | null;
  handleCategoryNodeChange: (selected: CategoryNode) => void;
  handleFilterByLocation: (newOptions: FilterByLocationOptions) => void;
  loading: boolean;
  error: boolean;
  biasPoint: BiasPoint | null;
  radiusIndex: number;
  radiusOn: boolean;
};

const ExplorePage = ({
  needs,
  selectedCategoryNode,
  handleCategoryNodeChange,
  handleFilterByLocation,
  loading,
  error,
  biasPoint,
  radiusIndex,
  radiusOn,
}: ExplorePageProps): JSX.Element => {
  const [searchFilterException, setSearchFilterException] = useState(false);

  function isAncestorCategoryNode(
    node: CategoryNode,
    ancestor: CategoryNode,
  ): boolean {
    let currentNode = node.parent;
    let isAncestor = false;
    while (currentNode !== null) {
      if (currentNode === ancestor) {
        isAncestor = true;
        break;
      }
      currentNode = currentNode.parent;
    }
    return isAncestor;
  }

  function renderTabs() {
    const TabsComponents = [];
    let node = selectedCategoryNode;

    while (node !== null) {
      if (node.children !== null) {
        const currentCategoryNode = node;
        let selectedCategoryNodeIndex = 0;
        if (
          currentCategoryNode.children !== null &&
          selectedCategoryNode !== null
        ) {
          selectedCategoryNodeIndex = currentCategoryNode.children.findIndex(
            (child) =>
              child === selectedCategoryNode ||
              isAncestorCategoryNode(selectedCategoryNode, child),
          );
        }

        TabsComponents.unshift(
          <Box key={node.name}>
            <MyTabs
              key={currentCategoryNode.name}
              value={
                selectedCategoryNodeIndex >= 0
                  ? selectedCategoryNodeIndex + 1
                  : 0
              }
              // eslint-disable-next-line no-loop-func
              onChange={(e, selectedTabIndex) => {
                if (
                  currentCategoryNode !== null &&
                  currentCategoryNode.children !== null
                ) {
                  if (selectedTabIndex === 0) {
                    handleCategoryNodeChange(currentCategoryNode);
                  } else {
                    handleCategoryNodeChange(
                      currentCategoryNode.children[selectedTabIndex - 1],
                    );
                  }
                }
              }}
              onClick={() => {
                (
                  window as {
                    scroll: ({
                      top,
                      left,
                    }: {
                      top: number;
                      left: number;
                    }) => void;
                  }
                ).scroll({
                  top: 0,
                  left: 0,
                  // behavior: 'smooth',
                });
              }}
            >
              <MyTab label="All" />
              {currentCategoryNode.children !== null &&
                currentCategoryNode.children.map((child) => (
                  <MyTab key={child.name} label={child.name} />
                ))}
            </MyTabs>
            <Divider
              sx={{
                marginTop: '-1px',
                marginLeft: '2px',
                marginRight: '2px',
                borderColor: divider.color,
              }}
            />
          </Box>,
        );
      }
      node = node.parent;
    }

    return TabsComponents;
  }

  const canShowSpinner = () => loading && !error && !searchFilterException;
  const canShowErrorMessage = () =>
    !loading && (error || searchFilterException);
  const canShowEmptyMessage = () =>
    !loading &&
    !error &&
    !searchFilterException &&
    needs.length === 0 &&
    !radiusOn;
  const canShowEmptyMessageRadiusOn = () =>
    !loading &&
    !error &&
    !searchFilterException &&
    needs.length === 0 &&
    radiusOn;
  const canShowNeeds = () =>
    !loading && !error && !searchFilterException && needs.length > 0;

  const radiusInMiles = calculateValue(radiusIndex);

  return (
    <PageLayout>
      {true && (
        <Box>
          {/* The negative margin fixes the side borders of the cards showing up behind the Title when scrolling */}
          <Box position="sticky" top={bar.height} zIndex="2" margin="0 -2px">
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
              }}
            >
              {/* The padding cancels the negative margin on the previous comment */}
              <Box padding="0 2px">
                <Box marginBottom="0">
                  <TitleBar
                    title="Explore money by category"
                    radiusIndex={radiusIndex}
                    biasPoint={biasPoint}
                    handleFilterByLocation={handleFilterByLocation}
                    filtersActivated={needs.length >= 0}
                    onSearchFilterException={() =>
                      setSearchFilterException(true)
                    }
                  />
                </Box>

                {canShowSpinner() && <Spinner />}

                {canShowErrorMessage() && <SomethingWentWrongFeedback />}

                {canShowEmptyMessageRadiusOn() && (
                  <AlertFeedback
                    renderIcon={() => (
                      <SentimentVeryDissatisfiedIcon
                        sx={{ fontSize: `${icon.fontSize.xxlarge} !important` }}
                      />
                    )}
                    title={`Nothing in a ${radiusInMiles} mile${
                      radiusInMiles > 1 ? 's' : ''
                    } radius`}
                    subtitle="Increase the radius to see more results."
                  />
                )}

                {canShowEmptyMessage() && (
                  <AlertFeedback
                    // renderIcon={() => (
                    //   <SentimentVeryDissatisfiedIcon
                    //     sx={{ fontSize: '40px !important' }}
                    //   />
                    // )}
                    title="Come back later"
                    subtitle="All the users needs are taken for now."
                  />
                )}

                {canShowNeeds() && renderTabs()}
              </Box>
            </Box>
          </Box>

          {canShowNeeds() && selectedCategoryNode !== null && (
            <>
              {selectedCategoryNode.parent === null && (
                // <ResultsContainer
                //   needs={needs}
                //   searchedItemTitle=""
                //   sortOption=""
                // />
                <TabViewContainer
                  minAmount={0}
                  maxAmount={-1}
                  searchedItemTitle=""
                  categoryNode={selectedCategoryNode}
                  sortOption=""
                  radiusIndex={radiusIndex}
                  radiusOn={false}
                  biasPoint={biasPoint}
                />
              )}
              {selectedCategoryNode.parent !== null &&
                selectedCategoryNode.parent.children !== null &&
                selectedCategoryNode.parent.children
                  .filter((child) => child === selectedCategoryNode)
                  .map(() => (
                    <TabViewContainer
                      key={`${
                        (selectedCategoryNode.parent as CategoryNode).name
                      }${selectedCategoryNode.name}`}
                      searchedItemTitle=""
                      minAmount={0}
                      maxAmount={-1}
                      categoryNode={selectedCategoryNode}
                      sortOption=""
                      radiusIndex={radiusIndex}
                      radiusOn={false}
                      biasPoint={biasPoint}
                    />
                  ))}
            </>
          )}
        </Box>
      )}
    </PageLayout>
  );
};

export default ExplorePage;
