import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyButton from '../../components/MyButton';
import MyTextField from '../../components/MyTextField';
import Spinner from '../../components/Spinner';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import { SignupData } from '../../types/SignupData';
import { BASE_URL } from '../../constants';
import logger from '../../logger';
import { dialog, colors, icon } from '../../theme/theme';

type FormValues = {
  code: string;
};

type VerifyEmailProps = {
  goNext: () => void;
  goBack: () => void;
};

const VerifyEmail = ({ goNext, goBack }: VerifyEmailProps): JSX.Element => {
  const [email, setEmail] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);

  const { control, formState, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;

  const { post, loading, error, response } = useFetch(BASE_URL);

  function getSignupDataFromCache(): SignupData | null {
    const signupData = localStorage.getItem('signupData');
    if (signupData === null) {
      return null;
    }

    return JSON.parse(signupData);
  }

  function storeSignupDataInCache(data: SignupData) {
    localStorage.setItem('signupData', JSON.stringify(data));
  }

  async function sendSignupData(data: SignupData) {
    await post('/onboarding', data);
    if (response.ok) {
      storeSignupDataInCache(data);

      goNext();
    } else if (response.status === 403) {
      setInvalidCode(true);
    }
  }

  function buildSignupData(): SignupData {
    const signupData = getSignupDataFromCache();
    if (signupData === null) {
      logger.log({
        message: 'Failed to verify email during Signup',
        reason: 'No signup data in localstorage',
      });
      throw new Error('');
    }

    signupData.steps.push({
      name: 'verify_email',
      inputs: getValues(),
    });

    return signupData;
  }

  function getEmail(): string {
    const signupData = getSignupDataFromCache();
    if (signupData === null) {
      return '';
    }
    return signupData.steps.find((step) => step.name === 'create_account')
      ?.inputs.email as string;
  }

  function handleNextClick() {
    const signupData = buildSignupData();
    sendSignupData(signupData);
  }

  // function handleResendClick() {
  //   alert('resending...');
  // }

  function handleBackIconClick() {
    goBack();
  }

  useEffect(() => {
    setEmail(getEmail());
  }, []);

  return (
    <>
      <MyAppBar
        leftSideRender={() => (
          <Box marginLeft="0">
            <AppBarIconWrapper justifyContent="flex-start">
              <MyIconButton onClick={handleBackIconClick}>
                <ArrowBackIosIcon
                  sx={{ fontSize: icon.fontSize.medium, color: icon.color }}
                />
              </MyIconButton>
            </AppBarIconWrapper>
          </Box>
        )}
        middleRender={() => (
          <Typography variant="h5">We sent you a code</Typography>
        )}
        rightSideRender={() => (
          <Box marginRight="0">
            <MyButton
              size="small"
              disabled={!isDirty || !isValid}
              onClick={handleNextClick}
            >
              Next
            </MyButton>
          </Box>
        )}
      />

      {loading && !error && !invalidCode && <Spinner />}

      {!loading && error && !invalidCode && <SomethingWentWrongFeedback />}

      {!loading && (!error || (error && invalidCode)) && (
        <>
          <Box sx={{ padding: dialog.input.padding }}>
            <Typography
              variant="body1"
              sx={{
                color: colors.grey,
              }}
            >
              Enter it below to verify&nbsp;{email}.
            </Typography>
          </Box>
          <Box sx={{ padding: dialog.input.padding }}>
            <Controller
              control={control}
              name="code"
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <MyTextField
                  id="code-input"
                  label="Verification code"
                  error={invalidCode}
                  helperText={
                    invalidCode
                      ? 'The code you provided is not the right one... Please provide the right code'
                      : ''
                  }
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}
            />
            {/* <FormHelperText
              onClick={handleResendClick}
              sx={{
                marginTop: '2px',
                padding: '0 8px',
              }}
            >
              <Box
                component="span"
                sx={{
                  cursor: 'pointer',
                  color: '#1976d2',
                  textDecoration: 'none',
                  fontSize: '13px',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Didn&apos;t receive email? Resend
              </Box>
            </FormHelperText> */}
          </Box>
        </>
      )}
    </>
  );
};

export default VerifyEmail;
