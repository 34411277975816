import { Need } from '../types/Need';

const needs: Need[] = [
  // {
  //   ID: "2849242",
  //   category: 'Electronics > Phones & Accessories > Mobile & Smartphones',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Color Sierra Blue or White, 128GB, Unlocked',
  //   fulfilled: false,
  //   offer: 800,
  //   profile: profilesSample.find((p) => p.firstname === 'Sarah') as Profile,
  //   title: 'Iphone 13',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "6674",
  //   category: 'Fashion & Accessories > Women > Shoes & Boots',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'As new looking, in good condition, size 9',
  //   fulfilled: false,
  //   offer: 400,
  //   profile: profilesSample.find((p) => p.firstname === 'Sarah') as Profile,
  //   title: 'Christian Louboutin Heels',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "4563",
  //   category: 'Fashion & Accessories > Women > Skirts',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'As new looking, in good condition, size 9',
  //   fulfilled: false,
  //   offer: 11,
  //   profile: profilesSample.find((p) => p.firstname === 'Sarah') as Profile,
  //   title: 'John Lewis Skirt',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "9886536723",
  //   category: 'Electronics > Phones & Accessories > Cases, Covers & Skins',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Color Black',
  //   fulfilled: false,
  //   offer: 5,
  //   profile: profilesSample.find((p) => p.firstname === 'Sarah') as Profile,
  //   title: 'Iphone 13 Cover',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "1938141",
  //   category: 'Electronics > Phones & Accessories > Mobile & Smartphones',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Color Black or White, 128GB, Unlocked',
  //   fulfilled: false,
  //   offer: 900,
  //   profile: profilesSample.find((p) => p.firstname === 'James') as Profile,
  //   title: 'Samsung Galaxy S21',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "56747822",
  //   category: 'Electronics > Gaming > Games Consoles',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Color Black or White, 2 Controls(preferably wireless)',
  //   fulfilled: false,
  //   offer: 600,
  //   profile: profilesSample.find((p) => p.firstname === 'James') as Profile,
  //   title: 'Playstation 5',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "66428725",
  //   category: 'Electronics > Gaming > Video Games',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'No scratches',
  //   fulfilled: false,
  //   offer: 30,
  //   profile: profilesSample.find((p) => p.firstname === 'James') as Profile,
  //   title: 'Fifa 22',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "89348141",
  //   category: 'Electronics > Phones & Accessories > Mobile & Smartphones',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Color Black or White, 5GB, Unlocked',
  //   fulfilled: false,
  //   offer: 100,
  //   profile: profilesSample.find((p) => p.firstname === 'Fiona') as Profile,
  //   title: 'Nokia 3',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "245255",
  //   category: 'Electronics > Phones & Accessories > Mobile & Smartphones',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Color Black or White, minimum 128GB, Unlocked',
  //   fulfilled: false,
  //   offer: 750,
  //   profile: profilesSample.find((p) => p.firstname === 'Eric') as Profile,
  //   title: 'Google Pixel 6',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "4254275",
  //   category: 'Electronics > Phones & Accessories > Mobile & Smartphones',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Any color, a lot of storage',
  //   fulfilled: false,
  //   offer: 200,
  //   profile: profilesSample.find((p) => p.firstname === 'Frank') as Profile,
  //   title: 'Iphone',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "4287252",
  //   category: 'Motors',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Any color, no more than 1500 miles, pan roof',
  //   fulfilled: false,
  //   offer: 6000,
  //   profile: profilesSample.find((p) => p.firstname === 'Frank') as Profile,
  //   title: 'Kia sportage 2016',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "425792495",
  //   category: 'Motors',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Color Black, no more than 15000 miles, sunroof',
  //   fulfilled: false,
  //   offer: 5000,
  //   profile: profilesSample.find((p) => p.firstname === 'Herve') as Profile,
  //   title: 'Range Rover Sport 2017',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "4280",
  //   category: 'Electronics > Phones & Accessories > Mobile & Smartphones',
  //   CreatedAt: new Date(),
  //   currency: 'GBP',
  //   description: 'Color jet black',
  //   fulfilled: false,
  //   offer: 200,
  //   profile: profilesSample.find((p) => p.firstname === 'Max') as Profile,
  //   title: 'Iphone 7 plus',
  //   UpdatedAt: new Date(),
  // },
  // {
  //   ID: "247925",
  //   category: 'Toys and Games',
  //   CreatedAt: new Date(),
  //   currency: 'EURO',
  //   description: 'No scratches please',
  //   fulfilled: true,
  //   offer: 50,
  //   profile: profilesSample.find((p) => p.firstname === 'Max') as Profile,
  //   title: 'Fifa 22',
  //   UpdatedAt: new Date(),
  // },
];

export default needs;
