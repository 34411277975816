import React, { Dispatch, useEffect, useState } from 'react';
import useFetch from 'use-http';
import { useDispatch } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router';
import { Profile } from '../../types/Profile';
import { SomethingWentWrongFeedback } from '../../components/Feedback';
import PageLayout from '../../components/PageLayout';
import { Action } from '../../store/types';
import useSecurity from '../../hooks/useSecurity';
import useGeoLocation from '../../hooks/useGeoLocation';
import { BASE_URL } from '../../constants';
import logger from '../../logger';

const OauthCallbackPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const { updateLoggedIn } = useSecurity();
  const [tokenError, setTokenError] = useState(false);

  const {
    params: { email },
  } = useRouteMatch<{ email: string }>();

  const { data = null, error } = useFetch<{
    token: string;
    profile: Profile;
  } | null>(`${BASE_URL}/oauth/success?email=${email}`, {}, [email]);

  const searchParams = new URLSearchParams(useLocation().search);
  const userAction = searchParams.get('action');

  const { countryISOCode } = useGeoLocation();
  const { get: incrementUsersCountMetric } = useFetch(
    `${BASE_URL}/metrics2/users_count/increment?countryISOCode=${countryISOCode}`,
  );

  useEffect(() => {
    if (data !== null) {
      if (data.token && data.profile) {
        dispatch({
          type: 'SAVE_PROFILE',
          payload: data,
        });
        if (updateLoggedIn) {
          updateLoggedIn(true);
        }
        localStorage.setItem('token', data.token);
        localStorage.setItem('profile', JSON.stringify(data.profile));
        localStorage.setItem('isLoggedIn', 'true');

        if (userAction === 'signup') {
          incrementUsersCountMetric();
        }

        window.location.assign('/');
      } else {
        setTokenError(true);
        logger.log({
          message: 'Failed to handle jwt-token or profile after login',
        }); // eslint-disable-line
      }
    }
  }, [data]);

  if (error || tokenError) {
    return (
      <PageLayout>
        <SomethingWentWrongFeedback />
      </PageLayout>
    );
  }

  return <div />;
};

export default OauthCallbackPage;
