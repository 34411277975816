import React, { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import MyIconButton from '../../components/MyIconButton';
import MyTextField from '../../components/MyTextField';
import { colors } from '../../theme/theme';

type InputFieldProps = {
  onSend: (text: string) => void;
  onTyping: (typing: boolean) => void;
};

const InputField = ({ onSend, onTyping }: InputFieldProps): JSX.Element => {
  const [sendIconDisabled, setSendIconDisabled] = useState(true);

  const { control, formState, getValues, setValue } = useForm<{ text: string }>(
    {
      mode: 'onChange',
    },
  );
  const { errors, isValid, isDirty } = formState;

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  function restartTimeout(callback: () => void) {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(callback, 4000);
  }

  const handleChange = () => {
    onTyping(true);
    restartTimeout(() => onTyping(false));
  };

  const submit = () => {
    const { text } = getValues();

    if (text !== '') {
      onTyping(false);
      onSend(text);
      setValue('text', '', {
        shouldValidate: true,
        shouldDirty: true,
      });
      setSendIconDisabled(true);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    submit();
  };

  const handleSendIconClick = () => {
    submit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems="center">
        <Grid item flexGrow={1}>
          <Controller
            control={control}
            rules={{
              maxLength: {
                value: 140,
                message: 'Too many characters... Limit is 140',
              },
            }}
            name="text"
            defaultValue=""
            render={({ field }) => (
              <MyTextField
                id="chat-text"
                label="Aa"
                multiline
                error={!!errors.text}
                helperText={errors.text && errors.text.message}
                {...field} // eslint-disable-line react/jsx-props-no-spreading
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  if (!value) {
                    if (!sendIconDisabled) {
                      setSendIconDisabled(true);
                    }
                  } else if (sendIconDisabled) {
                    setSendIconDisabled(false);
                  }

                  setValue('text', value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });

                  handleChange();
                }}
              />
            )}
          />
        </Grid>
        <Grid item sx={{ marginLeft: '4px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '34px',
              height: '34px',
              opacity: !isDirty || !isValid || sendIconDisabled ? '0.5' : '1',
            }}
          >
            <MyIconButton
              onClick={handleSendIconClick}
              disabled={!isDirty || !isValid || sendIconDisabled}
            >
              <SendIcon sx={{ fontSize: '20px', color: colors.primary }} />
            </MyIconButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default InputField;
