import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import {
  PageTitle,
  ParagraphTitle1,
  Paragraph,
} from '../PrivacyPolicyPage/PrivacyPolicyPage';
import PageLayout from '../../components/PageLayout';

const ContactPage = (): JSX.Element => (
  <PageLayout>
    <Helmet>
      <title>Contact us | Bonvih</title>
    </Helmet>
    <Box padding="0 16px">
      <PageTitle>Contact us</PageTitle>
      <ParagraphTitle1>Bonvih - the pleasure of spending</ParagraphTitle1>
      <Paragraph>
        With Bonvih it’s simpler than ever to spend your money wisely
      </Paragraph>
      <ParagraphTitle1>Got a question or suggestion?</ParagraphTitle1>
      <Paragraph>
        You&apos;ve come to the right place. You can get in touch with us via
        email at <a href="mailto:support@bonvih.com">support@bonvih.com</a>.
      </Paragraph>
      <ParagraphTitle1>Official data</ParagraphTitle1>
      <Paragraph>BONVIH LTD</Paragraph>
      <Paragraph>Unit A 82 James Carter Road Mildenhall</Paragraph>
      <Paragraph>IP28 7DE</Paragraph>
      <Paragraph>Suffolk, United Kingdom</Paragraph>
      <Paragraph>
        <a href="https://www.bonvih.com">www.bonvih.com</a>
      </Paragraph>
      <Paragraph>VAT-Number: 13380728</Paragraph>
      <Paragraph>Business purpose: social media</Paragraph>
      <Paragraph>CEO: Hervé Tounda</Paragraph>
    </Box>
  </PageLayout>
);

export default ContactPage;
