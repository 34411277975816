import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { Need } from '../../types/Need';

type HashtagProps = {
  need: Need;
};

const Hashtag = ({ need }: HashtagProps): JSX.Element => {
  const theme = useTheme();

  const handleSubmit: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    window.location.replace(
      `/search?minAmount=0&maxAmount=&title=${need.title}&category=&orderBy=`,
    );
  };

  return (
    <Typography
      variant="body1"
      variantMapping={{ body1: 'span' }}
      sx={{
        display: 'inline-block',
        fontSize: 'inherit !important',
        fontWeight: 'normal !important',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'inherit !important',
      }}
      onClick={handleSubmit}
    >
      #{need.title}
    </Typography>
  );
};

export default Hashtag;
