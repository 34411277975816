import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { Profile } from '../../../types/Profile';
import { Message } from '../../../types/Message';
import { BASE_URL } from '../../../constants';
import Chats from './Chats';

type ChatsContainerProps = {
  profile: Profile;
};

const ChatsContainer = ({ profile }: ChatsContainerProps): JSX.Element => {
  const [messages, setMessages] = useState<Message[] | null>(null);
  const [reload, setReload] = useState(false);

  const { get, loading, error, response } = useFetch(BASE_URL);

  async function getChats() {
    const data = await get(`/profiles/${profile.ID}/chats`);
    if (response.ok) {
      setMessages(data);
    }
  }

  useEffect(() => {
    getChats();
  }, [reload]);

  function reloadChats() {
    setReload(!reload);
  }

  return (
    <Chats
      messages={messages}
      loading={loading}
      error={Boolean(error)}
      profile={profile}
      reloadChats={reloadChats}
    />
  );
};

export default ChatsContainer;
