import React from 'react';
import Masonry from 'react-masonry-css';
import { Box } from '@mui/system';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from '../../components/Spinner';
import useColumnCount from '../../hooks/useColumnCount';
import { borderRadius, card } from '../../theme/theme';

interface ResultsProps<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  more: boolean;
  handleFetchMore?: () => void;
}

function Results<T>({
  items,
  renderItem,
  more,
  handleFetchMore,
}: ResultsProps<T>): JSX.Element {
  const columnCount = useColumnCount();

  return (
    <Box
      sx={
        {
          // marginTop: '-1px',
          // marginTop: disableTopMargin ? '0px' : '12px',
          // minHeight: '100vh',
          // borderRadius: '4px',
          // border: '1px solid rgba(0,0,0,0.12)',
          // '& .my-masonry-grid': {
          //   marginLeft: '-12px',
          // },
          // '& .my-masonry-grid_column': {
          //   paddingLeft: '12px',
          // },
          // '& .my-masonry-grid_column:not(:nth-of-type(1))': {
          //   marginLeft: '-1px',
          // },
          // '& .my-masonry-grid_column > div': {
          //   marginBottom: '12px',
          // },
          // '& .my-masonry-grid_column > div:not(:nth-of-type(1))': {
          //   marginTop: '-1px',
          // },
        }
      }
    >
      <Masonry
        breakpointCols={columnCount}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <InfiniteScroll
          dataLength={items.length}
          next={() => {
            if (handleFetchMore) {
              handleFetchMore();
            }
          }}
          hasMore={more}
          loader={<Spinner />}
          endMessage={<div />}
        >
          {items.map((item, idx) => (
            <Box key={(item as unknown as { ID: string }).ID}>
              <Box
                sx={{
                  background: '#FFFFFF',
                  borderRadius,
                  borderBottom:
                    idx === items.length - 1
                      ? 'unset'
                      : `1px solid ${card.border.color}`,
                  '& > a': {
                    textDecoration: 'none',
                  },
                  '&:not(:nth-of-type(1))': {
                    // marginTop: '-1px',
                  },
                }}
              >
                {renderItem(item)}
              </Box>
            </Box>
          ))}
        </InfiniteScroll>
      </Masonry>
    </Box>
  );
}

Results.defaultProps = {
  handleFetchMore: undefined,
};

export default Results;
