import { S3, PutObjectCommand } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import {
  AWS_S3_REGION,
  AWS_S3_ITEM_PHOTOS_BUCKET_NAME,
  AWS_COGNITO_REGION,
  AWS_COGNITO_IDENTITY_POOL_ID,
} from '../constants';

const s3Client = new S3({
  region: AWS_S3_REGION,
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: AWS_COGNITO_REGION },
    identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID as string,
  }),
});

const CLOUDFRONT_DOMAIN_NAME = 'd1g5jn050vwlge.cloudfront.net';

export function convertS3ToCloudfrontURL(photoS3Url: string): string {
  const key = photoS3Url.split('.com/')[1];
  return `https://${CLOUDFRONT_DOMAIN_NAME}/${key}`;
}

export function addPhotoDimensionsToCloudfrontURL(
  width: string,
  height: string,
  photoCloudfrontURL: string,
): string {
  const key = photoCloudfrontURL.split('.net/')[1];
  return `https://${CLOUDFRONT_DOMAIN_NAME}/${width}x${height}/${key}`;
}

export function addSizeToItemPhotoUrl(photoUrl: string, size: string): string {
  const splitted = photoUrl.split('.net/');
  return `${splitted[0]}.net/${size}/${splitted[1]}`;
}

export function convertS3UrlToS3WebsiteUrl(url: string): string {
  if (!url.includes('s3-website')) {
    return url.replace('s3', 's3-website');
  }

  return url;
}

function makeItemPhotoObjectUrl(
  bucketName: string,
  region: string,
  key: string,
): string {
  return `http://${encodeURIComponent(bucketName)}.s3.${encodeURIComponent(
    region,
  )}.amazonaws.com/${encodeURIComponent(key)}`;
}

export async function storeItemPhoto(file: File): Promise<string> {
  const Key = `${uuidv4()}.jpg`;

  const uploadParams = {
    Bucket: AWS_S3_ITEM_PHOTOS_BUCKET_NAME,
    Key: encodeURIComponent(Key),
    Body: file,
  };

  await s3Client.send(new PutObjectCommand(uploadParams));

  return makeItemPhotoObjectUrl(
    AWS_S3_ITEM_PHOTOS_BUCKET_NAME as string,
    AWS_S3_REGION as string,
    Key,
  );
}

export default {};
