import { io, Socket } from 'socket.io-client';
import { CHAT_SERVER_URL } from '../constants';

const token = localStorage.getItem('token');

function getServerSocket(): Socket {
  return io(CHAT_SERVER_URL, {
    auth: {
      token,
    },
  });
}

export default getServerSocket;
