import React from 'react';
import { Box } from '@mui/system';
import MyList from '../../../components/MyList';
import Spinner from '../../../components/Spinner';
import Feedback, {
  SomethingWentWrongFeedback,
} from '../../../components/Feedback';
import { Profile } from '../../../types/Profile';
import { Message } from '../../../types/Message';
import { card } from '../../../theme/theme';
import ChatItem from './ChatItem';

type ItemsProps = {
  messages: Message[] | null;
  loading: boolean;
  error: boolean;
  profile: Profile;
  reloadChats: () => void;
};

const Items = ({
  messages,
  loading,
  error,
  profile,
  reloadChats,
}: ItemsProps): JSX.Element => {
  if (loading) return <Spinner />;

  if (error) return <SomethingWentWrongFeedback />;

  if (messages === null) return <Spinner />;

  if (messages.length === 0) {
    return (
      <Feedback
        title="Nothing to see here yet"
        subtitle="When you send or receive a chat message, it will show up here."
      />
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <MyList>
        {(messages as Message[]).map((message, idx) => (
          <Box
            key={message.proposition.ID}
            sx={{
              borderBottom:
                idx === messages.length - 1
                  ? 'unset'
                  : `1px solid ${card.border.color}`,
            }}
          >
            <ChatItem
              message={message}
              profile={profile}
              reloadChats={reloadChats}
            />
          </Box>
        ))}
      </MyList>
    </Box>
  );
};

export default Items;
