import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MyAppBar from '../../components/MyAppBar';
import AppBarIconWrapper from '../../components/AppBarIconWrapper';
import MyIconButton from '../../components/MyIconButton';
import MyDialog from '../../components/MyDialog';
import MyCloseIcon from '../../components/MyCloseIcon';
import MyList, {
  MyCategoryListItem,
  MyListItemText,
  MyListItemAvatar,
  MyListItemButton,
} from '../../components/MyList';
import { CategoryNode } from '../../utils/categories';
import { icon, divider } from '../../theme/theme';
import renderCategoryIcon from './renderCategoryIcon';

type CategoriesDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedCategoryNode: CategoryNode | null;
  currentCategoryNode: CategoryNode | null;
  handleSelectedCategoryNodeChange: (selected: CategoryNode) => void;
  handleCurrentCategoryNodeChange: (newCurrent: CategoryNode) => void;
};

const CategoriesDialog = ({
  open,
  onClose,
  selectedCategoryNode,
  handleSelectedCategoryNodeChange,
  currentCategoryNode,
  handleCurrentCategoryNodeChange,
}: CategoriesDialogProps): JSX.Element => {
  function handleCloseIconClick() {
    onClose();
  }

  function handleBackBtnClick() {
    if (currentCategoryNode !== null) {
      if (currentCategoryNode.parent !== null) {
        handleCurrentCategoryNodeChange(currentCategoryNode.parent);
      }
    }
  }

  function buildListItemClickHandler(node: CategoryNode) {
    return function handleListItemClick() {
      if (node.children === null) {
        handleSelectedCategoryNodeChange(node);
        handleCurrentCategoryNodeChange(node);
      } else {
        handleCurrentCategoryNodeChange(node);
      }
    };
  }

  function renderNode(node: CategoryNode) {
    return (
      <Box position="relative">
        {node.name !== '' && (
          <>
            <MyList>
              <MyCategoryListItem>
                {node.parent !== null && (
                  <MyListItemAvatar>
                    <MyIconButton
                      sx={{ cursor: 'pointer' }}
                      onClick={handleBackBtnClick}
                    >
                      <ArrowBackIosIcon
                        sx={{
                          fontSize: icon.fontSize.small,
                          color: icon.colorLight,
                          paddingLeft: '8px',
                        }}
                      />
                    </MyIconButton>
                  </MyListItemAvatar>
                )}
                <MyListItemText>
                  <Box sx={{ padding: '1px 0' }}>{node.name}</Box>
                </MyListItemText>
              </MyCategoryListItem>
            </MyList>
            <Divider sx={{ borderColor: divider.color }} />
          </>
        )}
        <MyList>
          {(node.children as CategoryNode[]).map((child) => (
            <MyListItemButton
              key={child.name}
              selected={child === selectedCategoryNode}
              onClick={buildListItemClickHandler(child)}
            >
              <MyCategoryListItem
                secondaryAction={
                  child.children !== null ? (
                    <ArrowForwardIosIcon
                      sx={{
                        fontSize: icon.fontSize.small,
                        color: icon.colorLight,
                      }}
                    />
                  ) : (
                    <div />
                  )
                }
              >
                <MyListItemAvatar>
                  {child.parent !== null && child.parent.name === '' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '34px',
                        height: '34px',
                      }}
                    >
                      {renderCategoryIcon(child.name)}
                    </Box>
                  ) : (
                    <MyIconButton>
                      <Box
                        sx={{
                          width: '20px',
                          height: '28px',
                        }}
                      />
                    </MyIconButton>
                  )}
                </MyListItemAvatar>
                <MyListItemText>
                  <Box fontWeight="normal !important" sx={{ padding: '1px 0' }}>
                    {child.name}
                  </Box>
                </MyListItemText>
              </MyCategoryListItem>
            </MyListItemButton>
          ))}
        </MyList>
      </Box>
    );
  }

  return (
    <MyDialog open={open} onClose={onClose}>
      <MyAppBar
        leftSideRender={() => <div />}
        middleRender={() => <Typography variant="h5">Categories</Typography>}
        rightSideRender={() => (
          <AppBarIconWrapper justifyContent="flex-end">
            <MyIconButton
              sx={{ marginRight: '-8px' }}
              onClick={handleCloseIconClick}
            >
              <MyCloseIcon />
            </MyIconButton>
          </AppBarIconWrapper>
        )}
      />

      {currentCategoryNode !== null && (
        <>
          <Box marginTop="20px" />
          {currentCategoryNode.children === null &&
            renderNode(currentCategoryNode.parent as CategoryNode)}

          {currentCategoryNode.children !== null &&
            renderNode(currentCategoryNode)}
        </>
      )}
    </MyDialog>
  );
};

export default CategoriesDialog;
