import React from 'react';
import { Box } from '@mui/system';
import Feedback, { SomethingWentWrongFeedback } from '../Feedback';
import Spinner from '../Spinner';
import WhatsHappeningCard from '../MyCards/WhatsHappeningCard';
import { Need } from '../../types/Need';

type WhatsHappeningProps = {
  needs: Need[] | null;
  loading: boolean;
  error: boolean;
};

const WhatsHappening = ({
  needs,
  loading,
  error,
}: WhatsHappeningProps): JSX.Element => {
  const canShowSpinner = () =>
    loading || (!loading && !error && needs === null);
  const canShowError = () => error;
  const canShowNoWhatsHappening = () => needs !== null && needs.length === 0;
  const canShowWhatsHappening = () =>
    !loading && !error && needs !== null && needs.length > 0;

  return (
    <Box>
      {canShowSpinner() && <Spinner />}

      {canShowError() && <SomethingWentWrongFeedback />}

      {canShowNoWhatsHappening() && (
        <Feedback
          title="Nothing is happening — yet"
          subtitle="When something is, it will show up here."
        />
      )}

      {canShowWhatsHappening() &&
        (needs as Need[])
          .filter((n, i) => i <= 5)
          .map((need) => (
            <Box key={need.ID}>
              <WhatsHappeningCard need={need} />
            </Box>
          ))}
    </Box>
  );
};

export default WhatsHappening;
